import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../common/CustomPagination";
import EmptyBox from "../../common/EmptyBox";
import { flattenData } from "./config";
import OtherReportsToolbar from "./toolbar";

export default function OtherReports({ CommByCustProdService }) {
  const {
    displayName = "",
    stopRemount = { current: false },
    columns = [],
    noTableDataMsg = "",
    tableData = {},
    reportingTo = [],
    menu = [],
    handleGetMyReportee = () => {},
    handleGetMyDetailsApplyClick = () => {},
    handleGetMyDetails = () => {},
    isTableLoading = false,
    selectedDropdown = "",
    setSelectedDropdown = () => {},
  } = CommByCustProdService();

  let sno = 1; // Initialize serial number counter
  const rows = flattenData(tableData, sno) || []; // Ensure rows is always an array

  return (
    <>
      <Box>
        {Array.isArray(columns) && columns.length > 0 ? (
          <DataGrid
            columns={columns}
            rows={rows}
            slots={{
              noRowsOverlay: () => <EmptyBox message={noTableDataMsg} />,
              toolbar: () => (
                <OtherReportsToolbar
                  stopRemount={stopRemount}
                  reportingTo={reportingTo}
                  displayName={displayName}
                  menu={menu}
                  handleGetMyDetailsApplyClick={handleGetMyDetailsApplyClick}
                  handleGetMyDetails={handleGetMyDetails}
                  handleGetMyReportee={handleGetMyReportee}
                  selectedDropdown={selectedDropdown}
                  setSelectedDropdown={setSelectedDropdown}
                />
              ),
              pagination: CustomPagination, // Custom pagination component
            }}
            disableRowSelectionOnClick
            autoHeight={Object.keys(rows).length >= 10}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            pageSizeOptions={[10, 25, 50]}
            loading={isTableLoading}
            density="compact"
            sx={{
              p: 1,
              height: 530,
              // removing the cell border
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        ) : null}
      </Box>
    </>
  );
}
