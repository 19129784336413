export const fields = [
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Email Address",
    name: "email",
    type: "email",
    required: true,
  },
  {
    label: "Organization Name",
    name: "organizationName",
    type: "text",
    required: true,
  },
  {
    label: "Contact Number",
    name: "phone",
    type: "tel",
    required: true,
  },
  {
    label: "Time Zone",
    name: "timeZone",
    required: true,
  },
  {
    label: "Subscription Plan",
    name: "subscriptionPlan",
    required: true,
  },
];
