// Define a function to get the IP address (for demonstration purposes; replace with actual implementation)
const getIpAddress = async () => {
  try {
    const response = await fetch("https://api64.ipify.org/?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return "";
  }
};

// Helper function to fetch conversion rate from Frankfurter API
const getCurrencyConversionRate = async (fromCurrency, toCurrency) => {
  const url = `https://api.exchangerate-api.com/v4/latest/${fromCurrency}`;

  try {
    const response = await fetch(url);

    // Check if the response status is OK
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    if (data?.rates?.[toCurrency]) {
      return data.rates[toCurrency];
    } else {
      throw new Error("Conversion rate not found in response data");
    }
  } catch (error) {
    console.error("Failed to fetch conversion rate:", error);
    return null; // Return null to allow the UI to handle it gracefully
  }
};

const PrivateApiService = {
  getIpAddress,
  getCurrencyConversionRate,
};

export default PrivateApiService;
