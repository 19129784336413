import { Box } from "@mui/material";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";

export default function CustomerColumns({
  actionsColumn = null,
  dynamicColumns = [],
  isErrorColor = false,
  handleEdit = () => {},
}) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "customerId",
      headerName: "Customer ID",
      width: 200,
      renderCell: ({ row }) => {
        // Check if the row exists to prevent errors
        if (!row) {
          return <Box sx={{ width: "100%", height: "100%" }} />; // Empty box ensures UI layout is maintained
        }
        return (
          <Box
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            title={row?.customerId || ""} // Add the title attribute for the tooltip
            onClick={() => {
              try {
                // Ensure row.id exists before calling handleEdit
                if (row?.id) {
                  handleEdit(row?.id, true)(); // Call handleEdit with row ID
                }
              } catch (error) {
                console.error("Error occurred during edit:", error);
              }
            }}
          >
            {row?.customerId || ""}
          </Box>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "renewalDate",
      headerName: "Renewal Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "fullCustomerName",
      headerName: "Full Cust Name",
      width: 170,
    },
    {
      field: "customerLevel",
      headerName: "Cust Level",
      width: 100,
    },
    {
      field: "subCustomerOf",
      headerName: "Sub Cust Of",
      width: 100,
    },
    {
      field: "relationshipType",
      headerName: "Relationship Type",
      width: 200,
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      width: 100,
    },
    {
      field: "terms",
      headerName: "Terms",
      width: 300,
    },
    {
      field: "active",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
    },
    // Conditionally display the description column based on isErrorColor
    ...(isErrorColor
      ? [
          {
            field: "description",
            headerName: "Error Description",
            width: 200,
          },
        ]
      : []),
    // Only spread dynamicColumns if it's actually an array (just being cautious!)
    ...(Array.isArray(dynamicColumns) ? dynamicColumns : []),
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
