import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Global error handler for synchronous errors
window.onerror = (message, source, lineno, colno, error) => {
  console.error("Global error caught:", {
    message,
    source,
    lineno,
    colno,
    error,
  });

  // Redirect to the custom error page if the error message contains "Uncaught Error" or "Timeout"
  if (message.includes("Uncaught Error") || message.includes("Timeout")) {
    window.location.href = "/uncaught_error"; // Redirect to a custom error page
  }
};

// Global unhandled promise rejection handler for asynchronous errors
window.addEventListener("unhandledrejection", (event) => {
  console.error("Unhandled promise rejection event:", event);
  console.error("Unhandled promise rejection reason:", event.reason);
  console.error(
    "Unhandled promise rejection includes:",
    event?.reason?.includes("Timeout")
  );

  // Check if the rejection reason is a timeout
  if (event?.reason?.includes("Timeout")) {
    window.location.href = "/uncaught_error"; // Redirect to a timeout error page
  }

  console.error(
    "Unhandled promise rejection message:",
    event?.reason?.message?.includes("Timeout")
  );
});

root.render(
  <>
    <ErrorBoundary>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ErrorBoundary>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
