import { useEffect, useRef } from "react";

function useFetchOnMount(fetchData, interval = null) {
  const stopRemount = useRef(true);

  useEffect(() => {
    // Fetch data on initial mount
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }

    // If an interval is provided, set up repeated fetching
    if (interval) {
      const fetchInterval = setInterval(fetchData, interval);

      // Cleanup: clear the interval when component unmounts
      return () => clearInterval(fetchInterval);
    }
  }, [fetchData, interval]); // Adding interval to dependencies ensures it re-runs if interval changes
}

export default useFetchOnMount;
