export const FormFields = [
  {
    name: "screenName",
    label: "Screen Name",
    type: "text",
    required: true,
  },
  {
    name: "errorTime",
    label: "Error Time",
    type: "datetime-local",
    shrink: true,
    required: true,
  },
  {
    name: "status",
    label: "Status",
    type: "text",
  },
  {
    name: "assignedTo",
    label: "Assigned To",
    type: "text",
    required: true,
  },
  {
    isEditor: true,
    isSubtitle: true,
    subtitle: "Description:",
  },
  {
    name: "conversation",
    type: "textarea",
    multiline: true,
    placeholder: "Please provide clarification here...",
    rows: 7,
    required: false,
  },
  {
    isHistory: true,
    isSubtitle: true,
  },
];
