import { Box } from "@mui/material";

export const PlanColumns = ({
  actionsColumn = null,
  handleEdit = () => {},
}) => {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "title",
      headerName: "Plan Name",
      align: "center",
      headerAlign: "center",
      width: 130,
      renderCell: ({ row }) => {
        // Check if the row exists to prevent errors
        if (!row) {
          return <Box sx={{ width: "100%", height: "100%" }} />; // Empty box ensures UI layout is maintained
        }
        return (
          <Box
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            title={row?.title || ""} // Add the title attribute for the tooltip
            onClick={() => {
              try {
                // Ensure row.id exists before calling handleEdit
                if (row?.id) {
                  handleEdit(row?.id, true)(); // Call handleEdit with row ID
                }
              } catch (error) {
                console.error("Error occurred during edit:", error);
              }
            }}
          >
            {row?.title || ""}
          </Box>
        );
      },
    },
    {
      field: "price",
      headerName: "Cost/user",
      align: "right",
      headerAlign: "right",
      width: 130,
    },
    {
      field: "description",
      headerName: "Description",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "features",
      headerName: "Features",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "subscriptionPeriods",
      headerName: "Subscription Periods",
      align: "center",
      headerAlign: "center",
      width: 200,
      valueGetter: (params) => {
        const { subscriptionPeriods } = params.row;
        return subscriptionPeriods
          .map(
            (period) =>
              // `Duration: ${period.duration} months, Discount: ${
              //   period.discountPercentage
              // }%, Price: ${period.actualAmount.toFixed(2)}` // use this code later price changes format
              `Duration: ${period.duration} months, Discount: ${period.discountPercentage}%, Price: ${period.actualAmount}`
          )
          .join("; ");
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
};
