import { useMemo, useState } from "react";
import axiosClient from "../../../apis/Api";
import ApiService from "../../../apis/ApiService";
import { JWT_TOKEN, ROLE_ACCESS } from "../../config/sessionStorage";
import { addSerialNumber } from "../../config/fieldConfig";
import useFetchOnMount from "../../config/useFetchOnMount";
import {
  COMMISSION_DISPLAY_NAME,
  COMMISSION_SCREEN,
  CUSTOMER_SCREEN,
  PRODUCT_SCREEN,
  TRANSACTION_SCREEN,
  VARIABLE_SCREEN,
} from "../../config/constants";
import { CalculationFields, CheckBoxFields, ConditionFields } from "./fields";
import useSubmitForm from "../../../utils/useSubmitForm";
import { getActionsColumn } from "../../../utils/actionColumn";
import CommissionColumns, {
  RoleUpColumn,
  SplitColumn,
  TieredColumn,
} from "./columns";
import toast from "react-hot-toast";

export const CommissionService = () => {
  const [token, setToken] = useState("");
  const [variableNames, setVariableNames] = useState([]);
  const [selectFrequencyOption, setSelectFrequencyOption] = useState([]);
  const [frequencyOptionsWithId, setFrequencyOptionsWithId] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectFrequency, setSelectFrequency] = useState("");
  const [commissionConfigNames, setCommissionConfigNames] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [productEntity, setProductEntity] = useState([]);
  const [customerEntity, setCustomerEntity] = useState([]);
  const [transactionEntity, setTransactionEntity] = useState([]);

  const [productNames, setProductNames] = useState([]);
  const [fullProductNames, setFullProductNames] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [viewData, setViewData] = useState(true); // view purpose only
  const [loading, setLoading] = useState(true); // Initial loading state is true

  const conditionEntityNames = useMemo(
    () => [PRODUCT_SCREEN, CUSTOMER_SCREEN, TRANSACTION_SCREEN],
    []
  );

  const calcEntityNames = useMemo(
    () => [
      PRODUCT_SCREEN,
      CUSTOMER_SCREEN,
      TRANSACTION_SCREEN,
      VARIABLE_SCREEN,
      "Assign",
    ],
    []
  );

  //condition
  const symbols = [
    "*",
    "+",
    "==",
    "/",
    "%",
    "-",
    ">",
    "<",
    "<=",
    ">=",
    "!=",
    "IN",
    "NOT IN",
  ];
  const conditionOption = ["||", "&&", "NOT"];
  const braces = ["", "(", ")"];
  const percentageOption = ["", "%"];
  const [row, setRow] = useState([
    {
      conDescription: "",
      startBrace: braces[0],
      conditionEntityName: conditionEntityNames[0],
      conditionOption: conditionOption[0],
      field: "",
      symbols: symbols[0],
      endBrace: braces[0],
    },
  ]);

  //calculation
  const calculationData = [];
  const [calcRow, setCalcRow] = useState([
    {
      calcDescription: "",
      calcEntityName: calcEntityNames[0],
      calcOption: "",
      calcField: "",
      calcSymbols: symbols[0],
      percentage: percentageOption[0],
    },
  ]);

  //checkBox model
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({});

  // Checkbox
  const [roleUpChecked, setRoleUpChecked] = useState(false);
  const [splitChecked, setSplitChecked] = useState(false);
  const [tieredChecked, setTieredChecked] = useState(false);
  const [cumulativeValue, setCumulativeValue] = useState(false);

  const roleAccess = ROLE_ACCESS();
  const TOKEN = JWT_TOKEN();
  // Constants for screen identifier
  const screenName = COMMISSION_SCREEN || "";
  // Constants for display screen name UI identifier
  const displayName = COMMISSION_DISPLAY_NAME || "";

  const fetchData = async () => {
    try {
      // If token is not available, throw an error
      if (!TOKEN) throw new Error("Token not found or invalid");
      setToken(TOKEN);

      const getAllCommission = await ApiService.getCommissions(TOKEN);

      const formateCommission = getAllCommission.map((item) => ({
        ...item,
        credit: `${item.credit.crediting}: ${item.credit.portion}`,
      }));

      const getAllPaymentFrequency = await ApiService.getPaymentFrequencies(
        TOKEN
      );

      const frequencyData = await ApiService.getFrequencies(TOKEN);
      // Map over transferData and replace employeeType ID with typeValue
      const TransferData = getAllPaymentFrequency?.data.map((transfer) => {
        // Find the corresponding employeeTypeData entry
        const matchedfrequency = frequencyData?.data.find(
          (frequency) => frequency.id === parseInt(transfer.frequency) // Ensure both are numbers
        );

        // Return a new object with the updated employeeType
        return {
          ...transfer,
          frequency: matchedfrequency
            ? matchedfrequency.frequency
            : transfer.frequency, // Use typeValue or fallback to original
        };
      });

      const extractedData = TransferData.map((item) => item.frequency);

      const filteredFrequencyOption = extractedData.filter(
        (option) => option !== null
      );

      validateArray(filteredFrequencyOption, "FilteredFrequencyOption");
      setSelectFrequencyOption(filteredFrequencyOption);

      const optionsWithId = TransferData.map((frequency) => ({
        frequency: frequency.frequency,
        id: frequency.id,
      }));
      validateArray(optionsWithId, "OptionsWithId");

      setFrequencyOptionsWithId(optionsWithId);

      // Map over transferData and replace employeeType ID with typeValue
      const updatedTransferData = formateCommission.map((transfer) => {
        // Find the corresponding employeeTypeData entry
        const matchedfrequency = getAllPaymentFrequency?.data.find(
          (frequency) => frequency.id === parseInt(transfer.selectFrequencyId) // Ensure both are numbers
        );

        // Return a new object with the updated employeeType
        return {
          ...transfer,
          selectFrequency: matchedfrequency
            ? matchedfrequency.frequency
            : transfer.selectFrequency,
          // Use typeValue or fallback to original
        };
      });
      validateArray(updatedTransferData, "UpdatedTransferData");

      setTableData(updatedTransferData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const formApiCall = async () => {
    try {
      const getAllVariable = await ApiService.getVariables(token);

      const variableField = getAllVariable?.data.map((item) => item.variable);
      validateArray(variableField, "VariableField");
      setVariableNames(variableField);

      const newData = [];
      for (let i = 0; i < conditionEntityNames.length; i++) {
        try {
          const lastField_Response = await axiosClient.get(
            "/commission/getAttributesFromPojo",
            {
              headers: {
                entityName: conditionEntityNames[i],
                Authorization: token,
              },
            }
          );
          newData.push(lastField_Response.data);
        } catch (error) {
          console.error(
            `Error fetching data for entity ${conditionEntityNames[i]}:`,
            error
          );
        }
      }

      validateArray(newData[0], "ProductEntity");
      validateArray(newData[1], "CustomerEntity");
      validateArray(newData[2], "TransactionEntity");

      setProductEntity(newData[0]);
      setCustomerEntity(newData[1]);
      setTransactionEntity(newData[2]);

      const allProducts = await ApiService.getProducts(token);
      const getAllProducts = allProducts?.data;

      // Extracting 'productName' property from each object
      const productName = getAllProducts?.map((product) => product.productName);
      // Extracting 'fullProductName' property from each object
      const fullProductName = getAllProducts.map(
        (product) => product.fullProductName
      );

      validateArray(productName, "ProductName");
      validateArray(fullProductName, "FullProductName");

      setProductNames(productName);
      setFullProductNames(fullProductName);

      const getCustomers = await ApiService.getCustomers(token);
      // Extracting 'customerName' property from each object
      const customerName = getCustomers?.data?.map(
        (customer) => customer.customerName
      );
      validateArray(customerName, "CustomerName");
      setCustomerNames(customerName);
      const getLastPaymentFrequency = await ApiService.getLastPaymentFrequency(
        token
      );
      validateArray(getLastPaymentFrequency, "GetLastPaymentFrequency");
      setSelectFrequency(getLastPaymentFrequency[0]);

      const getAllCommissionNames = await ApiService.getCommissionNames(token);
      validateArray(getAllCommissionNames, "GetAllCommissionNames");
      setCommissionConfigNames(getAllCommissionNames);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Utility function to validate arrays
  const validateArray = (array, field) => {
    if (!Array.isArray(array)) {
      throw new Error(` ${field} not found or invalid`);
    }
  };

  const handleEdit = (id, viewData) => async () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }

      // Check if data is an array and has elements
      if (!Array.isArray(tableData) || tableData?.length === 0) {
        throw new Error("Data is not available or empty");
      }

      // Find the row data based on the provided ID
      const rowData = tableData.find((row) => row.id === id); // Find the row data based on the id

      // Check if row data is found
      if (!rowData || typeof rowData !== "object" || Array.isArray(rowData)) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      const getAllAssignCommissions =
        await ApiService.getAssignedCommWithHierarchy(token);

      // Function to map commission IDs to their respective names
      const mapIDsToNames = (ids, data) =>
        ids.map(
          (id) => data.find((item) => item.id === id)?.commissionName || null
        );

      // Transform data
      const transformedData = Array.isArray(getAllAssignCommissions?.data)
        ? getAllAssignCommissions?.data?.map((item) => ({
            commission: mapIDsToNames(item.commission, tableData),
          }))
        : [];

      // Step 1: Extract all commission arrays into a single array
      const allCommissions = transformedData.reduce((acc, obj) => {
        acc.push(...obj.commission);
        return acc;
      }, []);

      // Step 2: Remove duplicates using a Set
      const uniqueCommissions = [...new Set(allCommissions)];

      if (uniqueCommissions.some((name) => name === rowData.commissionName)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setEditItemId(id);
      setEditFormOpen(true);
      setRoleData("");
      setSplitData("");
      setTieredData([]);
      setRoleUpChecked(false);
      setSplitChecked(false);
      setTieredChecked(false);
      setEditFormData(rowData); // Set edit form data
      setViewData(viewData);
      formApiCall();
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData,
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Create column configuration by combining actual columns with action columns
  const columns = Array.isArray(CommissionColumns(actionsColumn, handleEdit))
    ? CommissionColumns(actionsColumn, handleEdit)
    : []; // Default to empty array if not an array

  // Add serial numbers to data rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(tableData))
    ? addSerialNumber(tableData)
    : []; // Default to empty array if not an array
  const conditionFields = [...ConditionFields];
  const calculationFields = [...CalculationFields];
  const checkBoxFields = [...CheckBoxFields];

  // Tiered
  const [tieredData, setTieredData] = useState([]);
  const tieredRow = tieredData.map((item, index) => ({
    id: index + 1,
    from: item.from,
    to: item.to,
    commissionRate: item.commissionRate,
  }));

  const RemoveTieredRow = (id) => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for removeing: ${id}`);
      }
      // Check if tieredRow is an array and has elements
      validateArray(tieredRow, "TieredRow");
      // const updatedRows = undefined;

      const updatedRows = tieredRow?.filter((row) => row.id !== id);
      // Check if updatedRows is an array and has elements
      validateArray(updatedRows, "UpdatedRows");

      setTieredData(
        updatedRows?.reduce((acc, row) => {
          acc.push({
            from: row.from,
            to: row.to,
            commissionRate: row.commissionRate,
          });
          return acc;
        }, [])
      );
      if (updatedRows.length === 0) {
        setTieredChecked(false);
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Create column configuration by combining actual columns with action columns
  const tieredColumn = Array.isArray(TieredColumn(RemoveTieredRow, viewData))
    ? TieredColumn(RemoveTieredRow, viewData)
    : []; // Default to empty array if not an array

  //Split
  const [splitData, setSplitData] = useState({});
  const splitRow = Object.keys(splitData).map((level, index) => ({
    id: index + 1,
    Level: level,
    Value: splitData[level] + "%",
  }));

  const RemoveSplitRow = (id) => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for removeing: ${id}`);
      }
      // Check if tieredRow is an array and has elements
      validateArray(splitRow, "SplitRow");
      const updatedRows = splitRow.filter((row) => row.id !== id);
      // Check if updatedRows is an array and has elements
      validateArray(updatedRows, "UpdatedRows");
      setSplitData(
        updatedRows?.reduce((acc, row) => {
          const valueWithoutPercent = row.Value.replace("%", "");
          acc[row.Level] = valueWithoutPercent;
          return acc;
        }, {})
      );
      if (updatedRows.length === 0) {
        setSplitChecked(false);
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Create column configuration by combining actual columns with action columns
  const splitColumn = Array.isArray(SplitColumn(RemoveSplitRow, viewData))
    ? SplitColumn(RemoveSplitRow, viewData)
    : []; // Default to empty array if not an array

  // Role
  const [roleData, setRoleData] = useState({});

  // Function to capitalize the first letter of the level
  const capitalizeLevel = (level) => {
    return level.charAt(0).toUpperCase() + level.slice(1);
  };

  const roleUpRow = Object.keys(roleData).map((level, index) => ({
    id: index + 1,
    Level: capitalizeLevel(level),
    Value: roleData[level] + "%",
  }));

  const RemoveRoleUpRow = (id) => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for removeing: ${id}`);
      }
      // Check if tieredRow is an array and has elements
      validateArray(roleUpRow, "roleUpRow");

      const updatedRows = roleUpRow.filter((row) => row.id !== id);
      // Check if updatedRows is an array and has elements
      validateArray(updatedRows, "UpdatedRows");
      console.log(updatedRows);
      setRoleData(
        updatedRows?.reduce((acc, row) => {
          const valueWithoutPercent = row.Value.replace("%", "");
          acc[row.Level] = valueWithoutPercent;
          return acc;
        }, {})
      );
      if (updatedRows.length === 0) {
        setRoleUpChecked(false);
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Create column configuration by combining actual columns with action columns
  const roleUpColumn = Array.isArray(RoleUpColumn(RemoveRoleUpRow, viewData))
    ? RoleUpColumn(RemoveRoleUpRow, viewData)
    : []; // Default to empty array if not an array

  //  calculation functionality
  const handleCalculation = (index, fieldName, newValue) => {
    try {
      // Check if a valid index is provided using nullish coalescing
      if (typeof index !== "number") {
        throw new Error("Invalid ID provided.");
      }
      // Ensure value is defined and a string
      if (typeof fieldName !== "string" || typeof newValue !== "string") {
        throw new Error("Invalid input: value must be a string");
      }
      setCalcRow((prevRows) => {
        const newRows = [...prevRows];
        newRows[index][fieldName] = newValue;

        // Assuming you have access to the entity fields (e.g., productEntity, customerEntity)
        const entityFields = getSelectionOptions(newRows[index].calcEntityName);

        // Update the selection based on the calcEntityName only if calcEntityName changes
        if (fieldName === "calcEntityName") {
          newRows[index].calcField = entityFields[0];
        }
        return newRows;
      });
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  const addCalcRow = (index) => {
    try {
      // Check if a valid index is provided using nullish coalescing
      if (typeof index !== "number") {
        throw new Error("Invalid ID provided.");
      }
      if (productEntity.length > 0 && variableNames.length > 0) {
        setCalcRow((prevRows) => {
          // Create a copy of the current rows
          const newRows = [...prevRows];

          // Define the new row object with default values
          const newRow = {
            calcDescription: "",
            calcEntityName: calcEntityNames[0],
            calcOption: variableNames[0], // Set the field to the first item from productEntity
            calcField: productEntity[0], // Set the field to the first item from productEntity
            calcSymbols: symbols[0],
            percentage: percentageOption[0],
          };
          // Insert the new row at index + 1
          newRows.splice(index + 1, 0, newRow);
          return newRows;
        });
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  const RemoveCalcRow = (index) => {
    try {
      setCalcRow((prevRows) => {
        const newRows = [...prevRows];
        newRows.splice(index, 1);
        return newRows;
      });
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  const calculationForm = (calculateFormula) => {
    try {
      const result = calculateFormula.map((item) => {
        const key = Object.keys(item)[0].replace("#", "$");
        const value = Object.values(item)[0];
        const descriptionMatch =
          value &&
          value.match(
            /#?([^.<!>*+/%=-]+)?\.?([^<!>*+/%=-]+)?([<!>*+/%=-]{1,2}|==|<=?|>=?|!=|<|>)([^]+)/
          );
        let descriptionValue = (descriptionMatch && descriptionMatch[4]) || "";

        let descriptionValue1 = "";
        // Check if descriptionValue contains "/ 100"
        if (descriptionValue.includes("/ 100")) {
          // Split descriptionValue at "/ 100" and keep the first part as the number
          descriptionValue1 = "%";
          descriptionValue = descriptionValue.replace("/ 100", "").trim(); // Retain just the number
        }
        const calcEntityNameValue =
          (descriptionMatch[1] &&
            calcEntityNames.find(
              (exactMatch) =>
                exactMatch ===
                descriptionMatch[1].charAt(0).toUpperCase() +
                  descriptionMatch[1].slice(1).toLowerCase()
            )) ||
          "";
        return {
          calcOption: key,
          calcEntityName:
            (descriptionMatch[1] && (calcEntityNameValue || VARIABLE_SCREEN)) ||
            "Assign",
          calcField:
            (descriptionMatch && descriptionMatch[2]) ||
            (descriptionMatch && `$${descriptionMatch[1]}`),
          calcSymbols: (descriptionMatch && descriptionMatch[3]) || "",
          calcDescription: descriptionValue,
          percentage: descriptionValue1 || "",
        };
      });
      return result;
    } catch (error) {
      return [
        {
          calcDescription: "",
          calcEntityName: calcEntityNames[0],
          calcOption: "",
          calcField: "",
          calcSymbols: symbols[0],
          percentage: percentageOption[0],
        },
      ];
    }
  };

  const generateOutputArray = (rows) => {
    try {
      let outputArray = [];
      rows.forEach((row, index) => {
        let calculation =
          row.calcEntityName !== null &&
          `#${row.calcEntityName.toLowerCase()}.${row.calcField}${
            row.calcSymbols
          }${row.calcDescription}${row.percentage}`;
        // Check if the calculation contains '%' and replace it with '/ 100 '
        if (calculation && calculation.includes("%")) {
          calculation = calculation.replace("%", "/ 100 ");
        }

        // Append an object without the "calculation" key to the outputArray
        outputArray.push(
          row.calcOption !== null && {
            [`#${row.calcOption.replace("$", "")}`]: calculation,
          }
        );
      });
      // Remove unwanted parts
      if (outputArray) {
        outputArray.forEach((obj, index) => {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              // Check if obj[key] is a string before calling replace
              if (typeof obj[key] === "string") {
                obj[key] = obj[key]
                  .replace(/#assign\.null/g, "")
                  .replace(/#assign./g, "")
                  .replace(/#variable./g, "#")
                  .replace("$", "");
              }
            }
          }
        });
      }
      if (tieredData.length === 0) {
        return outputArray;
      }
    } catch (error) {
      console.error(error); // Log error to console
    }
  };
  const outputArray = generateOutputArray(calcRow);

  // condition functionality
  const handleCondition = (index, fieldName, newValue) => {
    try {
      // Check if a valid index is provided using nullish coalescing
      if (typeof index !== "number") {
        throw new Error("Invalid ID provided.");
      }
      // Ensure value is defined and a string
      if (typeof fieldName !== "string" || typeof newValue !== "string") {
        throw new Error("Invalid input: value must be a string");
      }
      setRow((prevRows) => {
        const newRows = [...prevRows];
        newRows[index][fieldName] = newValue;

        // Assuming you have access to the entity fields (e.g., productEntity, customerEntity)
        const entityFields = getSelectionOptions(
          newRows[index].conditionEntityName
        );

        // Update the selection based on the calcEntityName only if calcEntityName changes
        if (fieldName === "conditionEntityName") {
          newRows[index].field = entityFields[0];
        }
        return newRows;
      });
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  const handleAddRow = (index) => {
    try {
      // Check if a valid index is provided using nullish coalescing
      if (typeof index !== "number") {
        throw new Error("Invalid ID provided.");
      }
      if (productEntity.length > 0) {
        setRow((prevRows) => {
          // Create a copy of the current rows
          const newRows = [...prevRows];

          // Define the new row object with default values
          const newRow = {
            conDescription: "",
            startBrace: braces[0],
            conditionEntityName: conditionEntityNames[0],
            conditionOption: conditionOption[0],
            field: productEntity[0],
            symbols: symbols[0],
            endBrace: braces[0],
          };
          // Insert the new row at index + 1
          newRows.splice(index + 1, 0, newRow);
          return newRows;
        });
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  const RemoveConditionRow = (index) => {
    try {
      setRow((prevRows) => {
        const newRows = [...prevRows];
        newRows.splice(index, 1);
        return newRows;
      });
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  const conditionForm = (conditioned) => {
    try {
      // 'conditioned' is null or undefined"
      if (!conditioned) {
        return [
          {
            conDescription: "",
            startBrace: braces[0],
            conditionEntityName: conditionEntityNames[0],
            conditionOption: conditionOption[0],
            field: "",
            symbols: symbols[0],
            endBrace: braces[0],
          },
        ]; // Return an empty array or handle it as needed
      }
      // Regular expression to remove the outermost parentheses
      const updatedConditioned = conditioned.replace(/^\s*\(|\)\s*$/g, "");

      // setMode(mode)
      const inputString = updatedConditioned;

      const conditions = inputString.split(/(&&|\|\||\bNOT\b)/);

      // Define a regular expression to match the condition pattern
      const conditionRegex = /#(\w+)\.(\w+)([*+=/\-%><!]+|IN|NOT IN)'([^']+)'/;

      // Initialize an array to store the extracted conditions
      const outputArray = [];

      // Initialize a variable to track the condition option value
      let conditionOptionValue = "";

      // Function to capitalize the first letter of a string
      const capitalizeFirstLetter = (str) =>
        str.charAt(0).toUpperCase() + str.slice(1);

      // Iterate through each part and extract conditions
      for (let i = 0; i < conditions.length; i++) {
        // Check for leading and trailing parentheses manually
        let trimmedCondition = conditions[i].trim();
        let startBrace = "";
        let endBrace = "";

        if (trimmedCondition.startsWith("(")) {
          startBrace = "(";
          trimmedCondition = trimmedCondition.slice(1).trim();
        }

        if (trimmedCondition.endsWith(")")) {
          endBrace = ")";
          trimmedCondition = trimmedCondition.slice(0, -1).trim();
        }

        // Match each condition using the regular expression
        const match = conditions[i].match(conditionRegex);

        if (match) {
          let [, conditionEntityName, field, symbols, conDescription] = match;

          // Capitalize conditionEntityName
          const capitalizedEntityName =
            capitalizeFirstLetter(conditionEntityName);

          // Determine condition option based on the original string
          if (i > 0) {
            conditionOptionValue = conditions[i - 1];
          }
          // Create the object and push it to the output array
          if (symbols === "IN" || symbols === "NOT IN") {
            conDescription = conDescription
              .split(",")
              .map((item) => item.trim());
          }

          // Create the object and push it to the output array
          outputArray.push({
            symbols: symbols,
            conditionEntityName: capitalizedEntityName,
            conditionOption: conditionOptionValue,
            conDescription,
            field: field,
            startBrace,
            endBrace,
          });
        }
      }
      return outputArray.length === 0
        ? [
            {
              conDescription: "",
              startBrace: braces[0],
              conditionEntityName: conditionEntityNames[0],
              conditionOption: conditionOption[0],
              field: "",
              symbols: symbols[0],
              endBrace: braces[0],
            },
          ]
        : outputArray;
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  const generateOutputStrings = (rows) => {
    try {
      validateArray(rows, "Rows");
      // Initialize both output strings
      let outputString = "";
      let outputString1 = "";

      // Iterate over each row
      rows.forEach((row, index) => {
        if (!row.conditionEntityName) {
          return; // Skip if conditionEntityName is null
        }

        // Format `conDescription` for each output string
        const formattedConDescription1 = Array.isArray(row.conDescription)
          ? `(${row.conDescription.map((desc) => `'${desc}'`).join(",")})`
          : `'${row.conDescription}'`;

        const formattedConDescription = Array.isArray(row.conDescription)
          ? `${row.conDescription.join(",")}`
          : row.conDescription;

        // Create the condition strings
        const condition =
          row.conditionEntityName !== null &&
          `${row.startBrace}#${row.conditionEntityName.toLowerCase()}.${
            row.field
          }${row.symbols}'${formattedConDescription}'${row.endBrace}`;

        const condition1 =
          row.conditionEntityName !== null &&
          `${row.startBrace}#${row.conditionEntityName.toLowerCase()}.${
            row.field
          } ${row.symbols} ${formattedConDescription1} ${row.endBrace}`;

        // Append each condition to the respective output strings
        outputString +=
          index > 0 ? ` ${row.conditionOption} ${condition}` : `${condition}`;
        outputString1 +=
          index > 0 ? ` ${row.conditionOption} ${condition1}` : `${condition1}`;
      });

      // Check if a valid index is provided using nullish coalescing
      if (
        typeof outputString !== "string" ||
        typeof outputString1 !== "string"
      ) {
        throw new Error("Invalid value provided.");
      }
      // Return both output strings
      return { outputString, outputString1 };
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  const generateRowString = (rows) => {
    try {
      let rowString = ""; // Separate string for console output
      // Wrap the entire condition set in additional parentheses
      rowString += "(";
      rows.forEach((row, index) => {
        const condition =
          row.conditionEntityName !== null &&
          `${row.startBrace}#${row.conditionEntityName.toLowerCase()}.${
            row.field
          }${row.symbols}'${row.conDescription}' ${row.endBrace}`;

        // Handle the special console output for 'IN' and 'NOT IN' symbols
        if (
          (row.symbols === "IN" || row.symbols === "NOT IN") &&
          Array.isArray(row.conDescription)
        ) {
          // Determine the operator based on the symbol
          const operator = row.symbols === "IN" ? "==" : "!=";

          // Create a transformed string for the console with braces
          const productName = `#${row.conditionEntityName.toLowerCase()}.${
            row.field
          }`;
          const transformedConditions = row.conDescription
            .map((item) => `${productName}${operator}'${item}'`)
            .join(" || ");
          // .join(row.symbols === "IN" ?" || ":" && ");

          const transformedCondition =
            index > 0
              ? ` ${row.conditionOption} ${row.startBrace}${transformedConditions}${row.endBrace}`
              : `${row.startBrace}${transformedConditions}${row.endBrace}`;

          rowString += transformedCondition;
        } else {
          // Add the normal condition if symbols are not 'IN' or 'NOT IN'
          const normalCondition =
            index > 0 ? ` ${row.conditionOption} ${condition}` : `${condition}`;

          rowString += normalCondition;
          // Wrap the entire condition set in additional parentheses
        }
      });
      rowString += ")";
      return rowString;
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  const outputStrings = generateOutputStrings(row);
  const rowString = generateRowString(row);

  function getConditionOptions(field, conditionEntityName) {
    switch (field.name) {
      case "conditionOption":
        return conditionOption;
      case "conditionEntityName":
        return conditionEntityNames;
      case "field":
        return getSelectionOptions(conditionEntityName);
      case "symbols":
        return symbols;
      default:
        return [];
    }
  }

  function getCalcOptions(field, calcEntityName) {
    switch (field.name) {
      case "calcOption":
        return variableNames;
      case "calcEntityName":
        return calcEntityNames;
      case "calcField":
        return getSelectionOptions(calcEntityName);
      case "calcSymbols":
        return symbols.filter(
          (symbol) => symbol !== "IN" && symbol !== "NOT IN"
        );
      case "percentage":
        return percentageOption;
      default:
        return [];
    }
  }

  function getSelectionOptions(selectedEntity) {
    switch (selectedEntity) {
      case PRODUCT_SCREEN:
        return productEntity;
      case CUSTOMER_SCREEN:
        return customerEntity;
      case TRANSACTION_SCREEN:
        return transactionEntity;
      case VARIABLE_SCREEN:
        return variableNames;
      default:
        return [];
    }
  }

  return {
    rows,
    selectFrequencyOption,
    tableData,
    selectFrequency,
    commissionConfigNames,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    setEditFormOpen,
    variableNames,
    conditionFields,
    calculationFields,
    checkBoxFields,
    getConditionOptions,
    getCalcOptions,
    handleAddRow,
    addCalcRow,
    RemoveConditionRow,
    RemoveCalcRow,
    RemoveRoleUpRow,
    row,
    setRow,
    calcRow,
    setCalcRow,
    calcEntityNames,
    RemoveSplitRow,
    RemoveTieredRow,
    handleCondition,
    handleCalculation,
    outputStrings,
    outputArray,
    splitRow,
    splitColumn,
    splitData,
    setSplitData,
    roleUpRow,
    roleUpColumn,
    roleData,
    setRoleData,
    tieredRow,
    tieredColumn,
    tieredData,
    setTieredData,
    calculationData,
    calculationForm,
    conditionForm,
    roleAccess,
    formApiCall,
    productEntity,
    customerEntity,
    transactionEntity,
    conditionEntityNames,
    conditionOption,
    symbols,
    productNames,
    fullProductNames,
    customerNames,
    roleUpChecked,
    setRoleUpChecked,
    splitChecked,
    setSplitChecked,
    tieredChecked,
    setTieredChecked,
    isDisabled,
    viewData,
    setViewData,
    braces,
    frequencyOptionsWithId,
    percentageOption,
    rowString,
    loading,
    cumulativeValue,
    setCumulativeValue,
    onSubmitForm, // Function to handle form submission
    submitLoading,
    columns,
    displayName,
    screenName,
  };
};
