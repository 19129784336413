import { useEffect, useRef } from "react";
import { Container, CssBaseline } from "@mui/material";
import { CONTACT_MAIL } from "../../config/constants";
import {
  CfTransLogo,
  ImgEmpower,
  ImgFeature,
  ImgSalesBanner,
  ImgSlideTrans,
  ImgSlideWhite,
  ImgWhyScom,
} from "../../config/imageConfig";
import { firstHalfFeatures, secondHalfFeatures } from "./features";
import HeaderTopBar from "./header";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import FooterBar from "./footer";

export default function LandingPage() {
  const pricingRef = useRef(null);

  useEffect(() => {
    // Smooth scroll to the PricingHome component if the URL hash is #pricing
    if (window.location.hash === "#pricing") {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Container disableGutters maxWidth="xl">
        <CssBaseline />

        {/* Header Section */}
        <HeaderTopBar imgLogoTrans={CfTransLogo} />

        {/* First Section */}
        <Section1
          bgImgSlideTop={ImgSlideTrans}
          bgImgSalesBanner={ImgSalesBanner}
        />

        {/* Second Section */}
        <Section2 bgImgEmpower={ImgEmpower} />

        {/* Third Section */}
        <Section3
          firstHalfFeatures={firstHalfFeatures}
          secondHalfFeatures={secondHalfFeatures}
          bgImgSlide={ImgSlideWhite}
          bgImgFeature={ImgFeature}
        />

        {/* Fourth Section */}
        <Section4 bgImgWhyCf={ImgWhyScom} />

        {/* Fifth Section */}
        <Section5 bgImgSlide={ImgSlideWhite} />

        {/* Sixth Section */}
        <Section6 pricingRef={pricingRef} bgImgSlide={ImgSlideWhite} />

        {/* Footer Section */}
        <FooterBar imgLogoTrans={CfTransLogo} contactMail={CONTACT_MAIL} />
      </Container>
    </>
  );
}
