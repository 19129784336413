import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandLessTwoTone, ExpandMoreTwoTone } from "@mui/icons-material";
import FullScreenLoader from "../../common/LoadingScreen";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import TypeForm from "./form";
import { TypeService } from "./service";

export default function Type() {
  const {
    screenName = "",
    rows = [],
    columns = [],
    fields = [],
    pageOptions = [],
    stopRemount = true,
    setSelectedOption = () => {},
    typeOptions = [],
    selectedOption = "",
    editFormData = {},
    editFormOpen = () => {},
    editItemId = null,
    setEditFormOpen = () => {},
    loading = false,
    onSubmitForm = () => {},
    submitLoading = false,
    writeAccess = false,
    showForm = false,
    setShowForm = () => {},
    toggleForm = () => {},
  } = TypeService() || {};

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader loading={submitLoading} />

      {/* Add form and menu display */}
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {writeAccess && (
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  showForm ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
                }
                onClick={() => {
                  toggleForm();
                }}
              >
                Add {screenName}
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={2.5}>
            <Autocomplete
              id="my-autocomplete"
              options={pageOptions ?? []}
              size="small"
              fullWidth
              sx={{ py: 1 }}
              value={selectedOption}
              onChange={(event, newValue) => {
                stopRemount.current = true;
                setSelectedOption(newValue);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField {...params} label="Choose Type Name" />
              )}
              renderOption={(props, option, { selected }) => (
                <Typography
                  {...props}
                  key={props.key}
                  color={selected ? "secondary" : "inherit"}
                >
                  {option}
                </Typography>
              )}
            />
          </Grid>
        </Grid>

        {showForm && (
          <Paper sx={{ p: 3 }}>
            <TypeForm
              mode="add"
              screenName={screenName}
              fields={fields}
              typeOptions={typeOptions}
              selectedOption={selectedOption}
              setShowForm={setShowForm}
              onClose={setShowForm}
              onSubmitForm={onSubmitForm}
            />
          </Paper>
        )}
      </Box>

      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={loading}
        toolbarProps={{ displayName: screenName }}
      />

      {/* Edit form display */}
      <Dialog open={editFormOpen} maxWidth="xl">
        <DialogTitle>Edit {screenName}</DialogTitle>
        <DialogContent>
          <TypeForm
            mode="edit"
            screenName={screenName}
            fields={fields}
            editItemId={editItemId}
            editFormData={editFormData}
            typeOptions={typeOptions}
            selectedOption={selectedOption}
            onClose={setShowForm}
            setEditFormOpen={setEditFormOpen}
            onSubmitForm={onSubmitForm}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
