import { useState, useEffect } from "react";
import { debounce } from "@mui/material";
import PrivateApiService from "../apis/PrivateApiService";
import GradientCircularProgress from "./GradientCircularProgress";
import { US_CURRENCY } from "../components/config/constants";
import { CURRENCY_NAME } from "../components/config/sessionStorage";
import { getDefaultLocale } from "./localeUtils";

// Helper function to format the amount based on currency and locale
export const formatAmount = async ({
  amount = 0,
  fromCurrency = US_CURRENCY,
  toCurrency = CURRENCY_NAME(),
  symbol = true,
}) => {
  try {
    if (fromCurrency === toCurrency) {
      // Convert to a numeric value if symbol is false
      if (!symbol) return Number(amount);

      // Otherwise, return formatted string
      const locale = getDefaultLocale(fromCurrency);
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: fromCurrency,
      }).format(amount);
    }

    // Fetch conversion rate and calculate the converted amount
    const conversionRate = await PrivateApiService.getCurrencyConversionRate(
      fromCurrency,
      toCurrency
    );
    if (!conversionRate) {
      throw new Error("Conversion rate not available");
    }

    const convertedAmount = amount * conversionRate;

    // Return numeric value if symbol is false
    if (!symbol) return Number(convertedAmount.toFixed(2));

    // Otherwise, return formatted string with symbol
    const locale = getDefaultLocale(toCurrency);
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: toCurrency,
    }).format(convertedAmount);
  } catch (error) {
    console.error("Error formatting amount:", error);
    return symbol ? amount : Number(amount); // Return unformatted number in case of an error if symbol is false
  }
};

export const AsyncCurrencyAmount = ({
  amount = 0,
  fromCurrency = US_CURRENCY,
  toCurrency = CURRENCY_NAME(),
  progress = false,
}) => {
  const [formattedAmount, setFormattedAmount] = useState("");

  useEffect(() => {
    // Create the debounced fetch function
    const fetchFormattedAmount = debounce(async () => {
      const result = await formatAmount({
        amount: amount || 0,
        fromCurrency: fromCurrency || US_CURRENCY,
        toCurrency: toCurrency || CURRENCY_NAME(),
      });
      setFormattedAmount(result);
    }, 300); // Adjust delay as needed

    // Call the debounced function
    fetchFormattedAmount();

    // Cleanup debounce on unmount to prevent memory leaks
    return () => {
      fetchFormattedAmount.clear();
    };
  }, [amount, fromCurrency, toCurrency]); // Dependencies for when to re-run the effect

  return (
    <span>
      {formattedAmount || (
        <GradientCircularProgress size={progress ? "2.5rem" : "0.7rem"} />
      )}
    </span>
  );
};
