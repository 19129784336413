import {
  customOptions,
  dateOptions,
  dayOptions,
  executeJobTaskOptions,
  monthOptions,
  pojoTypeOptions,
  scheduleOptions,
} from "./config";

// Ensure all options are arrays, defaulting to empty arrays if not
const options = {
  customOptions: Array.isArray(customOptions) ? customOptions : [],
  dateOptions: Array.isArray(dateOptions) ? dateOptions : [],
  dayOptions: Array.isArray(dayOptions) ? dayOptions : [],
  executeJobTaskOptions: Array.isArray(executeJobTaskOptions)
    ? executeJobTaskOptions
    : [],
  monthOptions: Array.isArray(monthOptions) ? monthOptions : [],
  pojoTypeOptions: Array.isArray(pojoTypeOptions) ? pojoTypeOptions : [],
  scheduleOptions: Array.isArray(scheduleOptions) ? scheduleOptions : [],
};

export default function ScheduleFields() {
  const fields = [
    {
      name: "schedule",
      label: "Schedule",
      type: "autocomplete",
      options: options?.scheduleOptions ?? [],
      autoFocus: true,
      required: true,
    },
    {
      name: "executeJobTask",
      label: "Execute JobTask",
      type: "autocomplete",
      options: options?.executeJobTaskOptions ?? [],
      required: true,
    },
    {
      name: "pojoType",
      label: "Pojo Type",
      type: "autocomplete",
      options: options?.pojoTypeOptions ?? [],
      required: true,
    },
    {
      name: "path",
      label: "Path",
      type: "text",
      required: true,
    },
    {
      name: "day",
      label: "Select Day",
      type: "autocomplete",
      options: options?.dayOptions ?? [],
      required: true,
    },
    {
      name: "date",
      label: "Select Date",
      type: "autocomplete",
      options: options?.dateOptions ?? [],
      isNumber: true,
      required: true,
    },
    {
      name: "month",
      label: "Month",
      type: "autocomplete",
      options: options?.monthOptions ?? [],
      required: true,
    },
    {
      name: "customDays",
      label: "Custom Days",
      type: "text",
      required: true,
    },
    {
      name: "customPeriod",
      label: "Custom Period",
      type: "autocomplete",
      options: options?.customOptions ?? [],
      required: true,
    },
    {
      isSubtitle: true,
      subtitle: "at once",
    },
    {
      name: "time",
      label: "Schedule Time",
      type: "time",
      required: true,
      isTime: true,
    },
    {
      name: "selectedTimeZone",
      label: "Selected Time Zone",
      type: "text",
      required: false,
      shrink: true,
    },
  ];

  return fields;
}
