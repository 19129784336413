import { useRef } from "react";
import ProductForm from "./form";
import FullScreenLoader from "../../common/LoadingScreen";
import { ProductService } from "./service";
import CustomDataGrid from "../../../utils/CustomDataGrid";

export default function Product() {
  const fileInputRef = useRef(null);

  const {
    screenName = "",
    displayName = "",
    uploadLoadingMsg = "",
    rows = [],
    columns = [],
    fields = [],
    writeAccess = false,
    toolbarMenuProps = {},
    toolbarImportProps = {},
    open = false,
    addFormOpen = () => {},
    addFormClose = () => {},
    editFormOpen = false,
    editItemId = null,
    editFormData = {},
    setEditFormOpen = () => {},
    viewData = false,
    setViewData = () => {},
    onSubmitForm = () => {},
    submitLoading = false,
    dynamicFormFields = [],
    handleAddCustomField = () => {},
    openCustomFieldDialog = false,
    setOpenCustomFieldDialog = () => {},
    columnVisibilityModel = () => {},
    apiEndpoint = "",
    isUploading = false,
    isTableLoading = false,
    isErrorColor = false,
    isEData = false,
    resObject = {},
    response = {},
    addProductType = () => {},
    productTypeOptions = [],
    productTypeOptionsWithId = [],
    productIdCheck = [],
    productNameCheck = [],
    setNewFields = () => {},
  } = ProductService(fileInputRef) || {};

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader
        loading={isUploading || submitLoading}
        message={isUploading ? uploadLoadingMsg : ""}
      />

      {/* Handle both add and edit form display */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={isTableLoading}
        apiEndpoint={apiEndpoint}
        columnVisibilityModel={columnVisibilityModel}
        toolbarProps={{
          displayName,
          screenName,
          addFormOpen,
          ...toolbarMenuProps,
          ...toolbarImportProps,
        }}
        sx={{
          ...(isErrorColor && {
            "& .MuiDataGrid-row": {
              color: "error.main",
            },
          }),
        }}
      />

      {/* Handle both and and edit form display */}
      <ProductForm
        screenName={screenName}
        mode={editFormOpen ? "edit" : "add"}
        open={editFormOpen || open}
        onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
        fields={fields}
        writeAccess={writeAccess}
        editItemId={editFormOpen ? editItemId : undefined}
        editFormData={editFormOpen ? editFormData : undefined}
        viewData={viewData}
        setViewData={setViewData}
        onSubmitForm={onSubmitForm}
        dynamicFormFields={dynamicFormFields}
        handleAddCustomField={handleAddCustomField}
        openCustomFieldDialog={openCustomFieldDialog}
        setOpenCustomFieldDialog={setOpenCustomFieldDialog}
        isEData={isEData}
        resObject={resObject}
        response={response}
        addProductType={addProductType}
        productTypeOptions={productTypeOptions}
        productTypeOptionsWithId={productTypeOptionsWithId}
        productIdCheck={productIdCheck}
        productNameCheck={productNameCheck}
        setNewFields={setNewFields}
      />
    </>
  );
}
