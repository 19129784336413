import { Box, Grid, Typography } from "@mui/material";

export default function Section4({ bgImgWhyCf }) {
  return (
    <Grid
      container
      sx={{
        px: { xs: 2, md: 7 }, // Adjust overall horizontal padding for responsiveness
        bgcolor: "white",
        mt: { xs: -1, md: 0 }, // Adjust margin top to align with the previous section
      }}
    >
      {/* Content on the left */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            p: { xs: 3, md: 5 }, // Responsive padding
            pt: { xs: 8, md: 10 }, // Responsive padding top
            display: "flex",
            flexDirection: "column",
            height: "100%", // Ensure the box takes up full height of the grid item
          }}
        >
          <Typography
            variant="h3"
            align="left"
            gutterBottom
            sx={{
              backgroundImage: "linear-gradient(220deg, #0066ff, #7730e6)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
              lineHeight: 1.2,
              fontSize: { xs: "1.75rem", md: "2.5rem" }, // Responsive font size
              mb: { xs: 2, md: 3 }, // Margin bottom for spacing
            }}
          >
            Why CompFact ?
          </Typography>

          <Typography
            variant="h5"
            align="left"
            gutterBottom
            sx={{
              color: "#333",
              fontSize: { xs: "1rem", md: "1.375rem" }, // Responsive font size
              py: { xs: 1, md: 1.5 }, // Responsive vertical padding
            }}
          >
            With CompFact, managing sales commissions has never been easier. Our
            user-friendly interface, robust features, and dedicated support team
            make us the top choice for businesses of all sizes.
          </Typography>
        </Box>
      </Grid>

      {/* Image on the right */}
      <Grid
        item
        xs={false} // Hide at xs
        sm={false} // Hide at sm
        md={6} // Show at md
        sx={{
          pt: { xs: 2, md: 0 }, // Responsive padding top
          display: { xs: "none", sm: "none", md: "flex" },
          justifyContent: "center", // Center align the image
          alignItems: "flex-start",
        }} // Responsive display
      >
        <Box
          sx={{
            backgroundImage: `url(${bgImgWhyCf})`,
            backgroundSize: "contain", // Ensure the full image is displayed
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat", // Avoid repeating the image
            height: { xs: "auto", md: "100%" }, // Responsive height
            minHeight: { xs: "60vh", md: "70vh" }, // Ensure proper height on various screens
            width: "100%",
            borderRadius: { xs: "8px", md: "16px" }, // Responsive border radius
          }}
        />
      </Grid>
    </Grid>
  );
}
