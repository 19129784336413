import { useState } from "react";
import toast from "react-hot-toast";
import apiMappings from "../apis/ApiMappings";
import { JWT_TOKEN } from "../components/config/sessionStorage";
import {
  BENEFICIARY_SCREEN,
  PLAN_SCREEN,
  TYPE_SCREEN,
} from "../components/config/constants";
import { PAGE_OPTIONS } from "../components/config/fieldConfig";
import { handleApiCall } from "./handleApiCall";
import { handleMasterApiCall } from "./handleMasterApiCall";

const useSubmitForm = ({
  screenName = "",
  fetchData = () => {},
  adminUserName = "",
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmitForm = async (formData, action, transformedObjects) => {
    try {
      const token = JWT_TOKEN(); // Retrieve the JWT token from session storage
      if (!token) {
        throw new Error("Token is missing."); // Check if the token is present
      }

      setSubmitLoading(true); // Set loading state to true when the submission starts

      if (!formData || typeof formData !== "object") {
        throw new Error("Invalid form data provided."); // Form data validation
      }
      if (action === "delete" && !formData?.id) {
        throw new Error("ID is required for deletion."); // ID validation for deletion
      }

      // Get the appropriate API functions based on screenName and action
      const apiActions = apiMappings[screenName];
      if (!apiActions) {
        throw new Error(`No API mappings found for screen: ${screenName}`);
      }

      const apiAction = apiActions[action];
      if (!apiAction) {
        throw new Error(`No API action found for action: ${action}`);
      }

      if (Array.isArray(PAGE_OPTIONS) && PAGE_OPTIONS.includes(screenName)) {
        if (
          (!transformedObjects || typeof transformedObjects !== "object") &&
          action !== "delete"
        ) {
          throw new Error("Invalid form data provided."); // Form data validation
        }

        // Call handleMasterApiCall with the appropriate API action and parameters
        await handleMasterApiCall(
          transformedObjects,
          screenName,
          token,
          action,
          // Correct logic for apiAction call
          screenName === BENEFICIARY_SCREEN && action === "add"
            ? apiAction(formData, token, adminUserName) // Use formData, token, adminUserName for this specific case
            : apiAction(action === "delete" ? formData?.id : formData, token), // Default logic for delete or other cases
          fetchData // Pass fetchData to call after API action
        );
      } else {
        // Call the API action and handle the response
        await handleApiCall(
          screenName, // Pass the screen identifier for access control
          screenName === PLAN_SCREEN && action === "edit"
            ? apiAction(formData, token, adminUserName) // Use formData, token, adminUserName for this specific case
            : apiAction(action === "delete" ? formData?.id : formData, token), // Call appropriate API function
          action, // Pass the action performed (add/edit/delete)
          fetchData, // Re-fetch data after performing the action
          screenName === TYPE_SCREEN && action === "add"
            ? formData?.typeName
            : null // Pass formData.typeName only for "add"
        );
      }
    } catch (error) {
      console.error(`Error ${action} form data:`, error);
      toast.error(`Error ${action} form: ${error?.message}`); // Display the exact error message in a toast
    } finally {
      // Reset loading state when the submission is complete (whether successful or failed)
      setSubmitLoading(false);
    }
  };

  return { onSubmitForm, submitLoading };
};

export default useSubmitForm;
