import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { CheckTwoTone } from "@mui/icons-material";
import { AsyncCurrencyAmount } from "./CurrencyConverter";

export default function PricingCard({
  pricingPlans = [],
  onCardClick = () => {},
  isPricingHome = false,
  currentPlanId = null,
}) {
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {/* Page Heading with Gradient Text */}
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            ...(isPricingHome
              ? {
                  backgroundImage: "linear-gradient(220deg, #0066ff, #7730e6)", // Gradient effect
                  WebkitBackgroundClip: "text", // Clip background to text for gradient text effect
                  WebkitTextFillColor: "transparent", // Make the text color transparent for gradient
                }
              : {
                  color: "#00084D",
                }),
            fontWeight: 650,
            lineHeight: 1,
            fontSize: { xs: "1.5rem", md: "2.5rem" }, // Responsive font size
            pb: 3,
          }}
        >
          Pricing Plans
        </Typography>

        {/* Display Pricing Cards if Plans Exist, Otherwise Show Message */}
        {pricingPlans?.length > 0 ? (
          // Render Pricing Cards
          <Grid container spacing={4} justifyContent="center">
            {pricingPlans?.map((plan, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  variant="outlined"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 3,
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 6px 18px rgba(0, 0, 0, 0.2)",
                    },
                    mb: 4,
                    padding: 2,
                    border: plan.id === currentPlanId ? 1 : 0,
                    borderColor: plan.id === currentPlanId && "#00084D",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    {/* Title */}
                    <Typography
                      variant="h5"
                      color="#00084D"
                      sx={{ minHeight: "40px" }}
                    >
                      {plan.title}
                    </Typography>

                    {/* Description */}
                    <Typography
                      variant="body2"
                      color="#505968"
                      sx={{ minHeight: "40px" }}
                    >
                      {plan.description}
                    </Typography>

                    {/* Price Section */}
                    <Box display="flex" alignItems="flex-end" sx={{ py: 3 }}>
                      {/* Price */}
                      <Typography
                        variant="h3"
                        color="#00084D"
                        fontWeight="600"
                        sx={{ fontSize: "48px", lineHeight: 1 }}
                      >
                        <AsyncCurrencyAmount
                          amount={plan.price}
                          progress={true}
                        />
                      </Typography>

                      {/* Frequency Text */}
                      <Typography
                        variant="body2"
                        color="#505968"
                        sx={{ ml: 1, fontSize: "16px" }}
                      >
                        per user* <br />
                        {plan.frequency === "Year" ? "per year" : "per month"}
                      </Typography>
                    </Box>

                    {/* Notes */}
                    {plan.notes && (
                      <Typography
                        variant="body1"
                        color="#17191E"
                        mt={1}
                        fontWeight="700"
                      >
                        {plan.notes}
                      </Typography>
                    )}

                    {/* Features */}
                    <Box mt={2} sx={{ flexGrow: 1 }}>
                      {plan?.features.map((feature, index) => (
                        <Box key={index} display="flex" alignItems="flex-start">
                          <CheckTwoTone
                            fontSize="small"
                            color="success"
                            sx={{ mr: 1 }}
                          />
                          <Typography
                            variant="body2"
                            color="#393F49"
                            sx={{ minHeight: "24px" }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>

                    {/* Button */}
                    {isPricingHome ? (
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3, borderRadius: 2, minHeight: "48px" }}
                        onClick={() => onCardClick(plan.title)}
                        style={{
                          background:
                            currentPlanId && plan.id <= currentPlanId
                              ? "#757575"
                              : plan.buttonBgColor,
                          color: "white",
                          "&:hover": {
                            background:
                              currentPlanId && plan.id <= currentPlanId
                                ? "lightgrey"
                                : plan.buttonBgColor,
                          },
                        }}
                      >
                        SignUp Now
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => onCardClick(plan)}
                        disabled={plan.id <= currentPlanId} // Disable button for current and lower ID plans
                        sx={{
                          mt: 3,
                          borderRadius: 2,
                          minHeight: "48px",
                          "&.Mui-disabled": {
                            bgcolor: "grey.500",
                            color: "white",
                          },
                        }}
                      >
                        {plan.id < currentPlanId
                          ? "Existing Plan"
                          : plan.id === currentPlanId
                          ? "Current Plan"
                          : "Upgrade"}
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          // Display message if no pricing plans are available
          <Typography
            variant="body1"
            align="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60vh", // Center message vertically
            }}
          >
            No pricing plans available at the moment.
          </Typography>
        )}
      </Box>
    </>
  );
}
