import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ApiService from "../../../apis/ApiService";
import {
  IS_SUPER_ADMIN,
  IS_MONITOR,
  JWT_TOKEN,
  ROLE_ACCESS,
  USER_NAME,
  ORG_NAME,
} from "../../config/sessionStorage";
import { PAGE_OPTIONS, addSerialNumber } from "../../config/fieldConfig";
import { TICKET_SCREEN } from "../../config/constants";
import { handleApiCall } from "../../../utils/handleApiCall";
import { FormFields } from "./fields";
import { resourceComponents } from "../../../router";

export const TicketService = () => {
  // select the screen
  const [selectedOption, setSelectedOption] = useState(PAGE_OPTIONS[0]);
  const [token, setToken] = useState(null);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]); // show the logo in screen
  const [beneficiaries, setBeneficiaries] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const fileInputRef = useRef(null);
  const [viewData, setViewData] = useState(true); // view purpose only
  const roleAccess = ROLE_ACCESS();
  const [loading, setLoading] = useState(true); // Initial loading state is true

  const stopRemount = useRef(true);
  const superAdmin = IS_SUPER_ADMIN();
  const isSuperAdmin = superAdmin === "false" ? false : true;

  const monitor = IS_MONITOR();
  const isMonitor = monitor === "false" ? false : true;

  const userName = USER_NAME();
  const orgName = ORG_NAME();

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const ticketData = await ApiService.getTickets(TOKEN);
      setData(ticketData || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Apply the formatting and update table data
  const rows = addSerialNumber(data);

  const handleEdit = (id, viewData) => async () => {
    try {
      const rowData = data.find((row) => row.id === id); // Find the row data based on the id
      setEditItemId(id);
      setEditFormOpen(true);
      setEditFormData(rowData); // Set edit form data
      setSelectedImage(rowData.ticketData);
      setViewData(viewData);
      isMonitor || isSuperAdmin ? setOrgId(rowData.orgId) : setOrgId(null);
    } catch (error) {
      console.log(error);
    }
  };
  // Create a new object for filtered resourceComponents
  const filteredResourceComponents = Object.keys(
    resourceComponents || {}
  ).reduce((acc, key) => {
    // Find the corresponding roleAccess item for the current resource key
    const resourceAccess = roleAccess.find((item) => item.resource === key);

    // If menuManagement is true, add the resource to the accumulator
    if (resourceAccess && resourceAccess.menuManagement) {
      acc[key] = resourceComponents[key];
    }

    return acc;
  }, {});

  // Create an array of resource names
  let resourceNames;
  if (isSuperAdmin || isMonitor) {
    // Create an array of resource names
    resourceNames = Object.keys(resourceComponents || {});
  } else {
    // Create an array of resource names
    resourceNames = Object.keys(filteredResourceComponents || {});
  }

  const handleFileChangeLogo = (e, replaceIndex = null) => {
    try {
      // Check if the event and file input are valid
      if (e && e.target && e.target.files && e.target.files.length > 0) {
        // Get the selected file
        const filesArray = Array.from(e.target.files);
        const newFiles = [];
        let filesProcessed = 0;

        filesArray.forEach((file) => {
          const uploadTimeUtc = new Date().toISOString();
          // Create a new FileReader object to read the file content
          const reader = new FileReader();
          // Callback function executed when file reading is completed
          reader.onload = function (event) {
            // Get the base64-encoded content of the file
            const base64Content = event.target.result;
            // Create the new file object
            const newFile = {
              type: file.type.startsWith("image/") ? "image" : "file",
              content: base64Content,
              name: file.name,
              metadata: { uploadTime: uploadTimeUtc },
            };
            // If replacing, update the specific file at the replaceIndex
            if (replaceIndex !== null) {
              setSelectedImage((prevFiles) => {
                const updatedFiles = [...prevFiles];
                updatedFiles[replaceIndex] = newFile; // Replace the file at the specific index
                return updatedFiles;
              });
            } else {
              // Otherwise, just add new files
              newFiles.push(newFile);
            }
            filesProcessed++;
            if (filesProcessed === filesArray.length && replaceIndex === null) {
              setSelectedImage((prevFiles) => [...prevFiles, ...newFiles]); // Add new files
            }
          };
          reader.onerror = function () {
            toast.error("Error reading file: " + file.name);
          };
          reader.readAsDataURL(file);
        });
        // Reset file input after processing
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        console.error("Invalid file input event:", e);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAvatarClick = () => {
    try {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      } else {
        console.log("File input ref does not exist"); // Debugging log
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addTicket,
        edit: ApiService.updateTicket,
        delete: ApiService.deleteTicket,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        TICKET_SCREEN, // Pass the screen here
        // Pass appropriate parameters based on the action type
        apiAction(
          action === "delete" ? formData.id : formData,
          token,
          selectedImage,
          orgId
        ),
        action, // Pass the action performed
        fetchData // Pass fetchData to call after API action
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this ticket?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  const fields = FormFields;

  return {
    fields,
    PAGE_OPTIONS,
    stopRemount,
    setSelectedOption,
    selectedOption,
    roleAccess,
    rows,
    onAddForm,
    onEditForm,
    handleEdit,
    handleDelete,
    editFormData,
    editFormOpen,
    editItemId,
    setEditFormOpen,
    selectedImage,
    handleFileChangeLogo,
    handleAvatarClick,
    fileInputRef,
    resourceNames,
    beneficiaries,
    setBeneficiaries,
    viewData,
    setViewData,
    setSelectedImage,
    loading,
    isSuperAdmin,
    isMonitor,
    userName,
    orgName,
    setOrgId,
  };
};
