import {
  ASSIGN_COMMISSION_SCREEN,
  AUDIT_LOG_SCREEN,
  BENEFICIARY_SCREEN,
  COMMISSION_SCREEN,
  CUSTOMER_SCREEN,
  MONITOR_SCREEN,
  PAY_FREQUENCY_SCREEN,
  PLAN_SCREEN,
  PRODUCT_SCREEN,
  ROLE_SCREEN,
  SCHEDULE_SCREEN,
  TICKET_SCREEN,
  TRANSACTION_SCREEN,
  TYPE_SCREEN,
  VARIABLE_SCREEN,
} from "../../components/config/constants";

export const useToolbarConfig = {
  [MONITOR_SCREEN]: {
    showMenu: true,
    showExportButton: false,
    showImportButton: false,
    showAddButton: false,
  },
  [AUDIT_LOG_SCREEN]: {
    showMenu: true,
    showExportButton: false,
    showImportButton: false,
    showAddButton: false,
  },
  [TRANSACTION_SCREEN]: {
    showMenu: true,
    showExportButton: true,
    showImportButton: true,
    showAddButton: true,
  },
  [BENEFICIARY_SCREEN]: {
    showMenu: true,
    showExportButton: true,
    showImportButton: true,
    showAddButton: true,
  },
  [CUSTOMER_SCREEN]: {
    showMenu: true,
    showExportButton: true,
    showImportButton: true,
    showAddButton: true,
  },
  [PRODUCT_SCREEN]: {
    showMenu: true,
    showExportButton: true,
    showImportButton: true,
    showAddButton: true,
  },
  [ASSIGN_COMMISSION_SCREEN]: {
    showMenu: true,
    showExportButton: false,
    showImportButton: false,
    showAddButton: true,
  },
  [COMMISSION_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: false,
  },
  [PAY_FREQUENCY_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: true,
  },
  [PLAN_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: true,
  },
  [ROLE_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: true,
  },
  [SCHEDULE_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: true,
  },
  [TICKET_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: false,
  },
  [TYPE_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: false,
  },
  [VARIABLE_SCREEN]: {
    showMenu: false,
    showExportButton: false,
    showImportButton: false,
    showAddButton: true,
  },
};
