// Beneficiary order
export const isOrder_Beneficiary = [
  "userId",
  "userName",
  "email",
  "roleName",
  "territory",
  "reportingTo",
  "startDate",
  "endDate",
  "jobCategory",
  "employeeType",
  "notes",
  "eligible",
  "mobileNumber",
];

// Customer order
export const isOrder_Customer = [
  "customerId",
  "customerName",
  "fullCustomerName",
  "subCustomerOf",
  "startDate",
  "endDate",
  "renewalDate",
  "leadSource",
  "customerLevel",
  "relationshipType",
  "terms",
  "isActive",
];

// Product order
export const isOrder_Product = [
  "productId",
  "productName",
  "productDescription",
  "fullProductName",
  "productLevel",
  "subProductOf",
  "productCost",
  "productPrice",
  "accountName",
  "productType",
  "commissionRate",
  "commissionAmount",
  "discountAmount",
  "discountPercentage",
  "preferredVendor",
  "isActive",
];

// Transaction order
export const isOrder_Transaction = [
  "transactionId",
  "transactionLine",
  "transactionDate",
  "effectiveDate",
  "transactionLineLevel",
  "transactionStatus",
  "unitOfMeasure",
  "unitCost",
  "transactionLineType",
  "transactionType",
  "salesAmount",
  "commissionAmount",
  "grossProfit",
  "commissionRate",
  "profitPercentage",
  "salesRep",
  "discount",
  "quantity",
  "productId",
  "isProjection",
  "customerId",
];

// Field Mapping Mandatory Keys
export const mandatoryKey = [
  "productLevel",
  "productType",
  "effectiveDate",
  "transactionType",
  "customerLevel",
  "relationshipType",
  "employeeType",
  "productId",
  "productName",
  "transactionId",
  "transactionLine",
  "userId",
  "customerName",
  "roleName",
  "email",
  "userName",
  "salesAmount",
  "transactionDate",
  "salesRep",
  "customerId",
];
