import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";

export default function DesktopMenu({
  menuItems,
  currentPath,
  handleOpenMenu,
  currentMenu,
  anchorEl,
  handleCloseMenu,
}) {
  // Adding an additional state for nested menus
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const [nestedMenu, setNestedMenu] = useState(null);

  // Function to handle opening the nested submenu
  const handleOpenNestedMenu = (event, menu) => {
    setNestedAnchorEl(event.currentTarget);
    setNestedMenu(menu);
  };

  // Function to handle closing the nested submenu
  const handleCloseNestedMenu = () => {
    setNestedAnchorEl(null);
    setNestedMenu(null);
    handleCloseMenu();
  };

  return (
    <>
      {menuItems
        .filter(
          (menu) =>
            menu.isAccess !== false &&
            (menu.children === null ||
              (menu.children &&
                menu.children.some((child) => child.isAccess !== false)))
        )
        .map((menu) => {
          const hasAccessibleChildren =
            menu.children &&
            menu.children.some((child) => child.isAccess !== false);

          const isActive =
            currentPath === menu.link ||
            (menu.children &&
              menu.children.some((child) => currentPath === child.link));

          // Default menu display logic for other items
          return (
            <Box key={menu.text}>
              {(menu.children === null || hasAccessibleChildren) && (
                <>
                  {menu.children ? (
                    <>
                      {hasAccessibleChildren && (
                        <Button
                          onClick={(event) => handleOpenMenu(event, menu)}
                          aria-controls={menu.text}
                          aria-haspopup="true"
                          style={{
                            color: "white", // Highlight active menu
                            fontWeight: isActive ? "bold" : "normal",
                          }}
                        >
                          {menu.text}
                        </Button>
                      )}

                      {currentMenu && currentMenu.text === menu.text && (
                        <Menu
                          id={menu.text}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          {menu.children
                            .filter((child) => child.isAccess !== false)
                            .map((child) => (
                              <Link
                                key={child.text}
                                to={child.children ? null : child.link}
                                style={{ position: "relative" }}
                              >
                                <MenuItem
                                  onClick={
                                    child.children
                                      ? (event) =>
                                          handleOpenNestedMenu(event, child)
                                      : handleCloseMenu
                                  }
                                  sx={{
                                    backgroundColor:
                                      currentPath === child.link
                                        ? "#e8eaf6"
                                        : "inherit",
                                    "&:hover": {
                                      backgroundColor:
                                        currentPath === child.link
                                          ? "#f5f5f5"
                                          : "inherit",
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="inherit"
                                    noWrap
                                    color={
                                      currentPath === child.link
                                        ? "secondary"
                                        : "inherit"
                                    }
                                  >
                                    {child.text}
                                  </Typography>
                                  {child.children && (
                                    <ArrowRight
                                      style={{
                                        marginLeft: "auto",
                                        fontSize: "20px",
                                      }}
                                    />
                                  )}
                                </MenuItem>

                                {child.children && (
                                  <Menu
                                    id={`${child.text}-submenu`}
                                    anchorEl={nestedAnchorEl}
                                    open={Boolean(
                                      nestedAnchorEl &&
                                        nestedMenu &&
                                        nestedMenu.text === child.text
                                    )}
                                    onClose={handleCloseNestedMenu}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right", // Positions the submenu to the right
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left", // Ensures the submenu starts next to the parent menu
                                    }}
                                  >
                                    {child.children
                                      .filter(
                                        (subChild) =>
                                          subChild.isAccess !== false
                                      )
                                      .map((subChild) => (
                                        <Link
                                          key={subChild.text}
                                          to={subChild.link}
                                          style={{ textDecoration: "none" }}
                                          onClick={handleCloseNestedMenu}
                                        >
                                          <MenuItem
                                            sx={{
                                              backgroundColor:
                                                currentPath === subChild.link
                                                  ? "#e8eaf6"
                                                  : "inherit",
                                              "&:hover": {
                                                backgroundColor:
                                                  currentPath === subChild.link
                                                    ? "#f5f5f5"
                                                    : "inherit",
                                              },
                                            }}
                                          >
                                            <Typography
                                              variant="inherit"
                                              noWrap
                                              color={
                                                currentPath === subChild.link
                                                  ? "secondary"
                                                  : "inherit"
                                              }
                                            >
                                              {subChild.text}
                                            </Typography>
                                          </MenuItem>
                                        </Link>
                                      ))}
                                  </Menu>
                                )}
                              </Link>
                            ))}
                        </Menu>
                      )}
                    </>
                  ) : (
                    <Link to={menu.link} style={{ textDecoration: "none" }}>
                      <Button
                        style={{
                          color: "white", // Highlight active menu
                          fontWeight: isActive ? "bold" : "normal",
                        }}
                      >
                        {menu.text}
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </Box>
          );
        })}
    </>
  );
}
