import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { SaveAsTwoTone } from "@mui/icons-material";
import { arraysAreEqual } from "../../config/fieldConfig";

export default function AccessToolbar({
  handleSetRole,
  selectedRole,
  dynamicOptions,
  data,
  onUpdateForm,
  dataId,
  rows,
  loading,
}) {
  const { handleSubmit } = useForm();

  // Check if data is different from rows
  const isDifferent = loading ? !loading : !arraysAreEqual(data, rows);

  const onSubmit = () => {
    isDifferent
      ? onUpdateForm(selectedRole, data)
      : toast("Please make changes in a records");
  };

  return (
    <>
      <GridToolbarContainer>
        <Typography variant="h6" color="primary" marginRight={1}>
          Page Access
        </Typography>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <div style={{ flex: 1 }} />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} id="myForm">
          <Autocomplete
            size="small"
            id="role"
            options={dynamicOptions}
            sx={{ width: 200 }}
            disableClearable
            getOptionLabel={(option) => option.roleName}
            value={
              dynamicOptions.find((option) => option.id === selectedRole) ||
              null
            }
            onChange={(event, newValue) => {
              handleSetRole(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Role" />
            )}
          />
        </Box>
        <GridToolbarQuickFilter />
        <Button
          type="submit"
          variant="contained"
          startIcon={<SaveAsTwoTone />}
          disabled={!isDifferent}
          form="myForm" // Ensure button is associated with the form
        >
          {dataId === null ? "Save" : "Update"}
        </Button>
      </GridToolbarContainer>
    </>
  );
}
