export default function ScheduleColumns({ actionsColumn = null }) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 50,
    },
    {
      field: "schedule",
      headerName: "Schedule",
      width: 100,
    },
    {
      field: "day",
      headerName: "Day",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
    },

    {
      field: "time",
      headerName: "Time",
      width: 150,
    },
    {
      field: "selectPeriod",
      headerName: "Select Period",
      width: 150,
    },
    {
      field: "selectQuarter",
      headerName: "Select Quarterly",
      width: 150,
    },
    {
      field: "executeJobTask",
      headerName: "Task",
      width: 150,
    },
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
