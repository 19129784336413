import { useEffect, useRef, useState } from "react";
import ApiService from "../../../apis/ApiService";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { NO_DATA_MSG } from "../../config/toastMessage";
import { addSerialNumber, formatDateTime } from "../../config/fieldConfig";
import { USER_ACTIVITY_DISPLAY_NAME } from "../../config/constants";
import { getInitialDateRange } from "../../../utils/getInitialDateRange";
import UserActivityColumns from "./columns";

export const UserActivityService = () => {
  // State to hold the user activity data and loading state
  const [data, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Ref to control the remounting of the component
  const stopRemount = useRef(true);

  // Constants for display screen name UI identifier
  const displayName = USER_ACTIVITY_DISPLAY_NAME || "";

  // Message to display when there is no data
  const noTableDataMsg = NO_DATA_MSG || "";

  // Get the initial date range using a utility function
  const { startDate, endDate } = getInitialDateRange(stopRemount);

  // Effect to fetch data when component mounts or dates change
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false; // Prevent further remounting
      fetchData(); // Fetch user activity data
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]); // Dependencies to trigger data fetch

  // Function to fetch user activity data from the API
  const fetchData = async () => {
    try {
      // Check if startDate and endDate are valid
      if (!startDate || !endDate) {
        throw new Error("Start date and end date must be provided.");
      }

      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Call the API to get user activity data
      const res = await ApiService.getAllUserActivityForOrganization(
        token,
        startDate,
        endDate
      );

      // Check if the response status is 200 and the data is an array
      if (res?.status === 200 && Array.isArray(res?.data)) {
        // Format the data if the conditions are met
        const formattedData = res?.data.map((item) => ({
          ...item,
          date: formatDateTime(item?.date || ""), // Format date using utility function
        }));
        // Set the table data
        setTableData(formattedData);
      } else {
        // Set an empty array if the conditions are not met
        setTableData([]); // Clear data if API response is not as expected
      }
    } catch (error) {
      console.error("Error fetching data:", error); // Log error to console
      setTableData([]); // Clear the table data if there's an error
    } finally {
      // Stop the loading indicator once the data fetching is complete
      setLoading(false);
    }
  };

  // Create column configuration by combining actual columns with action columns
  const columns = Array.isArray(UserActivityColumns())
    ? UserActivityColumns()
    : [];

  // Add serial numbers to data rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : [];

  // Return values to be used by the component that calls this service
  return {
    displayName, // The name of the user activity screen
    rows, // Data rows for the table, including user activity
    columns, // Column definitions for the data grid
    loading, // Indicates if data is currently being loaded
    noTableDataMsg, // Message for when there is no data available
  };
};
