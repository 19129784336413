export default function ProductFields({
  dynamicFormFields = [],
  newFields = [],
  productTypeOptions = [],
}) {
  const fields = [
    {
      name: "productId",
      label: "Product ID",
      type: "text",
      autoFocus: true,
      required: true,
    },
    {
      name: "productName",
      label: "Product Name",
      type: "text",
      required: true,
    },
    {
      name: "productDescription",
      label: "Product Description",
      type: "textarea",
      multiline: true,
      rows: 1,
      required: false,
    },
    {
      name: "fullProductName",
      label: "Full Product Name",
      type: "text",
      required: false,
    },
    {
      name: "productLevel",
      label: "Product Level",
      type: "text",
      required: true,
    },
    {
      name: "subProductOf",
      label: "Sub Product Of",
      type: "text",
      required: false,
    },
    {
      name: "productCost",
      label: "Product Cost",
      type: "number",
      required: false,
    },
    {
      name: "productPrice",
      label: "Product Price",
      type: "number",
      required: false,
    },
    {
      name: "accountName",
      label: "Account Name",
      type: "text",
      required: false,
    },
    {
      name: "productType",
      label: "Product Type",
      type: "autocomplete",
      options: Array.isArray(productTypeOptions) ? productTypeOptions : [],
      required: true,
    },
    {
      name: "commissionRate",
      label: "Commission Rate",
      type: "number",
      required: false,
    },
    {
      name: "commissionAmount",
      label: "Commission Amount",
      type: "number",
      required: false,
    },
    {
      name: "discountAmount",
      label: "Discount Amount",
      type: "number",
      required: false,
    },
    {
      name: "discountPercentage",
      label: "Discount Percentage",
      type: "number",
      required: false,
    },
    {
      name: "preferredVendor",
      label: "Preferred Vendor",
      type: "text",
      required: false,
    },
    {
      name: "active",
      label: "Is Active",
      type: "boolean",
      required: false,
    },
    ...(Array.isArray(dynamicFormFields) ? dynamicFormFields : []),
    ...(Array.isArray(newFields) ? newFields : []),
  ];

  return fields;
}
