import { useState } from "react";
import ApiService from "../../../apis/ApiService";
import {
  PAYMENT_HISTORY_DISPLAY_NAME,
  US_CURRENCY,
} from "../../config/constants";
import {
  IS_MONITOR,
  IS_SUPER_ADMIN,
  JWT_TOKEN,
  ORG_ID,
} from "../../config/sessionStorage";
import { addSerialNumber, formatDateDDMMYYYY } from "../../config/fieldConfig";
import useFetchOnMount from "../../config/useFetchOnMount";
import { formatCurrencySymbol } from "../../../utils/CurrencyFormatter";
import PaymentHistoryColumns from "../PaymentHistory/columns";

export const BillingService = () => {
  // State variables for managing billing information
  const [data, setTableData] = useState([]); // Stores payment history data
  const [token, setToken] = useState(""); // Stores the token
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(""); // Start date of the subscription
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(""); // End date of the subscription
  const [renewalPrice, setRenewalPrice] = useState(""); // Price for renewal
  const [planTitle, setPlanTitle] = useState(""); // Current pricing plan
  const [pricingMonth, setPricingMonth] = useState(""); // Duration of the pricing plan
  const [noOfBeneficiary, setNoOfBeneficiary] = useState(1); // Number of beneficiaries
  const [currentCurrency, setCurrentCurrency] = useState(""); // Current currency type
  const [loading, setLoading] = useState(true); // Loading state for data fetching
  const [lastAmountToPay, setLastAmountToPay] = useState(0);
  const [planRenewalDate, setPlanRenewalDate] = useState("");

  // Display name for the payment history screen
  const displayName = PAYMENT_HISTORY_DISPLAY_NAME || "";

  // Default currency for billing
  const defaultCurrency = US_CURRENCY;

  // Helper function to reset all billing information to default values
  const resetBillingInfo = () => {
    setTableData([]); // Clear table data
    setSubscriptionStartDate(""); // Reset start date
    setSubscriptionEndDate(""); // Reset end date
    setRenewalPrice(""); // Reset renewal price
    setPlanTitle(""); // Reset pricing plan
    setPricingMonth(""); // Reset pricing month
    setNoOfBeneficiary(1); // Reset number of beneficiaries
    setCurrentCurrency(defaultCurrency); // Reset currency to default
  };

  // Fetch payment history data based on user roles
  const fetchData = async () => {
    try {
      // Get JWT token for authorization
      const token = JWT_TOKEN();
      // If token is missing, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Set the token in state after validation
      setToken(token);

      const orgId = ORG_ID(); // Get organization ID
      const isMonitor = IS_MONITOR(); // Check if user is a monitor
      const isSuperAdmin = IS_SUPER_ADMIN(); // Check if user is a super admin

      let res; // Variable to hold API response

      // Fetch payment history based on user roles
      if (isMonitor === "true" || isSuperAdmin === "true") {
        res = await ApiService.getPaymentHistory(token); // Get all payment history
      } else {
        res = await ApiService.getPaymentHistoryForOrgId(token, orgId); // Get payment history for specific org
      }

      // Check if the response is valid
      if (
        res?.status === 200 &&
        Array.isArray(res?.data) &&
        res?.data?.length > 0
      ) {
        // Format the payment history data
        const formattedData = res?.data?.map((item) => ({
          ...item,
          paidDate: formatDateDDMMYYYY(item.paidDate), // Format the paid date
          amountToPay: formatCurrencySymbol(item.amountToPay, item.currency), // Format the amount to pay
        }));

        setTableData(formattedData); // Update the table data

        const firstPayment = res?.data[0]; // Get the first payment record

        setLastAmountToPay(
          isNaN(Number(firstPayment?.amountToPay))
            ? 0
            : Number(firstPayment?.amountToPay)
        );
        setSubscriptionStartDate(firstPayment?.subscriptionStartDate || ""); // Set subscription start date
        setSubscriptionEndDate(firstPayment?.subscriptionEndDate || ""); // Set subscription end date
        setPlanRenewalDate(firstPayment?.renewalDate || "");
        setRenewalPrice(
          formatCurrencySymbol(
            firstPayment?.amountToPay,
            firstPayment?.currency
          )
        ); // Set renewal price
        setPlanTitle(firstPayment?.pricingPlan); // Set pricing plan
        setPricingMonth(firstPayment?.month); // Set pricing month
        setNoOfBeneficiary(
          isNaN(Number(firstPayment?.beneficiary))
            ? 1
            : Number(firstPayment?.beneficiary)
        ); // Set number of beneficiaries
        setCurrentCurrency(firstPayment?.currency); // Set current currency
      } else {
        // Reset billing information if no data is found
        resetBillingInfo();
      }
    } catch (error) {
      console.error("Error fetching payment history:", error); // Log error
      resetBillingInfo(); // Reset billing info on error
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  // Use custom hook to fetch data on component mount
  useFetchOnMount(fetchData);

  // Handle PDF download for a specific invoice
  const handlePdfDownload = async (params) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      const response = await ApiService.downloadInvoicePDF(
        token,
        params?.row?.id // Fetch the invoice PDF
      );

      // Check if response data is valid
      if (!response.data) {
        console.error("Error: Empty response data"); // Log empty response error
        return;
      }

      // Create a Blob from the response data
      const blob = new Blob([response?.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${params.row.invoice}_${params.row.paidDate}.pdf`; // Set the filename
      a.click(); // Trigger the download

      // Revoke the object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error); // Log download error
    }
  };

  // Fetch pricing plans from the API
  const planFetchData = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      const res = await ApiService.getPricingPlans(token); // Fetch pricing plans

      // Check if the response is valid
      if (
        res?.status === 200 &&
        Array.isArray(res?.data) &&
        res?.data?.length > 0
      ) {
        // Format the pricing plans data
        const pricingPlans = res?.data;

        return pricingPlans || []; // Return the plans or empty array
      } else {
        return []; // Return empty array on failure
      }
    } catch (error) {
      console.error("Error fetching pricing plans:", error); // Log error
      return []; // Return empty array on error
    }
  };

  // Create column configuration for the payment history table
  const columns = Array.isArray(PaymentHistoryColumns({ handlePdfDownload }))
    ? PaymentHistoryColumns({ handlePdfDownload })
    : []; // Default to empty array if columns are not valid

  // Add serial numbers to data rows for table display
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : []; // Default to empty array if data is not valid

  return {
    token, // Pass the token
    displayName, // Display name for UI
    rows, // Rows of data for table
    columns, // Columns configuration for table
    currentCurrency, // Current currency used
    subscriptionStartDate, // Subscription start date
    subscriptionEndDate, // Subscription end date
    renewalPrice, // Renewal price
    planTitle, // Current pricing plan
    pricingMonth, // Duration of pricing plan
    noOfBeneficiary, // Number of beneficiaries
    fetchData, // Function to fetch payment history
    planFetchData, // Function to fetch pricing plans
    loading, // Loading state
    lastAmountToPay,
    planRenewalDate,
  };
};
