import { CircularProgress } from "@mui/material";

export default function ProgressCircle() {
  return (
    <>
      <CircularProgress
        size={60}
        sx={{
          position: "absolute",
          top: "50%",
          left: "30%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </>
  );
}
