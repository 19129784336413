import { Box, Grid, Typography } from "@mui/material";

export default function Section2({ bgImgEmpower }) {
  return (
    <Grid
      container
      sx={{
        bgcolor: "white",
        pt: { xs: 5, md: 0 }, // Responsive padding top
        px: { xs: 2, md: 6 }, // Responsive padding x
      }}
    >
      {/* Image Section */}
      <Grid
        item
        xs={false}
        sm={false}
        md={7}
        sx={{
          px: { xs: 2, md: 6 },
          display: { xs: "none", sm: "none", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bgImgEmpower})`,
            backgroundSize: "cover", // Cover the entire container
            backgroundPosition: "center", // Center the background image
            backgroundRepeat: "no-repeat",
            height: { xs: "auto", md: "60vh" }, // Responsive height
            minHeight: { xs: "300px", md: "60vh" }, // Minimum height to maintain aspect ratio
            width: "100%",
            borderRadius: { xs: "8px", md: "16px" }, // Responsive border radius
          }}
        />
      </Grid>

      {/* Content Section */}
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            paddingTop: { xs: 4, md: 15 }, // Responsive padding top
            padding: { xs: 2, md: 4 }, // Responsive padding
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" }, // Center align for small screens, left align for medium and up
            textAlign: { xs: "center", md: "left" }, // Text alignment
            height: "100%",
            bgcolor: "white",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              backgroundImage: "linear-gradient(220deg, #0066ff, #7730e6)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
              fontSize: { xs: "24px", md: "48px" }, // Responsive font size
              lineHeight: 1.2,
              px: { xs: 2, md: 0 }, // Responsive padding x
            }}
            align="center" // Center align text for small screens, left align for medium and up
            gutterBottom
          >
            Empower Your Sales Team, Maximize Performance
          </Typography>

          <Box
            sx={{
              backgroundImage: `url(${bgImgEmpower})`,
              backgroundSize: "cover", // Cover the entire container
              backgroundPosition: "center", // Center the background image
              backgroundRepeat: "no-repeat",
              height: { xs: "40vh", md: "60vh" }, // Responsive height in viewport height units
              minHeight: { xs: "30vh", md: "60vh" }, // Minimum height to maintain aspect ratio
              width: "100%",
              borderRadius: { xs: "4vw", md: "2vw" }, // Responsive border radius using viewport width units
              my: { xs: "2vh", md: 0 }, // Responsive vertical margin in viewport height units
              display: { xs: "flex", md: "none" }, // Show on small screens only
            }}
          />

          <Typography
            variant="h5"
            sx={{
              color: "#333",
              fontSize: { xs: "16px", md: "21px" }, // Responsive font size
              px: { xs: 2, md: 0 }, // Responsive padding x
              textAlign: { xs: "center", md: "left" }, // Center align text for small screens, left align for medium and up
            }}
            align="center" // Center align text for small screens, left align for medium and up
            gutterBottom
          >
            At CompFact, we understand the importance of rewarding your sales
            team for their hard work. Our commission software simplifies the
            process, allowing you to focus on what matters most – driving sales
            and growing your business.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
