import { Box } from "@mui/material";

export default function ScheduleStatusColumns() {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 80,
    },
    {
      field: "jobName",
      headerName: "Job Name",
      width: 100,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 130,
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 130,
    },
    {
      field: "scheduleType",
      headerName: "Schedule Type",
      width: 100,
    },
    {
      field: "scheduleTime",
      headerName: "Schedule Time",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Box
          title={params.value || ""}
          sx={{
            color:
              params.value === "Success"
                ? "green"
                : params.value === "Failed"
                ? "red"
                : "orange", // Text color for different statuses
            fontWeight: "bold",
            padding: "3px 8px",
            borderRadius: 1,
            backgroundColor:
              params.value === "Success"
                ? "#e0ffe0"
                : params.value === "Failed"
                ? "#ffe0e0"
                : "#fff4e0", // Background color for different statuses
          }}
        >
          {params.value || ""}
        </Box>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
    },
    {
      field: "nextRunDate",
      headerName: "Next Run Date",
      width: 100,
    },
    {
      field: "nextRunTime",
      headerName: "Next Run Time",
      width: 100,
    },
  ];

  return columns;
}
