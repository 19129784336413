import { PAGE_OPTIONS } from "../../config/fieldConfig";

export const scheduleOptions = [
  "Minutes",
  "Hourly",
  "Daily",
  "Weekly",
  "Bi-Weekly",
  "Monthly",
  "Quarterly",
  "Half-Yearly",
  "Yearly",
  "Custom",
];

export const executeJobTaskOptions = ["Import", "Export", "Calculation"];

export const pojoTypeOptions = Array.isArray(PAGE_OPTIONS) ? PAGE_OPTIONS : [];

export const dayOptions = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dateOptions = Array.from({ length: 31 }, (_, index) => index + 1);

export const monthOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const customOptions = ["days", "hours", "min"];
