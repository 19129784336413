export default function TransactionFields({
  salesRepOptions = [],
  customerOptions = [],
  productOptions = [],
  transactionTypeOptions = [],
  dynamicFormFields = [],
  newFields = [],
}) {
  const fields = [
    {
      name: "transactionId",
      label: "Transaction ID",
      type: "text",
      autoFocus: true,
      required: true,
    },
    {
      name: "transactionLine",
      label: "Transaction Line",
      type: "number",
      isNonDecimal: true,
      required: true,
    },
    {
      name: "transactionDate",
      label: "Transaction Date",
      type: "date",
      shrink: true,
      required: true,
    },
    {
      name: "effectiveDate",
      label: "Effective Date",
      type: "date",
      shrink: true,
      required: true,
    },
    {
      name: "transactionLineLevel",
      label: "Transaction Line Level",
      type: "text",
      required: false,
    },
    {
      name: "transactionStatus",
      label: "Transaction Status",
      type: "text",
      required: false,
    },
    {
      name: "unitOfMeasure",
      label: "Unit Of Measure",
      type: "text",
      required: false,
    },
    {
      name: "unitCost",
      label: "Unit Cost",
      type: "number",
      required: false,
    },
    {
      name: "transactionLineType",
      label: "Transaction Line Type",
      type: "text",
      required: false,
    },
    {
      name: "transactionType",
      label: "Transaction Type",
      type: "autocomplete",
      options: Array.isArray(transactionTypeOptions)
        ? transactionTypeOptions
        : [],
      required: true,
    },
    {
      name: "salesAmount",
      label: "Sales Amount",
      type: "number",
      required: true,
    },
    {
      name: "commissionAmount",
      label: "Commission Amount",
      type: "number",
      required: false,
    },
    {
      name: "grossProfit",
      label: "Gross Profit",
      type: "number",
      required: false,
    },
    {
      name: "commissionRate",
      label: "Commission Rate",
      type: "number",
      required: false,
    },
    {
      name: "profitPercentage",
      label: "Profit Percentage",
      type: "number",
      required: false,
    },
    {
      name: "salesRep",
      label: "Sales Rep",
      type: "autocomplete",
      options: Array.isArray(salesRepOptions) ? salesRepOptions : [],
      required: true,
    },
    {
      name: "discount",
      label: "Discount",
      type: "number",
      required: false,
    },
    {
      name: "quantity",
      label: "Quantity",
      type: "number",
      isNonDecimal: true,
      required: false,
    },
    {
      name: "productId",
      label: "Product",
      type: "autocomplete",
      options: Array.isArray(productOptions) ? productOptions : [],
      required: true,
    },
    {
      name: "projection",
      label: "Is Projection",
      type: "checkbox",
      required: false,
    },
    {
      name: "customerId",
      label: "Customer",
      type: "autocomplete",
      options: Array.isArray(customerOptions) ? customerOptions : [],
      required: true,
    },
    ...(Array.isArray(dynamicFormFields) ? dynamicFormFields : []),
    ...(Array.isArray(newFields) ? newFields : []),
  ];

  return fields;
}
