import { Box, Button, Typography } from "@mui/material";
import { ExpandMoreTwoTone } from "@mui/icons-material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  convertCamelToTitle,
  displayLimitedItems,
} from "../../config/fieldConfig";
import { useState } from "react";
import handleReportingToMenuItems from "../../../utils/reportingToMenu";

export default function OtherReportsToolbar({
  stopRemount = { current: false }, // Default object structure as per logged output
  reportingTo = [], // Default empty array
  displayName = "",
  menu = [], // Default value as per logged output
  handleGetMyReportee = () => {}, // Default function for handling reportee fetching
  handleGetMyDetailsApplyClick = () => {}, // Default function for applying details
  handleGetMyDetails = () => {}, // Default function for fetching user details
  selectedDropdown = "", // Default value as per logged output
  setSelectedDropdown = () => {}, // Placeholder for state setter function
}) {
  const [checkedItems, setCheckedItems] = useState(new Set()); // Stores selected user IDs
  const [checkedNameItems, setCheckedNameItems] = useState(new Set()); // Stores selected user names
  const [isReporteeMenuOpen, setIsReporteeMenuOpen] = useState(false); // Manages reportee menu visibility
  const [appliedCheckedNames, setAppliedCheckedNames] = useState([]); // Stores applied names for display
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownIconClick = (event) => {
    event.stopPropagation(); // Prevent triggering the main click event
    setIsReporteeMenuOpen(!isReporteeMenuOpen); // Toggle checkbox menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Reset all selected items and close the menu
  const handleCancelClick = () => {
    setCheckedItems(new Set()); // Clear checked items if needed
    setCheckedNameItems(new Set()); // Clear checked names if needed
    setIsReporteeMenuOpen(false); // Close the reportee menu if open
    handleMenuClose(); // Close the main dropdown menu
  };

  // Handle dropdown selection and update UI accordingly
  const handleMenuItemClick = (item) => {
    try {
      setSelectedDropdown(item); // Set the currently selected dropdown item
      setAppliedCheckedNames([]); // Clear previously applied names
      setCheckedItems(new Set()); // Reset checked items when dropdown changes

      if (item === "My Reportees") {
        setIsReporteeMenuOpen(false); // Close reportee menu
        handleGetMyReportee(); // Fetch reportee data
      } else {
        handleGetMyDetails(); // Fetch general details for other selections
        handleCancelClick();
      }
      handleMenuClose(); // Close the dropdown menu
    } catch (error) {
      console.error("Error in handleMenuItemClick:", error);
    }
  };

  // Toggle selection of an item, updating both checkedItems and checkedNameItems sets
  const handleMenuItemChange = (item, event) => {
    try {
      // Handle userId changes
      const newCheckedItems = new Set(checkedItems);
      if (newCheckedItems.has(item.userId)) {
        newCheckedItems.delete(item.userId);
      } else {
        newCheckedItems.add(item.userId);
      }
      setCheckedItems(newCheckedItems);

      // Handle userName changes
      const newCheckedNameItems = new Set(checkedNameItems);
      if (newCheckedNameItems.has(item.userName)) {
        newCheckedNameItems.delete(item.userName);
      } else {
        newCheckedNameItems.add(item.userName);
      }
      setCheckedNameItems(newCheckedNameItems);
    } catch (error) {
      console.error("Error in handleMenuItemChange:", error);
    }
  };

  // Apply selected items and save names if there are checked items
  const handleApplyClick = () => {
    try {
      if (!checkedItems || checkedItems.size === 0) {
        setAppliedCheckedNames([]); // Clear applied names if no items are selected
      } else {
        setAppliedCheckedNames(Array.from(checkedNameItems)); // Update applied names from checked items
        handleGetMyDetailsApplyClick(Array.from(checkedItems)); // Pass selected IDs for processing
        setSelectedDropdown("My Reportees");
      }
      handleMenuClose(); // Close the dropdown menu
    } catch (error) {
      console.error("Error in handleApplyClick:", error);
      handleCancelClick(); // Reset selections on error
    }
  };

  const filteredReportees = Array.isArray(reportingTo)
    ? reportingTo?.filter((reportee) =>
        `${reportee.userId} - ${reportee.userName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <GridToolbarContainer sx={{ flexWrap: "wrap" }}>
      <Typography variant="h5" color="primary">
        {displayName}
      </Typography>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {Array.isArray(reportingTo) && reportingTo.length > 0 ? (
        <Button
          variant="text"
          size="small"
          onClick={handleMenuClick}
          endIcon={<ExpandMoreTwoTone />}
          sx={{
            padding: "3px 8px",
            fontSize: "0.85rem",
            minWidth: "unset",
            display: "flex", // Make sure the button content is flexbox for proper alignment
            alignItems: "center",
          }}
        >
          <Box
            component="span"
            sx={{
              maxWidth: 120, // Adjust based on your needs
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "inline-block",
            }}
          >
            {displayLimitedItems(appliedCheckedNames) ||
              convertCamelToTitle(selectedDropdown) ||
              ""}
          </Box>
        </Button>
      ) : (
        <Box sx={{ p: 1.9 }}></Box>
      )}
      {/* show the reporting to dropdown */}
      {handleReportingToMenuItems({
        anchorEl,
        handleMenuClose,
        menu,
        checkedItems,
        reportingTo,
        handleMenuItemClick,
        selectedDropdown,
        handleDropdownIconClick,
        isReporteeMenuOpen,
        searchQuery,
        setSearchQuery,
        filteredReportees,
        handleMenuItemChange,
        handleApplyClick,
        handleCancelClick,
      })}

      <div style={{ flex: 1 }} />

      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
