import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { isEmpty, isValueContainsSplChars } from "../../config/fieldConfig";
import CountryPhone from "./countryPhone";
import { CustomTextField } from "../../../utils/CustomInputFields";

export default function ProfileForm({
  fields = [],
  profileData = {},
  updateProfile = () => {},
  selectedImage = null,
}) {
  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  // Use useEffect to populate form fields with profile data when profileData is updated
  useEffect(() => {
    if (profileData && profileData.userName) {
      const nameParts = profileData.userName.trim().split(" "); // Split userName into first and last name
      if (nameParts.length === 1) {
        setValue("firstName", nameParts[0]); // Set firstName if only one part exists
        setValue("lastName", ""); // Set lastName to empty string
      } else {
        const lastName = nameParts.pop(); // Remove and set last name
        const firstName = nameParts.join(" "); // Join remaining parts for first name
        setValue("firstName", firstName); // Set firstName field
        setValue("lastName", lastName); // Set lastName field
      }
      setValue("mobileNumber", profileData.mobileNumber || ""); // Set mobileNumber or default to an empty string
    }
  }, [profileData, setValue]);

  // Handle changes in the mobile number field
  const onPhoneNumberChange = (newNumber) => {
    setValue("mobileNumber", newNumber); // Update the mobile number in the form state
  };

  // Handle form submission
  const onSubmit = async (data) => {
    try {
      // Trigger form validation before submission
      const isValid = await trigger();
      if (!isValid) return; // Prevent form submission if validation fails

      console.log("Updated Profile Data: ", data); // Log form data for debugging

      // Prepare updated profile data
      const updatedData = {
        ...profileData, // Merge existing profile data
        ...data, // Merge form data from fields
        userName: `${data.firstName} ${data.lastName}`.trim(), // Update userName using first and last names
        imageData: selectedImage, // Add the selected image in byte-based Base64 format
      };

      // Remove firstName and lastName fields from the updatedData object
      delete updatedData.firstName;
      delete updatedData.lastName;

      console.log("updatedData", updatedData); // Log updated data for debugging

      // Call the updateProfile function to save the updated profile data
      updateProfile(updatedData);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error("Error updating profile: ", error);
    }
  };

  return (
    <Box
      component={"form"}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{ textAlign: "center", mt: 7 }}
    >
      {/* Container to display First Name and Last Name side by side on larger screens */}
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 2,
          justifyContent: "center",
        }}
      >
        {fields.slice(0, 2).map((field, index) => (
          <CustomTextField
            key={index}
            field={field}
            register={register}
            trigger={trigger}
            errors={errors}
            validate={{
              isEmpty: (value) => isEmpty(value),
              isValueContainsSplChars: (value) =>
                isValueContainsSplChars(value),
            }}
          />
        ))}
      </Box>

      {/* Render additional fields below the first row */}
      {fields.slice(2).map(({ label, name, type, required }, index) => (
        <Box key={index} sx={{ mt: 1.5 }}>
          {/* Conditionally render the CountryPhone component for mobileNumber field */}
          {name === "mobileNumber" && (
            <CountryPhone
              name={name}
              label={label}
              type={type}
              required={required}
              open={true}
              editFormData={profileData}
              onPhoneNumberChange={onPhoneNumberChange}
              register={register}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
            />
          )}
        </Box>
      ))}

      {/* Submit button to trigger the form submission */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2.5 }}
      >
        Update Profile
      </Button>
    </Box>
  );
}
