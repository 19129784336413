import { CURRENCY_NAME } from "../components/config/sessionStorage";

// Importing the country-locale-map module
const clm = require("country-locale-map");

/**
 * Get the default locale for a given currency
 * @param {string} currencyName - Currency name (e.g., "USD", "INR")
 * @returns {string} - Locale string (e.g., "en-US")
 */

export function getDefaultLocale(currencyName = CURRENCY_NAME()) {
  // Retrieve all countries from the country-locale-map
  const allCountries = clm.getAllCountries();

  // Find the matching country using the currency name
  const currencyMap = allCountries.find((c) => c.currency === currencyName);

  // Replace underscores with hyphens in the locale and return
  const formattedLocale = currencyMap?.default_locale.replace("_", "-");

  return formattedLocale || "en-US"; // Default to "en-US" if not found
}
