import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import { ACCESS_SCREEN, DASHBOARD_SCREEN } from "../../config/constants";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { EXCEPTION_MESSAGE } from "../../config/toastMessage";
import { handleMenuUpdate } from "../../../utils/menuHandler";
import { resourceComponents } from "../../../router";

export const AccessService = () => {
  // Create an array of resource names
  const resourceNames = Object.keys(resourceComponents);
  // Create a new array for initialTableData
  const initialTableData = resourceNames.map((resource) => {
    return {
      resource,
      menuManagement: resource === DASHBOARD_SCREEN,
      readAccess: resource === DASHBOARD_SCREEN,
      writeAccess: resource === DASHBOARD_SCREEN,
      deleteAccess: resource === DASHBOARD_SCREEN,
    };
  });

  const [tableData, setTableData] = useState(initialTableData);
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(1);
  const [dataId, setDataId] = useState();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const [submitLoading, setSubmitLoading] = useState(false); // submit loading

  //Table header checkbox
  const [headerCheckboxes, setHeaderCheckboxes] = useState({
    menuManagement: false,
    readAccess: false,
    writeAccess: false,
    deleteAccess: false,
  });

  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData(selectedRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (selectedRole) => {
    try {
      // Fetch JWT token for authorization
      const TOKEN = JWT_TOKEN();

      // If token is not available, throw an error
      if (!TOKEN) throw new Error("Token not found or invalid");
      setToken(TOKEN);

      const getAllUserAccess = await ApiService.getAllUserAccess(TOKEN);

      const data = getAllUserAccess?.find(
        (getAllUserAccess) => getAllUserAccess.roleName === selectedRole
      );

      const userData = data ? data.userRoleAccess : initialTableData;

      const dataId = data ? data.id : null;
      setDataId(dataId);
      setTableData(userData);

      const response = await ApiService.getRoles(TOKEN);

      const extractedData = response?.map((item) => ({
        roleName: item.roleName,
        id: item.id,
      }));
      setDynamicOptions(extractedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const rows = tableData;

  const handleSetRole = (newValue) => {
    fetchData(newValue ? newValue.id : null);
    setSelectedRole(newValue ? newValue.id : null);
  };

  const onUpdateForm = async (selectedRole, data) => {
    setSubmitLoading(true);
    let combinedData;
    if (dataId == null) {
      combinedData = { roleName: selectedRole, userRoleAccess: data };
    } else {
      combinedData = {
        id: dataId,
        roleName: selectedRole,
        userRoleAccess: data,
      };
    }

    const authServiceFunction =
      dataId === null ? ApiService.addUserAccess : ApiService.updateUserAccess;

    try {
      const res = await authServiceFunction(combinedData, token);
      console.log("res", res);

      // const UserRoleAccessString = JSON.stringify(combinedData?.userRoleAccess);

      if (res?.status === 200) {
        // Call handleMenuUpdate only for addUserAccess API call
        if (authServiceFunction === ApiService.addUserAccess) {
          // Call the updateMenu function
          await handleMenuUpdate(ACCESS_SCREEN);
        }
        toast.success(res?.data);
        // sessionStorage.setItem("UserRoleAccess", UserRoleAccessString || []);
        fetchData(selectedRole);
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(res?.response?.data || EXCEPTION_MESSAGE);
      }
    } catch (error) {
      console.error("Updated item:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    // Sort the rows array based on the resource value in ascending order
    const sortedRows = rows.sort((a, b) =>
      a.resource.localeCompare(b.resource)
    );

    // Add unique IDs to the sorted rows
    const rowsWithId = sortedRows.map((row, index) => ({
      ...row,
      id: index + 1, // Use a unique identifier based on your requirements
    }));

    // Set the sorted and updated rows to the state
    setData(rowsWithId);
  }, [rows]);

  const handleSwitchChange = (id) => {
    try {
      const updatedData = [...data];
      const index = updatedData.findIndex((item) => item.id === id);
      updatedData[index].menuManagement = !updatedData[index].menuManagement;

      const allTrue = data.every((menuMgt) => menuMgt.menuManagement);
      headerCheckboxes.menuManagement = allTrue;
      headerCheckboxes.readAccess = allTrue;

      // If switch is disabled, reset the selected option and disable checkboxes
      if (!updatedData[index].menuManagement) {
        updatedData[index].selectedOption = null;
        updatedData[index].readAccess = false;
        updatedData[index].writeAccess = false;
        updatedData[index].deleteAccess = false;
      } else {
        // If switch is enabled, set readAccess to true
        updatedData[index].readAccess = true;
      }
      setData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const readAccessTrue = data.every((menuMgt) => menuMgt.readAccess);
    headerCheckboxes.menuManagement = readAccessTrue;
    headerCheckboxes.readAccess = readAccessTrue;

    const writeAccessTrue = data.every((menuMgt) => menuMgt.writeAccess);
    headerCheckboxes.writeAccess = writeAccessTrue;

    const deleteAccessTrue = data.every((menuMgt) => menuMgt.deleteAccess);
    headerCheckboxes.deleteAccess = deleteAccessTrue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCheckboxChange = (id, columnName) => {
    try {
      const updatedData = [...data];

      const allTrue = data.every((menuMgt) => menuMgt.readAccess);
      headerCheckboxes.menuManagement = allTrue;
      headerCheckboxes.readAccess = allTrue;

      const index = updatedData.findIndex((item) => item.id === id);

      // Toggle the state directly for all checkboxes
      updatedData[index][columnName] = !updatedData[index][columnName];

      // If "writeAccess" is enabled, enable "deleteAccess"
      if (columnName === "writeAccess" && !updatedData[index][columnName]) {
        updatedData[index].deleteAccess = false;
      }
      if (!updatedData[index].readAccess) {
        updatedData[index].selectedOption = null;
        updatedData[index].menuManagement = false;
        updatedData[index].writeAccess = false;
        updatedData[index].deleteAccess = false;
      }
      setData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleHeaderCheckboxChange = (fieldName, isChecked) => {
    try {
      // Determine the new header checkboxes state
      const newHeaderCheckboxes = { ...headerCheckboxes };

      if (fieldName === "menuManagement") {
        newHeaderCheckboxes.menuManagement = isChecked;
        // If menuManagement is checked, set readAccess and other fields to true
        if (isChecked) {
          newHeaderCheckboxes.readAccess = true;
        } else {
          // If menuManagement is unchecked, reset related fields
          newHeaderCheckboxes.readAccess = false;
          newHeaderCheckboxes.writeAccess = false;
          newHeaderCheckboxes.deleteAccess = false;
        }
      } else if (fieldName === "readAccess") {
        // Update readAccess only if menuManagement is true
        if (newHeaderCheckboxes.menuManagement && isChecked) {
          newHeaderCheckboxes.readAccess = isChecked;
        } else {
          newHeaderCheckboxes.menuManagement = false;
          newHeaderCheckboxes.readAccess = false;
          newHeaderCheckboxes.writeAccess = false;
          newHeaderCheckboxes.deleteAccess = false;
        }
      } else if (fieldName === "writeAccess") {
        if (newHeaderCheckboxes.menuManagement && isChecked) {
          newHeaderCheckboxes.writeAccess = isChecked;
        } else {
          newHeaderCheckboxes.writeAccess = false;
          newHeaderCheckboxes.deleteAccess = false;
        }
      } else if (fieldName === "deleteAccess") {
        if (newHeaderCheckboxes.writeAccess && isChecked) {
          newHeaderCheckboxes.deleteAccess = isChecked;
        } else {
          newHeaderCheckboxes.deleteAccess = false;
        }
      }

      // Update the state for header checkboxes
      setHeaderCheckboxes(newHeaderCheckboxes);

      // Update the row data based on new header checkboxes state
      data.forEach((row, index) => {
        // Always set the Dashboard resource's permissions to true
        if (row.resource === DASHBOARD_SCREEN) {
          row.menuManagement = true;
          row.readAccess = true;
          row.writeAccess = true;
          row.deleteAccess = true;
          return; // Skip further processing for this row
        }

        // Continue with the original logic for other resources
        if (fieldName === "menuManagement") {
          row.menuManagement = isChecked;
          if (!isChecked) {
            row.readAccess = false;
            row.writeAccess = false;
            row.deleteAccess = false;
          } else {
            row.readAccess = true;
          }
        } else if (fieldName === "readAccess") {
          if (row.menuManagement && isChecked) {
            row.readAccess = isChecked;
          } else {
            row.menuManagement = false;
            row.readAccess = false;
            row.writeAccess = false;
            row.deleteAccess = false;
          }
        } else if (fieldName === "writeAccess") {
          if (newHeaderCheckboxes.menuManagement) {
            if (row.menuManagement && isChecked) {
              row.writeAccess = isChecked;
            } else {
              row.writeAccess = false;
              row.deleteAccess = false;
            }
          }
        } else if (fieldName === "deleteAccess") {
          if (newHeaderCheckboxes.writeAccess) {
            if (isChecked) {
              row.deleteAccess = isChecked;
            } else {
              row.deleteAccess = false;
            }
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    data,
    dataId,
    selectedRole,
    dynamicOptions,
    headerCheckboxes,
    handleSetRole,
    setSelectedRole,
    handleSwitchChange,
    handleCheckboxChange,
    onUpdateForm,
    fetchData,
    handleHeaderCheckboxChange,
    loading,
    rows,
    submitLoading,
  };
};
