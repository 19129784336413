import CustomDataGrid from "../../../utils/CustomDataGrid";
import { MonitorService } from "./service";

export default function Monitor() {
  const {
    rows = [],
    columns = [],
    screenName = "",
    isTableLoading = false,
    toolbarMenuProps = {},
  } = MonitorService() || {};

  return (
    <>
      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={isTableLoading}
        toolbarProps={{
          displayName: screenName,
          screenName,
          ...toolbarMenuProps,
        }}
      />
    </>
  );
}
