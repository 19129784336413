import { Fragment } from "react";
import { CardContent, Grid, Typography } from "@mui/material";
import { getDefaultLocale } from "../../../utils/localeUtils";

export const statusOption = ["New", "Open", "Inprogress", "Fixed"];

export const assignedToOption = [
  "J-hope",
  "Park Jimin",
  "Kim Namjoon",
  "Jeon Junkook",
];

export const card = (valuesArray) => (
  <Fragment>
    <CardContent>
      {valuesArray.map((values, index) => (
        <Fragment key={index}>
          {/* Status and Date in the same line */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }} // Align Status and Date in one line
            >
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                {/* {values?.replyedUser || "Renuka"} */}
                {values?.replyedUser}
              </Typography>
              <Typography
                gutterBottom
                sx={{ color: "text.secondary", fontSize: 14 }}
              >
                {values?.time} , {values?.status}
              </Typography>
            </Grid>
          </Grid>

          {/* Well meaning and kindly text */}
          <Typography variant="body1" gutterBottom>
            {values?.clarification === "undefined" ? "" : values?.clarification}
          </Typography>

          {/* Divider between entries with margins */}
          {index < valuesArray.length - 1 && (
            <hr style={{ marginBottom: 8, marginTop: 8 }} />
          )}
        </Fragment>
      ))}
    </CardContent>
  </Fragment>
);

export const formatCurrentDateTime = () => {
  try {
    const currentDate = new Date();
    const locale = getDefaultLocale();

    // Get day, month, and year
    const day = String(currentDate.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    // Format the date as day/month/year
    const formattedDate = `${day}/${month}/${year}`;

    // Format the time
    const formattedTime = currentDate
      .toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toUpperCase();

    const currentDateTime = `${formattedDate} ${formattedTime}`;
    return currentDateTime || "";
  } catch (error) {
    console.log(error);
  }
};
