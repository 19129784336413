import { Box, Typography } from "@mui/material";
import GradientCircularProgress from "../../utils/GradientCircularProgress";

export default function FullScreenLoader({ loading, message }) {
  if (!loading) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1200, // Ensure the loader is above other content
      }}
    >
      <GradientCircularProgress />
      <Typography
        variant="body1"
        sx={{
          mt: { xs: 2, sm: 3 }, // Margin top for spacing
          color: "white",
          textAlign: "center",
          maxWidth: { xs: "75%", sm: "50%", md: "25%" },
        }}
      >
        {message || "Loading..."} {/* Display custom message if provided */}
      </Typography>
    </Box>
  );
}
