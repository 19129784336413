import { useState } from "react";
import {
  Box,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowDownTwoTone,
  KeyboardArrowUpTwoTone,
} from "@mui/icons-material";

export default function RenderMobileView({
  drawerOpen,
  handleToggleDrawer,
  menuItems,
  currentPath,
  openSubMenu,
  setOpenSubMenu,
}) {
  const [openSubMenuChildren, setOpenSubMenuChildren] = useState(null); // Track which main menu is expanded

  const handleToggleSubMenu = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const handleSubMenuToggle = (index) => {
    // Toggle submenu open/close based on the clicked main menu item
    setOpenSubMenuChildren(openSubMenuChildren === index ? null : index);
  };

  return (
    <>
      <Drawer anchor="left" open={drawerOpen} onClose={handleToggleDrawer}>
        <List>
          {menuItems.map((menu, index) => {
            const hasAccessChildren =
              menu.children &&
              menu.children.some((child) => child.isAccess !== false);

            if (!menu.isAccess && (!menu.children || !hasAccessChildren)) {
              return null; // Hide the menu if both the menu and its children have isAccess set to false
            }
            const isActive = menu.link === currentPath; // Check if the menu is active

            return (
              <Box key={menu.text}>
                {menu.children ? (
                  // Render ListItemButton for menu items with children
                  <ListItemButton
                    onClick={() => handleToggleSubMenu(index)}
                    sx={{
                      backgroundColor: isActive ? "#c5cae9" : "inherit",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography color={isActive ? "secondary" : "inherit"}>
                          {menu.text}
                        </Typography>
                      }
                    />
                    {menu.children && (
                      <>
                        {openSubMenu === index ? (
                          <KeyboardArrowUpTwoTone />
                        ) : (
                          <KeyboardArrowDownTwoTone />
                        )}
                      </>
                    )}
                  </ListItemButton>
                ) : (
                  // Render ListItemButton for menu items without children
                  <ListItemButton
                    component={Link}
                    to={menu.link}
                    onClick={handleToggleDrawer}
                    sx={{
                      backgroundColor: isActive ? "#c5cae9" : "inherit",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography color={isActive ? "secondary" : "inherit"}>
                          {menu.text}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                )}

                {/* Submenu */}
                {menu.children && openSubMenu === index && (
                  <List component="div" disablePadding>
                    {menu.children.map(
                      (child) =>
                        child.isAccess !== false && (
                          <div key={child.text}>
                            {/* Main Menu Item */}
                            <ListItemButton
                              key={child.text}
                              component={Link}
                              to={child.link !== "#" ? child.link : undefined} // Only add link if it exists
                              onClick={() => handleSubMenuToggle(index)} // Toggle submenu visibility
                              sx={{
                                backgroundColor:
                                  currentPath === child.link
                                    ? "#c5cae9"
                                    : "inherit",
                                "&:hover": {
                                  backgroundColor: "#f5f5f5",
                                },
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    color={
                                      currentPath === child.link
                                        ? "secondary"
                                        : "inherit"
                                    }
                                  >
                                    {child.text}
                                  </Typography>
                                }
                              />
                              {/* Show icon only if the main menu item has children */}
                              {child.children && (
                                <>
                                  {openSubMenuChildren === index ? (
                                    <KeyboardArrowUpTwoTone />
                                  ) : (
                                    <KeyboardArrowDownTwoTone />
                                  )}
                                </>
                              )}
                            </ListItemButton>

                            {/* Render Submenu Children if they exist */}
                            {child.children &&
                              openSubMenuChildren === index && (
                                <List component="div" disablePadding>
                                  {child.children.map(
                                    (subChild) =>
                                      subChild.isAccess !== false && (
                                        <ListItemButton
                                          key={subChild.text}
                                          component={Link}
                                          to={subChild.link}
                                          onClick={handleToggleDrawer}
                                          sx={{
                                            backgroundColor:
                                              currentPath === subChild.link
                                                ? "#c5cae9"
                                                : "inherit",
                                            "&:hover": {
                                              backgroundColor: "#f5f5f5",
                                            },
                                          }}
                                        >
                                          <ListItemText
                                            primary={
                                              <Typography
                                                color={
                                                  currentPath === subChild.link
                                                    ? "secondary"
                                                    : "inherit"
                                                }
                                              >
                                                {subChild.text}
                                              </Typography>
                                            }
                                          />
                                        </ListItemButton>
                                      )
                                  )}
                                </List>
                              )}
                          </div>
                        )
                    )}
                  </List>
                )}
              </Box>
            );
          })}
        </List>
      </Drawer>
    </>
  );
}
