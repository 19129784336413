export const accessColumns = [
  {
    field: "id",
    headerName: "S.No.",
    align: "center",
    headerAlign: "center",
    width: 100,
  },
  {
    field: "resource",
    headerName: "Resources",
    width: 300,
  },
  {
    field: "menuManagement",
    headerName: "Menu Management",
    width: 200,
    renderCell: "switch",
  },
  {
    field: "readAccess",
    headerName: "Read Access",
    width: 200,
    renderCell: "readAccess",
  },
  {
    field: "writeAccess",
    headerName: "Write Access",
    width: 200,
    renderCell: "writeAccess",
  },
  {
    field: "deleteAccess",
    headerName: "Delete Access",
    width: 200,
    renderCell: "deleteAccess",
  },
];
