import {
  AccountBalanceWalletTwoTone,
  AddModeratorTwoTone,
  AssignmentTwoTone,
  AssuredWorkloadTwoTone,
  CreditScoreTwoTone,
  GroupAddTwoTone,
  GroupsTwoTone,
  LocalMallTwoTone,
  MapTwoTone,
  MonetizationOnTwoTone,
  ReportTwoTone,
  ScheduleSendTwoTone,
  SettingsAccessibilityTwoTone,
  SpaceDashboardTwoTone,
} from "@mui/icons-material";

// Map breadcrumb text to specific icons
export const IconMap = {
  access: <SettingsAccessibilityTwoTone />,
  assignCommission: <AssignmentTwoTone />,
  beneficiary: <GroupAddTwoTone />,
  fieldMapping: <MapTwoTone />,
  commission: <MonetizationOnTwoTone />,
  customer: <GroupsTwoTone />,
  dashboard: <SpaceDashboardTwoTone />,
  product: <LocalMallTwoTone />,
  payFrequency: <AccountBalanceWalletTwoTone />,
  report: <ReportTwoTone />,
  role: <AssuredWorkloadTwoTone />,
  schedule: <ScheduleSendTwoTone />,
  transaction: <CreditScoreTwoTone />,
  variable: <AddModeratorTwoTone />,
};
