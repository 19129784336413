import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  CancelTwoTone,
  CloseTwoTone,
  EditTwoTone,
  SaveTwoTone,
} from "@mui/icons-material";
import CustomFieldDialog from "../../common/customFieldDialog";
import {
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  isValidTransaction,
  isDuplicate,
  orderErrorFocus,
} from "../../config/fieldConfig";
import { processFormData } from "../../../utils/Master/formDataProcessor";
import { prepareTransformedObjects } from "../../../utils/Master/transformObjectsHelper";
import { handleSaveType } from "../../../utils/Master/handleSaveTypeHelper";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../../utils/CustomInputFields";

export default function TransactionForm({
  screenName,
  mode,
  open,
  onClose,
  fields,
  writeAccess,
  editItemId,
  editFormData, // Corrected syntax
  viewData,
  setViewData,
  onSubmitForm,
  dynamicFormFields,
  handleAddCustomField,
  openCustomFieldDialog,
  setOpenCustomFieldDialog,
  isEData = false,
  resObject,
  response,
  selectedSalesRep1,
  setSelectedSalesRep1,
  selectedCustomer1,
  setSelectedCustomer1,
  selectedProduct1,
  setSelectedProduct1,
  setNewFields,
  transactionCombos,
  addTransactionType,
  transactionTypeOptions,
  transactionTypeOptionsWithId,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [isProjection, setIsProjectionChecked] = useState(false); // Default to false for add mode

  const [selectedSalesRep, setSelectedSalesRep] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  const [isAddingTransactionType, setIsAddingTransactionType] = useState(false);
  const [newTransactionType, setNewTransactionType] = useState("");

  useEffect(() => {
    setSelectedSalesRep1("");
    setSelectedCustomer1("");
    setSelectedProduct1("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, open]);

  useEffect(() => {
    reset();
    setIsProjectionChecked(false);
    setIsAddingTransactionType(false);
    setNewFields([]);

    if (mode === "add") {
      setViewData();
    }

    // If an editItemId is provided and editFormData exists, fetch the data for editing
    if (mode === "edit" && editItemId !== null && editFormData) {
      // Set form data with the provided editFormData for regular fields
      fields.forEach((field) => {
        setValue(field.name, editFormData[field.name]);
      });

      // Set the isProjection state based on the value from editFormData
      setIsProjectionChecked(editFormData.projection === true);

      // Set selected values for Autocomplete fields
      setSelectedSalesRep1(editFormData.salesRep);
      setSelectedCustomer1(editFormData.customerId);
      setSelectedProduct1(editFormData.productId);

      // Set selected values for Autocomplete fields
      setSelectedSalesRep(editFormData.salesRep);
      setSelectedCustomer(editFormData.customerId);
      setSelectedProduct(editFormData.productId);

      // Set form data with the provided editFormData for dynamic fields
      dynamicFormFields.forEach((field) => {
        const nestedField = editFormData.customFields[field.name];
        setValue(field.name, nestedField); // Edit form customField append data to the fields

        // Check if the nestedField is an object and has the property with the same name
        if (nestedField && nestedField.hasOwnProperty(field.name)) {
          setValue(field.name, nestedField[field.name]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dynamicFormFields,
    editFormData,
    editItemId,
    mode,
    open,
    reset,
    setValue,
  ]);

  const onSubmit = async (formData) => {
    try {
      const { mainFormData, customFields } = processFormData(
        formData,
        fields,
        dynamicFormFields,
        transactionTypeOptionsWithId,
        "transactionType",
        "transactionTypeId"
      );

      mainFormData.salesRep = selectedSalesRep;
      mainFormData.customerId = selectedCustomer;
      mainFormData.productId = selectedProduct;
      mainFormData.valid = true;

      // Prepare transformed data and objects using the helper function
      const { transformedData, transformedObjects } = prepareTransformedObjects(
        mainFormData,
        customFields,
        response,
        resObject
      );

      // check if the type form is closed
      if (isAddingTransactionType === false) {
        // Validate that transformedData is not empty
        if (!transformedData || Object.keys(transformedData).length === 0) {
          throw new Error("Transformed data is empty");
        }

        // Validate that transformedObjects is not empty
        if (
          !transformedObjects ||
          Object.keys(transformedObjects).length === 0
        ) {
          throw new Error("TransformedObjects is empty");
        }

        // You can now proceed to handle the transformedData as required, such as sending it to the server
        // Only call handleSubmit if there are no validation errors
        if (mode === "add") {
          onSubmitForm(transformedData, "add", transformedObjects);
        } else if (mode === "edit") {
          transformedData.id = editItemId;
          onSubmitForm(transformedData, "edit", transformedObjects);
        }
        onClose();
      } else {
        setError("addNewTransactionType", {
          type: "duplicate",
          message: "Please save or close the New Transaction Type",
        });
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error fetching customer data:", error?.message);
    }
  };

  const handleAddTransactionType = () => {
    setIsAddingTransactionType(true);
    setNewTransactionType("");
  };

  const handleCancelAdd = () => {
    setIsAddingTransactionType(false);
    setNewTransactionType("");
    clearErrors("addNewTransactionType");
  };

  const handleSaveTransactionType = async (event) => {
    await handleSaveType({
      event,
      typeOptions: transactionTypeOptions,
      newType: newTransactionType,
      screenName,
      triggerValidation: trigger, // Your validation function
      setError,
      addType: addTransactionType, // API function for adding transaction types
      setValue,
      clearErrors,
      setNewType: setNewTransactionType,
      setIsAddingType: setIsAddingTransactionType,
      typeName: "addNewTransactionType",
    });
  };

  // Transaction id and line check
  const watchTransactionId = watch("transactionId");
  const watchTransactionLine = watch("transactionLine");

  useEffect(() => {
    if (watchTransactionId && watchTransactionLine) {
      isValidTransaction(
        watchTransactionId,
        watchTransactionLine,
        mode,
        editItemId,
        editFormData,
        transactionCombos
      );
      trigger("transactionId");
      trigger("transactionLine");
    }
  }, [
    watchTransactionId,
    watchTransactionLine,
    mode,
    editItemId,
    editFormData,
    transactionCombos,
    trigger,
  ]);

  const fieldRefs = useRef({});

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
        &nbsp;Transaction
        {viewData && writeAccess && editItemId && !editFormData?.processed ? (
          <IconButton
            aria-label="edit"
            style={{ position: "absolute", top: 10, right: 45 }}
            color="primary"
            title="Edit"
            onClick={() => setViewData(false)}
          >
            <EditTwoTone />
          </IconButton>
        ) : null}
        <IconButton
          aria-label="close"
          title="Close"
          style={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            orderErrorFocus(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index}>
                {field.name === "projection" ? (
                  <FormControl
                    error={!!errors[field.name]}
                    component="fieldset"
                    variant="outlined"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={isProjection} // Use the state variable here
                          onChange={(e) =>
                            setIsProjectionChecked(e.target.checked)
                          } // Update the state when the checkbox changes
                          disabled={viewData} // Disable the checkbox based on viewData condition
                        />
                      }
                      sx={{ mt: 1 }} // UI display center
                      label={field.label}
                      required={field.required}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                    />
                    <FormHelperText>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                ) : field.name === "transactionType" ? (
                  isAddingTransactionType ? (
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        <TextField
                          label="Add New Transaction Type"
                          name="addNewTransactionType"
                          variant="outlined"
                          size="small"
                          margin="dense"
                          autoFocus
                          fullWidth
                          required
                          value={newTransactionType}
                          {...register("addNewTransactionType", {
                            required: "Add New Transaction Type is required",
                            validate: {
                              isEmpty: (value) => isEmpty(value),
                              isValueContainsSplChars: (value) =>
                                isValueContainsSplChars(value),
                              isValueStartsWithNumber: (value) =>
                                isValueStartsWithNumber(value, field.label),
                              isDuplicate: (value) => {
                                if (field.name === "transactionType") {
                                  return isDuplicate(
                                    transactionTypeOptions,
                                    field.label,
                                    value,
                                    mode,
                                    [],
                                    field.name,
                                    isEData
                                  );
                                } else {
                                  return true;
                                }
                              },
                            },
                          })}
                          error={!!errors.addNewTransactionType}
                          helperText={errors.addNewTransactionType?.message}
                          FormHelperTextProps={{ sx: { mb: -3 } }}
                          onChange={(e) => {
                            setNewTransactionType(e.target.value);
                            setValue("addNewTransactionType", e.target.value);
                            setError("addNewTransactionType");
                            trigger("addNewTransactionType");
                          }}
                          onBlur={() => {
                            trigger("addNewTransactionType");
                          }}
                          inputRef={(el) => {
                            if (el) {
                              fieldRefs.current[field.name] = el;
                            }
                          }} // Set ref
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <IconButton
                          aria-label="save"
                          color="success"
                          title="Save"
                          onClick={handleSaveTransactionType}
                        >
                          <SaveTwoTone />
                        </IconButton>
                        <IconButton
                          aria-label="cancel"
                          color="primary"
                          title="Cancel"
                          onClick={handleCancelAdd}
                        >
                          <CancelTwoTone />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={10}>
                        <CustomAutocomplete
                          field={field}
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          watch={watch}
                          errors={errors}
                          fieldRefs={fieldRefs}
                          style={{
                            pointerEvents:
                              viewData && editItemId ? "none" : "auto",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {!viewData && writeAccess ? (
                          <IconButton
                            aria-label="add"
                            color="primary"
                            title="Add Field"
                            onClick={handleAddTransactionType}
                          >
                            <AddCircleOutlineTwoTone />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  )
                ) : field.type === "autocomplete" ? (
                  <Autocomplete
                    sx={{ mt: 1 }}
                    name={field.name}
                    options={field.options}
                    getOptionLabel={(option) => {
                      if (field.name === "salesRep") {
                        return option.salesRepLabel;
                      } else if (field.name === "customerId") {
                        return option.custLabel;
                      } else if (field.name === "productId") {
                        return option.prodLabel;
                      }
                      return ""; // Fallback return in case none match
                    }}
                    value={
                      // Ensure a default value (null) is set consistently
                      field.name === "salesRep"
                        ? field.options.find(
                            (option) => option.salesRepId === selectedSalesRep1
                          ) || null
                        : field.name === "customerId"
                        ? field.options.find(
                            (option) => option.custId === selectedCustomer1
                          ) || null
                        : field.name === "productId"
                        ? field.options.find(
                            (option) => option.prodId === selectedProduct1
                          ) || null
                        : null // Default case
                    }
                    onChange={(event, newValue) => {
                      if (field.name === "salesRep") {
                        setSelectedSalesRep(
                          newValue ? newValue.salesRepId : null
                        ); // Update selectedSalesRep state
                        setSelectedSalesRep1(
                          newValue ? newValue.salesRepId : null
                        ); // Update selectedSalesRep state
                      } else if (field.name === "customerId") {
                        setSelectedCustomer(newValue ? newValue.custId : null); // Update selectedCustomer state
                        setSelectedCustomer1(newValue ? newValue.custId : null);
                      } else if (field.name === "productId") {
                        setSelectedProduct(newValue ? newValue.prodId : null); // Update selectedProduct state
                        setSelectedProduct1(newValue ? newValue.prodId : null);
                      }
                    }} // Ensure a default null value if no value is selected
                    style={{
                      pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field.label}
                        variant="outlined"
                        required={field.required}
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            pointerEvents: "none",
                          },
                        }}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        error={!!errors[field.name] && !params.inputProps.value} // Check if field is empty
                        helperText={
                          errors[field.name]?.message &&
                          (!params.inputProps.value
                            ? errors[field.name]?.message
                            : "")
                        } // Show helper text only when there's an error and field is empty
                        FormHelperTextProps={{ sx: { mb: -3 } }}
                        inputRef={(el) => {
                          if (el) {
                            fieldRefs.current[field.name] = el; // Set ref for the input
                          }
                        }}
                      />
                    )}
                  />
                ) : (
                  <CustomTextField
                    field={field}
                    register={register}
                    trigger={trigger}
                    errors={errors}
                    fieldRefs={fieldRefs}
                    autoFocus={editItemId && isEData ? true : field.autoFocus}
                    InputProps={{
                      style: {
                        pointerEvents: viewData && editItemId ? "none" : "auto",
                      }, // remove the hover effect
                      pattern: field.type === "number" ? "\\d*" : undefined,
                    }}
                    validate={{
                      isValueContainsSplChars: (value) =>
                        field.type === "text" &&
                        field.name !== "notes" &&
                        field.type !== "email" &&
                        field.type !== "number"
                          ? isValueContainsSplChars(value)
                          : true,
                      isValidTransaction: (value) => {
                        if (field.name === "transactionId") {
                          return isValidTransaction(
                            value,
                            watchTransactionLine,
                            mode,
                            editItemId,
                            editFormData,
                            transactionCombos,
                            isEData
                          );
                        } else if (field.name === "transactionLine") {
                          return isValidTransaction(
                            watchTransactionId,
                            value,
                            mode,
                            editItemId,
                            editFormData,
                            transactionCombos,
                            isEData
                          );
                        }
                      },
                    }}
                    inputProps={{
                      max: "9999-12-31",
                      style: {
                        textTransform:
                          field.type === "date" ? "uppercase" : "none",
                      },
                      ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                    }}
                    InputLabelProps={{
                      shrink: field.type === "date" ? true : field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          {viewData && editItemId ? null : (
            <DialogActions sx={{ p: 0, mt: 2 }}>
              <Button
                onClick={() => setOpenCustomFieldDialog(true)}
                variant="outlined"
                color="primary"
              >
                Add Custom Field
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {mode === "add" ? "Submit" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          )}
        </Box>
      </DialogContent>

      {/* Custom Field Dialog */}
      <CustomFieldDialog
        open={openCustomFieldDialog}
        onClose={() => setOpenCustomFieldDialog(false)}
        onAddCustomField={handleAddCustomField}
      />
    </Dialog>
  );
}
