import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";

// calculation fields
const Calculations = forwardRef(
  (
    {
      isSubmitted = false,
      setIsSubmitted = () => {},
      RemoveCalcRow = () => {},
      // row,
      calculationFields = [],
      getCalcOptions = () => {},
      calcRow = [],
      mode = "",
      tieredData = [],
      productNames = [],
      fullProductNames = [],
      customerNames = [],
      viewData = false,
      editItemId = "",
      getOptionsStartsWith = () => {},
      setCalcRow = () => {},
      handleCalculation = () => {},
      isEmpty = () => {},
      addCalcRow = () => {},
    },
    ref
  ) => {
    const {
      control,
      setValue,
      trigger,
      clearErrors,
      formState: { errors },
    } = useForm({});

    const errorInputRefs = useRef({});

    const isMounted = useRef(true); // Track if component is mounted

    useEffect(() => {
      // Set isMounted to false when the component unmounts
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      if (isSubmitted) {
        const timeoutId = setTimeout(() => {
          try {
            const firstErrorField = Object.keys(errors)[0];
            if (firstErrorField && errorInputRefs.current[firstErrorField]) {
              // Focus the first field with an error if available
              errorInputRefs.current[firstErrorField].focus();
            }
            setIsSubmitted(false); // Reset after focusing
          } catch (error) {
            console.error("Error focusing input:", error);
          }
        }, 0); // Using a 0ms delay to allow state update to propagate

        // Cleanup timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted, errors]);

    const handleRemoveCalcRow = (index, row) => {
      setValue(`calcField_${index}`, "");
      setValue(`calcDescription_${index}`, "");
      setValue(`calcOption_${index}`, "");
      clearErrors(`calcField_${index}`, "");
      clearErrors(`calcDescription_${index}`, "");
      clearErrors(`calcOption_${index}`, "");
      RemoveCalcRow(index); // logic for removing the row
    };

    // Memoize validateForm to ensure it doesn't change on each render
    const validateForm = useCallback(async () => {
      try {
        await trigger();
        // Combine errors
        const combinedErrors = { ...errors };
        return combinedErrors;
      } catch (error) {
        console.log(error);
      }
    }, [trigger, errors]);

    useImperativeHandle(ref, () => ({ validateForm }), [validateForm]);

    return calcRow.map((row, rowIndex) => (
      <Grid
        container
        spacing={2}
        key={rowIndex}
        className="bg-gray-100 border"
        mt={rowIndex > 0 ? 1 : -1}
        pb={1.5}
      >
        {calculationFields.map((field, setIndex) => (
          <Grid
            item
            xs={12}
            sm={field.isCalculationBtn ? 2.5 : field.isEqual ? 5.5 : 5.5}
            md={field.isCalculationBtn ? 1 : field.isEqual ? 3.66 : 3.66}
            lg={
              field.isCalculationBtn
                ? 1
                : field.isEqual
                ? 1
                : field.name === "percentage"
                ? 1
                : field.name === "calcSymbols"
                ? 1
                : 2
            }
            key={setIndex}
            p={1}
          >
            {field.name === "calcDescription" ? (
              row[`isAutocomplete_${field.name}`] ||
              row.calcField === "productName" ||
              row.calcField === "fullProductName" ||
              row.calcField === "customerName" ? (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={row[field.name] || ""}
                  rules={
                    mode === "edit"
                      ? {
                          required:
                            row[field.name] === null ||
                            row[field.name] === undefined ||
                            (row[field.name] === "" &&
                              field.required &&
                              tieredData.length === 0)
                              ? `${field.label} is required`
                              : false,
                        }
                      : {
                          required:
                            field.required && tieredData.length === 0
                              ? `${field.label} is required`
                              : false,
                        }
                  }
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={
                        row.calcField === "productName"
                          ? productNames
                          : row.calcField === "fullProductName"
                          ? fullProductNames
                          : row.calcField === "customerName"
                          ? customerNames
                          : getOptionsStartsWith("$")
                      }
                      fullWidth
                      disableClearable
                      style={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                      }}
                      onBlur={() => {
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          size="small"
                          disabled={tieredData.length === 0 ? false : true}
                          required={tieredData.length !== 0 ? false : true}
                          error={!!error}
                          helperText={error ? error.message : null}
                          FormHelperTextProps={{ sx: { mb: -3 } }}
                          inputRef={(ref) => {
                            errorInputRefs.current[
                              `${field.name}_${rowIndex}`
                            ] = ref;
                          }}
                          InputLabelProps={{
                            style: {
                              pointerEvents: "none",
                            },
                          }}
                          onChange={(e) => {
                            const userInput = e.target.value;

                            // Check if the input starts with '$' to show options
                            if (!userInput.startsWith("$")) {
                              const updatedRow = {
                                ...row,
                                [`isAutocomplete_${field.name}`]: false,
                              };
                              setCalcRow((prevState) =>
                                prevState.map((r, index) =>
                                  index === rowIndex ? updatedRow : r
                                )
                              );
                            }
                          }}
                        />
                      )}
                      value={
                        row[field.name] !== undefined && row[field.name] !== ""
                          ? row[field.name]
                          : null
                      }
                      onChange={(_, newValue) => {
                        onChange(newValue);
                        handleCalculation(rowIndex, field.name, newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                    />
                  )}
                />
              ) : (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={row[field.name] || ""}
                  rules={{
                    required:
                      mode === "edit"
                        ? row[field.name] === null ||
                          row[field.name] === undefined ||
                          (row[field.name] === "" &&
                            field.required &&
                            tieredData.length === 0)
                          ? `${field.label} is required`
                          : false
                        : field.required && tieredData.length === 0
                        ? `${field.label} is required`
                        : false,
                    validate: {
                      validateNotEmptySpace: (value) =>
                        field.required &&
                        value !== "" &&
                        field.name === `calcDescription`
                          ? isEmpty(value)
                          : true,
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      name={`${field.name}_${rowIndex}`}
                      type={field.type}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onBlur={() => {
                        if (mode === "edit") {
                          setValue(`calcDescription_${rowIndex}`, "");
                        }
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                      }}
                      disabled={tieredData.length === 0 ? false : true}
                      label={field.label}
                      required={tieredData.length !== 0 ? false : true}
                      value={row[field.name]}
                      style={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                      }}
                      InputLabelProps={{
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      onChange={(e) => {
                        onChange(e.target.value);
                        const userInput = e.target.value;
                        handleCalculation(rowIndex, field.name, userInput);
                        // Check if the input starts with '$' to switch to autocomplete
                        if (userInput.startsWith("$")) {
                          const updatedRow = {
                            ...row,
                            [`isAutocomplete_${field.name}`]: true,
                          };
                          setCalcRow((prevState) =>
                            prevState.map((r, index) =>
                              index === rowIndex ? updatedRow : r
                            )
                          );
                        }
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                      FormHelperTextProps={{ sx: { mb: -3 } }}
                      inputRef={(ref) => {
                        errorInputRefs.current[`${field.name}_${rowIndex}`] =
                          ref;
                      }}
                    />
                  )}
                />
              )
            ) : field.isEqual ? (
              <Typography variant="h6" marginTop={0.5}>
                =
              </Typography>
            ) : field.isCalculationBtn ? (
              viewData && editItemId ? null : (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      addCalcRow(rowIndex);
                    }}
                    color={"success"}
                  >
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: "black" }}
                    ></Typography>
                    {tieredData.length === 0 ? (
                      <AddCircleOutlineTwoTone />
                    ) : null}
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveCalcRow(rowIndex, row)}
                    color={"error"}
                    disabled={calcRow.length > 1 ? false : true}
                  >
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: "black" }}
                    ></Typography>
                    {tieredData.length === 0 ? (
                      <RemoveCircleOutlineTwoTone />
                    ) : null}
                  </IconButton>
                </Box>
              )
            ) : (
              <Controller
                name={`${field.name}_${rowIndex}`}
                control={control}
                defaultValue={row[field.name] || ""}
                rules={
                  mode === "edit"
                    ? {
                        required:
                          (field.required && row[field.name] === null) ||
                          (field.required && row[field.name] === undefined) ||
                          (row[field.name] === "" &&
                            field.required &&
                            tieredData.length === 0)
                            ? `${field.label} is required`
                            : false,
                      }
                    : null
                }
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    options={getCalcOptions(field, row.calcEntityName)}
                    fullWidth
                    disableClearable
                    style={{
                      pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                    }}
                    disabled={tieredData.length === 0 ? false : true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label={field.label}
                        disabled={tieredData.length === 0 ? false : true}
                        required={tieredData.length !== 0 ? false : true}
                        InputLabelProps={{
                          style: {
                            pointerEvents: "none", // Disable pointer events for the label
                          },
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                        FormHelperTextProps={{ sx: { mb: -3 } }}
                        inputRef={(ref) => {
                          errorInputRefs.current[`${field.name}_${rowIndex}`] =
                            ref;
                        }}
                      />
                    )}
                    value={
                      row[field.name] !== undefined && row[field.name] !== ""
                        ? row[field.name]
                        : null
                    }
                    onBlur={() => {
                      trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                      if (mode === "edit") {
                        trigger(`calcField_${rowIndex}`); // Trigger validation for field_0 as well
                      }
                    }}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                      handleCalculation(rowIndex, field.name, newValue);

                      // Update the row with the new value
                      row[field.name] = newValue;

                      // Trigger validation for both fields
                      trigger(`${field.name}_${rowIndex}`).then(() => {
                        // Check if the other field needs validation
                        if (
                          mode === "edit" &&
                          field.name === "calcEntityName" &&
                          row.calcField !== undefined &&
                          row.calcField !== null &&
                          row.calcField !== ""
                        ) {
                          trigger(`calcField_${rowIndex}`);
                        } else if (
                          field.name === "calcEntityName" &&
                          row.calcEntityName !== undefined &&
                          row.calcEntityName !== null &&
                          row.calcEntityName !== ""
                        ) {
                          console.log(
                            `${field.name}_${rowIndex}`,
                            `calcField_${rowIndex}`,
                            newValue,
                            row
                          );
                          clearErrors(`calcField_${rowIndex}`); // Clear error if the value is not a duplicate
                          setValue(`calcField_${rowIndex}`, row.calcField);
                        }
                      });
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                )}
              />
            )}
          </Grid>
        ))}
      </Grid>
    ));
  }
);
export default Calculations;
