import { useEffect, useRef, useState } from "react";
import ApiService from "../../../apis/ApiService";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { NO_DATA_MSG } from "../../config/toastMessage";
import { AUDIT_DISPLAY_NAME, AUDIT_LOG_SCREEN } from "../../config/constants";
import { getInitialDateRange } from "../../../utils/getInitialDateRange";
import { addSerialNumber, formatDateTime } from "../../config/fieldConfig";
import AuditLogColumns from "./columns";

export const AuditLogService = () => {
  // State for table data and loading indicator
  const [data, setTableData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);

  // State for tracking the selected filter option ("Get All", "My Data", "My Reportee")
  const [selectedOption, setSelectedOption] = useState("Get All");

  // State for API endpoint, dynamically set based on selected option
  const [apiEndpoint, setApiEndpoint] = useState("getAuditLogsWithHierarchy");

  // Ref to control component remounting to avoid unnecessary API calls
  const stopRemount = useRef(true);

  // Constants for screen identifier
  const screenName = AUDIT_LOG_SCREEN || "";

  // Constants for display screen name UI identifier
  const displayName = AUDIT_DISPLAY_NAME || "";

  // Message to display when there is no data
  const noTableDataMsg = NO_DATA_MSG || "";

  // Get the initial date range using a utility function
  const { startDate, endDate } = getInitialDateRange(stopRemount);

  // Handles option change and sets loading state, API endpoint, and selected option
  const handleOptionChange = (option, endpoint) => {
    // Set loading state only if the option has changed
    setIsTableLoading(selectedOption !== option);
    // Update API endpoint based on the selected option
    setApiEndpoint(endpoint);
    // Set the currently selected option
    setSelectedOption(option);
    // Flag to trigger data fetching on next render
    stopRemount.current = true;
  };

  // Menu items with labels and associated actions for each option
  const menuItems = [
    {
      label: "Get All",
      action: () => handleOptionChange("Get All", "getAuditLogsWithHierarchy"),
    }, // Action for fetching all logs
    {
      label: "My Data",
      action: () => handleOptionChange("My Data", "getMyAuditLog"),
    }, // Action for fetching user-specific logs
    {
      label: "My Reportee",
      action: () =>
        handleOptionChange("My Reportee", "getAuditLogForReportees"),
    }, // Action for fetching reportee logs
  ];

  // Props for the toolbar menu, including items and currently selected option
  const toolbarMenuProps = { menuItems, selectedOption };

  // Effect to fetch data when the component mounts or when dates change
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false; // Prevent further remounting
      fetchData(); // Fetch data on mount or date change
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, startDate, endDate]); // Trigger data fetch when deps changes

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      // Validate the date range before making the API call
      if (!startDate || !endDate) {
        throw new Error("Start date and end date must be provided.");
      }

      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Call the API service function using the current endpoint
      const res = await ApiService[apiEndpoint](token, startDate, endDate);

      // Check if the response status is 200 and the data is an array
      if (res?.status === 200 && Array.isArray(res?.data)) {
        // Format the data if response is valid
        const formattedData = res?.data.map((item) => ({
          ...item,
          date: formatDateTime(item?.date || ""), // Format the date using the utility function
        }));
        // Update the table data with the formatted data
        setTableData(formattedData);
      } else {
        // Clear table data if API response is not as expected
        setTableData([]);
      }
    } catch (error) {
      // Handle errors gracefully and log them
      console.error("Error fetching data:", error?.message);
      // Set table data to empty array on error to avoid displaying stale data
      setTableData([]);
    } finally {
      // Stop the loading indicator once data fetching is complete
      setIsTableLoading(false);
    }
  };

  // Create column configuration by calling the column function
  const columns = Array.isArray(AuditLogColumns()) ? AuditLogColumns() : [];

  // Add serial numbers to the rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : [];

  return {
    rows,
    columns,
    screenName,
    displayName,
    noTableDataMsg,
    isTableLoading,
    toolbarMenuProps,
  };
};
