import { useState, useEffect } from "react";
import { Container } from "@mui/material";
import FullScreenLoader from "../../common/LoadingScreen";
import AvatarUploadBox from "./AvatarUploadBox";
import ProfileForm from "./form";
import { ProfileService } from "./service";

export default function Profile() {
  const {
    fields = [],
    profileData = {},
    loading = false,
    updateProfile = () => {},
  } = ProfileService() || {};

  // State to manage the selected image for the avatar
  const [selectedImage, setSelectedImage] = useState(null);

  // Update selectedImage when profileData changes
  useEffect(() => {
    // Set selectedImage if imageData is present in profileData
    if (profileData?.imageData) {
      setSelectedImage(profileData?.imageData);
    }
  }, [profileData]);

  // Check if profile data is available and loading is false
  const isProfileAvailable =
    !loading && profileData && Object.keys(profileData)?.length > 0;

  return (
    <Container maxWidth="sm" sx={{ pt: 2, pb: 4 }}>
      {/* Show loading screen if loading is true */}
      <FullScreenLoader loading={loading} />

      {/* Component for uploading the avatar image */}
      <AvatarUploadBox
        selectedImage={selectedImage} // Current selected image for the avatar
        setSelectedImage={setSelectedImage} // Function to update the selected image
      />

      {/* Render ProfileForm only if not loading and profileData is available */}
      {isProfileAvailable && (
        <ProfileForm
          fields={fields} // Fields to render in the profile form
          profileData={profileData} // User's profile data to populate the form
          updateProfile={updateProfile} // Function to handle profile updates
          selectedImage={selectedImage} // Selected image for the form
        />
      )}
    </Container>
  );
}
