import { Grid } from "@mui/material";
import PricingHome from "../Pricing/pricingHome";

export default function Section6({ pricingRef, bgImgSlide }) {
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${bgImgSlide})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        padding: 2,
        height: "auto", // Maintain auto height
        minHeight: { xs: "60vh", sm: "70vh", md: "80vh" }, // Minimum height adjustments for different screens
        display: "flex",
        alignItems: "center", // Center content vertically
        justifyContent: "center", // Center content horizontally
      }}
      ref={pricingRef}
    >
      <PricingHome />
    </Grid>
  );
}
