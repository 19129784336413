import { alpha, styled, useMediaQuery } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import {
  AddBoxRounded,
  DisabledByDefaultRounded,
  IndeterminateCheckBoxRounded,
} from "@mui/icons-material";

// Custom styling for TreeItem
export const CustomTreeItem = styled(TreeItem)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

  return {
    [`& .${treeItemClasses.content}`]: {
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.2, 0),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      display: "flex",
      alignItems: "center",
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      ...(isMobile && {
        // For mobile, remove the tree structure
        marginLeft: 0,
        paddingLeft: 0,
        borderLeft: "none",
      }),
      ...(!isMobile && {
        // Keep the tree structure for larger views
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      }),
    },
  };
});

// Role tree view
export function ExpandIcon(props) {
  return <AddBoxRounded {...props} sx={{ opacity: 0.8 }} />;
}

export function CollapseIcon(props) {
  return <IndeterminateCheckBoxRounded {...props} sx={{ opacity: 0.8 }} />;
}

export function EndIcon(props) {
  return <DisabledByDefaultRounded {...props} sx={{ opacity: 0.3 }} />;
}

export const handleButtonClick = (e) => {
  e.stopPropagation(); // Prevent the click event from propagating to the CustomTreeItem
};
