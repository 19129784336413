import toast from "react-hot-toast";
import ApiService from "../apis/ApiService";
import { BENEFICIARY_SCREEN } from "../components/config/constants";
import { USER_ID } from "../components/config/sessionStorage";
import { EXCEPTION_MESSAGE } from "../components/config/toastMessage";
import { handleMenuUpdate } from "./menuHandler";

// Helper function to check if an object is empty
const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// Function to handle API calls with feedback messages and data handling
export const handleMasterApiCall = async (
  transformedObjects, // Object with transformed data to save
  screen, // Current screen (e.g., Beneficiary, Customer)
  token = "", // Authorization token for API requests
  action, // Action performed (e.g., edit, delete)
  promise, // Promise representing the API call
  fetchData // Function to fetch fresh data after API call
) => {
  try {
    // Await the resolution of the API promise
    const res = await promise;

    // Log the response for debugging purposes
    console.log(`Response for ${action}:`, res);

    // Display feedback messages based on the response status
    if (res?.status === 200) {
      // Success feedback
      toast.success(res?.data);
    } else if (res?.code === "ERR_NETWORK") {
      // Network-related error feedback
      toast.error(res?.message);
    } else {
      // General error feedback with fallback message
      toast.error(res?.response?.data || EXCEPTION_MESSAGE);
    }

    // Condition to check the action is add, and response is 200
    if (action === "add" && res?.status === 200) {
      // Call the updateMenu function
      await handleMenuUpdate(screen);
    }

    // If the response is successful, save the mappings if needed
    if (
      res?.status === 200 && // Check for a successful API call
      transformedObjects?.customFields && // Ensure customFields exist
      !isEmptyObject(transformedObjects.customFields) && // Ensure customFields is not empty
      action !== "delete" // Avoid saving mappings for delete action
    ) {
      // Save the transformed mappings using the ApiService
      await ApiService.saveMappings(transformedObjects, screen, token).then(
        (res) => console.log("Mapping saved:", res), // Log successful mapping save
        (error) => console.log("Error saving mappings:", error) // Log errors during saving
      );
    }

    // Specific logic for Beneficiary screen: store userName in sessionStorage after editing
    if (
      screen === BENEFICIARY_SCREEN && // Only for Beneficiary screen
      action === "edit" && // Only for 'edit' action
      res?.status === 200 && // Ensure successful API call
      fetchData.userId === USER_ID() // Check if the userId matches
    ) {
      sessionStorage.setItem("userName", fetchData.userName); // Set userName in sessionStorage
    }

    // Always fetch new data after the API call, including after deletion
    fetchData(); // Refresh the data on the screen
  } catch (error) {
    // Log any errors that occur during the API call or subsequent operations
    console.error(`Error during ${action}:`, error);
  }
};
