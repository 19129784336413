import { useRef } from "react";
import BeneficiaryForm from "./form";
import FullScreenLoader from "../../common/LoadingScreen";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import { BeneficiaryService } from "./service";

export default function Beneficiary() {
  const fileInputRef = useRef(null);

  const {
    screenName = "",
    displayName = "",
    uploadLoadingMsg = "",
    rows = [],
    columns = [],
    fields = [],
    writeAccess = false,
    toolbarMenuProps = {},
    toolbarImportProps = {},
    open = false,
    addFormOpen = () => {},
    addFormClose = () => {},
    editFormOpen = false,
    editItemId = null,
    editFormData = {},
    setEditFormOpen = () => {},
    viewData = false,
    setViewData = () => {},
    onSubmitForm = () => {},
    submitLoading = false,
    dynamicFormFields = [],
    handleAddCustomField = () => {},
    openCustomFieldDialog = false,
    setOpenCustomFieldDialog = () => {},
    columnVisibilityModel = () => {},
    apiEndpoint = "",
    isUploading = false,
    isTableLoading = false,
    isErrorColor = false,
    isEData = false,
    resObject = {},
    response = {},
    productOptions,
    customerOptions,
    salesRepOptions,
    selectedSalesRep1,
    setSelectedSalesRep1,
    selectedCustomer1,
    setSelectedCustomer1,
    selectedProduct1,
    setSelectedProduct1,
    setNewFields,
    transactionCombos,
    addTransactionType,
    transactionTypeOptions,
    transactionTypeOptionsWithId,

    isNavigateToAllErrorsActive,
    allRole,
    roleOptions,
    reportingToOptions,
    setRole,
    roleAccess,
    apiCall,
    newFields,
    setFields,
    allTableData,
    userIdCheck,
    emailCheck,
    addEmployeeType,
    employeeTypeOptions,
    setIsEData,
    employeeTypeOptionsWithId,
    selectedRepo,
    setSelectedRepo,
  } = BeneficiaryService(fileInputRef);

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader
        loading={isUploading || submitLoading}
        message={isUploading ? uploadLoadingMsg : ""}
      />
      <CustomDataGrid
        rows={rows ?? []} // Data rows for the grid
        columns={columns ?? []} // Column definitions for the grid
        loading={isTableLoading} // Show loading indicator when fetching data
        apiEndpoint={apiEndpoint}
        columnVisibilityModel={columnVisibilityModel}
        toolbarProps={{
          displayName,
          screenName,
          addFormOpen,
          ...toolbarMenuProps,
          ...toolbarImportProps,
        }} // Toolbar props for display name, screen name, and add button
        sx={{
          // Conditionally applying styles based on apiEndpoint
          ...(apiEndpoint === "getBeneficiariesForDirectReportees" && {
            "& .super-app-theme--header": {
              color: "#1976d2", // Change text color
            },
          }),
          // Applying styles for error color if isErrorColor is true
          ...(isErrorColor && {
            "& .MuiDataGrid-row": {
              color: "error.main",
            },
          }),
        }}
      />

      {/* Handle both and and edit form display */}
      <BeneficiaryForm
        screenName={screenName}
        mode={editFormOpen ? "edit" : "add"}
        open={editFormOpen || open}
        onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
        fields={fields}
        writeAccess={writeAccess}
        editItemId={editFormOpen ? editItemId : undefined}
        editFormData={editFormOpen ? editFormData : undefined}
        viewData={viewData}
        setViewData={setViewData}
        onSubmitForm={onSubmitForm}
        dynamicFormFields={dynamicFormFields}
        handleAddCustomField={handleAddCustomField}
        openCustomFieldDialog={openCustomFieldDialog}
        setOpenCustomFieldDialog={setOpenCustomFieldDialog}
        isEData={isEData}
        resObject={resObject}
        response={response}
        productOptions={productOptions}
        customerOptions={customerOptions}
        salesRepOptions={salesRepOptions}
        selectedSalesRep1={selectedSalesRep1}
        setSelectedSalesRep1={setSelectedSalesRep1}
        selectedCustomer1={selectedCustomer1}
        setSelectedCustomer1={setSelectedCustomer1}
        selectedProduct1={selectedProduct1}
        setSelectedProduct1={setSelectedProduct1}
        setNewFields={setNewFields}
        transactionCombos={transactionCombos}
        addTransactionType={addTransactionType}
        transactionTypeOptions={transactionTypeOptions}
        transactionTypeOptionsWithId={transactionTypeOptionsWithId}
        allRole={allRole}
        roleOptions={roleOptions}
        reportingToOptions={reportingToOptions}
        setRole={setRole}
        apiCall={apiCall}
        newFields={newFields}
        setFields={setFields}
        allTableData={allTableData}
        userIdCheck={userIdCheck}
        emailCheck={emailCheck}
        addEmployeeType={addEmployeeType}
        employeeTypeOptions={employeeTypeOptions}
        roleAccess={roleAccess}
        isNavigateToAllErrorsActive={isNavigateToAllErrorsActive}
        setIsEData={setIsEData}
        employeeTypeOptionsWithId={employeeTypeOptionsWithId}
        setSelectedRepo={setSelectedRepo}
        selectedRepo={selectedRepo}
      />
    </>
  );
}
