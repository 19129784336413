import { useMemo } from "react";
import { validateArray } from "../../components/config/fieldConfig";
import { toInitCaps } from "../stringUtils";

// Custom hook to generate dynamic fields from an array of custom fields and configurations
export const useDynamicFields = (
  customFieldsArrayObject = [], // Default to an empty array if not provided
  fieldMappingArrayObject = [], // Default to an empty array if not provided
  configObj = [] // Default to an empty array if not provided
) => {
  return useMemo(() => {
    // Ensure customFieldsArrayObject is an array
    if (!Array.isArray(customFieldsArrayObject)) return [];

    // Reduce the array to gather the maximum fields
    return customFieldsArrayObject
      .reduce((maxFields, currentObject) => {
        // Map through each entry in the current object to create field objects
        const currentFields = Object.entries(currentObject)?.map(
          ([propertyName, fieldData]) => {
            // Create a label by converting to Initial Caps format
            const labelName = toInitCaps(propertyName);

            // Return field object if fieldData exists
            if (fieldData) {
              return {
                name: propertyName, // Field name
                label: labelName, // Display label for the field in Initial Caps
                type: fieldData.dataType, // Data type of the field
                required: fieldData.isMandatory, // Is the field mandatory?
                isCustom: true, // Indicate that it's a custom field
              };
            } else {
              // If fieldData does not exist, check fieldMappingArrayObject
              if (fieldMappingArrayObject) {
                return {
                  name: propertyName, // Field name
                  label: labelName, // Display label for the field
                  isCustom: true, // Indicate that it's a custom field
                };
              }
              return null; // Return null if neither condition is met
            }
          }
        );

        // Check if the currentFields are more than the maxFields collected so far
        if (currentFields.length > maxFields.length) {
          const fieldsArray = [];
          // Ensure configObj is defined and is an array before processing
          if (Array.isArray(configObj)) {
            configObj.forEach((obj) => {
              // If the object has customFields, process them
              if (obj.customFields) {
                Object.keys(obj.customFields).forEach((key) => {
                  const field = obj.customFields[key];
                  fieldsArray.push({
                    name: key, // Field name from customFields
                    label: toInitCaps(key), // Use key as label in Initial Caps
                    type: field.dataType || "text", // Default type to "text" if not defined
                    required: field.isMandatory || false, // Default required to false
                    isCustom: true, // Indicate that it's a custom field
                  });
                });
              }
            });
          }
          // Check the fieldsArray is an array
          validateArray(fieldsArray, "FieldsArray");
          return fieldsArray; // Return the collected fields array
        } else {
          // Check the maxFields is an array
          validateArray(maxFields, "MaxFields");
          return maxFields; // Return maxFields if currentFields are not greater
        }
      }, []) // Start with an empty array for maxFields
      .filter(Boolean); // Remove null values from the final array
  }, [customFieldsArrayObject, fieldMappingArrayObject, configObj]); // Memoize based on these dependencies
};
