export default function TypeFields() {
  const fields = [
    {
      name: "typeValue",
      label: "Type Value",
      type: "text",
      autoFocus: true,
      required: true,
    },
  ];

  return fields;
}
