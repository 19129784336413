import { planFrequencyOptions } from "./config";

export const PlanFields = () => {
  const fields = [
    {
      name: "title",
      label: "Plan Name",
      type: "text",
      autoFocus: true,
      required: true,
    },
    {
      name: "price",
      label: "Cost/user",
      type: "number",
      required: true,
    },
    {
      name: "notes",
      label: "Notes",
      type: "textarea",
      multiline: true,
      rows: 1,
      required: false,
    },
    {
      name: "frequency",
      label: "Frequency",
      type: "autocomplete",
      options: Array.isArray(planFrequencyOptions) ? planFrequencyOptions : [],
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      multiline: true,
      rows: 1,
      required: true,
    },
  ];

  return fields;
};
