import { Box, Typography } from "@mui/material";
import { CustomTooltip } from "../../config/fieldConfig";
import { tableHoverStyle } from "../../styles/style";

export default function AuditLogColumns() {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "beneficiaryName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "entityType",
      headerName: "Screen Name",
      width: 150,
    },
    {
      field: "changes",
      headerName: "Changes",
      width: 200,
      sortComparator: (v1, v2) => {
        const toStr = (arr) =>
          Array.isArray(arr)
            ? arr
                .map(({ field = "", newValue = "" }) => `${field}: ${newValue}`)
                .join(", ")
            : "";
        return toStr(v1).localeCompare(toStr(v2));
      },
      renderCell: ({ value }) =>
        Array.isArray(value) && value.length ? (
          <CustomTooltip
            content={
              <Box sx={tableHoverStyle}>
                <table>
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Old Value</th>
                      <th>New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value?.map(
                      ({ field = "", oldValue = "", newValue = "" }, index) => (
                        <tr key={index}>
                          <td>{field}</td>
                          <td>{oldValue}</td>
                          <td>{newValue}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Box>
            }
          >
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
                width: { xs: 180, sm: 200 },
              }}
            >
              {value
                ?.map(
                  ({ field = "", newValue = "" }) => `${field}: ${newValue}`
                )
                .join(" | ")}
            </Typography>
          </CustomTooltip>
        ) : (
          ""
        ),
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      width: 200,
    },
    {
      field: "date",
      headerName: "Date",
      width: 170,
    },
    {
      field: "timeZone",
      headerName: "Time Zone",
      width: 200,
    },
  ];

  return columns;
}
