import { Box } from "@mui/material";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";

export default function PayFrequencyColumns({
  actionsColumn = null,
  handleEdit = () => {},
}) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "frequencyName",
      headerName: "Frequency Name",
      width: 250,
      renderCell: ({ row }) => {
        // Check if the row exists to prevent errors
        if (!row) {
          return <Box sx={{ width: "100%", height: "100%" }} />; // Empty box ensures UI layout is maintained
        }
        return (
          <Box
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            title={row?.frequencyName || ""} // Provide an empty string if frequencyName is missing
            onClick={() => {
              try {
                // Ensure row.id exists before calling handleEdit
                if (row?.id) {
                  handleEdit(row?.id, true)(); // Call handleEdit with row ID
                }
              } catch (error) {
                console.error("Error occurred during edit:", error);
              }
            }}
          >
            {row?.frequencyName || ""}
          </Box>
        );
      },
    },
    {
      field: "fiscalYear",
      headerName: "Fiscal Year",
      width: 130,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      width: 130,
    },
    {
      field: "currentPeriod",
      headerName: "Current Period",
      width: 130,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "current",
      headerName: "Is Current",
      type: "boolean",
      width: 100,
    },
  ];

  // Conditionally add the actions column if provided
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
