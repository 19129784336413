import { scopeOptions } from "./config";

export default function VariableFields() {
  const fields = [
    {
      name: "variableName",
      label: "Variable Name",
      type: "text",
      autoFocus: true,
      required: true,
    },
    {
      name: "variable",
      label: "Variable",
      type: "text",
      required: false,
    },
    {
      name: "scope",
      label: "Scope",
      type: "autocomplete",
      options: Array.isArray(scopeOptions) ? scopeOptions : [], // Ensuring options is an array
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      multiline: true,
      rows: 3,
      required: true,
    },
  ];

  return fields;
}
