import { validateArray } from "../../components/config/fieldConfig";

// Extract custom field values from a single object
export const extractCustomFieldValues = (obj = {}) => {
  try {
    const customFieldValues = {}; // Initialize an empty object to store extracted values

    for (const value of Object.values(obj)) {
      // Iterate over the values of the input object
      if (
        typeof value === "object" && // Check if the value is an object
        value !== null && // Ensure the object is not null
        !Array.isArray(value) // Ensure it's not an array
      ) {
        // If value is an object, iterate over its key-value pairs
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          // Extract the value at the key that matches the key name (nestedValue[nestedKey])
          customFieldValues[nestedKey] = nestedValue[nestedKey];
        }
      }
    }
    return customFieldValues; // Return the object containing the extracted field values
  } catch (error) {
    console.error("Error extracting custom field values:", error);
    return {}; // Return an empty object in case of an error
  }
};

// Extract custom field values from a single object
export const extractCustomField = (
  tableData = [],
  getPojo = {},
  mappingPojoRes = {},
  setResponse = () => {},
  setResObject = () => {},
  setDynamicColumns = () => {},
  setCustomFieldsArrayObject = () => {},
  setFieldMappingArrayObject = () => {}
) => {
  try {
    // Initialize an array to store custom field values of each row
    const customFieldsArray = [];
    const customFieldsArrayObject = []; // Initialize an array to store the desired output

    // Loop through each row and accumulate custom field values for each
    tableData?.forEach((currentItem) => {
      const customFieldValuesOfCurrentItem =
        extractCustomFieldValues(currentItem);
      customFieldsArray.push(customFieldValuesOfCurrentItem);

      // Push the desired output structure to customFieldsArrayObject
      customFieldsArrayObject.push(currentItem.customFields || {});
    });

    // Check the customFieldsArrayObject is an array
    validateArray(customFieldsArrayObject, "CustomFieldsArrayObject");
    setCustomFieldsArrayObject(customFieldsArrayObject);

    // Merge all custom field objects from the customFieldsArray into a single object
    const mergedCustomFields = customFieldsArray?.reduce((acc, current) => {
      return { ...acc, ...current };
    }, {});

    if (!mergedCustomFields || typeof mergedCustomFields !== "object") {
      throw new Error("Invalid MergedCustomFields."); // Form data validation
    }
    // To display custom field at form
    customFieldsArrayObject.push(mergedCustomFields);

    // Find keys that exist in mappingPojoRes but not in getPojo
    const nonMatchingFields = Object.keys(mappingPojoRes)?.filter(
      (key) => !getPojo.hasOwnProperty(key)
    );

    // To send pojo field name for mapping fields
    const matchingFields = {};
    for (const key in mappingPojoRes) {
      if (getPojo.hasOwnProperty(key)) {
        matchingFields[key] = mappingPojoRes[key];
      }
    }

    setResponse(mappingPojoRes);
    setResObject(matchingFields);

    // Find non-matching fields in nonMatchingFields that are not present in mergedCustomFields
    const nonMatchingInMerged = nonMatchingFields?.filter(
      (field) => !mergedCustomFields.hasOwnProperty(field)
    );

    setFieldMappingArrayObject(nonMatchingInMerged);
    // Iterate over nonMatchingInMerged and add each field to mergedCustomFields
    nonMatchingInMerged?.forEach((field) => {
      mergedCustomFields[field] = "";
    });

    // Convert mergedCustomFields into dynamic columns
    const dynamicColumnsArray = Object.keys(mergedCustomFields)?.map((key) => {
      // Capitalize the first letter of the key for headerName
      const capitalizedHeader =
        key.charAt(0).toUpperCase() +
        key.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");

      return {
        field: key, // Field name will be the key from mergedCustomFields
        headerName: capitalizedHeader, // Header will also be the key
        width: 150, // Adjust the width as per your requirement
        valueGetter: (params) => {
          //  params.row.customFields --> This will log the nested customFields object
          return params.row.customFields[key]
            ? params.row.customFields[key][key] ||
                (params.row.customFields[key] &&
                  (params.row.customFields[key][key] === ""
                    ? ""
                    : params.row.customFields[key]))
            : ""; // Access nested value based on the column key
        },
      };
    });

    // Check the dynamicColumnsArray is an array
    validateArray(dynamicColumnsArray, "DynamicColumnsArray");
    setDynamicColumns(dynamicColumnsArray);
  } catch (error) {
    console.error("Error fetching data:", error);
    setCustomFieldsArrayObject([]);
    setResponse({});
    setResObject({});
    setFieldMappingArrayObject([]);
    setDynamicColumns([]);
  }
};
