import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  ArrowDropDown,
  ArrowRight,
  Check,
  CloseRounded,
  DeleteTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { ADMIN_NAME, ROLE_SCREEN } from "../../config/constants";
import { CustomTreeItem, handleButtonClick } from "./config";
import FullScreenLoader from "../../common/LoadingScreen";
import { RoleService } from "./service";

export default function Role() {
  const {
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    roleAccess,
    loading,
    treeData,
    editingId,
    setEditingId,
    addingId,
    setAddingId,
    newRoleName,
    setNewRoleName,
    roleNameError,
    setRoleNameError,
    handleValidate,
    handleNodeToggle,
    expandedItems,
    handleCancel,
    handleAddClick,
    newRoleInputRef,
    expandAllItems,
    handleInputBlur,
    handleInputChange,
    isExpanded,
    handleToggle,
  } = RoleService();

  const handleInputSubmit = () => {
    try {
      const isValid = handleValidate(newRoleName); // Get validation result
      if (!isValid) {
        return;
      }

      let newRole;
      if (addingId !== null && addingId !== undefined) {
        // Adding a new role
        const targetNode = treeData.find((t) => t.id === addingId);
        if (!targetNode) {
          throw new Error("Target node not found for adding a new role.");
        }

        newRole = {
          roleName: newRoleName,
          reportingTo: targetNode.roleName,
          rank: targetNode.rank + 1,
        };

        setAddingId(null); // Reset addingId after submission
      } else if (editingId !== null && editingId !== undefined) {
        // Find the object associated with editingId
        newRole = treeData.find((item) => item.id === editingId);

        if (!newRole) {
          throw new Error("Role not found for editing.");
        }
        newRole.roleName = newRoleName;
        setEditingId(null); // Reset editingId after submission
      }

      // Call the relevant form function (uncomment when ready)
      if (addingId !== null && addingId !== undefined) {
        onAddForm(newRole);
      } else if (editingId !== null && editingId !== undefined) {
        onEditForm(newRole);
      }

      // Reset newRoleName
      setNewRoleName("");
    } catch (error) {
      // Optionally show an error message in the UI
      toast.error(error.message || "An error occurred during role submission.");
    }
  };

  const [hoveredItemId, setHoveredItemId] = useState(null); // Track hovered item by ID

  const renderTree = (itemId) => {
    try {
      const item = Array.isArray(treeData)
        ? treeData.find((t) => t.id === itemId)
        : null;
      if (!item) return null;

      const writeAccess = roleAccess.some(
        (accessItem) =>
          accessItem.resource === ROLE_SCREEN && accessItem.writeAccess
      );
      const deleteAccess = roleAccess.some(
        (accessItem) =>
          accessItem.resource === ROLE_SCREEN && accessItem.deleteAccess
      );

      const handleInputSubmitWithValidation = () => {
        try {
          // Ensure enteredValue is a valid string
          if (typeof newRoleName !== "string") {
            throw new Error("Entered Value must be a string");
          }
          if (newRoleName.trim() !== "") {
            setRoleNameError(null); // Clear error if input is valid
            handleInputSubmit(); // Proceed with submit logic
          }
        } catch (error) {
          toast.error(error.message);
        }
      };

      return (
        <CustomTreeItem
          key={item.id}
          itemId={String(item.id)} // Convert item.id to a string
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
                position: "relative",
              }}
              onMouseEnter={() => setHoveredItemId(item.id)}
              onMouseLeave={() => setHoveredItemId(null)}
            >
              {editingId === item.id ? (
                <Grid container onClick={(e) => e.stopPropagation()}>
                  <Grid item>
                    <TextField
                      inputRef={newRoleInputRef} // Attach the ref to the TextField
                      variant="outlined"
                      size="small"
                      value={newRoleName}
                      autoFocus
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      placeholder="Enter new role"
                      error={!!roleNameError} // Show error state
                      helperText={roleNameError} // Show error message
                      onKeyDown={(e) => e.stopPropagation()}
                      onKeyUp={(e) => e.stopPropagation()}
                      style={{ marginRight: "8px", flex: "1" }} // Take full width
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="success"
                      size="small"
                      onClick={(e) => {
                        handleButtonClick(e);
                        handleInputSubmitWithValidation(); // Call submit with validation
                      }}
                    >
                      <Check sx={{ fontSize: "15px", marginTop: "8px" }} />
                    </IconButton>
                    <IconButton
                      color="error"
                      size="small"
                      onClick={(e) => {
                        handleButtonClick(e);
                        handleCancel();
                      }}
                    >
                      <CloseRounded
                        sx={{ fontSize: "15px", marginTop: "8px" }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container>
                    <Grid item>
                      <span style={{ flex: "1" }}>{item.roleName}</span>
                    </Grid>
                    <Grid
                      item
                      visibility={
                        hoveredItemId === item.id || addingId === item.id
                          ? "visible"
                          : "hidden"
                      }
                      marginLeft="auto"
                      display="flex"
                    >
                      <IconButton
                        size="small"
                        color="success"
                        disabled={!writeAccess}
                        onClick={(e) => {
                          handleButtonClick(e);
                          handleAddClick(item.id);
                          setAddingId(item.id);
                        }}
                      >
                        <AddCircleOutlineTwoTone sx={{ fontSize: "17px" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={!writeAccess || item.roleName === ADMIN_NAME}
                        onClick={(e) => {
                          handleButtonClick(e);
                          handleEdit(item);
                        }}
                      >
                        <EditTwoTone sx={{ fontSize: "15px" }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        disabled={!deleteAccess}
                        onClick={(e) => {
                          handleButtonClick(e);
                          handleDelete(e, item.id);
                        }}
                      >
                        <DeleteTwoTone sx={{ fontSize: "15px" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          }
          onClick={() => handleNodeToggle(item.id)}
        >
          {addingId === item.id && (
            <Grid container>
              <Grid item>
                <TextField
                  inputRef={newRoleInputRef} // Attach the ref to the TextField
                  variant="outlined"
                  size="small"
                  value={newRoleName}
                  onChange={handleInputChange}
                  onBlur={(e) => handleInputBlur()}
                  placeholder="Enter new role"
                  error={!!roleNameError}
                  helperText={roleNameError}
                  onKeyDown={(e) => e.stopPropagation()}
                  onKeyUp={(e) => e.stopPropagation()}
                  style={{ marginRight: "8px", flex: "1" }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  color="success"
                  size="small"
                  onClick={(e) => {
                    handleButtonClick(e);
                    handleInputSubmitWithValidation(); // Call submit with validation
                  }}
                >
                  <Check sx={{ fontSize: "15px", marginTop: "8px" }} />
                </IconButton>
                <IconButton
                  color="error"
                  size="small"
                  onClick={(e) => {
                    handleButtonClick(e);
                    handleCancel();
                  }}
                >
                  <CloseRounded sx={{ fontSize: "15px", marginTop: "8px" }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {treeData
            .filter(
              (t) =>
                t.reportingTo === item.roleName && t.roleName !== t.reportingTo
            )
            .map((child) => renderTree(child.id))}
        </CustomTreeItem>
      );
    } catch (error) {
      return null; // Return null if an error occurs to avoid breaking the UI
    }
  };

  useEffect(() => {
    if (expandAllItems) {
      expandAllItems();
    } else {
      console.error("expandAllItems is not a function");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  return (
    <>
      <Box
        sx={{
          minHeight: 500,
          width: "100%",
          p: 2,
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Typography variant="h5" color="primary">
          Role
        </Typography>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", py: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleToggle}
              endIcon={
                isExpanded ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
              }
              sx={{
                minWidth: 125, // Set a minimum width to keep the button width consistent
              }}
            >
              {isExpanded ? "Collapse All" : "Expand All"}
            </Button>
          </Box>

          <Box sx={{ display: "flex" }}>
            {!loading ? (
              treeData && treeData.length > 0 ? ( // Add check for treeData and its length
                <SimpleTreeView
                  aria-label="customized"
                  expandedItems={
                    Array.isArray(expandedItems)
                      ? expandedItems.map((id) => String(id))
                      : []
                  } // Use empty array instead of empty string
                  slots={{
                    expandIcon: ArrowRight,
                    collapseIcon: ArrowDropDown,
                  }}
                >
                  {renderTree(treeData[0]?.id)}{" "}
                  {/* Start rendering with the root node's id */}
                </SimpleTreeView>
              ) : null
            ) : (
              <FullScreenLoader loading={loading} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
