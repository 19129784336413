import { useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { ExpandMoreTwoTone } from "@mui/icons-material";
import { convertCamelToTitle } from "../../config/fieldConfig";

export default function TopSection({
  billingScreens = [],
  selectedDropdown = "",
  setSelectedDropdown = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to handle opening the menu
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to handle menu item clicks
  const handleMenuItemClick = (item) => {
    // Check if the item is valid before setting it as selected
    if (billingScreens.includes(item)) {
      setSelectedDropdown(item);
      handleMenuClose();
    } else {
      console.warn(`Invalid menu item: ${item}`); // Log warning for invalid item
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
          my: 1,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          {/* Display the selected dropdown title */}
          {/* {convertCamelToTitle(selectedDropdown)} */}
        </Typography>

        <Button
          variant="outlined"
          onClick={handleMenuClick}
          endIcon={<ExpandMoreTwoTone />}
          sx={{ mt: { xs: 1, md: 0 } }} // Margin-top adjusted for small screens
        >
          {convertCamelToTitle(selectedDropdown)}
        </Button>
      </Box>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
        {billingScreens.map((item) => (
          <MenuItem
            key={item}
            selected={selectedDropdown === item}
            onClick={() => handleMenuItemClick(item)}
          >
            <Typography
              color={selectedDropdown === item ? "secondary" : "inherit"}
            >
              {convertCamelToTitle(item)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
