import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { Autocomplete, Box, Grid, IconButton, TextField } from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import { isEmpty, validateArray } from "../../config/fieldConfig";
import {
  ADJUSTMENT_ERROR,
  EDATE_ERROR,
  EDATE_LESSTHAN_ERROR,
  SDATE_ERROR,
  SDATE_GREATERTHAN_ERROR,
} from "../../config/toastMessage";

const AdjustmentComponent = forwardRef(
  (
    {
      viewData = false,
      adjFields = [],
      editItemId = "",
      adjustmentOptions = [],
      setAdjustmentComponents = () => {},
      mode = "",
      open = false,
      editFormData = {},
      extractedData = [],
      adjustmentComponents = [],
      onChildDataChange = () => {}, // This is the callback passed from the parent
    },
    ref
  ) => {
    const {
      register,
      setValue,
      getValues,
      watch,
      trigger,
      reset,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      try {
        reset();
        setAdjustmentComponents([0]);

        if (mode === "add") {
          setValue(`adjustmentLabel${0}`, adjustmentOptions[0]);
          setArrayList([adjustmentOptions[0]]);
        }
      } catch (error) {
        console.error(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, open]);

    useEffect(() => {
      try {
        if (mode === "edit" && editItemId !== null) {
          // Initialize an empty array to store adjustment labels
          const adjustmentLabels = [];

          if (
            editFormData.adjustments &&
            Array.isArray(editFormData.adjustments)
          ) {
            editFormData.adjustments.forEach((adjustment, index) => {
              // Get the keys of the adjustment object
              const adjustmentKeys = Object.keys(adjustment);
              // Filter out the keys that are not "sDate" or "eDate"
              const filteredKeys = adjustmentKeys.filter(
                (key) => key !== "sDate" && key !== "eDate"
              );

              // Assuming there's only one key, which is the adjustmentLabel
              const adjustmentLabel = filteredKeys[0]; // Get the adjustmentLabel
              const adjustmentValue = adjustment[adjustmentLabel]; // Get the adjustmentValue

              // Push the adjustmentLabel into the array
              adjustmentLabels.push(adjustmentLabel);

              // Set adjustmentLabel and adjustmentValue fields
              setValue(`adjustmentLabel${index}`, adjustmentLabel);
              setValue(`adjustmentValue${index}`, adjustmentValue);

              // Set sDate and eDate fields
              setValue(`sDate${index}`, adjustment.sDate);
              setValue(`eDate${index}`, adjustment.eDate);
            });
          }

          validateArray(adjustmentLabels, "AdjustmentLabels");
          setArrayList(adjustmentLabels);
          setAdjustmentComponents(
            editFormData.adjustments.map((_, index) => index)
          );

          if (
            editFormData.adjustments &&
            editFormData.adjustments.length === 0
          ) {
            setAdjustmentComponents([0]);
            setValue(`adjustmentLabel${0}`, adjustmentOptions[0]);
            setArrayList([adjustmentOptions[0]]);
          }
        }
      } catch (error) {
        console.log(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editFormData, editItemId, extractedData, mode, reset, setValue, open]);

    const [arrayList, setArrayList] = useState([adjustmentOptions[0]]);
    const [duplicatedValues, setDuplicatedValues] = useState([]);

    // Function to update duplicatedValues based on arrayList
    const updateDuplicatedValues = (arrayList, duplicatedValues) => {
      try {
        const updatedDuplicatedValues = { ...duplicatedValues };

        Object.keys(updatedDuplicatedValues).forEach((key) => {
          const value = duplicatedValues[key];
          // Count occurrences of each value in arrayList
          const count = arrayList.filter((item) => item === value).length;
          // If the value appears more than once, keep it; otherwise, set it to undefined
          updatedDuplicatedValues[key] = count > 1 ? value : undefined;
        });

        return updatedDuplicatedValues;
      } catch (error) {
        console.log(error);
      }
    };

    // Function to add a new component
    const handleAddComponent = (adjustmentIndex) => {
      try {
        // Determine the largest number in the adjustmentComponents array
        const lastIndex =
          adjustmentComponents.length > 0
            ? Math.max(...adjustmentComponents)
            : -1;

        const newIndex = lastIndex + 1;

        const adjustmentValue = watch(`adjustmentValue${lastIndex}`);
        const sDate = watch(`sDate${lastIndex}`);
        const eDate = watch(`eDate${lastIndex}`);

        if (adjustmentValue && sDate && eDate) {
          // Filter non-matching options
          const nonMatchingOptions = adjustmentOptions.filter(
            (option) => !arrayList.includes(option)
          );

          // Remove empty items from the end of the array
          while (arrayList[arrayList.length - 1] === "") {
            arrayList.pop();
          }

          if (nonMatchingOptions[0]) {
            // Insert newIndex at adjustmentIndex + 1 position in adjustmentComponents
            const updatedComponents = [...adjustmentComponents];
            updatedComponents.splice(adjustmentIndex + 1, 0, newIndex);

            // Insert the new option at the same position in arrayList
            const updatedArrayList = [...arrayList];
            updatedArrayList.splice(lastIndex + 1, 0, nonMatchingOptions[0]);

            validateArray(updatedArrayList, "UpdatedArrayList");
            validateArray(updatedComponents, "UpdatedComponents");
            // Update the state with the new arrays
            setArrayList(updatedArrayList);
            setAdjustmentComponents(updatedComponents);

            validateArray(nonMatchingOptions, "NonMatchingOptions");
            // Set the value for the new adjustmentLabel
            setValue(`adjustmentLabel${newIndex}`, nonMatchingOptions[0]);
          }
        } else {
          if (adjustmentValue && !sDate && eDate) {
            setError(`sDate${lastIndex}`, {
              type: "manual",
              message: SDATE_ERROR,
            });
          } else if (adjustmentValue && sDate && !eDate) {
            setError(`eDate${lastIndex}`, {
              type: "manual",
              message: EDATE_ERROR,
            });
          } else if (!adjustmentValue && sDate && eDate) {
            setError(`adjustmentValue${lastIndex}`, {
              type: "manual",
              message: ADJUSTMENT_ERROR,
            });
          } else if (!adjustmentValue && !sDate && !eDate) {
            setError(`sDate${lastIndex}`, {
              type: "manual",
              message: SDATE_ERROR,
            });
            setError(`eDate${lastIndex}`, {
              type: "manual",
              message: EDATE_ERROR,
            });
            setError(`adjustmentValue${lastIndex}`, {
              type: "manual",
              message: ADJUSTMENT_ERROR,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Function to remove a component
    const handleRemoveComponent = (indexToRemove) => {
      try {
        // Filter out the component to remove
        setValue(`adjustmentLabel${indexToRemove}`, "");
        setValue(`adjustmentValue${indexToRemove}`, "");
        setValue(`sDate${indexToRemove}`, "");
        setValue(`eDate${indexToRemove}`, "");

        arrayList[indexToRemove] = "";
        //to find the last item ""
        if (!arrayList[arrayList.length - 1]) {
          setArrayList((prevList) =>
            prevList.filter((items, index) => index !== indexToRemove)
          );
        }
        setAdjustmentComponents(
          adjustmentComponents.filter((index) => index !== indexToRemove)
        );

        const updatedDuplicatedValues = updateDuplicatedValues(
          arrayList,
          duplicatedValues
        );
        if (
          !updatedDuplicatedValues ||
          typeof updatedDuplicatedValues !== "object" ||
          Array.isArray(updatedDuplicatedValues)
        ) {
          throw new Error("No data found ");
        }
        setDuplicatedValues(updatedDuplicatedValues);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    };

    const handleChange = useCallback(() => {
      try {
        const formData = getValues();
        onChildDataChange(formData);
      } catch (error) {
        console.log(error);
      }
    }, [getValues, onChildDataChange]);

    useEffect(() => {
      try {
        const subscription = watch((data) => {
          handleChange();
        });
        return () => subscription?.unsubscribe();
      } catch (error) {
        console.log(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const validateEndDate = (endDate, startDate, adjustmentIndex) => {
      try {
        if (!startDate && endDate) {
          setError(`sDate${adjustmentIndex}`, {
            type: "manual",
            message: SDATE_ERROR,
          });
        } else if (startDate && new Date(startDate) <= new Date(endDate)) {
          clearErrors(`sDate${adjustmentIndex}`);
        } else if (!startDate && !endDate) {
          clearErrors(`adjustmentValue${adjustmentIndex}`);
          clearErrors(`sDate${adjustmentIndex}`);
        }

        if (!startDate || new Date(endDate) >= new Date(startDate)) {
          return true;
        }

        return EDATE_LESSTHAN_ERROR;
      } catch (error) {
        console.log(error);
      }
    };

    const validateStartDate = (startDate, endDate, adjustmentIndex) => {
      try {
        if (startDate && !endDate) {
          setError(`eDate${adjustmentIndex}`, {
            type: "manual",
            message: EDATE_ERROR,
          });
        } else if (startDate && new Date(startDate) <= new Date(endDate)) {
          clearErrors(`eDate${adjustmentIndex}`);
        } else if (!startDate && endDate) {
          return EDATE_ERROR;
        } else if (!startDate && !endDate) {
          clearErrors(`adjustmentValue${adjustmentIndex}`);
          clearErrors(`eDate${adjustmentIndex}`);
        }
        if (endDate && new Date(startDate) > new Date(endDate)) {
          return SDATE_GREATERTHAN_ERROR;
        }
        return true;
      } catch (error) {
        console.log(error);
      }
    };

    const validateValue = (
      adjustmentValue,
      startDate,
      endDate,
      adjustmentIndex
    ) => {
      try {
        if (!adjustmentValue && !startDate && !endDate) {
          clearErrors(`sDate${adjustmentIndex}`);
          clearErrors(`eDate${adjustmentIndex}`);
        }
        return true;
      } catch (error) {
        console.log(error);
      }
    };

    // Memoize validateForm to ensure it doesn't change on each render
    const validateForm = useCallback(async () => {
      try {
        await trigger();
        // Combine errors and duplicatedValues
        const combinedErrors = { ...errors };

        // Include duplicatedValues in combinedErrors
        for (const key in duplicatedValues) {
          if (duplicatedValues[key]) {
            combinedErrors[key] = {
              message: `${duplicatedValues[key]} is already mapped.`,
            };
          }
        }

        return combinedErrors;
      } catch (error) {
        console.log(error);
      }
    }, [trigger, errors, duplicatedValues]);

    useImperativeHandle(ref, () => ({ validateForm }), [validateForm]);

    return adjustmentComponents.map((adjustmentIndex) => (
      <Grid
        container
        spacing={2}
        className="bg-gray-100 border"
        sx={{ my: 1, px: 1, pb: 2.5 }}
        key={adjustmentIndex}
      >
        {adjFields.map((adjField, idx) => (
          <Grid
            item
            xs={12}
            sm={adjField.isButton ? 2 : 5.5}
            md={adjField.isButton ? 1.2 : 2.7}
            key={idx}
          >
            {adjField.isButton ? (
              viewData && editItemId ? null : (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => handleAddComponent(adjustmentIndex)}
                    color="success"
                  >
                    <AddCircleOutlineTwoTone />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveComponent(adjustmentIndex)}
                    color="error"
                    disabled={adjustmentComponents.length > 1 ? false : true}
                  >
                    <RemoveCircleOutlineTwoTone />
                  </IconButton>
                </Box>
              )
            ) : adjField.name === "adjustmentLabel" ? (
              <Autocomplete
                options={adjustmentOptions}
                fullWidth
                style={{
                  pointerEvents: viewData && editItemId ? "none" : "auto",
                }}
                disableClearable //remove clear button
                defaultValue={
                  adjField.name === "adjustmentLabel" &&
                  editFormData &&
                  editFormData.adjustments &&
                  editFormData.adjustments.length > adjustmentIndex &&
                  editFormData.adjustments[adjustmentIndex]
                    ? Object.keys(editFormData.adjustments[adjustmentIndex])[0]
                    : arrayList[adjustmentIndex]
                }
                value={arrayList[adjustmentIndex]}
                onChange={(event, newValue) => {
                  const fieldName = `${adjField.name}${adjustmentIndex}`;
                  const isDuplicate = arrayList.includes(newValue);
                  setValue(`adjustmentLabel${adjustmentIndex}`, newValue);
                  arrayList[adjustmentIndex] = newValue;
                  const updatedDuplicatedValues = updateDuplicatedValues(
                    arrayList,
                    duplicatedValues
                  );
                  setDuplicatedValues(updatedDuplicatedValues);
                  if (isDuplicate) {
                    setDuplicatedValues((prevState) => ({
                      ...prevState,
                      [fieldName]: newValue,
                    }));
                    if (!errors[fieldName]) {
                      // Check if error already exists
                      setError(fieldName, {
                        // Only set error if it doesn't exist
                        type: "duplicate",
                        message: `${newValue} is already mapped.`,
                      });
                    }
                  } else {
                    setDuplicatedValues((prevState) => ({
                      ...prevState,
                      [fieldName]: undefined,
                    }));
                    clearErrors(fieldName); // Clear error if the value is not a duplicate
                  }
                }}
                onBlur={() => {
                  trigger(`${adjField.name}${adjustmentIndex}`); // Trigger validation on blur
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label={adjField.label}
                    required={
                      !!watch(`sDate${adjustmentIndex}`) ||
                      !!watch(`eDate${adjustmentIndex}`) ||
                      !!watch(`adjustmentValue${adjustmentIndex}`)
                    }
                    {...register(`${adjField.name}${adjustmentIndex}`, {
                      required:
                        !!watch(`sDate${adjustmentIndex}`) ||
                        !!watch(`eDate${adjustmentIndex}`) ||
                        !!watch(`adjustmentValue${adjustmentIndex}`)
                          ? `${adjField.label} is required`
                          : false,
                    })}
                    InputLabelProps={{
                      shrink: adjField.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    error={
                      !!errors[`${adjField.name}${adjustmentIndex}`] ||
                      !!duplicatedValues[`${adjField.name}${adjustmentIndex}`]
                    }
                    helperText={
                      (errors[`${adjField.name}${adjustmentIndex}`]?.message &&
                        (!params.inputProps.value
                          ? errors[`${adjField.name}${adjustmentIndex}`]
                              ?.message
                          : "")) ||
                      (duplicatedValues[`${adjField.name}${adjustmentIndex}`] &&
                        `${
                          duplicatedValues[`${adjField.name}${adjustmentIndex}`]
                        } is already mapped.`)
                    }
                    FormHelperTextProps={{ sx: { mb: -3 } }}
                  />
                )}
              />
            ) : (
              <TextField
                name={`${adjField.name}${adjustmentIndex}`}
                label={adjField.label}
                type={adjField.type}
                required={
                  adjField.name === "adjustmentValue" ||
                  adjField.name === "sDate" ||
                  adjField.name === "eDate"
                    ? !!watch(`sDate${adjustmentIndex}`) ||
                      !!watch(`eDate${adjustmentIndex}`) ||
                      !!watch(`adjustmentValue${adjustmentIndex}`)
                    : adjField.required
                }
                variant="outlined"
                size="small"
                multiline={adjField.multiline}
                maxRows={adjField.maxRows}
                placeholder={adjField.placeholder}
                fullWidth
                InputLabelProps={{
                  shrink: adjField.shrink,
                  style: {
                    pointerEvents: "none",
                  },
                }}
                InputProps={{
                  style: {
                    pointerEvents: viewData && editItemId ? "none" : "auto",
                  },
                  inputProps: {
                    max: adjField.type === "date" ? "9999-12-31" : undefined,
                    style: {
                      textTransform:
                        adjField.type === "date" ? "uppercase" : "none",
                    },
                  },
                }}
                {...register(`${adjField.name}${adjustmentIndex}`, {
                  required:
                    adjField.name === "adjustmentValue" ||
                    adjField.name === "sDate" ||
                    adjField.name === "eDate"
                      ? !!watch(`sDate${adjustmentIndex}`) ||
                        !!watch(`eDate${adjustmentIndex}`) ||
                        !!watch(`adjustmentValue${adjustmentIndex}`)
                        ? `${adjField.label} is required`
                        : false
                      : adjField.required
                      ? `${adjField.label} is required`
                      : false,
                  validate: {
                    isEmpty: (value) =>
                      (adjField.name === "adjustmentValue" ||
                        adjField.name === "sDate" ||
                        adjField.name === "eDate") &&
                      (!!watch(`sDate${adjustmentIndex}`) ||
                        !!watch(`eDate${adjustmentIndex}`) ||
                        !!watch(`adjustmentValue${adjustmentIndex}`))
                        ? isEmpty(value)
                        : true,
                    validateEndDate: (value) =>
                      adjField.name === "eDate"
                        ? validateEndDate(
                            value,
                            watch(`sDate${adjustmentIndex}`),
                            adjustmentIndex
                          )
                        : true,
                    validateStartDate: (value) =>
                      adjField.name === "sDate"
                        ? validateStartDate(
                            value,
                            watch(`eDate${adjustmentIndex}`),
                            adjustmentIndex
                          )
                        : true,
                    validateValue: (value) =>
                      adjField.name === "adjustmentValue"
                        ? validateValue(
                            value,
                            watch(`sDate${adjustmentIndex}`),
                            watch(`eDate${adjustmentIndex}`),
                            adjustmentIndex
                          )
                        : true,
                  },
                })}
                error={!!errors[`${adjField.name}${adjustmentIndex}`]}
                helperText={
                  errors[`${adjField.name}${adjustmentIndex}`]?.message
                }
                FormHelperTextProps={{ sx: { mb: -3 } }}
                onChange={(e) => {
                  // Call the custom onChange handler
                  clearErrors(`${adjField.name}${adjustmentIndex}`);
                  setValue(
                    `${adjField.name}${adjustmentIndex}`,
                    e.target.value
                  ); // Update the form value
                  setValue(
                    `${adjField.name}${adjustmentIndex}`,
                    e.target.value
                  );
                  trigger(`${adjField.name}${adjustmentIndex}`); // Trigger validation on change
                  trigger(`adjustmentLabel${adjustmentIndex}`);
                }}
                onBlur={() => {
                  trigger(`${adjField.name}${adjustmentIndex}`); // Trigger validation on blur
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    ));
  }
);
export default AdjustmentComponent;
