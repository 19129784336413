import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  CloseTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import { TICKET_SCREEN } from "../../config/constants";
import CommissionToolbar from "./toolbar";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import Columns from "./columns";
import TicketForm from "./form";
import { TicketService } from "./service";

export default function Ticket() {
  const {
    rows,
    onEditForm,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    viewData,
    setViewData,
    fields,
    productTypeOptions,
    selectedOption,
    roleAccess,
    onAddForm,
    onProductUpdate,
    setEditFormOpen,
    selectedImage,
    handleFileChangeLogo,
    handleAvatarClick,
    fileInputRef,
    resourceNames,
    beneficiaries,
    setBeneficiaries,
    setSelectedImage,
    loading,
    isSuperAdmin,
    isMonitor,
    userName,
    orgName,
    setOrgId,
  } = TicketService();

  const columns = Columns(
    handleEdit,
    handleDelete,
    roleAccess,
    isSuperAdmin,
    isMonitor
  );

  const readAccess = roleAccess.some(
    (item) => item.resource === TICKET_SCREEN && item.writeAccess
  );

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const editAccess = roleAccess.some(
    (item) => item.resource === TICKET_SCREEN && item.writeAccess
  );

  const reset = () => {
    setEditFormOpen(false);
    // Reset file input after processing
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      {readAccess && (
        <Box sx={{ m: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1 }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={
                showForm ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
              }
              onClick={() => {
                toggleForm();
                reset();
              }}
            >
              Add Ticket
            </Button>
          </Box>
          {showForm && (
            <Paper sx={{ px: 3, py: 2 }}>
              <Typography variant="h6" gutterBottom mb={5}>
                Add Ticket
              </Typography>
              <TicketForm
                mode="add"
                onClose={toggleForm}
                productTypeOptions={productTypeOptions}
                editAccess={editAccess}
                fields={fields}
                onAddForm={onAddForm}
                selectedOption={selectedOption}
                selectedImage={selectedImage}
                handleFileChangeLogo={handleFileChangeLogo}
                handleAvatarClick={handleAvatarClick}
                fileInputRef={fileInputRef}
                resourceNames={resourceNames}
                beneficiaries={beneficiaries}
                setBeneficiaries={setBeneficiaries}
                viewData={viewData}
                setViewData={setViewData}
                setSelectedImage={setSelectedImage}
                isSuperAdmin={isSuperAdmin}
                isMonitor={isMonitor}
                userName={userName}
                orgName={orgName}
                setOrgId={setOrgId}
              />
            </Paper>
          )}
        </Box>
      )}
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: CommissionToolbar,
            pagination: CustomPagination,
          }}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          autoHeight={Object.keys(rows).length >= 10}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          loading={loading}
          density="compact"
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />

        <Dialog open={editFormOpen} maxWidth="lg">
          <DialogTitle>
            {viewData && editItemId ? "View" : "Edit"}
            &nbsp;Ticket
            {viewData && (editAccess || isSuperAdmin || isMonitor) ? (
              <IconButton
                aria-label="edit"
                style={{ position: "absolute", top: 10, right: 45 }}
                color="primary"
                title="Edit"
                onClick={() => setViewData(false)}
              >
                <EditTwoTone />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="close"
              style={{ position: "absolute", top: "8px", right: "8px" }}
              onClick={reset}
            >
              <CloseTwoTone />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TicketForm
              mode="edit"
              onClose={reset}
              editItemId={editItemId}
              editFormData={editFormData} // Pass the edit form data to ProductFormEdit
              onEditForm={onEditForm}
              fields={fields}
              productTypeOptions={productTypeOptions}
              editAccess={editAccess}
              onProductUpdate={onProductUpdate}
              selectedOption={selectedOption}
              selectedImage={selectedImage}
              handleFileChangeLogo={handleFileChangeLogo}
              handleAvatarClick={handleAvatarClick}
              fileInputRef={fileInputRef}
              resourceNames={resourceNames}
              beneficiaries={beneficiaries}
              setBeneficiaries={setBeneficiaries}
              viewData={viewData}
              setViewData={setViewData}
              setSelectedImage={setSelectedImage}
              isSuperAdmin={isSuperAdmin}
              isMonitor={isMonitor}
              userName={userName}
              orgName={orgName}
              setOrgId={setOrgId}
              setShowForm={setShowForm}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
