import { Typography } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import ImportButton from "./Toolbar/ImportButton";
import ExportButton from "./Toolbar/ExportButton";
import ViewMenu from "./Toolbar/ViewMenu";
import AddButton from "./Toolbar/AddButton";

export default function CustomToolbar({
  hasRecords = false,
  apiRef,
  apiEndpoint,
  displayName = "",
  screenName = "",
  addFormOpen = () => {},
  menuItems = [],
  selectedOption = "",
  handleUploadFile = () => {},
  fileInputRef,
  uploading = false,
  setUploadInProgress = () => {},
  isReportingTo,
}) {
  return (
    <>
      <GridToolbarContainer>
        {/* Displaying the title of the grid with the provided display name */}
        <Typography variant="h5" color="primary">
          {displayName}
        </Typography>

        {/* Button to show/hide columns in the data grid */}
        <GridToolbarColumnsButton />

        {/* Button to open the filter settings for the data grid */}
        <GridToolbarFilterButton />

        {/* Button to adjust the density (spacing) of the data grid */}
        <GridToolbarDensitySelector />

        {/* Displaying the toolbarImportButton */}
        <ImportButton
          screenName={screenName}
          handleUploadFile={handleUploadFile}
          fileInputRef={fileInputRef}
          uploading={uploading}
          setUploadInProgress={setUploadInProgress}
        />

        {/* Displaying the toolbarExportButton */}
        <ExportButton
          screenName={screenName}
          hasRecords={hasRecords}
          apiRef={apiRef}
          apiEndpoint={apiEndpoint}
        />

        {/* Displaying the toolbarMenu */}
        <ViewMenu
          screenName={screenName}
          menuItems={menuItems}
          selectedOption={selectedOption}
          isReportingTo={isReportingTo}
        />

        {/* Spacer div to push the quick filter and add button to the right side */}
        <div style={{ flex: 1 }} />

        {/* Quick filter input to search within the data grid */}
        <GridToolbarQuickFilter />

        {/* Custom Add button to open the add form dialog, takes screenName and addFormOpen props */}
        <AddButton screenName={screenName} addFormOpen={addFormOpen} />
      </GridToolbarContainer>
    </>
  );
}
