import { Box } from "@mui/material";

export default function ProductColumns({
  actionsColumn = null,
  dynamicColumns = [],
  isErrorColor = false,
  handleEdit = () => {},
  currency = "",
}) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "productId",
      headerName: "Prod ID",
      width: 90,
      renderCell: ({ row }) => {
        // Check if the row exists to prevent errors
        if (!row) {
          return <Box sx={{ width: "100%", height: "100%" }} />; // Empty box ensures UI layout is maintained
        }
        return (
          <Box
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            title={row?.productId || ""} // Add the title attribute for the tooltip
            onClick={() => {
              try {
                // Ensure row.id exists before calling handleEdit
                if (row?.id) {
                  handleEdit(row?.id, true)(); // Call handleEdit with row ID
                }
              } catch (error) {
                console.error("Error occurred during edit:", error);
              }
            }}
          >
            {row?.productId || ""}
          </Box>
        );
      },
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
    },
    {
      field: "productDescription",
      headerName: "Prod Description",
      width: 150,
    },
    {
      field: "subProductOf",
      headerName: "Sub Prod Of",
      width: 130,
    },
    {
      field: "productCost",
      headerName: `Prod Cost (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "productType",
      headerName: "Prod Type",
      width: 100,
    },
    {
      field: "commissionRate",
      headerName: `Comm Rate (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "discountPercentage",
      headerName: "Discount %",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "fullProductName",
      headerName: "Full Prod Name",
      width: 130,
    },
    {
      field: "productLevel",
      headerName: "Prod Level",
      width: 100,
    },
    {
      field: "productPrice",
      headerName: `Prod Price (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 130,
    },
    {
      field: "commissionAmount",
      headerName: `Comm Amt (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "discountAmount",
      headerName: `Discount Amt (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "preferredVendor",
      headerName: "Preferred Vendor",
      width: 130,
    },
    {
      field: "active",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
    },
    // Conditionally display the description column based on isErrorColor
    ...(isErrorColor
      ? [
          {
            field: "description",
            headerName: "Error Description",
            width: 200,
          },
        ]
      : []),
    // Only spread dynamicColumns if it's actually an array (just being cautious!)
    ...(Array.isArray(dynamicColumns) ? dynamicColumns : []),
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
