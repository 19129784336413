import { CURRENCY_NAME } from "../../config/sessionStorage";

export const ReportColumns = () => {
  // Session storage to taken currency value
  const curName = CURRENCY_NAME();

  return [
    {
      field: "sno",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "transactionId",
      headerName: "Trans ID",
      width: 170,
    },
    {
      field: "transactionLine",
      headerName: "Trans Line",
      headerAlign: "right",
      align: "right",
      width: 90,
    },
    {
      field: "date",
      headerName: "Trans Date",
      width: 100,
    },
    {
      field: "customerName",
      headerName: "Cust Name",
      width: 150,
    },
    {
      field: "productName",
      headerName: "Prod Name",
      type: "line",
      width: 150,
    },
    {
      field: "salesAmount",
      headerName: `Sales Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 120,
    },
    {
      field: "creditAmount",
      headerName: `Credit Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 120,
    },
    {
      field: "commissionRate",
      headerName: "Comm %",
      headerAlign: "right",
      align: "right",
      width: 90,
    },
    {
      field: "commissionAmount",
      headerName: `Comm Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
  ];
};
