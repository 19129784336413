import { fiscalYearOptions, frequencyOptions } from "./config";

export default function PayFrequencyFields() {
  // Ensure all options are arrays, defaulting to empty arrays if not
  const options = {
    fiscalYearOptions: Array.isArray(fiscalYearOptions)
      ? fiscalYearOptions
      : [],
    frequencyOptions: Array.isArray(frequencyOptions) ? frequencyOptions : [],
  };

  const fields = [
    {
      name: "frequencyName",
      label: "Frequency Name",
      type: "text",
      autoFocus: true,
      required: true,
    },
    {
      name: "fiscalYear",
      label: "Fiscal Year",
      type: "autocomplete",
      options: options?.fiscalYearOptions ?? [],
      required: true,
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      shrink: true,
      required: true,
    },
    {
      name: "frequency",
      label: "Frequency",
      type: "autocomplete",
      options: options?.frequencyOptions ?? [],
      required: true,
    },
    {
      name: "currentPeriod",
      label: "Current Period",
      type: "number",
      isNonDecimal: true,
      required: true,
    },
    {
      name: "current",
      label: "Is Current",
      type: "boolean",
    },
  ];

  return fields;
}
