import { useState } from "react";
import { Box } from "@mui/material";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import TopSection from "./topSection";
import Subscriptions from "./subscriptions";
import { BillingService } from "./service";

export default function Billing() {
  const {
    displayName = "",
    rows = [],
    columns = [],
    token = "",
    currentCurrency = "",
    subscriptionStartDate = "",
    subscriptionEndDate = "",
    renewalPrice = "",
    planTitle = "",
    pricingMonth = "",
    noOfBeneficiary = 1,
    fetchData,
    planFetchData,
    loading = false,
    lastAmountToPay = 0,
    planRenewalDate = "",
  } = BillingService() || {};

  // State to manage selected dropdown
  const [selectedDropdown, setSelectedDropdown] = useState("subscription");

  // Options for the billing screen dropdown
  const billingScreens = ["subscription", "paymentHistory"];

  return (
    <Box sx={{ p: 2 }}>
      {/* Top section with screen selection */}
      <TopSection
        billingScreens={billingScreens} // Options for billing screens
        selectedDropdown={selectedDropdown} // Current selected dropdown value
        setSelectedDropdown={setSelectedDropdown} // Function to update selected dropdown
      />

      {/* Conditional rendering for subscription view */}
      {selectedDropdown === "subscription" && (
        <Box sx={{ my: 2 }}>
          {/* Margin for vertical spacing */}
          <Subscriptions
            subscriptionStartDate={subscriptionStartDate} // Start date for subscription
            subscriptionEndDate={subscriptionEndDate} // End date for subscription
            renewalPrice={renewalPrice} // Price for renewal
            renewalPlan={planTitle} // Current pricing plan
            renewalMonth={pricingMonth} // Month of the current plan
            noOfBeneficiary={noOfBeneficiary} // Number of beneficiaries
            currentCurrency={currentCurrency} // Current currency
            fetchData={fetchData} // Function to fetch data
            planFetchData={planFetchData} // Function to fetch pricing plans
            token={token} // JWT token for authorization
            lastAmountToPay={lastAmountToPay}
            planRenewalDate={planRenewalDate}
          />
        </Box>
      )}

      {/* Conditional rendering for payment history view */}
      {selectedDropdown === "paymentHistory" && (
        <>
          {/* CustomDataGrid to display the list with CRUD operations */}
          <CustomDataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            toolbarProps={{ displayName }}
          />
        </>
      )}
    </Box>
  );
}
