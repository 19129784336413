import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { jwtDecode } from "jwt-decode";
import ApiService from "../../apis/ApiService";
import PrivateApiService from "../../apis/PrivateApiService";
import { ORG_ID, USER_ID } from "../config/sessionStorage";
import { decryptAes, encryptPwd } from "../config/fieldConfig";
import {
  EXCEPTION_MESSAGE,
  PLAN_REMINE_MSG,
  PWD_NOT_MATCH,
} from "../config/toastMessage";
import { ADMIN_NAME, DEMO_MAIL } from "../config/constants";

// Register
const onRegister = async (
  formData,
  navigate,
  domain,
  setLoading,
  selectedTimezone,
  recaptchaResponse,
  recaptchaRef
) => {
  try {
    const res = await ApiService.postUserData(
      formData,
      domain,
      selectedTimezone,
      recaptchaResponse
    );
    console.log("Reg res:", res);
    if (res?.status === 200) {
      if (res?.data?.error === false) {
        navigate("/register_success");
      } else {
        toast.error(res?.data?.response); // Error response from API
        recaptchaRef.current.reset(); // Reset reCAPTCHA after submit
      }
    } else if (res?.code === "ERR_NETWORK") {
      toast.error(res?.message); // Network server error
    } else {
      toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
    }
  } catch (error) {
    // Handle any other errors that occur during the fetch process
    console.error("An error occurred during registration:", error);
    toast.error(EXCEPTION_MESSAGE);
  } finally {
    setLoading(false); // After the delay, set loading back to false
  }
};

// SignUp
const onSignUp = async (
  values,
  navigate,
  verifyToken,
  setLoading,
  combinedData,
  orgId,
  userName
) => {
  try {
    const res = await ApiService.SignUp(values, verifyToken);
    if (res?.status === 200) {
      const ress = await ApiService.privateAddUserAccess(
        combinedData,
        orgId,
        userName
      );
      if (ress?.status === 200) {
        navigate("/signup_success");
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
      }
    } else {
      if (res?.response?.data) {
        navigate("/token_verified");
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
      }
    }
  } catch (error) {
    console.error("An error occurred during sign-up:", error);
    toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
  } finally {
    setLoading(false); // After the delay, set loading back to false
  }
};

// SignIn
const onSignIn = async (
  values,
  navigate,
  setIsAuthenticated,
  setRes,
  setUserRoleAccess,
  setLoading,
  recaptchaResponse,
  recaptchaRef
) => {
  try {
    const isRecaptcha = true;

    // Step 1: Call SignIn API
    const signInRes = await ApiService.SignIn(
      values,
      isRecaptcha,
      recaptchaResponse
    );
    console.log("SignIn response:", signInRes);

    if (signInRes?.status === 200) {
      // Check for successful sign-in response
      if (signInRes?.data?.statusCodeValue === 200 && signInRes?.data?.body) {
        // MFA is required, proceed to MFA verification page
        sessionStorage.setItem("signInValue", JSON.stringify(values) || "");

        // MFA is required, proceed to MFA verification page
        navigate("/mfa-verify");

        toast.success(signInRes?.data?.body?.message);
      } else if (
        signInRes?.data?.forcePassword === false &&
        !signInRes?.data?.error &&
        !signInRes?.data?.accessToken
      ) {
        // MFA is not required, handle session storage and navigate to reset password
        sessionStorage.setItem("orgId", signInRes?.data?.orgId || "");
        sessionStorage.setItem("beneficiaryId", signInRes?.data?.userId || "");
        sessionStorage.setItem(
          "UserRoleAccess",
          signInRes?.data?.userRoleAccess
        );
        sessionStorage.setItem("response", "JWT token validated."); // Backend needs response

        setIsAuthenticated(true);
        setRes(true);
        navigate("/reset_pwd");
      } else if (
        signInRes?.data?.status === 200 &&
        signInRes?.data?.accessToken
      ) {
        // Step 2: Generate unique session ID
        const sessionId = uuidv4();

        // Step 3: Get IP address
        const ipAddress = await PrivateApiService.getIpAddress();

        // Step 4: Prepare session values
        const sessionValues = {
          email: values.email,
          sessionId: sessionId,
          ipAddress: ipAddress,
        };

        // Step 5: Call loginSessionUser API
        const sessionRes = await ApiService.loginSessionUser(sessionValues);
        console.log("Login response:", sessionRes);

        // Check if loginSessionUser was successful
        if (sessionRes?.status === 200) {
          const ress = await ApiService.jwtVerifiedToken(
            signInRes?.data?.accessToken,
            sessionId,
            ipAddress
          );

          if (ress?.status === 200 && ress?.data?.newJwtToken) {
            const decryptedText = decryptAes(ress?.data?.newJwtToken);
            console.log("ress", ress);

            const decoded = jwtDecode(decryptedText);
            console.log("decoded", decoded);

            // Check if the email is demo user
            const isDemoUser = decoded?.email === DEMO_MAIL; // Check for demo user by email

            const UserRoleAccessString = JSON.stringify(
              signInRes?.data?.userRoleAccess
            );
            setUserRoleAccess(UserRoleAccessString);

            const imgData = await ApiService.getMyBeneficiary(
              ress?.data?.newJwtToken
            );

            sessionStorage.setItem("UserRoleAccess", UserRoleAccessString);
            sessionStorage.setItem("token", ress?.data?.newJwtToken);
            sessionStorage.setItem(
              "organizationName",
              decoded?.organizationName
            );
            sessionStorage.setItem("userName", decoded?.userName);
            sessionStorage.setItem("role", decoded?.roleName);
            sessionStorage.setItem("beneficiaryId", decoded?.beneficiaryId);
            sessionStorage.setItem("orgId", decoded?.organizationId);
            sessionStorage.setItem("response", ress?.data?.message);
            sessionStorage.setItem("countryName", signInRes?.data?.country);
            sessionStorage.setItem("currencyName", signInRes?.data?.currency);
            sessionStorage.setItem("timeZone", signInRes?.data?.timeZone);
            sessionStorage.setItem("isMonitor", decoded?.isMonitoredUser);
            sessionStorage.setItem("isSuperAdmin", decoded?.isSuperAdminUser);
            sessionStorage.setItem("email", decoded?.email);
            sessionStorage.setItem("sessionId", decoded?.sessionId);
            sessionStorage.setItem("ipAddress", ipAddress);
            sessionStorage.setItem("isFileUpload", false);
            sessionStorage.setItem(
              "menus",
              JSON.stringify(signInRes?.data?.menus)
            );
            sessionStorage.setItem(
              "profileImage",
              imgData?.data[0]?.imageData || ""
            );
            sessionStorage.setItem("isDemoUser", true);
            sessionStorage.setItem("isBilling", decoded?.isBilling || false);

            setIsAuthenticated(true);
            setRes(true);

            if (isDemoUser) {
              navigate("/demoUser_info");
            } else {
              navigate("/dashboard");
            }
          }
        }
      } else {
        toast.error(signInRes?.data?.error);
        recaptchaRef.current.reset(); // Reset reCAPTCHA after submit
      }
    } else if (signInRes?.code === "ERR_NETWORK") {
      toast.error(signInRes?.message); // Network server error
    } else {
      toast.error(EXCEPTION_MESSAGE);
    }
  } catch (error) {
    console.error("An error occurred during sign-in:", error); // Notify user about the error
    toast.error(EXCEPTION_MESSAGE);
  } finally {
    setLoading(false); // Set loading back to false after the API call is done
  }
};

// MFA Verify
const onMfaVerify = async (
  email,
  mfaCode,
  setUserRoleAccess,
  setIsAuthenticated,
  setRes,
  navigate,
  setLoading
) => {
  try {
    // Step 1: Verify MFA code
    const res = await ApiService.verifyOtp(email, mfaCode);
    console.log("verifyOtp response", res);

    // Only proceed if verifyOtp was successful
    if (res?.status === 200) {
      // Step 2: Generate unique session ID
      const sessionId = uuidv4();

      // Step 3: Get IP address
      const ipAddress = await PrivateApiService.getIpAddress();

      // Step 4: Prepare session values
      const sessionValues = {
        email: email,
        sessionId: sessionId,
        ipAddress: ipAddress,
      };

      // Step 5: Call loginSessionUser API
      const sessionRes = await ApiService.loginSessionUser(sessionValues);
      console.log("Login response:", sessionRes);

      // Check if loginSessionUser was successful
      if (sessionRes?.status === 200) {
        if (res?.data?.accessToken && sessionId && ipAddress) {
          const ress = await ApiService.jwtVerifiedToken(
            res?.data?.accessToken,
            sessionId,
            ipAddress
          );

          if (ress?.status === 200 && ress?.data?.newJwtToken) {
            const decryptedText = decryptAes(ress?.data?.newJwtToken);
            console.log("ress", ress);

            const decoded = jwtDecode(decryptedText);
            console.log("decoded", decoded);

            const UserRoleAccessString = JSON.stringify(
              res?.data?.userRoleAccess
            );
            setUserRoleAccess(UserRoleAccessString);

            const imgData = await ApiService.getMyBeneficiary(
              ress?.data?.newJwtToken
            );

            sessionStorage.setItem("UserRoleAccess", UserRoleAccessString);
            sessionStorage.setItem("token", ress?.data?.newJwtToken);
            sessionStorage.setItem(
              "organizationName",
              decoded?.organizationName
            );
            sessionStorage.setItem("userName", decoded?.userName);
            sessionStorage.setItem("role", decoded?.roleName);
            sessionStorage.setItem("beneficiaryId", decoded?.beneficiaryId);
            sessionStorage.setItem("orgId", decoded?.organizationId);
            sessionStorage.setItem("response", ress?.data?.message);
            sessionStorage.setItem("countryName", res?.data?.country);
            sessionStorage.setItem("currencyName", res?.data?.currency);
            sessionStorage.setItem("timeZone", res?.data?.timeZone);
            sessionStorage.setItem("isMonitor", decoded?.isMonitoredUser);
            sessionStorage.setItem("isSuperAdmin", decoded?.isSuperAdminUser);
            sessionStorage.setItem("email", decoded?.email);
            sessionStorage.setItem("sessionId", decoded?.sessionId);
            sessionStorage.setItem("ipAddress", ipAddress);
            sessionStorage.setItem("isFileUpload", false);
            sessionStorage.setItem("menus", JSON.stringify(res?.data?.menus));
            sessionStorage.setItem(
              "profileImage",
              imgData?.data[0]?.imageData || ""
            );

            setIsAuthenticated(true);
            setRes(true);

            // Navigate based on conditions
            if (
              decoded?.forcePassword === true &&
              decoded?.isMonitoredUser === false &&
              decoded?.isSuperAdminUser === false
            ) {
              if (decoded?.isBilling === true) {
                navigate("/billing");
              } else if (res?.data?.screen === "companySetup") {
                navigate("/companySetup");
              } else {
                navigate("/dashboard");
              }
              if (res?.data?.popUp && decoded?.roleName === ADMIN_NAME) {
                toast.success(PLAN_REMINE_MSG); //backend response needed
              }
            } else if (
              decoded?.isMonitoredUser === true &&
              decoded?.forcePassword === true
            ) {
              navigate("/monitor");
            } else if (
              decoded?.isSuperAdminUser === true &&
              decoded?.forcePassword === true
            ) {
              navigate("/plan");
            } else {
              navigate("/reset_pwd");
            }

            sessionStorage.removeItem("signInValue");
          } else {
            navigate("/access_denied");
          }
        } else {
          toast.error(res?.response?.data || EXCEPTION_MESSAGE);
        }
      } else if (sessionRes?.code === "ERR_NETWORK") {
        toast.error(sessionRes?.message); // Network server error
      } else {
        navigate("/access_denied");
      }
    } else {
      toast.error(res?.response?.data || EXCEPTION_MESSAGE);
    }
  } catch (error) {
    console.error("An error occurred during MFA verification:", error);
    toast.error(EXCEPTION_MESSAGE);
  } finally {
    setLoading(false);
  }
};

// Resend MFA
export const onResendMfaCode = async (values) => {
  try {
    const isRecaptcha = false;

    const res = await ApiService.SignIn(values, isRecaptcha);
    if (res.status === 200 && res?.data?.body) {
      toast.success(res?.data?.body?.message);
    } else if (res?.code === "ERR_NETWORK") {
      toast.error(res?.message); // Network server error
    } else {
      toast.error(EXCEPTION_MESSAGE);
    }
  } catch (error) {
    console.error("Error resending MFA code:", error);
    toast.error(EXCEPTION_MESSAGE);
  }
};

// Forgot Password
const onForgotPwd = async (values, url, navigate, setLoading) => {
  try {
    const res = await ApiService.forgotPassword(values, url);

    if (res?.status === 200) {
      navigate("/forgot_success");
    } else if (res?.response?.data) {
      toast.error(res?.response?.data);
    } else if (res?.code === "ERR_NETWORK") {
      toast.error(res?.message); // Network server error
    } else {
      toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
    }
  } catch (error) {
    // Handle the error as needed, such as displaying an error message to the user
    console.error("An error occurred during forgot password:", error);
    // Notify user about the error
    toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
  } finally {
    setLoading(false); // Set loading back to false regardless of success or failure
  }
};

// Recover Password
const onRecoverPwd = async (user, resetToken, navigate, setLoading) => {
  try {
    if (user?.password === user?.confirmPassword) {
      const encodePwd = await encryptPwd(user?.password);
      user.password = encodePwd;
      user.confirmPassword = "";

      const res = await ApiService.recoverPassword(user, resetToken);

      if (res?.status === 200) {
        toast.success(res?.data);
        navigate("/sign_in");
      } else if (res?.response?.data === "Invalid token") {
        navigate("/token_verified");
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
      }
    } else {
      toast.error(PWD_NOT_MATCH);
    }
  } catch (error) {
    console.error("An error occurred during password recovery:", error);
    toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
  } finally {
    setLoading(false); // Set loading back to false regardless of success or failure
  }
};

// Reset Password
const onResetPwd = async (values, navigate, setLoading) => {
  try {
    if (values?.password === values?.confirmPassword) {
      const encodeNewPwd = await encryptPwd(values?.password);
      values.password = encodeNewPwd;
      values.confirmPassword = "";

      const userId = USER_ID();
      const orgId = ORG_ID();

      const res = await ApiService.resetPassword(
        userId,
        orgId,
        values?.oldPassword,
        values?.password
      );

      if (res?.status === 200) {
        toast.success(res.data);
        navigate("/sign_in");
      } else if (res?.response?.data) {
        toast.error(res?.response?.data);
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
      }
    } else {
      toast.error(PWD_NOT_MATCH);
    }
  } catch (error) {
    // Handle the error appropriately, such as displaying an error message to the user
    console.error("An error occurred during password reset:", error);
    toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
  } finally {
    setLoading(false); // Set loading back to false regardless of success or failure
  }
};

export {
  onRegister,
  onSignUp,
  onSignIn,
  onForgotPwd,
  onRecoverPwd,
  onResetPwd,
  onMfaVerify,
};
