import { Box, IconButton, Typography } from "@mui/material";
import { DownloadingTwoTone } from "@mui/icons-material";
import { CustomTooltip, formatDate } from "../../config/fieldConfig";
import { tableHoverStyle } from "../../styles/style";

export default function AssignCommissionColumns(
  actionsColumn = null,
  handleEdit = () => {},
  handlePdfDownload = () => {}
) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "beneficiaryName",
      headerName: "Beneficiaries",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.beneficiaryName} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true)}
          >
            {row.beneficiaryName}
          </div>
        );
      },
    },
    {
      field: "commission",
      headerName: "Commission",
      width: 250,
      // Custom sort comparator
      sortComparator: (v1, v2) => {
        // Convert to strings if they are arrays
        const value1 = Array.isArray(v1) ? v1.join(", ") : v1;
        const value2 = Array.isArray(v2) ? v2.join(", ") : v2;

        // Use localeCompare for string comparison
        return value1.localeCompare(value2);
      },
      renderCell: ({ row }) => {
        // Join commission names with newline characters for default tooltip
        const commissionString = Array.isArray(row.commission)
          ? row.commission.join(", ")
          : row.commission;

        const tooltipText = Array.isArray(row.commission)
          ? row.commission.join("\n") // Join with newline characters for default tooltip
          : commissionString || "No Commission";

        return (
          <Typography
            variant="body2"
            gutterBottom
            title={tooltipText} // Use title for default tooltip
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {commissionString || "No Commission"}
          </Typography>
        );
      },
    },
    {
      field: "adjustments",
      headerName: "Adjustments",
      width: 250,
      sortComparator: (v1, v2) => {
        // Convert arrays to strings for comparison
        const value1 = Array.isArray(v1)
          ? v1
              .map((adjustment) => `${adjustment.sDate} - ${adjustment.eDate}`)
              .join(", ")
          : "";
        const value2 = Array.isArray(v2)
          ? v2
              .map((adjustment) => `${adjustment.sDate} - ${adjustment.eDate}`)
              .join(", ")
          : "";
        return value1.localeCompare(value2);
      },
      renderCell: (params) => {
        const { value } = params;
        if (Array.isArray(value) && value.length > 0) {
          const tooltipContent = (
            <Box sx={tableHoverStyle}>
              <table>
                <thead>
                  <tr>
                    <th>Adjustments</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {value.map((adjustment, index) => {
                    const { sDate, eDate, ...rest } = adjustment;
                    return (
                      <tr key={index}>
                        <td>
                          {Object.entries(rest)
                            .map(([key, val]) => `${key}: ${val}`)
                            .join(", ")}
                        </td>
                        <td>{formatDate(sDate)}</td>
                        <td>{formatDate(eDate)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          );
          return (
            <CustomTooltip content={tooltipContent}>
              <Typography
                variant="body2"
                gutterBottom
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  cursor: "pointer",
                  width: "20vw",
                }}
              >
                {value
                  .map((adjustment) => {
                    const { sDate, eDate, ...rest } = adjustment;
                    return Object.entries(rest)
                      .map(([key, val]) => `${key}: ${val}`)
                      .join(", ");
                  })
                  .join(" | ")}
              </Typography>
            </CustomTooltip>
          );
        }

        return "";
      },
    },
    {
      field: "downloadPDF",
      headerName: "Download PDF",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <IconButton
          aria-label="download PDF"
          color="success"
          title="Download Pdf"
          onClick={() => handlePdfDownload(params)} // Assuming you have a function to handle PDF download
        >
          <DownloadingTwoTone />
        </IconButton>
      ),
    },
  ];
  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
