import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import { JWT_TOKEN } from "../../config/sessionStorage";
import {
  addSerialNumber,
  formatDateWithTimePart,
} from "../../config/fieldConfig";
import { EXCEPTION_MESSAGE } from "../../config/toastMessage";
import MonitorColumns from "./columns";
import { MONITOR_SCREEN } from "../../config/constants";

// Custom hook to manage the monitor service functionality
export const MonitorService = () => {
  // State for holding the table data
  const [data, setTableData] = useState([]);
  // State to track the user type
  const [type, setType] = useState("registeredAndSignUp");
  // State to track approval status for each user
  const [approvalStatus, setApprovalStatus] = useState({});
  // Currently selected option in the toolbar
  const [selectedOption, setSelectedOption] = useState("SignUp");
  // Loading state for the table
  const [isTableLoading, setIsTableLoading] = useState(true);

  // Ref to prevent remounting the component
  const stopRemount = useRef(true);

  // Constant for screen identifier
  const screenName = MONITOR_SCREEN || "";

  // Function to handle option change and update the selected type
  const handleOptionChange = (label, userType) => {
    // Update the selected option label
    setSelectedOption(label);
    // Update the user type based on the selected option
    setType(userType);
    // Fetch data based on the new user type
    fetchData(userType);
  };

  // Array of menu items for the toolbar, including labels and actions
  const menuItems = [
    {
      label: "SignUp",
      action: () => handleOptionChange("SignUp", "registeredAndSignUp"),
    },
    {
      label: "Registered",
      action: () => handleOptionChange("Registered", "getAllRegistration"),
    },
    {
      label: "Approved",
      action: () => handleOptionChange("Approved", "approved"),
    },
    {
      label: "Non Approved",
      action: () => handleOptionChange("Non Approved", "nonApproved"),
    },
  ];

  // Props for the toolbar menu, including the menu items and the currently selected option
  const toolbarMenuProps = { menuItems, selectedOption };

  // useEffect to fetch data on initial mount or when the user type changes
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false; // Prevents the component from remounting
      fetchData(type); // Fetch initial data with the default user type
    }
  }, [type]); // Dependency array ensures fetchData is called when type changes

  // Function to fetch data based on the current user type
  const fetchData = async (userType) => {
    try {
      setIsTableLoading(true); // Set loading state before fetching data

      // Check if userType is valid
      if (!userType || typeof userType !== "string") {
        throw new Error("Invalid or missing userType");
      }

      // Fetch JWT token for authorization
      const token = JWT_TOKEN();
      if (!token) throw new Error("Token not found or invalid"); // Handle missing token

      let res;

      // Conditional fetching based on user type
      if (userType === "getAllRegistration") {
        res = await ApiService.getAllRegistrationsBasedOnType(token, userType);
      } else {
        res = await ApiService.getAllUsersBasedOnType(token, userType);
      }

      // Check if the response is valid
      if (res?.status === 200 && Array.isArray(res?.data)) {
        const tableData =
          userType === "getAllRegistration"
            ? res?.data?.map((item) => ({
                ...item,
                id: item?.registrationId, // Assign registrationId as id for each entry
                userName: item?.name,
                createdDate: formatDateWithTimePart(item?.createdDate ?? ""), // Format created date
              }))
            : res?.data?.map((item) => ({
                ...item,
                createdDate: formatDateWithTimePart(item?.createdDate ?? ""), // Format created date
              }));

        setTableData(tableData); // Set the table data in state

        // Initialize approval status based on fetched data
        const initialApprovalStatus = tableData?.reduce((acc, user) => {
          acc[user.email] = user.approved; // Map email to its approval status
          return acc;
        }, {});
        // Set approval status in state
        setApprovalStatus(initialApprovalStatus);
      } else {
        setTableData([]); // Reset table data if the response format is unexpected
        setApprovalStatus({}); // Reset approval status
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData([]); // Reset table data on error
      setApprovalStatus({}); // Reset approval status on error
    } finally {
      setIsTableLoading(false); // Reset loading state after fetching data
    }
  };

  // Function to toggle the approval status of a user
  const toggleApproval = (email, beneficiaryId) => {
    setApprovalStatus((prevStatus) => {
      const newStatus = {
        ...prevStatus,
        [email]: !prevStatus[email], // Toggle approval status
      };

      // Create data object to send to the API
      const data = {
        email: email,
        beneficiaryId: beneficiaryId,
        approved: newStatus[email], // New approval status
      };

      try {
        sendDataToEndpoint(data); // Send updated data to the endpoint
      } catch (error) {
        console.error("Error toggling approval status:", error);
        toast.error(error?.message); // Show error message if sending fails
      }

      return newStatus; // Return updated approval status
    });
  };

  // Function to send updated user data to the API endpoint
  const sendDataToEndpoint = async (data) => {
    try {
      // Check if data is empty and show an error message
      if (!data || Object.keys(data).length === 0) {
        throw new Error("No data provided to send."); // Show an error message
      }

      // Fetch JWT token for authorization
      const token = JWT_TOKEN();
      if (!token) throw new Error("Token not found or invalid"); // Handle missing token

      const res = await ApiService.editUser(token, data); // Send data to the API

      // Handle the API response
      if (res?.status === 200) {
        toast.success(res?.data); // Show success message
      } else if (res?.code === "ERR_NETWORK") {
        toast.error("Network error: " + res?.message); // Show network error
      } else {
        toast.error(res?.response?.data || EXCEPTION_MESSAGE); // Show error message from response
      }

      fetchData(type); // Refetch data to reflect changes after editing
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error(error?.message); // Show error message
    }
  };

  // Determine if the current type is 'registeredUser' for column configuration
  const isRegisteredUser = type === "getAllRegistration";

  // Generate columns for the table based on approval status and user type
  const columns = Array.isArray(
    MonitorColumns({
      approvalStatus,
      toggleApproval,
      registeredUser: isRegisteredUser,
    })
  )
    ? MonitorColumns({
        approvalStatus,
        toggleApproval,
        registeredUser: isRegisteredUser,
      })
    : []; // Fallback to an empty array if not valid

  // Add serial numbers to the rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : []; // Fallback to an empty array if not valid

  // Return relevant data and props for the component
  return {
    screenName,
    rows,
    columns,
    isTableLoading,
    toolbarMenuProps,
  };
};
