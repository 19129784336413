import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import toast from "react-hot-toast";
import Captcha from "../../common/Captcha";
import Copyright from "../../common/Copyright";
import { CAPTCHA_FAIL } from "../../config/toastMessage";
import { encryptPwd, isEmpty } from "../../config/fieldConfig";
import { CfTransBlueLogo, ImgSignIn } from "../../config/imageConfig";
import { onSignIn } from "../../service/outerPagesService";
import { fields } from "./formFields";

export default function SignIn({
  setIsAuthenticated,
  setRes,
  setUserRoleAccess,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  // Loading Button
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // State to store the ReCAPTCHA value

  const recaptchaRef = useRef(null);

  const handleCaptchaChange = (isCaptchaChecked, value) => {
    setCaptchaValue(value); // Store the ReCAPTCHA value
    setIsCaptchaChecked(isCaptchaChecked);
  };

  let navigate = useNavigate();

  const onSubmit = async (data) => {
    const user = { ...data };

    if (isCaptchaChecked === true) {
      setLoading(true);
      try {
        const encryptedText = encryptPwd(user.password);
        user.password = encryptedText;

        await onSignIn(
          user,
          navigate,
          setIsAuthenticated,
          setRes,
          setUserRoleAccess,
          setLoading,
          captchaValue,
          recaptchaRef
        );
      } catch (error) {
        navigate("/register");
        console.log(error);
      }
    } else {
      toast.error(CAPTCHA_FAIL);
    }
  };

  useEffect(() => {
    setIsAuthenticated(false); //To set frontend authentication
    setRes(false);
    sessionStorage.clear(); // To remove session storage for logout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        {/* Left Grid */}
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "white", // imgLogo bgColor "#f5f5f5"
            py: { xs: 5, md: 2 },
          }}
        >
          <Box sx={{ mb: { xs: 2, md: 4 } }}>
            <Link href={"/"}>
              <img
                src={CfTransBlueLogo}
                alt="Logo"
                style={{ width: "100%", maxWidth: 150 }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${ImgSignIn})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: { xs: "100vw", md: "100%" },
              maxWidth: 700,
              height: { xs: 300, md: 500 },
            }}
          />
        </Grid>

        {/* Right Grid */}
        <Grid item xs={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              m: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3 }}
            >
              {fields.map((field) => (
                <TextField
                  key={field.name}
                  margin="normal"
                  size="small"
                  required={field.required}
                  fullWidth
                  label={field.label}
                  name={field.name}
                  autoComplete="off"
                  type={
                    field.name === "password"
                      ? showPassword
                        ? "text"
                        : "password"
                      : field.type
                  }
                  InputProps={
                    field.name === "password"
                      ? {
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffTwoTone />
                              ) : (
                                <VisibilityTwoTone />
                              )}
                            </IconButton>
                          ),
                        }
                      : null
                  }
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    validate: {
                      isEmpty: (value) =>
                        field.required ? isEmpty(value) : true,
                    }, // Apply isEmpty for all fields
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  FormHelperTextProps={{ sx: { mb: -3 } }}
                  onChange={(e) => {
                    setValue(field.name, e.target.value); // Update the form value
                    trigger(field.name); // Trigger validation on change
                  }}
                  onBlur={() => {
                    trigger(field.name); // Trigger validation on blur
                  }}
                />
              ))}

              <Captcha
                onCaptchaChange={handleCaptchaChange}
                recaptchaRef={recaptchaRef}
              />

              <FormControlLabel
                control={<Checkbox {...register("remember")} color="primary" />}
                label="Remember me"
              />

              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                loading={loading}
                loadingPosition="start"
                startIcon={<></>}
                style={{
                  backgroundColor: loading ? "#448aff" : undefined,
                  color: loading ? "#fff" : undefined,
                }}
                sx={{ my: 1 }}
              >
                {!loading ? "Sign In" : "Loading..."}
              </LoadingButton>

              <Grid container>
                <Grid item xs>
                  <Link href="/forgot_pwd" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2">
                    {"Don't have an account? Register Now"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
