import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  CloseTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import PlanForm from "./form";
import PlanService from "./service";
import FullScreenLoader from "../../common/LoadingScreen";
import CustomDataGrid from "../../../utils/CustomDataGrid";

export default function Plan() {
  const {
    screenName = "",
    displayName = "",
    rows = [],
    columns = [],
    fields = [],
    loading = false,
    editFormOpen = false,
    editItemId = null,
    editFormData = {},
    viewData = false,
    showForm = false,
    reset = () => {},
    toggleForm = () => {},
    setViewData = () => {},
    onSubmitForm = () => {},
    submitLoading = false,
    setShowForm = () => {},
  } = PlanService() || {};

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader loading={submitLoading} />

      <Box sx={{ m: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1 }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={showForm ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
            onClick={() => {
              toggleForm();
            }}
          >
            Add {screenName}
          </Button>
        </Box>

        {showForm && (
          <Paper sx={{ px: 3, py: 2 }}>
            <Typography variant="h6" gutterBottom mb={6}>
              Add {screenName}
            </Typography>
            {/* Add Form */}
            <PlanForm
              mode="add"
              onClose={toggleForm}
              fields={fields}
              viewData={viewData}
              onSubmitForm={onSubmitForm}
            />
          </Paper>
        )}
      </Box>

      <Box>
        {/* Data grid to display the list with CRUD operations */}
        <CustomDataGrid
          rows={rows ?? []}
          columns={columns ?? []}
          loading={loading}
          toolbarProps={{ displayName }}
        />

        <Dialog open={editFormOpen} maxWidth="lg">
          <DialogTitle>
            {viewData && editItemId ? "View" : "Edit"}
            &nbsp;{screenName}
            {viewData ? (
              <IconButton
                aria-label="edit"
                style={{ position: "absolute", top: 10, right: 45 }}
                color="primary"
                title="Edit"
                onClick={() => setViewData(false)}
              >
                <EditTwoTone />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="close"
              style={{ position: "absolute", top: "8px", right: "8px" }}
              onClick={reset}
            >
              <CloseTwoTone />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* Edit Form */}
            <PlanForm
              mode="edit"
              onClose={reset}
              fields={fields}
              viewData={viewData}
              editItemId={editItemId}
              editFormData={editFormData}
              onSubmitForm={onSubmitForm}
              setShowForm={setShowForm}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
