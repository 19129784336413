import {
  ACCESS_MSG1,
  ACCESS_MSG2,
  FORGOT_MSG1,
  REGISTER_MSG1,
  REGISTER_MSG2,
  SESSION_EXPIRED_MSG,
  SESSION_MSG1,
  SIGNUP_MSG1,
  TOKEN_VERIFIED_MSG1,
  TOKEN_VERIFIED_MSG2,
} from "../config/toastMessage";
import MessageDisplay from "./messageDisplay";

export function RegisterSuccess() {
  return <MessageDisplay msg1={REGISTER_MSG1} msg2={REGISTER_MSG2} />;
}

export function SignUpSuccess() {
  return <MessageDisplay msg1={SIGNUP_MSG1} msg1Link={"Sign In now..."} />;
}

export function TokenVerified() {
  return (
    <MessageDisplay
      icon={"info"}
      msg1={TOKEN_VERIFIED_MSG1}
      msg2={TOKEN_VERIFIED_MSG2}
      msg2Link={"Sign In..."}
      msgBreak={"to the application."}
    />
  );
}

export function SessionLogout() {
  return (
    <MessageDisplay
      icon={"warning"}
      msg1={SESSION_EXPIRED_MSG}
      msg1Link={"To Sign In..."}
    />
  );
}

export function IdleLogout() {
  return (
    <MessageDisplay
      icon={"warning"}
      msg1={SESSION_MSG1}
      msg1Link={"Sign In now..."}
    />
  );
}

export function ForgotSuccess() {
  return <MessageDisplay msg1={FORGOT_MSG1} />;
}

export function AccessDenied() {
  return (
    <MessageDisplay icon="warning" msg1={ACCESS_MSG1} msg2={ACCESS_MSG2} />
  );
}
