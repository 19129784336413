import { Box } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { formatDateAndTime } from "../../config/fieldConfig";
import { TICKET_SCREEN } from "../../config/constants";

const Columns = (
  handleEdit,
  handleDelete,
  roleAccess,
  isSuperAdmin,
  isMonitor
) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === TICKET_SCREEN && item.writeAccess
  );

  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: !isSuperAdmin && !isMonitor ? 100 : 70,
    },
    ...(isSuperAdmin || isMonitor
      ? [
          {
            field: "userName",
            headerName: "User Name",
            width: 150,
            renderCell: ({ row }) => {
              return (
                <div
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline", // Optional: Add underline to text
                  }}
                  title={row.userName} // Add the title attribute for the tooltip
                  onClick={handleEdit(row.id, true)} // Ensure it's a function call here
                >
                  {row.userName}
                </div>
              );
            },
          },
        ]
      : []),
    ...(isSuperAdmin || isMonitor
      ? [
          {
            field: "orgName",
            headerName: "Organization Name",
            width: 150,
          },
        ]
      : []),
    {
      field: "screenName",
      headerName: "Screen Name",
      width: 150,
      ...(!isSuperAdmin &&
        !isMonitor && {
          renderCell: ({ row }) => {
            return (
              <div
                style={{
                  cursor: "pointer",
                  textDecoration: "underline", // Optional: Add underline to text
                }}
                title={row.screenName} // Add the title attribute for the tooltip
                onClick={handleEdit(row.id, true)} // Ensure it's a function call here
              >
                {row.screenName}
              </div>
            );
          },
        }),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 150,
    },
    {
      field: "errorTime",
      headerName: "Error Time",
      width: !isSuperAdmin && !isMonitor ? 200 : 150,
      renderCell: ({ row }) => {
        const formattedDate = formatDateAndTime(row.errorTime);
        return (
          <div
            title={formattedDate} // Tooltip with formatted date
          >
            {formattedDate}
          </div>
        );
      },
    },
    ...(!isSuperAdmin && !isMonitor
      ? []
      : [
          {
            field: "errorRaisedTime",
            headerName: "Ticket Raised Time",
            width: 150,
            renderCell: ({ row }) => {
              const formattedDate = formatDateAndTime(
                row.updatedDate ? row.updatedDate : row.createdDate
              );
              return (
                <div
                  title={formattedDate} // Tooltip with formatted date
                >
                  {formattedDate}
                </div>
              );
            },
          },
        ]),
    {
      field: "description",
      headerName: "Description",
      width: !isSuperAdmin && !isMonitor ? 300 : 150,
      renderCell: (params) => {
        const htmlContent = params.value;
        const textContent =
          new DOMParser().parseFromString(htmlContent, "text/html").body
            .textContent || "";

        return (
          <Box
            title={textContent} // Set the title attribute to plain text version
            dangerouslySetInnerHTML={{ __html: htmlContent }} // Render the HTML content
          />
        );
      },
    },
    ...(readAccess || isSuperAdmin || isMonitor
      ? [
          (readAccess || isSuperAdmin || isMonitor) && {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            align: "left",
            headerAlign: "left",
            cellClassName: "actions",
            width: 100,
            getActions: ({ id }) => {
              const editAccess = roleAccess.some(
                (item) => item.resource === TICKET_SCREEN && item.writeAccess
              );
              const deleteAccess = roleAccess.some(
                (item) => item.resource === TICKET_SCREEN && item.deleteAccess
              );
              const actions = [];

              if (editAccess || isSuperAdmin || isMonitor) {
                actions.push(
                  <GridActionsCellItem
                    icon={<EditTwoTone />}
                    label="Edit"
                    color="primary"
                    onClick={
                      editAccess || isSuperAdmin || isMonitor
                        ? handleEdit(id)
                        : undefined
                    }
                    title={
                      editAccess || isSuperAdmin || isMonitor
                        ? "Edit"
                        : "No Edit Access"
                    }
                  />
                );
              }

              if (deleteAccess) {
                actions.push(
                  <GridActionsCellItem
                    icon={<DeleteTwoTone />}
                    label="Delete"
                    color="error"
                    onClick={deleteAccess ? handleDelete(id) : undefined}
                    title={deleteAccess ? "Delete" : "No Delete Access"}
                  />
                );
              }
              return actions;
            },
          },
        ]
      : []),
  ];
};

export default Columns;
