import CustomDataGrid from "../../../utils/CustomDataGrid";
import { BillingService } from "../Billing/service";

export default function PaymentHistory() {
  const {
    displayName = "",
    rows = [],
    columns = [],
    loading = false,
  } = BillingService() || {};

  return (
    <>
      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={loading}
        toolbarProps={{ displayName }}
      />
    </>
  );
}
