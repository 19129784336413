/**
 * Format the response string by replacing commas and periods with newline characters.
 * @param {string} response - The response string to format.
 * @returns {string} - The formatted response string.
 */
export function formatUploadToastRes(response) {
  try {
    if (typeof response !== "string") {
      throw new Error("Invalid input: Expected a string.");
    }

    const formattedData = response.replace(/, |\./g, (match) => {
      return match === "," ? ",\n" : ".\n"; // Return a comma with newline or period with newline
    });

    return formattedData;
  } catch (error) {
    console.error("Error formatting response:", error);
    return "Error formatting response.";
  }
}
