import { Avatar, Box } from "@mui/material"; // Import necessary components from MUI
import toast from "react-hot-toast";

export default function AvatarUploadBox({
  selectedImage = null,
  setSelectedImage = () => {},
}) {
  // Function to handle the image change event
  const handleImageChange = (e) => {
    const file = e.target.files?.[0]; // Get the first file from the input
    if (file) {
      // Check if the selected file is an image
      const isImage = file.type.startsWith("image/"); // Check if the file type starts with 'image/'

      if (!isImage) {
        // If the file is not an image, show an error toast and exit the function
        toast.error("Please upload a valid image file!"); // Show error toast for invalid file type
        return;
      }

      try {
        const reader = new FileReader(); // Create a FileReader to read the image
        reader.onload = () => {
          const base64Image = reader.result; // This is the Base64 data URL
          console.log("Base64 Image Data URL: ", base64Image); // Log the Base64 image data URL

          // Convert Base64 Data URL to byte-based Base64 string
          const byteBase64 = base64Image.split(",")[1]; // Split to get the base64 part
          console.log("Byte-Based Base64 Image Data: ", byteBase64); // Log the byte-based Base64 string

          setSelectedImage(byteBase64); // Set the image state in the parent
        };
        reader.readAsDataURL(file); // Read the file as a Data URL
      } catch (error) {
        console.error("Error processing the image: ", error); // Log the error if something goes wrong
        toast.error(error?.message || "Failed to upload image!"); // Show error toast
      }
    }
  };
  // Function to trigger the file input click
  const handleAvatarClick = () => {
    try {
      const input = document.createElement("input"); // Create a new file input
      input.type = "file"; // Set the input type to file
      input.accept = "image/*"; // Accept all image files
      input.onchange = handleImageChange; // Set the onChange handler
      input.click(); // Simulate a click to open the file dialog
    } catch (error) {
      console.error("Error opening the file dialog: ", error); // Log any errors
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: 150,
        backgroundImage: "linear-gradient(135deg, #6f86d6, #48c6ef)", // Background gradient
        borderRadius: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Avatar
        alt="User Profile"
        src={selectedImage ? `data:image/*;base64,${selectedImage}` : undefined} // Use selectedImage for displaying the image
        sx={{
          width: 120,
          height: 130,
          border: 4,
          borderColor: "#fff", // White border color
          cursor: "pointer",
          objectFit: "cover",
          position: "absolute",
          bottom: -40,
        }}
        onClick={handleAvatarClick} // Click handler to open file dialog
      />
    </Box>
  );
}
