import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseTwoTone } from "@mui/icons-material";
import { orderErrorFocus } from "../../config/fieldConfig";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../../utils/CustomInputFields";

export default function ScheduleForm({
  screenName = "",
  open = false,
  onClose = () => {},
  mode = "add",
  editItemId = null,
  editFormData = {},
  fields = [],
  timeZone = "",
  onSubmitForm = () => {},
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();

  const fieldRefs = useRef({});

  const watchedFields = watch(); // Watch all fields to track changes

  const [initialized, setInitialized] = useState(false); // Track if the form is initialized

  useEffect(() => {
    if (!open) {
      // Reset the initialized state when the form is closed
      setInitialized(false);
    }
  }, [open]);

  useEffect(() => {
    if (!initialized && open) {
      // Reset the form when it is first opened
      reset();

      // Populate form with edit data if in edit mode
      if (mode === "edit" && editItemId !== null && editFormData) {
        try {
          fields.forEach((field) => {
            if (editFormData.hasOwnProperty(field.name)) {
              setValue(field.name, editFormData[field.name]); // Set existing values
            }
          });
        } catch (error) {
          console.error("Error setting form data:", error);
        }
      }

      setInitialized(true); // Mark the form as initialized
    }
  }, [
    editFormData,
    editItemId,
    fields,
    mode,
    reset,
    setValue,
    open,
    initialized,
  ]);

  const onSubmit = (formData) => {
    try {
      // Validate that formData is not empty
      if (!formData || Object.keys(formData).length === 0) {
        throw new Error("Form data is empty");
      }

      // Remove unnecessary field from formData
      delete formData.selectedTimeZone;

      // Handle form submission based on mode
      if (mode === "add") {
        onSubmitForm(formData, "add");
      } else if (mode === "edit") {
        formData.id = editItemId; // Include the ID for editing
        onSubmitForm(formData, "edit");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error(error.message); // Show toast with the error message
    } finally {
      onClose(); // Close the form after submission
    }
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {mode === "add" ? "Add" : "Edit"}
        &nbsp;{screenName}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ width: 415 }}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            orderErrorFocus(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map(
              (field, index) =>
                (field.name === "schedule" ||
                  field.name === "executeJobTask" ||
                  field.name === "selectedTimeZone" ||
                  (field.name === "day" &&
                    watchedFields["schedule"] === "Weekly") ||
                  (field.name === "month" &&
                    (watchedFields["schedule"] === "Quarterly" ||
                      watchedFields["schedule"] === "Half-Yearly" ||
                      watchedFields["schedule"] === "Yearly")) ||
                  (field.name === "customPeriod" &&
                    watchedFields["schedule"] === "Custom") ||
                  (field.name === "date" &&
                    (watchedFields["schedule"] === "Monthly" ||
                      watchedFields["schedule"] === "Quarterly" ||
                      watchedFields["schedule"] === "Half-Yearly" ||
                      watchedFields["schedule"] === "Yearly" ||
                      watchedFields["schedule"] === "Bi-Weekly")) ||
                  (field.name === "time" &&
                    (watchedFields["schedule"] === "Minutes" ||
                      watchedFields["schedule"] === "Hourly" ||
                      watchedFields["schedule"] === "Daily" ||
                      watchedFields["schedule"] === "Weekly" ||
                      watchedFields["schedule"] === "Bi-Weekly" ||
                      watchedFields["schedule"] === "Monthly" ||
                      watchedFields["schedule"] === "Quarterly" ||
                      watchedFields["schedule"] === "Half-Yearly" ||
                      watchedFields["schedule"] === "Yearly" ||
                      watchedFields["schedule"] === "Custom")) ||
                  (field.name === "pojoType" &&
                    watchedFields["executeJobTask"] === "Export") ||
                  (field.name === "customDays" &&
                    watchedFields["schedule"] === "Custom")) && (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sm={
                      field.name === "customDays" ||
                      field.name === "customPeriod"
                        ? 6
                        : 12
                    }
                  >
                    {field.name === "schedule" ||
                    field.name === "executeJobTask" ||
                    (field.name === "day" &&
                      watchedFields["schedule"] === "Weekly") ||
                    (field.name === "month" &&
                      (watchedFields["schedule"] === "Quarterly" ||
                        watchedFields["schedule"] === "Half-Yearly" ||
                        watchedFields["schedule"] === "Yearly")) ||
                    (field.name === "pojoType" &&
                      watchedFields["executeJobTask"] === "Export") ||
                    (field.name === "customPeriod" &&
                      watchedFields["schedule"] === "Custom") ||
                    (field.name === "date" &&
                      (watchedFields["schedule"] === "Monthly" ||
                        watchedFields["schedule"] === "Quarterly" ||
                        watchedFields["schedule"] === "Half-Yearly" ||
                        watchedFields["schedule"] === "Yearly" ||
                        watchedFields["schedule"] === "Bi-Weekly")) ? (
                      <CustomAutocomplete
                        field={field}
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        watch={watch}
                        errors={errors}
                        fieldRefs={fieldRefs}
                        onChange={() => {
                          if (field.name === "schedule") {
                            setValue("day", "");
                            setValue("month", "");
                            setValue("path", "");
                            setValue("date", "");
                            setValue("executeJobTask", "");
                            setValue("time", "");
                            setValue("pojoType", "");
                            clearErrors("executeJobTask");
                            clearErrors("time");
                            clearErrors("day");
                            clearErrors("month");
                            clearErrors("path");
                            clearErrors("date");
                            clearErrors("pojoType");
                          }
                        }}
                      />
                    ) : (field.name === "time" &&
                        (watchedFields["schedule"] === "Minutes" ||
                          watchedFields["schedule"] === "Hourly" ||
                          watchedFields["schedule"] === "Daily" ||
                          watchedFields["schedule"] === "Weekly" ||
                          watchedFields["schedule"] === "Bi-Weekly" ||
                          watchedFields["schedule"] === "Monthly" ||
                          watchedFields["schedule"] === "Quarterly" ||
                          watchedFields["schedule"] === "Half-Yearly" ||
                          watchedFields["schedule"] === "Yearly" ||
                          watchedFields["schedule"] === "Custom")) ||
                      (field.name === "pojoType" &&
                        watchedFields["executeJobTask"] === "Export") ||
                      field.name === "selectedTimeZone" ||
                      (field.name === "customDays" &&
                        watchedFields["schedule"] === "Custom") ? (
                      <CustomTextField
                        field={field}
                        register={register}
                        trigger={trigger}
                        errors={errors}
                        fieldRefs={fieldRefs}
                        InputLabelProps={{
                          style: {
                            pointerEvents: "selectedTimeZone" ? "none" : "auto",
                          },
                          shrink:
                            field.name === "selectedTimeZone" ||
                            field.name === "time",
                        }}
                        style={{
                          pointerEvents:
                            field.name === "selectedTimeZone" ? "none" : "auto",
                        }}
                        value={
                          field.name === "selectedTimeZone"
                            ? timeZone || ""
                            : watch(field.name) || ""
                        }
                      />
                    ) : field.isSubtitle &&
                      watchedFields["schedule"] === "Custom" ? (
                      <Typography variant="subtitle1" mt={-1}>
                        {watchedFields["customDays"]}
                        {watchedFields["customPeriod"]} {field.subtitle}
                      </Typography>
                    ) : null}
                  </Grid>
                )
            )}
          </Grid>

          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
