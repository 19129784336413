import { useEffect, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  CloseTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import { preventNonNumericInput } from "../../config/fieldConfig";

export default function TieredForm({
  open = false,
  onClose = () => {},
  tieredData = [],
  onTieredData = () => {},
  setTieredChecked = () => {},
  cumulativeValue = false,
  setCumulativeValue = () => {},
}) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dynamicFields: [{ from: "", to: "", commissionRate: "" }],
    },
  });

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: "dynamicFields",
  });

  useEffect(() => {
    try {
      // Define default values
      const defaultFields = { from: "", to: "", commissionRate: "" };

      const fieldsToSet =
        tieredData.length > 0
          ? tieredData.map((item) => ({
              from: item.from,
              to: item.to,
              commissionRate: item.commissionRate,
            }))
          : [defaultFields];
      if (!Array.isArray(fieldsToSet)) {
        throw new Error("TieredData is not available or empty");
      }
      // Ensure there's at least one default row
      if (fieldsToSet.length === 0) {
        fieldsToSet.push(defaultFields);
      }

      // Update the form data using setValue
      setValue("dynamicFields", fieldsToSet);
      reset({ dynamicFields: fieldsToSet });
    } catch (error) {
      console.error(error); // Log error to console
    }
  }, [tieredData, setValue, reset]);

  useEffect(() => {
    if (open) {
      reset(); // Reset the form only when dialog opens
    }
  }, [open, reset]);

  const timeoutRef = useRef(null);
  // Use a mounted check
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false; // Mark the component as unmounted when it is unmounted
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear any pending timeouts
      }
    };
  }, []);

  const onSubmit = () => {
    try {
      const numberOfItems = watch("dynamicFields")?.length || 0;

      const resultArray = [];

      for (let i = 0; i < numberOfItems; i++) {
        const from = watch(`dynamicFields[${i}].from`);
        const to = watch(`dynamicFields[${i}].to`);
        const commissionRate = watch(`dynamicFields[${i}].commissionRate`);

        const newItem = {
          from,
          to,
          commissionRate,
        };

        // Only add the item if at least one of its properties is not empty
        if (from || to || commissionRate) {
          resultArray.push(newItem);
        }
      }
      // Check if data is an array and has elements
      if (!Array.isArray(resultArray)) {
        throw new Error("Data is not available or empty");
      }
      onTieredData(resultArray);
      onClose(false);
      if (resultArray.length === 0) {
        setTieredChecked(false);
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: "600px", // Adjust this value as needed
          maxWidth: "600px", // Ensure that the maximum width is set
        },
      }}
    >
      <DialogTitle>
        Tiered Commission
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={() => {
            onClose();
            if (tieredData.length === 0) {
              setTieredChecked(false);
            }
          }}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          <Box
            sx={{
              display: "flex", // Align children in a row (flexbox)
              gap: "8px", // Add spacing between switch and labels
              alignItems: "center", // Align switch and labels vertically
              justifyContent: "flex-start", // Align to the left
            }}
          >
            <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
              Cumulative :
            </Typography>
            {/* Label for Yes */}
            <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
              No
            </Typography>

            {/* Switch Component */}
            <Switch
              checked={cumulativeValue} // Bind the checked value to your state
              onChange={() => {
                setCumulativeValue(!cumulativeValue); // Toggle between true and false
              }}
              size="small"
            />

            {/* Label for No */}
            <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
              Yes
            </Typography>
          </Box>
          {/* for tiered */}
          {fields.map((field, setIndex) => (
            <Grid container spacing={2} key={setIndex}>
              {["from", "to", "commissionRate"].map((property) => (
                <Grid
                  item
                  xs={12}
                  sm={property === "commissionRate" ? 3.9 : 2.7}
                  key={property}
                  pb={1}
                  mt={1}
                >
                  <TextField
                    fullWidth
                    name={`dynamicFields[${setIndex}].${property}`}
                    placeholder={`${
                      property.charAt(0).toUpperCase() + property.slice(1)
                    }%`}
                    label={property.charAt(0).toUpperCase() + property.slice(1)}
                    variant="outlined"
                    size="small"
                    type="number"
                    autoComplete="off"
                    onWheel={(event) => {
                      event.target.blur();

                      // Refocus with a delay but ensure the component is still mounted
                      timeoutRef.current = setTimeout(() => {
                        if (isMounted.current) {
                          // Check if the component is still mounted
                          event.target.focus();
                        }
                      }, 0); // Optional: Add more delay if needed
                    }}
                    {...register(`dynamicFields[${setIndex}].${property}`, {
                      validate: (value) => {
                        const from = watch(`dynamicFields[${setIndex}].from`);
                        const to = watch(`dynamicFields[${setIndex}].to`);
                        const commissionRate = watch(
                          `dynamicFields[${setIndex}].commissionRate`
                        );

                        // Validation for "from" field
                        if (property === "from" && value) {
                          // Ensure "From" is not greater than or equal to the "To" value in the same row
                          if (to && parseFloat(value) >= parseFloat(to)) {
                            return "From must be less than To";
                          }

                          // Ensure "From" is greater than the "To" value of the previous row
                          const prevTo = watch(
                            `dynamicFields[${setIndex - 1}].to`
                          );
                          if (
                            setIndex > 0 &&
                            prevTo &&
                            parseFloat(value) <= parseFloat(prevTo)
                          ) {
                            return "From must be less than To";
                          }
                        }

                        // Validation for "to" field
                        if (property === "to" && value) {
                          // Ensure "To" is not less than or equal to the "From" value in the same row
                          if (parseFloat(value) <= parseFloat(from)) {
                            return "To must be greater than From";
                          }

                          // Ensure "To" is not equal to the "From" value in the next row
                          const nextFrom = watch(
                            `dynamicFields[${setIndex + 1}].from`
                          );
                          if (
                            nextFrom &&
                            parseFloat(value) >= parseFloat(nextFrom)
                          ) {
                            return "To must be greater than From";
                          }
                        }

                        // Required field validation
                        if ((!from || !to || !commissionRate) && !value) {
                          if (
                            setIndex === 0 &&
                            !from &&
                            !to &&
                            !commissionRate
                          ) {
                            console.log(setIndex);
                          } else {
                            return `${
                              property.charAt(0).toUpperCase() +
                              property.slice(1)
                            } is required`;
                          }
                        }
                        return true; // Pass validation if the row is completely empty
                      },
                      required: `${
                        property.charAt(0).toUpperCase() + property.slice(1)
                      } is required`,
                    })}
                    onKeyDown={(e) => {
                      if (`dynamicFields[${setIndex}].inputValue`) {
                        preventNonNumericInput(e);
                      }
                    }}
                    error={!!errors?.dynamicFields?.[setIndex]?.[property]}
                    helperText={
                      errors?.dynamicFields?.[setIndex]?.[property]?.message
                    }
                    FormHelperTextProps={{ sx: { mb: -3 } }}
                    inputProps={{ min: 1, step: 1 }}
                    value={field[property]}
                    onChange={(e) => {
                      const newFields = [...fields];
                      newFields[setIndex][property] = e.target.value;
                      setValue(
                        `dynamicFields[${setIndex}].${property}`,
                        e.target.value
                      ); // Set the value
                      // Trigger validation for both "from" and "to" to keep them in sync
                      if (property === "from") {
                        trigger(`dynamicFields[${setIndex}].to`); // Revalidate "to" when "from" changes
                      }
                      if (property === "to") {
                        trigger(`dynamicFields[${setIndex}].from`); // Revalidate "from" when "to" changes
                      }
                      trigger(`dynamicFields[${setIndex}].${property}`); // Trigger revalidation for the current field
                      setValue("dynamicFields", newFields);
                    }}
                  />
                </Grid>
              ))}

              <Grid item mt={1}>
                <IconButton
                  onClick={async () => {
                    const isValid = await trigger(); // Validate all fields

                    // Check if any row is completely empty
                    const isAnyRowEmpty = fields.some((_, index) => {
                      const from = watch(`dynamicFields[${index}].from`);
                      const to = watch(`dynamicFields[${index}].to`);
                      const commissionRate = watch(
                        `dynamicFields[${index}].commissionRate`
                      );
                      return !from && !to && !commissionRate;
                    });

                    if (isValid && !isAnyRowEmpty) {
                      insert(setIndex + 1, {
                        from: "",
                        to: "",
                        commissionRate: "",
                      });
                    } else {
                      const from = watch(`dynamicFields[${setIndex}].from`);
                      const to = watch(`dynamicFields[${setIndex}].to`);
                      const commissionRate = watch(
                        `dynamicFields[${setIndex}].commissionRate`
                      );
                      if (from === "") {
                        setError(`dynamicFields[${setIndex}].from`, {
                          type: "manual",
                          message: "From is required",
                        });
                      }
                      if (to === "") {
                        setError(`dynamicFields[${setIndex}].to`, {
                          type: "manual",
                          message: "To is required",
                        });
                      }
                      if (commissionRate === "") {
                        setError(`dynamicFields[${setIndex}].commissionRate`, {
                          type: "manual",
                          message: "CommissionRate is required",
                        });
                      }
                      console.log(
                        "Please fill in all fields for each row before adding a new one."
                      );
                    }
                  }}
                  color="success"
                >
                  <AddCircleOutlineTwoTone />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if (fields.length > 1) {
                      // Revalidate all remaining rows after the deletion
                      const remainingFields = fields.slice(
                        0,
                        fields.length - 1
                      ); // After row deletion
                      remainingFields.forEach((_, idx) => {
                        // Update and reset validation logic based on new state
                        const previousTo = watch(
                          `dynamicFields[${idx - 1}]?.to`
                        );
                        const currentFrom = watch(`dynamicFields[${idx}].from`);

                        if (
                          idx > 0 &&
                          previousTo &&
                          currentFrom <= previousTo
                        ) {
                          setError(`dynamicFields[${idx}].from`, {
                            type: "manual",
                            message: "From must be less than To",
                          });
                        } else {
                          clearErrors(`dynamicFields[${idx}].from`);
                        }

                        // Revalidate the `To` field as well
                        trigger(`dynamicFields[${idx}].from`);
                        trigger(`dynamicFields[${idx}].to`);
                      });
                      // Clear errors for the current row before removing it
                      clearErrors(`dynamicFields[${setIndex}]`);
                      remove(setIndex);
                    } else {
                      console.log(setIndex);

                      // If only one row exists, clear the row instead of deleting
                      update(setIndex, {
                        from: "",
                        to: "",
                        commissionRate: "",
                      });
                      clearErrors(`dynamicFields[${setIndex}]`);
                    }
                  }}
                  color="error"
                >
                  <RemoveCircleOutlineTwoTone />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              {tieredData.length !== 0 ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
