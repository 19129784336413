import { useEffect, useMemo, useState } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import EmptyBox from "../components/common/EmptyBox";
import CustomPagination from "../components/common/CustomPagination";
import CustomToolbar from "./CustomToolbar";

export default function CustomDataGrid({
  rows = [], // Default to an empty array if rows are not provided
  columns = [], // Default to an empty array if columns are not provided
  toolbarProps = {}, // Accept props for the custom toolbar
  message = "",
  loading = false, // Default loading state to false
  sx = {}, // Additional styles can be passed
  apiEndpoint,
  columnVisibilityModel,
}) {
  const [page, setPage] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // 0-based index for the first page
    pageSize: 10,
  });

  // Constant to check if row has more than 0 records
  const hasRecords = rows?.length > 0 || false; // Defaults to false if rows is undefined or has length 0

  const apiRef = useGridApiRef();

  //  Reset pagination to the first page when selectedRole changes
  useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    setPage(false);
  }, [apiEndpoint]);

  useEffect(() => {
    setPage(true);
  }, [paginationModel]);

  // Initial state with column visibility model
  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel,
      },
      pagination: { paginationModel: { pageSize: 10 } },
    }),
    [columnVisibilityModel]
  );

  useEffect(() => {
    // Set the column visibility model after the initial render
    if (apiRef && apiRef.current) {
      apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
    }
  }, [columnVisibilityModel, apiRef]);

  return (
    <>
      <DataGrid
        rows={rows ?? []} // Rows of the data grid
        columns={columns ?? []} // Columns of the data grid
        apiRef={apiRef}
        slots={{
          noRowsOverlay: () => <EmptyBox message={message} />, // Custom component for no rows
          toolbar: () => (
            <CustomToolbar
              hasRecords={hasRecords}
              apiRef={apiRef}
              apiEndpoint={apiEndpoint}
              {...toolbarProps}
            />
          ), // Render the toolbar with props
          pagination: CustomPagination, // Custom pagination component
        }}
        disableRowSelectionOnClick // Disable row selection on click
        autoHeight={rows?.length >= 10} // Set autoHeight based on the number of rows
        initialState={initialState}
        onPaginationModelChange={!page ? setPaginationModel : undefined}
        paginationModel={!page ? paginationModel : undefined}
        pageSizeOptions={[10, 25, 50]} // Options for page size
        loading={loading} // Loading state
        density="compact" // Set the density of the DataGrid
        sx={{
          p: 1, // Padding
          height: 530, // Fixed height
          // Removing the cell border outlines on focus
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          ...sx, // Spread any additional styles
        }}
      />
    </>
  );
}
