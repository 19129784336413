import { useEffect, useRef, useState } from "react";
import ApiService from "../../../apis/ApiService";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  ORG_ID,
  TIME_ZONE,
  USER_ID,
  USER_NAME,
} from "../../config/sessionStorage";
import { addSerialNumber, formatDateDDMMYYYY } from "../../config/fieldConfig";
import { getInitialDateRange } from "../../../utils/getInitialDateRange";
import {
  formatCurrency,
  formatCurrencySymbol,
} from "../../../utils/CurrencyFormatter";
import { IndividualDashboardColumns } from "./columns";

export const IndividualDashboardService = () => {
  // Total Commission for SalesRep
  const [totalCommSalesRep, setTotalCommSalesRep] = useState("");

  // Top Commission Product SalesRep
  const [topCommissionProductsSalesRep, setTopCommissionProductsSalesRep] =
    useState([]);

  // Top Commission Customer SalesRep
  const [customerData, setCustomerData] = useState([]);

  // Not calculated table data
  const [tableData, setTableData] = useState([]);

  const [totalTransCount, setTotalTransCount] = useState("" || 0);
  const [processTransCount, setProcessTransCount] = useState("" || 0);
  const [salesRepData, setSalesRepData] = useState("");

  const currency = CURRENCY_NAME() || "";
  const timeZone = TIME_ZONE() || "";

  const order = "asc";

  const count = 5;
  const tableCount = 10;

  const stopRemount = useRef(true);

  const { startDate, endDate } = getInitialDateRange(stopRemount);

  const [reportingTo, setReportingTo] = useState([]);

  const orgId = ORG_ID();
  const userName = USER_NAME();
  const userId = USER_ID();

  const menu = [`${userId} - ${userName}`, "My Reportees"];

  const [checkedItems, setCheckedItems] = useState(new Set()); // Stores selected user IDs
  const [checkedNameItems, setCheckedNameItems] = useState(new Set()); // Stores selected user names
  const [selectedDropdown, setSelectedDropdown] = useState(menu[0]); // Tracks current dropdown selection
  const [isReporteeMenuOpen, setIsReporteeMenuOpen] = useState(false); // Manages reportee menu visibility
  const [appliedCheckedNames, setAppliedCheckedNames] = useState([]); // Stores applied names for display
  const [reporteeIds, setReporteeIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const salesRepsColumns = [
    {
      field: "beneficiaryName",
      headerName: "Beneficiary",
      width: 200,
      sortable: false,
    },
    {
      field: "totalNetPayout",
      headerName: `Net Payout (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 150,
      sortable: false,
    },
  ];

  // Convert salesRepData object to an array
  const salesRepsRows = Object.values(salesRepData).map((row, index) => ({
    id: index + 1,
    beneficiaryName: row.beneficiaryName,
    totalNetPayout: formatCurrency(parseFloat(row.totalNetPayout)),
  }));

  const handleGetMyDetailsApplyClick = (salesRe) => {
    stopRemount.current = true;
    setReporteeIds(salesRe);
    fetchData(startDate, endDate, "ApplyClick", salesRe);
  };

  const handleGetMyReportee = () => {
    stopRemount.current = true;
    setReporteeIds([]);
    fetchData(startDate, endDate, "My Reportees");
  };

  const handleGetMyDetails = () => {
    stopRemount.current = true;
    setReporteeIds([]);
    fetchData(startDate, endDate, "My Data");
  };

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;

      if (reporteeIds.length !== 0) {
        fetchData(startDate, endDate, "ApplyClick", reporteeIds);
      } else if (selectedDropdown === "My Reportees") {
        handleGetMyReportee();
      } else {
        fetchData(startDate, endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const fetchAndSetData = async (fetchFunctions) => {
    const [
      totalCommSalesRep,
      topCommissionProducts,
      topCustomers,
      notCalcTransData,
      topSalesReps,
    ] = await Promise.all(fetchFunctions);

    setTotalCommSalesRep(formatCurrencySymbol(totalCommSalesRep));

    const formattedTopCommissionProducts = topCommissionProducts.map((row) => ({
      value: row.totalNetPayout,
      label: row.productName,
    }));

    setTopCommissionProductsSalesRep(formattedTopCommissionProducts);

    setCustomerData(topCustomers);
    setTableData(notCalcTransData?.notProcessedTransactionRecords || []);

    setTotalTransCount(notCalcTransData?.totalTransactionCount || 0);
    setProcessTransCount(notCalcTransData?.processedTransactionCount || 0);

    setSalesRepData(topSalesReps);
  };

  const fetchData = async (startDate, endDate, selectedDropdown, salesRe) => {
    try {
      const TOKEN = JWT_TOKEN();

      const activeBeneficiaries = await ApiService.getActiveBeneficiaries(
        TOKEN
      );
      const response = activeBeneficiaries?.data;
      // Transform beneficiary data
      const transformedData = response.map((beneficiary) => ({
        userId: beneficiary.userId,
        userName: beneficiary.userName,
      }));

      // Filter out the entry with userId "T4"
      const filteredData = transformedData.filter(
        (beneficiary) => beneficiary.userId !== userId
      );
      setReportingTo(filteredData);

      if (selectedDropdown === "My Data" || !selectedDropdown) {
        await fetchAndSetData([
          ApiService.getIndividualTotalCommission(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualSalesByProduct(
            TOKEN,
            order,
            0,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualSalesByCustomer(
            TOKEN,
            order,
            count,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualUnProcessedTrans(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getCreditTransForUser(
            TOKEN,
            order,
            tableCount,
            startDate,
            endDate,
            null,
            null
          ),
        ]);
      } else if (selectedDropdown === "My Reportees") {
        await fetchAndSetData([
          ApiService.getReporteesTotalCommission(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesSalesByProduct(
            TOKEN,
            order,
            0,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesSalesByCustomer(
            TOKEN,
            order,
            count,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesUnProcessedTrans(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getCreditTransForReportees(
            TOKEN,
            order,
            tableCount,
            startDate,
            endDate,
            null,
            null
          ),
        ]);
      } else if (selectedDropdown === "ApplyClick") {
        await fetchAndSetData([
          ApiService.getIndividualTotalCommission(
            null,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualSalesByProduct(
            null,
            order,
            0,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualSalesByCustomer(
            null,
            order,
            count,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualUnProcessedTrans(
            null,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getCreditTransForUser(
            null,
            order,
            tableCount,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = IndividualDashboardColumns({ currency });

  // Function to format specific fields in each object of transData
  function formatFields(tableData) {
    return tableData.map((item) => ({
      ...item,
      transactionDate: formatDateDDMMYYYY(item.transactionDate),
      salesAmount: formatCurrency(item.salesAmount),
    }));
  }

  // Apply the formatting and update table data
  const formattedData = formatFields(tableData);

  const rows = addSerialNumber(formattedData);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownIconClick = (event) => {
    event.stopPropagation(); // Prevent triggering the main click event
    setIsReporteeMenuOpen(!isReporteeMenuOpen); // Toggle checkbox menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Reset all selected items and close the menu
  const handleCancelClick = () => {
    setCheckedItems(new Set()); // Clear checked items if needed
    setCheckedNameItems(new Set()); // Clear checked names if needed
    setIsReporteeMenuOpen(false); // Close the reportee menu if open
    handleMenuClose(); // Close the main dropdown menu
  };

  // Handle dropdown selection and update UI accordingly
  const handleMenuItemClick = (item) => {
    try {
      setSelectedDropdown(item); // Set the currently selected dropdown item
      setAppliedCheckedNames([]); // Clear previously applied names
      setCheckedItems(new Set()); // Reset checked items when dropdown changes

      if (item === "My Reportees") {
        setIsReporteeMenuOpen(false); // Close reportee menu
        handleGetMyReportee(); // Fetch reportee data
      } else {
        handleGetMyDetails(); // Fetch general details for other selections
        handleCancelClick();
      }
      handleMenuClose(); // Close the dropdown menu
    } catch (error) {
      console.error("Error in handleMenuItemClick:", error);
    }
  };

  // Toggle selection of an item, updating both checkedItems and checkedNameItems sets
  const handleMenuItemChange = (item, event) => {
    try {
      // Handle userId changes
      const newCheckedItems = new Set(checkedItems);
      if (newCheckedItems.has(item.userId)) {
        newCheckedItems.delete(item.userId);
      } else {
        newCheckedItems.add(item.userId);
      }
      setCheckedItems(newCheckedItems);

      // Handle userName changes
      const newCheckedNameItems = new Set(checkedNameItems);
      if (newCheckedNameItems.has(item.userName)) {
        newCheckedNameItems.delete(item.userName);
      } else {
        newCheckedNameItems.add(item.userName);
      }
      setCheckedNameItems(newCheckedNameItems);
    } catch (error) {
      console.error("Error in handleMenuItemChange:", error);
    }
  };

  // Apply selected items and save names if there are checked items
  const handleApplyClick = () => {
    try {
      if (!checkedItems || checkedItems.size === 0) {
        setAppliedCheckedNames([]); // Clear applied names if no items are selected
      } else {
        setAppliedCheckedNames(Array.from(checkedNameItems)); // Update applied names from checked items
        handleGetMyDetailsApplyClick(Array.from(checkedItems)); // Pass selected IDs for processing
        setSelectedDropdown("My Reportees");
      }
      handleMenuClose(); // Close the dropdown menu
    } catch (error) {
      console.error("Error in handleApplyClick:", error);
      handleCancelClick(); // Reset selections on error
    }
  };

  // Inside your component
  const [searchQuery, setSearchQuery] = useState("");

  const filteredReportees = Array.isArray(reportingTo)
    ? reportingTo.filter((reportee) =>
        `${reportee.userId} - ${reportee.userName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : [];

  return {
    // Total Commission SalesRep
    totalCommSalesRep,
    // Top Commission Product SalesRep
    topCommissionProductsSalesRep,
    // Top Commission Customer SalesRep
    customerData,
    // Not calculated table data
    rows,
    columns,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    totalTransCount,
    processTransCount,
    currency,
    timeZone,
    reportingTo,
    menu,
    checkedItems,
    appliedCheckedNames,
    selectedDropdown,
    isReporteeMenuOpen,
    anchorEl,
    searchQuery,
    filteredReportees,
    handleMenuClose,
    handleDropdownIconClick,
    handleMenuClick,
    handleMenuItemClick,
    handleMenuItemChange,
    handleApplyClick,
    handleCancelClick,
    setSearchQuery,
  };
};
