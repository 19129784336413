import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../components/common/EmptyBox";

export default function RenderDataGrid({
  rows = [], // Default to an empty array if rows are not provided
  columns = [], // Default to an empty array if columns are not provided
  viewData, // Accept props for disabling row selection on click
}) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      density="compact"
      sx={{
        width: {
          sm: 120, // Full width on small screens
          md: 220, // Fixed width on medium screens
          lg: 280, // Fixed width on large screens
        },
        height: {
          xs: 300, // Height on extra-small screens
          sm: 400, // Height on small screens
          md: 430, // Height on medium screens
        },
        mt: 1,
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
      }}
      disableColumnMenu
      disableRowSelectionOnClick={viewData} // This will disable row selection on click
      slots={{ noRowsOverlay: EmptyBox }}
      slotProps={{
        footer: {
          style: {
            display: "none",
          },
        },
      }}
    />
  );
}
