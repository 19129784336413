import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Box, Button, Grid, Stack } from "@mui/material";
import {
  isDuplicate,
  isValueContainsSplChars,
  isValueStartsWithNumber,
} from "../../config/fieldConfig";
import { CustomTextField } from "../../../utils/CustomInputFields";

export default function TypeForm({
  mode = "add", // Default mode is "add" (either "add" or "edit")
  screenName = "", // Optional prop for screen name (can be used for title or display)
  editItemId = null, // ID of the item being edited, if any
  fields = [], // Array of form fields to be rendered
  editFormData = {}, // Data for the form when in "edit" mode
  typeOptions = [], // List of type options (for dropdown or select input)
  selectedOption = [], // Selected option for the "type" field
  setShowForm = () => {}, // Function to control form visibility (e.g., closing it)
  onClose = () => {}, // Callback to be executed when the form is closed
  setEditFormOpen = () => {}, // Callback to open the form in "edit" mode
  onSubmitForm = () => {}, // Callback to handle form submission
}) {
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  // State to track the value of the "type" field
  const [newType, setNewType] = useState("");

  // Ref object to store references to form fields (if needed)
  const fieldRefs = useRef({});

  // useEffect for setting form values when in "edit" mode
  useEffect(() => {
    try {
      // Check if we are in edit mode and a valid item ID is provided
      if (mode === "edit" && editItemId !== null) {
        // If editFormData exists, set form fields with the provided data
        if (editFormData) {
          fields.forEach((field) => {
            setValue(field.name, editFormData[field.name]); // Set value for each field from editFormData
            if (field.name === "typeValue") {
              setNewType(editFormData.typeValue); // Set the value for "typeValue" field
            }
          });
        }
      }
    } catch (error) {
      console.error(
        "Exception occurred while loading form data for editing:",
        error
      ); // Log any errors that occur
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, editItemId, editFormData]);

  // useEffect for resetting form fields in "add" mode or when selectedOption changes
  useEffect(() => {
    try {
      // If we are in "add" mode, reset the form fields
      if (mode === "add") {
        setNewType(""); // Clear the value of the "typeValue" field
        setValue("typeValue", ""); // Reset the value for "typeValue" in the form
        clearErrors("typeValue"); // Clear any validation errors for "typeValue"
      }
    } catch (error) {
      console.error(
        "Exception occurred while resetting form in add mode:",
        error
      ); // Log any errors that occur
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]); // Run this effect when the selected option changes (e.g., dropdown selection)

  // Handle the form cancellation (reset or close the form)
  const handleCancelAdd = () => {
    try {
      setNewType(""); // Reset the "typeValue" field
      if (mode === "add") {
        setShowForm(false); // Close the form in "add" mode
      } else {
        setEditFormOpen(false); // Close the form in "edit" mode
      }
    } catch (error) {
      console.error("Exception occurred while canceling form:", error); // Log any errors that occur during cancellation
    }
  };

  // Function to handle form submission
  const onSubmit = () => {
    try {
      // Prepare form data to be submitted
      const formData = {
        typeValue: newType, // The value of the type field
        typeName: selectedOption, // The selected option from the dropdown
      };

      // Check if formData contains required values before submitting
      if (!formData?.typeValue || !formData?.typeName) {
        throw new Error("Form data is incomplete or missing required fields");
      }

      // If we're in "add" mode, call the onSubmitForm function for adding
      if (mode === "add") {
        onSubmitForm(formData, "add");
      }
      // If we're in "edit" mode, include the item ID and call the onSubmitForm function for editing
      else if (mode === "edit") {
        formData.id = editItemId;
        onSubmitForm(formData, "edit");
        setEditFormOpen(false); // Close the edit form after submission
      }
    } catch (error) {
      console.error("Error submitting form:", error); // Log any submission errors
      toast.error(error?.message); // Show an error message to the user via toast notification
    } finally {
      onClose(); // Ensure the form is closed after submission, whether successful or not
    }
  };

  return (
    <>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {fields.map((field, index) => (
            <Grid item xs={12} key={index} sm={mode === "edit" ? 12 : 6}>
              {field.name === "typeValue" ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={3}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {selectedOption} {screenName}:
                  </Grid>
                  <Grid item md={5}>
                    <CustomTextField
                      field={field}
                      register={register}
                      trigger={trigger}
                      errors={errors}
                      fieldRefs={fieldRefs}
                      validate={{
                        isValueContainsSplChars: (value) =>
                          isValueContainsSplChars(value),
                        isValueStartsWithNumber: (value) =>
                          isValueStartsWithNumber(value, field.label),
                        isDuplicate: (value) => {
                          if (field.name === "typeValue") {
                            return isDuplicate(
                              typeOptions,
                              field.label,
                              value,
                              mode,
                              editFormData,
                              field.name,
                              false
                            );
                          } else {
                            return true;
                          }
                        },
                      }}
                      onChange={(e) => setNewType(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Stack direction="row" spacing={2} m={1}>
                      <Button variant="contained" color="primary" type="submit">
                        {mode === "add" ? "Submit" : "Update"}
                      </Button>
                      <Button variant="outlined" onClick={handleCancelAdd}>
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
