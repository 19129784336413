import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import { frequencyColumn } from "./config";

export default function FrequencyTable({
  rows = [],
  frequency,
  viewData = true,
}) {
  const [page, setPage] = useState(false); // Manages pagination status
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // Initial page
    pageSize: 12, // Items per page
  });

  // Reset to the first page when 'frequency' changes
  useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 })); // Go to page 0
    setPage(false); // Reset page state
  }, [frequency]);

  // Enable pagination when the model changes
  useEffect(() => {
    setPage(true);
  }, [paginationModel]);

  // Disable sorting and column menu based on 'viewData'
  const disableSortingForAllColumns = (columns) =>
    columns.map((column) => ({
      ...column,
      sortable: !viewData, // Disable sorting if 'viewData' is true
      disableColumnMenu: viewData, // Disable column menu if 'viewData' is true
    }));

  // Process columns only if 'frequencyColumn' is an array
  const columns = Array.isArray(frequencyColumn)
    ? disableSortingForAllColumns(frequencyColumn)
    : [];

  return (
    <>
      <DataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        slots={{
          noRowsOverlay: EmptyBox,
          pagination: CustomPagination,
        }}
        initialState={
          page
            ? { pagination: { paginationModel: { pageSize: 12 } } }
            : undefined
        }
        onPaginationModelChange={!page ? setPaginationModel : undefined}
        paginationModel={!page ? paginationModel : undefined}
        pageSizeOptions={[12, 26, 52]}
        disableRowSelectionOnClick
        density="compact"
        autoHeight
        sx={{
          mt: 2,
          padding: 1,
          height: 460,
          // removing the cell border
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
      />
    </>
  );
}
