import { useState } from "react";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";

export const PricingService = () => {
  // State to hold pricing plan titles
  const [pricingPlanOptions, setPricingPlanOptions] = useState([]);
  // State to hold detailed pricing plan data
  const [pricingPlans, setPricingPlans] = useState([]);

  const fetchData = async () => {
    try {
      const res = await ApiService.getPricingPlans("pricingPlan"); // Fetch pricing plans from API

      // Check if the response is successful and contains valid pricing plan data
      if (
        res?.status === 200 &&
        Array.isArray(res?.data) &&
        res?.data?.length > 0
      ) {
        // Extract plan titles for dropdown options
        const options = res?.data?.map((plan) => plan.title);
        setPricingPlanOptions(options); // Update state with options

        setPricingPlans(res?.data); // Update state with updated plans
      } else {
        // If no valid data, reset pricing plan options and plans to empty arrays
        setPricingPlanOptions([]);
        setPricingPlans([]);
      }
    } catch (error) {
      console.log(error); // Log any errors that occur during the API call
      setPricingPlanOptions([]); // Reset to empty if error occurs
      setPricingPlans([]);
    }
  };

  // Use custom hook to fetch data on component mount
  useFetchOnMount(fetchData);

  return {
    pricingPlanOptions, // Titles of the pricing plans
    pricingPlans, // Detailed pricing plan data
  };
};
