import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";

export default function Section3({
  firstHalfFeatures,
  secondHalfFeatures,
  bgImgSlide,
  bgImgFeature,
}) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: { xs: 2, sm: 4 }, // Responsive padding
        backgroundImage: `url(${bgImgSlide})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { xs: 1, sm: 2, md: 4 }, // Responsive padding
        cursor: "default",
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h3"
          sx={{
            backgroundImage: "linear-gradient(220deg, #0066ff, #7730e6)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: "700",
            lineHeight: 1,
            fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" }, // Responsive font size
            textAlign: "center",
            mb: 4, // Margin bottom
          }}
        >
          Key Features
        </Typography>
      </Grid>

      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {/* 1st Grid */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2, // Space between cards
            }}
          >
            {firstHalfFeatures.map((feature, index) => (
              <Card
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  },
                  borderRadius: 2,
                  mx: { xs: 5, md: 0 },
                  p: { xs: 2, md: 0 },
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                    p: { sx: 5, md: 3 },
                    m: { sx: 5 },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "550",
                      pb: 1,
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: 13, md: 15 },
                    }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
        {/* 2nd Grid */}
        <Grid
          item
          xs={false} // Hide at xs
          sm={false} // Hide at sm
          md={4} // Show at md
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            justifyContent: "center",
          }} // Responsive display
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "400px",
              height: "auto",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              src={bgImgFeature}
              alt="Feature"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        {/* 3rd Grid */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2, // Space between cards
            }}
          >
            {secondHalfFeatures.map((feature, index) => (
              <Card
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  },
                  borderRadius: 2,
                  mx: { xs: 5, md: 0 },
                  p: { xs: 2, md: 0 },
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                    p: { sx: 5, md: 3 },
                    m: { sx: 5 },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "550",
                      pb: 1,
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: 13, md: 15 },
                    }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
