import { RemoveCircleOutlineTwoTone } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

// const Columns = (handleEdit, handleDelete, roleAccess) => {
export default function CommissionColumns(
  actionsColumn = null,
  handleEdit = () => {}
) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "commissionName",
      headerName: "Commission Name",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Box
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.commissionName} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true)}
          >
            {row.commissionName}
          </Box>
        );
      },
    },
    {
      field: "credit",
      headerName: "Credit",
      width: 150,
    },
    {
      field: "conditioned",
      headerName: "Condition",
      width: 200,
    },
    {
      field: "calculateFormula",
      headerName: "Calculate",
      width: 150,
      renderCell: ({ row }) => {
        const { calculateFormula } = row;

        if (Array.isArray(calculateFormula) && calculateFormula.length > 0) {
          const displayText = calculateFormula
            .map((formulaObj) => {
              const key = Object.keys(formulaObj)[0];
              const value = formulaObj[key];
              return `${key}: ${value}`;
            })
            .join(", ");

          return (
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",
              }}
              title={displayText}
            >
              {displayText}
            </Box>
          );
        } else {
          return <Box>Formula not available</Box>;
        }
      },
    },
    {
      field: "selectFrequency",
      headerName: "Frequency",
      width: 100,
    },
    {
      field: "rounding",
      headerName: "Rounding",
      width: 100,
    },
    {
      field: "active",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
    },
  ];
  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}

export function TieredColumn(RemoveTieredRow = () => {}, viewData = false) {
  const columns = [
    {
      field: "from",
      headerName: "From",
      width: 60, // Specify the desired width in pixels
    },
    {
      field: "to",
      headerName: "To",
      width: 60, // Specify the desired width in pixels
    },
    {
      field: "commissionRate",
      headerName: "Commission Rate",
      width: 80, // Specify the desired width in pixels
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 60, // Specify the desired width in pixels
      renderCell: (params) => (
        <IconButton
          onClick={() => RemoveTieredRow(params.row.id)}
          color="error"
          disabled={viewData}
        >
          <RemoveCircleOutlineTwoTone fontSize="small" />
        </IconButton>
      ),
    },
  ];

  return columns;
}

export function SplitColumn(RemoveSplitRow = () => {}, viewData = false) {
  const columns = [
    // const splitColumn = [
    {
      field: "Level",
      headerName: "Level",
      width: 90, // Specify the desired width in pixels
    },
    {
      field: "Value",
      headerName: "Commission(%)",
      width: 100, // Specify the desired width in pixels
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80, // Specify the desired width in pixels
      renderCell: (params) => (
        <IconButton
          onClick={() => RemoveSplitRow(params.row.id)}
          disabled={viewData}
          color="error"
        >
          <RemoveCircleOutlineTwoTone fontSize="small" />
        </IconButton>
      ),
    },
  ];
  return columns;
}

export function RoleUpColumn(RemoveRoleUpRow = () => {}, viewData = false) {
  const columns = [
    // const roleUpColumn = [
    {
      field: "Level",
      headerName: "Level",
      width: 90, // Specify the desired width in pixels
    },
    {
      field: "Value",
      headerName: "Commission(%)",
      width: 100, // Specify the desired width in pixels
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80, // Specify the desired width in pixels
      renderCell: (params) => (
        <IconButton
          onClick={() => RemoveRoleUpRow(params.row.id)}
          color="error"
          disabled={viewData}
        >
          <RemoveCircleOutlineTwoTone fontSize="small" />
        </IconButton>
      ),
    },
  ];
  return columns;
}
