import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../apis/ApiService";
import { EMAIL_ID, IP_ADDRESS, SESSION_ID } from "../../config/sessionStorage";

export default function Logout({ setIsAuthenticated, setRes }) {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // Function to handle clearing session and navigating to sign-in
  const clearSessionAndRedirect = useCallback(() => {
    // Clear the authentication token
    setIsAuthenticated(false);
    setRes(false);

    sessionStorage.clear(); // Clear session storage

    navigate("/sign_in"); // Navigate to sign-in page
  }, [setIsAuthenticated, setRes, navigate]);

  // Memoized handleLogout function
  const handleLogout = useCallback(async () => {
    if (isLoggingOut) return; // Prevent multiple logouts

    setIsLoggingOut(true);

    try {
      // Prepare session logout values
      const sessionLogoutValues = {
        email: EMAIL_ID(),
        sessionId: SESSION_ID(),
        ipAddress: IP_ADDRESS(),
      };

      // Call the logoutSessionUser API
      const sessionLogoutRes = await ApiService.logoutSessionUser(
        sessionLogoutValues
      );
      console.log("Logout Response:", sessionLogoutRes);
    } catch (error) {
      console.error("An error occurred during logout:", error);
    } finally {
      // Always clear session and redirect, regardless of API result
      clearSessionAndRedirect();
      setIsLoggingOut(false);
    }
  }, [isLoggingOut, clearSessionAndRedirect]);

  return handleLogout;
}
