// Social Logo images
import AmazomPayLogo from "../../assets/images/social/amazonPay.svg";
import ApplePayLogo from "../../assets/images/social/applePay.png";
import GooglePayLogo from "../../assets/images/social/googlePay.png";
import PaypalLogo from "../../assets/images/social/paypal.png";
import PaytmLogo from "../../assets/images/social/paytm.png";
import PhonePeLogo from "../../assets/images/social/phonePe.png";
import RazorPayLogo from "../../assets/images/social/razorpay.svg";
import StripePayLogo from "../../assets/images/social/stripe.svg";

// MFA Images
import MFAImage from "../../assets/images/landingImages/mfa1.jpg";
import MFAImage2 from "../../assets/images/landingImages/mfa2.jpg";
import MFAImage3 from "../../assets/images/landingImages/mfa3.jpg";
import MFAImage4 from "../../assets/images/landingImages/mfa4.jpg";

// Logo Images
import CfTransLogo from "../../assets/images/logos/cf_trans_logo.png";
import CfTransBlueLogo from "../../assets/images/logos/cf_blue_logo.png";

// Landing Images
import ImgSlideWhite from "../../assets/images/landingImages/slideWhite.webp";
import ImgSlideTrans from "../../assets/images/landingImages/slideTrans.webp";
import ImgWhyScom from "../../assets/images/landingImages/whyScom.webp";
import ImgFeature from "../../assets/images/landingImages/featureImage.webp";
import ImgEmpower from "../../assets/images/landingImages/empower.png";
import ImgSalesBanner from "../../assets/images/landingImages/salesBanner.webp";
import ImgRegister from "../../assets/images/landingImages/register.jpeg";
import ImgSignIn from "../../assets/images/landingImages/signIn.png";

export {
  // Social Logo images
  AmazomPayLogo,
  ApplePayLogo,
  GooglePayLogo,
  PaypalLogo,
  PaytmLogo,
  PhonePeLogo,
  RazorPayLogo,
  StripePayLogo,
  // MFA Images
  MFAImage,
  MFAImage2,
  MFAImage3,
  MFAImage4,
  // Logo Images
  CfTransLogo,
  CfTransBlueLogo,
  // Landing Images
  ImgSlideWhite,
  ImgSlideTrans,
  ImgWhyScom,
  ImgFeature,
  ImgEmpower,
  ImgSalesBanner,
  ImgRegister,
  ImgSignIn,
};
