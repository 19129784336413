import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import "./App.css";
import Themes from "./Theme";
import AllRouter from "./router";

function App() {
  const { toasts } = useToasterStore();
  const TOAST_LIMIT = 1;

  // Prevent multi toast display into dismiss existing toast
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  // Disabled the right click in webpage
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={Themes}>
        <BrowserRouter
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          <AllRouter />
          {/* Toaster configuration */}
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              duration: 3000, // Default duration for all toasts
              style: {
                maxWidth: "300px", // Common style for all toasts
                marginTop: 50, // Positioning the toast slightly below the top
              },
            }}
          />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
