import { Box, Typography } from "@mui/material";

export default function EmptyBox({ message }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center", // Center content vertically
        height: "100%", // Full screen height
        padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding for different screen sizes
        textAlign: "center", // Center text alignment across screens
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          mt: { xs: 3, sm: 4, md: 5 }, // Responsive margin-top
        }}
        gutterBottom
      >
        {message || "No data to display"}
      </Typography>
    </Box>
  );
}
