import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { EmailTwoTone, FacebookTwoTone, LinkedIn } from "@mui/icons-material";

export default function FooterBar({ imgLogoTrans, contactMail }) {
  return (
    <>
      <footer>
        <Grid
          container
          sx={{
            backgroundImage:
              "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
            p: { xs: 3, sm: 4, md: 5 }, // Responsive padding
            flexDirection: { xs: "column", md: "row" }, // Column layout on small screens, row on larger screens
            alignItems: "center", // Center items vertically
            textAlign: { xs: "center", md: "left" }, // Center text on small screens
          }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: { xs: "center", md: "flex-start" },
              px: { xs: 2, md: 5 }, // Responsive padding
            }}
          >
            <img
              src={imgLogoTrans}
              alt="compFact logo"
              style={{ height: "8vh", width: "auto", maxWidth: "100%" }} // Adjusted for better scaling
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 }, // Responsive font size
                color: "white",
                mt: 2,
                mx: { xs: 1 }, // Margin adjustments for responsiveness
              }}
            >
              Experience seamless commission management and empowered sales
              performance with our innovative platform. Transform your sales
              process, save time, and boost productivity with our automated
              commission solution.
            </Typography>
          </Grid>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "none", md: "block" }, // Hide on small screens
              borderColor: "rgba(255, 255, 255, 0.4)", // Light white color
              mx: { xs: 0, md: 2 }, // Margin adjustments
            }}
          />

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              pt: { xs: 3, sm: 4, md: 5 }, // Responsive padding
              pl: { xs: 0, md: 2 },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "650",
                lineHeight: 1.5,
                color: "white",
                fontSize: { xs: 16, sm: 18, md: 20 }, // Responsive font size
                mb: 1, // Margin bottom
              }}
            >
              Connect with us
            </Typography>
            <Divider
              textAlign="left"
              flexItem
              sx={{
                display: { xs: "block", md: "none" }, // Display only on small screens
                borderColor: "white",
                borderWidth: "1px",
                mb: 2, // Margin bottom for spacing
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: 14, sm: 16 }, // Responsive font size
                color: "white",
                mb: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmailTwoTone sx={{ fontSize: 20, marginRight: 1 }} />
              {contactMail}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                gap: { xs: 1, sm: 2 }, // Space between social icons
                mt: 1,
              }}
            >
              <IconButton
                href=""
                sx={{
                  color: "white",
                  "&:hover": { bgcolor: "#1877F2" },
                }}
              >
                <FacebookTwoTone sx={{ fontSize: { xs: 24, sm: 30 } }} />
              </IconButton>

              <IconButton
                href=""
                sx={{
                  color: "white",
                  "&:hover": { bgcolor: "#0077B5" }, // LinkedIn color
                }}
              >
                <LinkedIn sx={{ fontSize: { xs: 24, sm: 30 } }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </footer>

      <Typography
        variant="body2"
        sx={{
          p: { xs: 1, sm: 2 }, // Responsive padding
          textAlign: "center",
          color: "#333",
          fontSize: { xs: 14, sm: 16 }, // Responsive font size
          fontWeight: "650",
        }}
      >
        Copyright All Rights Reserved {new Date().getFullYear()} ©
        <Box
          component="span"
          sx={{
            backgroundImage:
              "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline-block",
            ml: 1, // Margin left for spacing
          }}
        >
          Compfact
        </Box>
      </Typography>
    </>
  );
}
