import { useEffect, useRef, useState } from "react";
import ApiService from "../../../apis/ApiService";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  ORG_ID,
  TIME_ZONE,
  USER_ID,
  USER_NAME,
} from "../../config/sessionStorage";
import { dateFormatRegex, validateArray } from "../../config/fieldConfig";
import { getInitialDateRange } from "../../../utils/getInitialDateRange";
import {
  formatCurrency,
  formatCurrencySymbol,
} from "../../../utils/CurrencyFormatter";
import { getApiCalls } from "./getApiCalls";
import { SalesRepsColumns } from "./columns";

export const DashboardService = () => {
  // Total Commission
  const [totalCommission, setTotalCommission] = useState("");

  //Trends Commission
  const [trendsCommission, setTrendsCommission] = useState("");
  const [trendsLabelsCom, setTrendsLabelsCom] = useState([]);
  const [trendsValuesCom, setTrendsValuesCom] = useState([]);

  // State variables to store Top 10 SalesReps
  const [beneficiaryNames, setBeneficiaryNames] = useState([]);
  const [totalNetPayouts, setTotalNetPayouts] = useState([]);
  const [salesRepData, setSalesRepData] = useState({});

  // Commission by Products
  const [productNames, setProductNames] = useState([]);
  const [totalNetProductPayouts, setTotalNetProductPayouts] = useState([]);

  // Commission by Customers
  const [customerNames, setCustomerNames] = useState([]);
  const [totalNetCustomerPayouts, setTotalNetCustomerPayouts] = useState([]);

  const order = "asc";

  const count = 10;
  const customCount = 5;
  const stopRemount = useRef(true);

  const { startDate, endDate } = getInitialDateRange(stopRemount);
  const [reportingTo, setReportingTo] = useState([]);

  // Session storage to taken the value
  const orgId = ORG_ID();
  const currency = CURRENCY_NAME();
  const timeZone = TIME_ZONE();
  const userName = USER_NAME();
  const userId = USER_ID();

  const menu = [`${userId} - ${userName}`, "My Reportees"];

  const [checkedItems, setCheckedItems] = useState(new Set()); // Stores selected user IDs
  const [checkedNameItems, setCheckedNameItems] = useState(new Set()); // Stores selected user names
  const [selectedDropdown, setSelectedDropdown] = useState(menu[0]); // Tracks current dropdown selection
  const [isReporteeMenuOpen, setIsReporteeMenuOpen] = useState(false); // Manages reportee menu visibility
  const [appliedCheckedNames, setAppliedCheckedNames] = useState([]); // Stores applied names for display
  const [reporteeIds, setReporteeIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const salesRepsColumns = SalesRepsColumns({ currency }).map((column) => ({
    ...column,
    sortable: false, // Disable sorting for each column
  }));

  useEffect(() => {
    if (stopRemount?.current) {
      stopRemount.current = false;

      if (reporteeIds.length !== 0) {
        fetchData(startDate, endDate, "ApplyClick", reporteeIds);
      } else if (selectedDropdown === "My Reportees") {
        handleGetMyReportee();
      } else {
        fetchData(startDate, endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleGetMyDetailsApplyClick = (salesRe) => {
    setReporteeIds(salesRe);
    stopRemount.current = true;
    fetchData(startDate, endDate, "ApplyClick", salesRe);
  };

  const handleGetMyReportee = () => {
    stopRemount.current = true;
    setReporteeIds([]);
    fetchData(startDate, endDate, "My Reportees");
  };

  const handleGetMyDetails = () => {
    stopRemount.current = true;
    setReporteeIds([]);
    fetchData(startDate, endDate, "My Data");
  };

  const fetchAndSetData = async (fetchFunctions) => {
    try {
      const [
        totalCommSalesRep,
        topCommissionProducts,
        topCustomers,
        topSalesReps,
      ] = await Promise.all(fetchFunctions);

      // If totalCommSalesRep is not available, throw an error
      if (!totalCommSalesRep && totalCommSalesRep !== 0)
        throw new Error("TotalCommSalesRep not found or invalid");
      setTotalCommission(formatCurrencySymbol(totalCommSalesRep));

      // Extract productName and totalNetPayout into separate arrays
      const [productNames, productPayouts] = [
        topCommissionProducts?.map((product) => product.productName),
        topCommissionProducts?.map((product) => product.totalNetPayout),
      ];

      // Check the productNames is an array
      validateArray(productNames, "productNames");
      // Check the productPayouts is an array
      validateArray(productPayouts, "productPayouts");

      // Set state variables with extracted arrays
      setProductNames(productNames);
      setTotalNetProductPayouts(productPayouts);

      // Extract customerName and totalNetPayout into separate arrays
      const customerNames = topCustomers?.map(
        (customer) => customer.customerName
      );
      const customerPayouts = topCustomers?.map(
        (customer) => customer.totalNetPayout
      );

      // Check the customerNames is an array
      validateArray(customerNames, "customerNames");
      // Check the customerPayouts is an array
      validateArray(customerPayouts, "customerPayouts");

      // Update state variables with extracted arrays
      setCustomerNames(customerNames);
      setTotalNetCustomerPayouts(customerPayouts);

      // Destructure the data directly
      const [beneficiaryNames, totalNetPayouts] = [
        topSalesReps?.map((salesRep) => salesRep.beneficiaryName),
        topSalesReps?.map((salesRep) => salesRep.totalNetPayout),
      ];
      // Check the topSalesReps is an array
      validateArray(topSalesReps, "topSalesReps");
      // Check the beneficiaryNames is an array
      validateArray(beneficiaryNames, "beneficiaryNames");
      // Check the totalNetPayouts is an array
      validateArray(totalNetPayouts, "totalNetPayouts");

      setSalesRepData(topSalesReps);
      setBeneficiaryNames(beneficiaryNames);
      setTotalNetPayouts(totalNetPayouts);
    } catch (error) {
      // Clear table data if API response is not as expected
      clearValue();
    }
  };

  const fetchData = async (startDate, endDate, selectedDropdown, salesRe) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      //To show the reportingTo names
      const activeBeneficiaries = await ApiService.getActiveBeneficiaries(
        token
      );
      const response = activeBeneficiaries?.data;

      // Check if the response status is 200 and the data is an array
      if (activeBeneficiaries?.status === 200 && Array.isArray(response)) {
        // Transform beneficiary data
        const transformedData = response?.map((beneficiary) => ({
          userId: beneficiary.userId,
          userName: beneficiary.userName,
        }));

        // Filter out the entry with userId "T4"
        const filteredData = transformedData?.filter(
          (beneficiary) => beneficiary.userId !== userId
        );

        // Check the filteredData is an array
        validateArray(filteredData, "filteredData");
        setReportingTo(filteredData);

        // Check if startDate is valid and in the correct format
        if (!startDate || !dateFormatRegex.test(startDate)) {
          throw new Error("StartDate not found or invalid");
        }

        // Check if endDate is valid and in the correct format
        if (!endDate || !dateFormatRegex.test(endDate)) {
          throw new Error("EndDate not found or invalid");
        }
        // trends commission
        const commissionTrends = await ApiService.getCommissionTrends(
          token,
          startDate,
          endDate
        );
        const trendsComRange = commissionTrends?.data;

        // Validate mappings response and transform to object
        if (
          commissionTrends?.status === 200 &&
          typeof trendsComRange === "object" &&
          !Array.isArray(trendsComRange)
        ) {
          const trendsLabels = Object.keys(trendsComRange);
          const trendsValues = Object.values(trendsComRange);

          if (trendsValues.every((value) => value === 0)) {
            setTrendsLabelsCom([]);
            setTrendsValuesCom([]);
            setTrendsCommission({});
          } else {
            setTrendsLabelsCom(trendsLabels);
            setTrendsValuesCom(trendsValues);
            setTrendsCommission(trendsComRange);
          }
        } else {
          // Clear config and fields if the response is not valid
          setTrendsLabelsCom([]);
          setTrendsValuesCom([]);
          setTrendsCommission({});
        }
        const apiCalls = getApiCalls({
          token,
          selectedDropdown,
          order,
          count,
          customCount,
          startDate,
          endDate,
          salesRe,
          orgId,
        });

        // Pass the API calls array to fetchAndSetData
        await fetchAndSetData(apiCalls);
      } else {
        // Clear table data if API response is not as expected
        clearValue();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Clear table data if API response is not as expected
      clearValue();
    }
  };

  // set the empty value
  const clearValue = () => {
    setTrendsLabelsCom([]);
    setTrendsValuesCom([]);
    setTotalCommission(formatCurrencySymbol(0));
    setProductNames([]);
    setTotalNetProductPayouts([]);
    setCustomerNames([]);
    setTotalNetCustomerPayouts([]);
    setSalesRepData([]);
    setBeneficiaryNames([]);
    setTotalNetPayouts([]);
  };

  // Check if salesRepData exists and is an object
  const salesRepsRows =
    Array.isArray(salesRepData) || typeof salesRepData === "object"
      ? // Convert salesRepData object to an array
        Object.values(salesRepData).map((row, index) => ({
          id: index + 1,
          beneficiaryName: row.beneficiaryName,
          totalNetPayout: formatCurrency(parseFloat(row.totalNetPayout)),
        }))
      : [];

  // Transform salesRepsRows data into the format expected by PieChart
  const salesRepPieChartData = Array.isArray(salesRepData)
    ? salesRepsRows?.map((row) => ({
        id: row.id,
        value: row.totalNetPayout, // Use totalNetPayout as the value
        label: row.beneficiaryName, // Use beneficiaryName as the label
      }))
    : [];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownIconClick = (event) => {
    event.stopPropagation(); // Prevent triggering the main click event
    setIsReporteeMenuOpen(!isReporteeMenuOpen); // Toggle checkbox menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Reset all selected items and close the menu
  const handleCancelClick = () => {
    setCheckedItems(new Set()); // Clear checked items if needed
    setCheckedNameItems(new Set()); // Clear checked names if needed
    setIsReporteeMenuOpen(false); // Close the reportee menu if open
    handleMenuClose(); // Close the main dropdown menu
  };

  // Handle dropdown selection and update UI accordingly
  const handleMenuItemClick = (item) => {
    try {
      setSelectedDropdown(item); // Set the currently selected dropdown item
      setAppliedCheckedNames([]); // Clear previously applied names
      setCheckedItems(new Set()); // Reset checked items when dropdown changes

      if (item === "My Reportees") {
        setIsReporteeMenuOpen(false); // Close reportee menu
        handleGetMyReportee(); // Fetch reportee data
      } else {
        handleGetMyDetails(); // Fetch general details for other selections
        handleCancelClick();
      }
      handleMenuClose(); // Close the dropdown menu
    } catch (error) {
      console.error("Error in handleMenuItemClick:", error);
    }
  };

  // Toggle selection of an item, updating both checkedItems and checkedNameItems sets
  const handleMenuItemChange = (item, event) => {
    try {
      // Handle userId changes
      const newCheckedItems = new Set(checkedItems);
      if (newCheckedItems.has(item.userId)) {
        newCheckedItems.delete(item.userId);
      } else {
        newCheckedItems.add(item.userId);
      }
      setCheckedItems(newCheckedItems);

      // Handle userName changes
      const newCheckedNameItems = new Set(checkedNameItems);
      if (newCheckedNameItems.has(item.userName)) {
        newCheckedNameItems.delete(item.userName);
      } else {
        newCheckedNameItems.add(item.userName);
      }
      setCheckedNameItems(newCheckedNameItems);
    } catch (error) {
      console.error("Error in handleMenuItemChange:", error);
    }
  };

  // Apply selected items and save names if there are checked items
  const handleApplyClick = () => {
    try {
      if (!checkedItems || checkedItems.size === 0) {
        setAppliedCheckedNames([]); // Clear applied names if no items are selected
      } else {
        setAppliedCheckedNames(Array.from(checkedNameItems)); // Update applied names from checked items
        handleGetMyDetailsApplyClick(Array.from(checkedItems)); // Pass selected IDs for processing
        setSelectedDropdown("My Reportees");
      }
      handleMenuClose(); // Close the dropdown menu
    } catch (error) {
      console.error("Error in handleApplyClick:", error);
      handleCancelClick(); // Reset selections on error
    }
  };

  // Inside your component
  const [searchQuery, setSearchQuery] = useState("");

  const filteredReportees = Array.isArray(reportingTo)
    ? reportingTo?.filter((reportee) =>
        `${reportee.userId} - ${reportee.userName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : [];

  // commission by products
  const [dynamicMaxValue, setDynamicMaxValue] = useState(null); // State to store dynamic max value

  const tickValuesRef = useRef([]); // Ref to store tick values
  // Update dynamicMaxValue based on data
  useEffect(() => {
    if (totalNetProductPayouts.length > 0) {
      const maxDataValue = Math.max(...totalNetProductPayouts);

      // Calculate the tick values and update dynamicMaxValue
      if (tickValuesRef.current.length > 1) {
        const differences = [];
        for (let i = 1; i < tickValuesRef.current.length; i++) {
          differences.push(
            tickValuesRef.current[i] - tickValuesRef.current[i - 1]
          );
        }

        // Calculate tick interval
        const tickInterval = Math.min(...differences);
        const calculatedMaxValue =
          Math.ceil(maxDataValue / tickInterval) * tickInterval + tickInterval;
        setDynamicMaxValue(calculatedMaxValue);
      }
    } else {
      setDynamicMaxValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNetProductPayouts, tickValuesRef.current]);

  //commission trends
  const [dynamicMaxValue1, setDynamicMaxValue1] = useState(null); // State to store dynamic max value
  const tickValuesRef1 = useRef([]); // Ref to store tick values

  useEffect(() => {
    if (trendsLabelsCom.length > 0) {
      const maxDataValue = Math.max(...trendsValuesCom);

      if (tickValuesRef1.current.length > 1) {
        const differences = [];
        for (let i = 1; i < tickValuesRef1.current.length; i++) {
          differences.push(
            tickValuesRef1.current[i] - tickValuesRef1.current[i - 1]
          );
        }

        // Calculate tick interval
        const tickInterval = Math.min(...differences);
        const calculatedMaxValue =
          Math.ceil(maxDataValue / tickInterval) * tickInterval + tickInterval;
        setDynamicMaxValue1(calculatedMaxValue);
      }
    } else {
      setDynamicMaxValue1(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendsValuesCom, tickValuesRef1.current]);

  //commission by customer
  const [dynamicMaxValue2, setDynamicMaxValue2] = useState(null); // State to store dynamic max value
  const tickValuesRef2 = useRef([]); // Ref to store tick values

  useEffect(() => {
    if (totalNetCustomerPayouts.length > 0) {
      const maxDataValue = Math.max(...totalNetCustomerPayouts);
      if (tickValuesRef2.current.length > 1) {
        const differences = [];
        for (let i = 1; i < tickValuesRef2.current.length; i++) {
          differences.push(
            tickValuesRef2.current[i] - tickValuesRef2.current[i - 1]
          );
        }
        // Calculate tick interval
        const tickInterval = Math.min(...differences);
        const calculatedMaxValue =
          Math.ceil(maxDataValue / tickInterval) * tickInterval + tickInterval;
        setDynamicMaxValue2(calculatedMaxValue);
      }
    } else {
      setDynamicMaxValue2(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNetCustomerPayouts, tickValuesRef2.current]);

  return {
    // Total Commissions
    totalCommission,
    //Trends Commission
    trendsCommission,
    trendsLabelsCom,
    trendsValuesCom,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    beneficiaryNames,
    totalNetPayouts,
    salesRepPieChartData,
    //  Commission by Product
    productNames,
    totalNetProductPayouts,
    // Commission by Customers
    customerNames,
    totalNetCustomerPayouts,
    // Date
    startDate,
    endDate,
    fetchData,
    currency,
    timeZone,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetMyDetailsApplyClick,
    reportingTo,
    stopRemount,
    menu,
    handleMenuItemClick,
    setIsReporteeMenuOpen,
    setCheckedItems,
    handleMenuClose,
    checkedItems,
    appliedCheckedNames,
    selectedDropdown,
    isReporteeMenuOpen,
    anchorEl,
    setAnchorEl,
    handleApplyClick,
    handleCancelClick,
    handleDropdownIconClick,
    handleMenuClick,
    handleMenuItemChange,
    searchQuery,
    setSearchQuery,
    filteredReportees,
    dynamicMaxValue,
    setDynamicMaxValue,
    dynamicMaxValue1,
    setDynamicMaxValue1,
    tickValuesRef1,
    dynamicMaxValue2,
    setDynamicMaxValue2,
    tickValuesRef2,
  };
};
