import { Box } from "@mui/material";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";

export default function BeneficiaryColumns({
  actionsColumn = null,
  dynamicColumns = [],
  isErrorColor = false,
  handleEdit = () => {},
}) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "userId",
      headerName: "User ID",
      width: 80,
      renderCell: ({ row }) => {
        // Check if the row exists to prevent errors
        if (!row) {
          return <Box sx={{ width: "100%", height: "100%" }} />; // Empty box ensures UI layout is maintained
        }
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.userId} // Add the title attribute for the tooltip
            onClick={() => {
              try {
                // Ensure row.id exists before calling handleEdit
                if (row?.id) {
                  handleEdit(row?.id, true)(); // Call handleEdit with row ID
                }
              } catch (error) {
                console.error("Error occurred during edit:", error);
              }
            }}
          >
            {row.userId || ""}
          </div>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      field: "jobCategory",
      headerName: "Job Category",
      width: 150,
    },
    {
      field: "territory",
      headerName: "Territory",
      width: 150,
    },
    {
      field: "reportingToName",
      headerName: "Reporting To",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "employeeType",
      headerName: "Emp Type",
      width: 150,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 150,
    },
    {
      field: "eligible",
      headerName: "Eligible",
      type: "boolean",
      width: 150,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 150,
    },
    // Conditionally display the description column based on isErrorColor
    ...(isErrorColor
      ? [
          {
            field: "description",
            headerName: "Error Description",
            width: 200,
          },
        ]
      : []),
    // Only spread dynamicColumns if it's actually an array (just being cautious!)
    ...(Array.isArray(dynamicColumns) ? dynamicColumns : []),
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
