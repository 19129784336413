import { AssignCommissionService } from "./service";
import AssignCommissionForm from "./form";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import FullScreenLoader from "../../common/LoadingScreen";
import { FormFields } from "./fields";

export default function AssignCommission() {
  const {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    onAddForm,
    onEditForm,
    setEditFormOpen = () => {},
    adjFields,
    commissionListOptions,
    commissionData,
    beneficiariesOption,
    terms,
    description,
    setBeneficiaries = () => {},
    right,
    setRight = () => {},
    left,
    setLeft = () => {},
    open,
    addFormOpen = () => {},
    addFormClose = () => {},
    roleAccess,
    extractedData,
    beneficiaries,
    viewData,
    setViewData = () => {},
    setTerms = () => {},
    setDescription,
    handleCalculation = () => {},
    adjustmentComponents,
    setAdjustmentComponents = () => {},
    isTableLoading,
    displayName,
    screenName,
    submitLoading,
    onSubmitForm = () => {}, // Function to handle form submission
    columns = [],
    toolbarMenuProps = {},
  } = AssignCommissionService() || {};

  const fields = FormFields(beneficiariesOption);

  return (
    <>
      <FullScreenLoader loading={submitLoading} />

      <CustomDataGrid
        rows={rows ?? []} // Data rows for the grid
        columns={columns ?? []} // Column definitions for the grid
        loading={isTableLoading} // Show loading indicator when fetching data
        toolbarProps={{
          displayName,
          screenName,
          addFormOpen,
          ...toolbarMenuProps,
        }} // Toolbar props for display name, screen name, and add button
      />

      {/* Handle both and and edit form display */}
      <AssignCommissionForm
        open={editFormOpen || open}
        onClose={
          editFormOpen
            ? () => {
                setEditFormOpen(false);
                setBeneficiaries("");
                setTerms("");
                setDescription("");
              }
            : () => {
                addFormClose();
                setBeneficiaries("");
                setTerms("");
                setDescription("");
              }
        }
        mode={editFormOpen ? "edit" : "add"}
        editItemId={editFormOpen ? editItemId : undefined}
        editFormData={editFormOpen ? editFormData : undefined}
        onEditForm={editFormOpen ? onEditForm : undefined}
        onAddForm={!editFormOpen ? onAddForm : undefined}
        fields={fields}
        adjFields={adjFields}
        commissionListOptions={commissionListOptions}
        commissionData={commissionData}
        beneficiariesOption={beneficiariesOption}
        terms={terms}
        description={description}
        setBeneficiaries={setBeneficiaries}
        beneficiaries={beneficiaries}
        right={right}
        setRight={setRight}
        left={left}
        setLeft={setLeft}
        extractedData={extractedData}
        viewData={viewData}
        setViewData={setViewData}
        setTerms={setTerms}
        setDescription={setDescription}
        handleCalculation={handleCalculation}
        adjustmentComponents={adjustmentComponents}
        setAdjustmentComponents={setAdjustmentComponents}
        roleAccess={roleAccess}
        onSubmitForm={onSubmitForm} // Function to handle form submission
      />
    </>
  );
}
