import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Stack, Typography } from "@mui/material";
import { DoubleArrowTwoTone, HomeTwoTone } from "@mui/icons-material";
import { FIELD_MAPPING_SCREEN, TYPE_SCREEN } from "../../../config/constants";
import { IS_MENU } from "../../../config/sessionStorage";
import { PAGE_OPTIONS } from "../../../config/fieldConfig";
import { IconMap } from "./iconMenu";
import { getBreadcrumbs } from "./getBreadcrumbs";

export default function BreadcrumbMenu({ baseMenu, settingMenuItems }) {
  const { pathname: currentPath } = useLocation();

  // Get the breadcrumb trail
  const breadcrumbItems = getBreadcrumbs(
    baseMenu,
    settingMenuItems,
    currentPath
  );

  const menuColor = IS_MENU() || {};

  return (
    <Breadcrumbs
      separator=""
      aria-label="breadcrumb"
      sx={{
        ".MuiBreadcrumbs-separator": {
          margin: 0.3, // Remove any margin between items
        },
      }}
    >
      {breadcrumbItems.map((item, index) => {
        // Construct the full path for comparison
        const itemPath = item.link.startsWith("/")
          ? item.link
          : `/${item.link}`;
        const currentPathPrefix = currentPath.startsWith("/")
          ? currentPath
          : `/${currentPath}`;

        // Check if the breadcrumb item is active
        const isActive = currentPathPrefix === itemPath;

        // Check if the breadcrumb item matches a key in menuColor
        const isMenuColor = Object.entries(menuColor).some(
          ([key, menuEntry]) => {
            const linkMatch = key.toLowerCase() === item.link.toLowerCase();
            const isSpecialScreen = [
              TYPE_SCREEN,
              FIELD_MAPPING_SCREEN,
            ].includes(key);

            // If the key matches and it's Type or FieldMapping, check all required keys are true
            return (
              linkMatch &&
              (isSpecialScreen
                ? PAGE_OPTIONS.every((option) => menuEntry[option])
                : Object.values(menuEntry).every(Boolean))
            );
          }
        );

        const icon =
          index === 0 ? (
            <HomeTwoTone />
          ) : (
            IconMap[item.link] || <DoubleArrowTwoTone />
          );

        const isParent = item.link === "#" || item.link === "/" || index === 0;

        return (
          <Stack
            direction="row"
            key={index}
            component={isActive && isParent ? Typography : Link}
            to={!isActive && (index !== 0 || !isParent) ? item.link : undefined} // Remove link for active items
            underline="none"
            sx={{
              // Ensure content is visible
              display: "flex",
              alignItems: "center",
              position: "relative",
              cursor: isActive || isParent ? "default" : "pointer",
              bgcolor: isActive
                ? "orange" // Orange for the active item
                : isMenuColor || isParent
                ? "#CCECCC" // Green if it matches menuColor
                : "#f2f6fa",
              color: isActive
                ? "black"
                : isMenuColor || index <= 0
                ? "green" // Green text for items that match menuColor
                : "inherit",
              pl: index === 0 ? 1 : 2,
              pr: index === breadcrumbItems.length - 1 ? 0.5 : 1,
              py: 0.3,
              mt: { xs: 1, md: 0 },
              ml: { xs: 1, md: index === 0 ? 0 : -1 }, // Adjusted margin for visibility
              clipPath:
                index === 0
                  ? "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)" // Original clip path for the first item
                  : index === breadcrumbItems.length - 1
                  ? "polygon(100% 0, 100% 50%, 100% 99%, 0% 100%, 10% 50%, 0% 0%)"
                  : "polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)", // Clip path for other items
              border: 1,
              borderRadius:
                index === 0 || index === breadcrumbItems.length - 1 ? 2 : 0,
              borderColor: isActive
                ? "#f4893e" // Orange border for active
                : isMenuColor || isParent
                ? "green" // Green border for items that match menuColor
                : "#bacdd9",
              "&:hover": {
                bgcolor: isActive
                  ? "orange" // Keep orange on hover for active
                  : isMenuColor || isParent
                  ? "#CCECCC" // Keep green on hover for matching items
                  : "#e4f0f5",
              },
            }}
          >
            {icon}
            <Typography component="span" sx={{ mx: 0.5 }}>
              {item.text}
            </Typography>
          </Stack>
        );
      })}
    </Breadcrumbs>
  );
}
