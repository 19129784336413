// Importing the ApiService for making API calls
import ApiService from "./ApiService";

// Importing constants that represent different screens for the application
import {
  ASSIGN_COMMISSION_SCREEN,
  BENEFICIARY_SCREEN,
  COMMISSION_SCREEN,
  CUSTOMER_SCREEN,
  PAY_FREQUENCY_SCREEN,
  PLAN_SCREEN,
  PRODUCT_SCREEN,
  ROLE_SCREEN,
  SCHEDULE_SCREEN,
  TICKET_SCREEN,
  TRANSACTION_SCREEN,
  TYPE_SCREEN,
  VARIABLE_SCREEN,
} from "../components/config/constants";

// Mapping of API operations for different screens in the application
const apiMappings = {
  [ASSIGN_COMMISSION_SCREEN]: {
    // API methods for Assign Commission Screen
    add: ApiService.addAssignCommission, // Method to add an assignment of commission
    edit: ApiService.updateAssignCommission, // Method to update an existing assignment of commission
    delete: ApiService.deleteAssignCommission, // Method to delete an assignment of commission
  },
  [BENEFICIARY_SCREEN]: {
    // API methods for Beneficiary Screen
    add: ApiService.addBeneficiary, // Method to add a new beneficiary
    edit: ApiService.updateBeneficiary, // Method to update an existing beneficiary
    delete: ApiService.deleteBeneficiary, // Method to delete a beneficiary
  },
  [COMMISSION_SCREEN]: {
    // API methods for Commission Screen
    add: ApiService.addCommission, // Method to add a new commission
    edit: ApiService.updateCommission, // Method to update an existing commission
    delete: ApiService.deleteCommission, // Method to delete a commission
  },
  [CUSTOMER_SCREEN]: {
    // API methods for Customer Screen
    add: ApiService.addCustomer, // Method to add a new customer
    edit: ApiService.updateCustomer, // Method to update an existing customer
    delete: ApiService.deleteCustomer, // Method to delete a customer
  },
  [PAY_FREQUENCY_SCREEN]: {
    // API methods for Pay Frequency Screen
    add: ApiService.addPayFrequencies, // Method to add a new pay frequency
    edit: ApiService.updatePayFrequency, // Method to update an existing pay frequency
    delete: ApiService.deletePayFrequency, // Method to delete a pay frequency
  },
  [PLAN_SCREEN]: {
    // API methods for Plan Screen
    add: ApiService.addPricingPlan, // Method to add a new pricing plan
    edit: ApiService.updatePricingPlan, // Method to update an existing pricing plan
    delete: ApiService.deletePricingPlan, // Method to delete a pricing plan
  },
  [PRODUCT_SCREEN]: {
    // API methods for Product Screen
    add: ApiService.addProduct, // Method to add a new product
    edit: ApiService.updateProduct, // Method to update an existing product
    delete: ApiService.deleteProduct, // Method to delete a product
  },
  [ROLE_SCREEN]: {
    // API methods for Role Screen
    add: ApiService.addRole, // Method to add a new role
    edit: ApiService.updateRole, // Method to update an existing role
    delete: ApiService.deleteRole, // Method to delete a role
  },
  [SCHEDULE_SCREEN]: {
    // API methods for Schedule Screen
    add: ApiService.addScheduler, // Method to add a new scheduler
    edit: ApiService.updateScheduler, // Method to update an existing scheduler
    delete: ApiService.deleteScheduler, // Method to delete a scheduler
  },
  [TICKET_SCREEN]: {
    // API methods for Ticket Screen
    add: ApiService.addTicket, // Method to add a new ticket
    edit: ApiService.updateTicket, // Method to update an existing ticket
    delete: ApiService.deleteTicket, // Method to delete a ticket
  },
  [TRANSACTION_SCREEN]: {
    // API methods for Transaction Screen
    add: ApiService.addTransaction, // Method to add a new transaction
    edit: ApiService.updateTransaction, // Method to update an existing transaction
    delete: ApiService.deleteTransaction, // Method to delete a transaction
  },
  [TYPE_SCREEN]: {
    // API methods for Type Screen
    add: ApiService.addType, // Method to add a new type
    edit: ApiService.updateType, // Method to update an existing type
    delete: ApiService.deleteType, // Method to delete a type
  },
  [VARIABLE_SCREEN]: {
    // API methods for Variable Screen
    add: ApiService.addVariable, // Method to add a new variable
    edit: ApiService.updateVariable, // Method to update an existing variable
    delete: ApiService.deleteVariable, // Method to delete a variable
  },
};

// Exporting the apiMappings object for use in other parts of the application
export default apiMappings;
