import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { Box, Container, Grid, Typography } from "@mui/material";
import { PrivacyTipTwoTone } from "@mui/icons-material";
import { IS_BiLLING, IS_DEMO_USER } from "../config/sessionStorage";
import { CfTransBlueLogo } from "../config/imageConfig";

export default function DemoUserInfo({ setIsAuthenticated, setRes }) {
  const [loading, setLoading] = useState(true); // State to track loading status

  const navigate = useNavigate();

  const isBilling = IS_BiLLING(); // Check if user is on a billing plan

  useEffect(() => {
    let isComponentMounted = true; // Track whether the component is still mounted

    // Navigate to the appropriate page (billing or dashboard)
    const handleNavigation = () => {
      if (isComponentMounted) {
        sessionStorage.removeItem("isDemoUser");
        sessionStorage.removeItem("isBilling");
        navigate(isBilling === "true" ? "/billing" : "/dashboard");
      }
    };

    // Log out the user and clear session storage
    const handleLogout = () => {
      setIsAuthenticated(false);
      setRes(false);
      sessionStorage.clear(); // Clear session storage for logout
      navigate("/sign_in");
    };

    try {
      const isDemoUser = IS_DEMO_USER(); // Check if the user is a demo user

      if (isDemoUser === "false") {
        // If not a demo user, log out
        handleLogout();
      } else {
        // Show loading spinner for 5 seconds before navigating
        const timer = setTimeout(() => {
          setLoading(false); // Stop loading after timeout
          handleNavigation();
        }, 4000);

        // Allow navigation on any screen click
        document.addEventListener("click", handleNavigation);

        // Clean up the timer and event listener on unmount
        return () => {
          clearTimeout(timer);
          document.removeEventListener("click", handleNavigation);
          isComponentMounted = false; // Avoid state updates after unmount
        };
      }
    } catch (error) {
      // Handle any errors (e.g., unexpected session issues)
      console.error("Error during DemoUserInfo setup:", error);
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "0 20px",
        flexDirection: "column", // Ensures vertical stacking of logo and content
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 3, // Adds spacing between the logo and content
        }}
      >
        <img
          src={CfTransBlueLogo} // Replace with your logo path
          alt="Logo"
          style={{
            maxWidth: "150px", // Adjust the size of the logo as needed
            height: "auto",
          }}
        />
      </Box>

      {/* Content Section */}
      <Container maxWidth="md">
        <Grid container spacing={3} alignItems="center">
          {/* Icon Section */}
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: "center",
            }}
          >
            <PrivacyTipTwoTone
              color="info"
              sx={{ fontSize: { xs: 50, sm: 80, md: 100 } }}
            />
          </Grid>

          {/* Welcome Message Section */}
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              gutterBottom
            >
              Welcome, Demo User!
            </Typography>

            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "1rem", sm: "1.125rem" }, mb: 1 }}
            >
              Multi-Factor Authentication (MFA) enhances the security of your
              login by verifying that only authorized users can access the
              application, ensuring your data remains accessible only to you.
              While this feature is available, it has been disabled for demo
              users.
            </Typography>

            {/* Show loading spinner or success message */}
            {loading ? (
              <ThreeDots
                visible={true}
                height="25"
                width="100"
                color="#0066ff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              <Typography variant="body1" sx={{ mt: 1 }}>
                Redirecting you now...
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
