import { Box } from "@mui/material";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";

export default function TransactionColumns(
  actionsColumn = null,
  dynamicColumns = [],
  isErrorColor = false,
  handleEdit = () => {},
  currency = ""
) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "transactionId",
      headerName: "Trans ID",
      width: 90,
      renderCell: ({ row }) => {
        // Check if the row exists to prevent errors
        if (!row) {
          return <Box sx={{ width: "100%", height: "100%" }} />; // Empty box ensures UI layout is maintained
        }
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row?.transactionId || ""} // Add the title attribute for the tooltip
            onClick={() => {
              try {
                // Ensure row.id exists before calling handleEdit
                if (row?.id) {
                  handleEdit(row?.id, true)(); // Call handleEdit with row ID
                }
              } catch (error) {
                console.error("Error occurred during edit:", error);
              }
            }}
          >
            {row?.transactionId || ""}
          </div>
        );
      },
    },
    {
      field: "customerNameTrans",
      headerName: "Customer",
      width: 130,
    },
    {
      field: "productNameTrans",
      headerName: "Product",
      width: 130,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "right",
      align: "right",
      width: 70,
    },
    {
      field: "transactionType",
      headerName: "Trans Type",
      width: 100,
    },
    {
      field: "projection",
      headerName: "Is Projection",
      type: "boolean",
      width: 100,
    },
    {
      field: "grossProfit",
      headerName: `Gross Profit (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "salesRepNameTrans",
      headerName: "Sales Rep",
      width: 130,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "unitCost",
      headerName: `Unit Cost (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "salesAmount",
      headerName: `Sales Amt (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "transactionLine",
      headerName: "Trans Line",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "transactionLineLevel",
      headerName: "Trans LineLevel",
      width: 100,
    },
    {
      field: "transactionStatus",
      headerName: "Trans Status",
      width: 100,
    },
    {
      field: "transactionLineType",
      headerName: "Trans LineType",
      width: 100,
    },
    {
      field: "transactionDate",
      headerName: "Trans Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      width: 100,
      valueFormatter: ({ value }) => {
        // Return the formatted date or empty string if value is invalid
        return formatDateDDMMYYYY(value) ?? "";
      },
    },
    {
      field: "unitOfMeasure",
      headerName: "UOM",
      width: 100,
    },
    {
      field: "profitPercentage",
      headerName: "Profit %",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "discount",
      headerName: "Discount",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "commissionAmount",
      headerName: `Comm Amt (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "commissionRate",
      headerName: `Comm Rate (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    // Conditionally display the description column based on isErrorColor
    ...(isErrorColor
      ? [
          {
            field: "description",
            headerName: "Error Description",
            width: 200,
          },
        ]
      : []),
    // Only spread dynamicColumns if it's actually an array (just being cautious!)
    ...(Array.isArray(dynamicColumns) ? dynamicColumns : []),
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
