import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import JoditEditor from "jodit-react";
import toast from "react-hot-toast";
import {
  excludePatterns,
  transformHTML,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  decodeHTML,
  areBracesBalanced,
  highlightUnbalancedBraces,
  orderErrorFocus,
} from "../../config/fieldConfig";
import RoleUPForm from "./roleUpForm";
import SplitForm from "./splitForm";
import TieredForm from "./tieredForm";
import Conditions from "./conditionForm";
import Calculations from "./calculationForm";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../../utils/CustomInputFields";
import RenderDataGrid from "../../../utils/CreditingCustomDataGrid";

export default function CommissionForm({
  mode,
  onClose,
  editItemId,
  editFormData,
  transactionEntity,
  fields,
  variableNames,
  conditionFields,
  calculationFields,
  checkBoxFields,
  getConditionOptions,
  getCalcOptions,
  handleAddRow,
  addCalcRow,
  RemoveConditionRow,
  RemoveCalcRow,
  row,
  setRow,
  calcRow,
  setCalcRow,
  handleCondition,
  handleCalculation,
  outputStrings,
  outputArray,
  splitRow,
  splitColumn,
  splitData,
  setSplitData,
  roleUpRow,
  roleUpColumn,
  roleData,
  setRoleData,
  tieredRow,
  tieredColumn,
  tieredData,
  setTieredData,
  calculationForm,
  conditionForm,
  commissionConfigNames,
  conditionEntityNames,
  conditionOption,
  symbols,
  calcEntityNames,
  productNames,
  fullProductNames,
  customerNames,
  roleUpChecked,
  setRoleUpChecked,
  splitChecked,
  setSplitChecked,
  tieredChecked,
  setTieredChecked,
  isDisabled,
  viewData,
  setViewData,
  productEntity,
  braces,
  frequencyOptionsWithId,
  percentageOption,
  rowString,
  cumulativeValue,
  setCumulativeValue,
  onSubmitForm,
  setShowForm,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  // Checkbox Form
  const [openRoleUpForm, setOpenRoleUpForm] = useState(false);
  const [openSplitForm, setOpenSplitForm] = useState(false);
  const [openTieredForm, setOpenTieredForm] = useState(false);

  // Checkbox
  const [isActiveChecked, setIsActiveChecked] = useState(false);
  // const [attainmentChecked, setAttainmentChecked] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors1, setErrors1] = useState({});

  useEffect(() => {
    reset();
    if (mode !== "edit") {
      setViewData();
      setRoleData("");
      setSplitData("");
      setTieredData([]);
      setRoleUpChecked(false);
      setSplitChecked(false);
      setTieredChecked(false);
      setCumulativeValue(false);
      setRow([
        {
          conDescription: "",
          startBrace: braces[0],
          conditionEntityName: conditionEntityNames[0],
          conditionOption: "",
          field: "",
          symbols: symbols[0],
          endBrace: braces[0],
        },
      ]);
      setCalcRow([
        {
          calcDescription: "",
          calcEntityName: calcEntityNames[0],
          calcOption: "",
          calcField: "",
          calcSymbols: symbols[0],
          percentage: percentageOption[0],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    try {
      // If an editItemId is provided, fetch the data for editing
      if (mode === "edit" && editItemId !== null) {
        // Check if editFormData exists
        if (editFormData) {
          setValue("commissionDescription", editFormData.commissionDescription);
          setCumulativeValue(editFormData.cumulative);
          setValue("cumulative", editFormData.cumulative);
          // Check if editFormData.roleUp exists
          if (
            editFormData.roleUp &&
            Object.keys(editFormData.roleUp).length !== 0
          ) {
            setRoleUpChecked(true);
            setRoleData(editFormData.roleUp);
          }
          // Check if editFormData.split exists
          if (
            editFormData.split &&
            Object.keys(editFormData.split).length !== 0
          ) {
            setSplitChecked(true);
            setSplitData(editFormData.split);
          }
          if (editFormData.tiered && editFormData.tiered.length !== 0) {
            setTieredChecked(true);
            setTieredData(editFormData.tiered);
          }

          const outputArray = conditionForm(editFormData.conditionedName);
          setRow(outputArray);

          const value = calculationForm(editFormData.calculateFormula);
          setCalcRow(value);

          fields.forEach((field) => {
            const { name, type } = field;
            let value = editFormData[name];
            if (
              type === "text" ||
              type === "number" ||
              type === "boolean" ||
              type === "autocomplete"
            ) {
              const creditString = editFormData.credit; // Assuming Key is 'salesAmount: 100'
              const [crediting, portion] = creditString
                .split(":")
                .map((item) => item.trim());
              setValue("credit", crediting);
              setValue("portion", portion);
              setValue(name, value);
            }
          });
          // Set the isActiveChecked state based on the value from editFormData
          setIsActiveChecked(editFormData.active === true);
        }
      }
    } catch (error) {
      console.error(error); // Log error to console
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onRoleData = (data) => {
    setRoleData(data);
  };

  const onSplitData = (data) => {
    setSplitData(data);
  };

  const onTieredData = (data) => {
    setTieredData(data);
  };

  // Wrap the conditionString with `if` and parentheses
  const outputStringValue = `if (${outputStrings.outputString})`;
  let highlightedString;

  const braceMap = areBracesBalanced(outputStrings.outputString1);
  highlightedString = highlightUnbalancedBraces(
    outputStrings.outputString1,
    braceMap
  );

  // directly connect the children component for showing error message
  const conditionRef = useRef(null);

  // directly connect the children component for showing error message
  const calculationRef = useRef(null);

  const handleFormSubmit = async (e) => {
    try {
      // Validate the child form adjustments
      const isConditionFormValid = await conditionRef.current.validateForm();

      // Validate the child form adjustments
      const isCalculationFormValid =
        await calculationRef.current.validateForm();

      // Filter out fields with undefined values
      const conditionErrors = Object.fromEntries(
        Object.entries(isConditionFormValid).filter(
          ([key, value]) => value !== undefined
        )
      );

      // Filter out fields with undefined values
      const calculationErrors = Object.fromEntries(
        Object.entries(isCalculationFormValid).filter(
          ([key, value]) => value !== undefined
        )
      );

      if (
        Object.keys(conditionErrors).length === 0 &&
        Object.keys(calculationErrors).length === 0
      ) {
        // Only call handleSubmit if there are no validation errors
        handleSubmit(onSubmit)(e); // Call handleSubmit with onSubmit function
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData) => {
    try {
      // Validate the child form adjustments
      const isConditionFormValid = await conditionRef.current.validateForm();

      // Validate the child form adjustments
      const isCalculationFormValid =
        await calculationRef.current.validateForm();

      if (
        Object.keys(isConditionFormValid).length === 0 &&
        Object.keys(isCalculationFormValid).length === 0
      ) {
        console.log("No error in child form.");
      } else {
        // Focus on the first field with an error
        const errorFields = document.querySelectorAll('[aria-invalid="true"]');
        if (errorFields.length > 0) {
          errorFields[0].focus();
        }
        return;
      }

      formData.credit = {
        crediting: formData.credit,
        portion: formData.portion,
      };
      formData.cumulative = cumulativeValue;
      if (braceMap.size === 0) {
        console.log("The braces are balanced.");
      } else {
        toast.error("The braces are not balanced in the condition field.");
        return;
      }

      // Remove the 'if ' prefix
      const trimmedOutput = outputStringValue.startsWith("if ")
        ? outputStringValue.substring(3) // Remove 'if ' which is 3 characters
        : outputStringValue;

      formData.conditionedName = trimmedOutput;
      formData.calculateFormula = outputArray;
      formData.conditioned = rowString;
      // Now you have structured mainFormData and customFields objects as per your requirements
      const TypeWithId = frequencyOptionsWithId.find(
        (frequency) => frequency.frequency === formData.selectFrequency
      );
      if (TypeWithId) {
        formData.selectFrequencyId = TypeWithId.id;
      } else {
        formData.selectFrequencyId = null;
      }

      if (Object.keys(roleData).length > 0) {
        // Iterate through the object's properties
        for (const key in roleData) {
          // Check if the property value is a string containing a number
          if (!isNaN(roleData[key])) {
            // Convert the string to a number
            roleData[key] = parseFloat(roleData[key]);
          }
        }
        formData.roleUp = roleData;
      } else if (Object.keys(roleData).length === 0) {
        delete formData.roleUp;
      }
      if (Object.keys(splitData).length > 0) {
        // Iterate through the object's properties
        for (const key in splitData) {
          // Check if the property value is a string containing a number
          if (!isNaN(splitData[key])) {
            // Convert the string to a number
            splitData[key] = parseFloat(splitData[key]);
          }
        }
        formData.split = splitData;
      } else if (Object.keys(splitData).length === 0) {
        delete formData.split;
      }
      if (tieredData.length > 0) {
        formData.tiered = tieredData;
      } else if (tieredData.length === 0) {
        delete formData.tiered;
      }

      // remove unwanted values
      const filterFormData = (data) => {
        const filteredData = {};

        for (const key in data) {
          if (!excludePatterns.test(key)) {
            filteredData[key] = data[key];
          }
        }

        return filteredData;
      };
      // Get the content from the editor
      const content =
        formData.commissionDescription &&
        formData.commissionDescription.replace(/<[^>]*>/g, ""); // Remove HTML tags
      const contentWithoutSpaces = content && content.replace(/\s+/g, ""); // Remove spaces

      // Check if the content length exceeds the limit
      if (contentWithoutSpaces && contentWithoutSpaces.length > 1000) {
        return; // Prevent form submission
      }

      const filteredFormData = filterFormData(formData);

      // format the style for editor
      const descriptionHTMLFormate = transformHTML(
        filteredFormData.commissionDescription
      );

      filteredFormData.commissionDescription = descriptionHTMLFormate;

      // Only call handleSubmit if there are no validation errors
      if (mode === "add") {
        onSubmitForm(filteredFormData, "add");
      } else if (mode === "edit") {
        filteredFormData.id = editItemId;
        onSubmitForm(filteredFormData, "edit");
      }
      setShowForm(false);
      onClose();
      setErrors1({});
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  useEffect(() => {
    if (mode === "add") {
      setRoleData(roleData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleData]);

  useEffect(() => {
    if (mode === "add") {
      setSplitData(splitData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitData]);

  useEffect(() => {
    if (mode === "add") {
      setTieredData(tieredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tieredData]);

  const isCheckeds = (field, checked) => {
    switch (field) {
      case "roleUp":
        if (Object.keys(roleData).length !== 0) {
          setRoleUpChecked(true);
          setOpenRoleUpForm(true);
        } else {
          setRoleUpChecked(checked);
          setOpenRoleUpForm(checked);
        }
        break;
      case "split":
        if (Object.keys(splitData).length !== 0) {
          setSplitChecked(true);
          setOpenSplitForm(true);
        } else {
          setSplitChecked(checked);
          setOpenSplitForm(checked);
        }
        break;
      case "tiered":
        if (tieredData.length !== 0) {
          setTieredChecked(true);
          setOpenTieredForm(true);
        } else {
          setTieredChecked(checked);
          setOpenTieredForm(checked);
        }
        break;
      // case "attainment":
      //   setAttainmentChecked(checked);
      //   break;
      default:
        break;
    }
  };

  const handleRoleUpFormClose = () => {
    setOpenRoleUpForm(false);
  };

  const handleSplitFormClose = () => {
    setOpenSplitForm(false);
  };

  const handleTieredFormClose = () => {
    setOpenTieredForm(false);
  };

  //default select in condition and calculation
  useEffect(() => {
    if (mode === "add") {
      if (productEntity.length > 0) {
        setRow((prevRows) =>
          prevRows.map((rowItem) => ({
            ...rowItem,
            field: productEntity[0], // Set the field to the first item from productEntity
          }))
        );
        setCalcRow((prevRows) =>
          prevRows.map((rowItem) => ({
            ...rowItem,
            calcField: productEntity[0], // Set the field to the first item from productEntity
          }))
        );
      }
      if (variableNames.length > 0) {
        setCalcRow((prevRows) =>
          prevRows.map((rowItem) => ({
            ...rowItem,
            calcOption: variableNames[0], // Set the field to the first item from productEntity
          }))
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEntity, mode, variableNames]);

  // totalCondition fields
  const TotalConditionTextField = () => {
    return (
      <div
        style={{
          border: "1px solid #ddd",
          padding: "9px",
          borderRadius: "4px",
          width: "100%", // Ensure it takes up full width of its parent
          maxWidth: "100%", // Ensure it does not exceed its parent container
          boxSizing: "border-box", // Include padding and border in the element's total width and height
          overflowWrap: "break-word", // Handle long words or URLs that may overflow
          pointerEvents: "none", // Disable interaction
        }}
        dangerouslySetInnerHTML={{ __html: `if(${highlightedString})` }}
      />
    );
  };

  const TotalCalculationTextField = () => {
    return (
      <div
        style={{
          border: "1px solid #ddd",
          padding: "9px",
          borderRadius: "4px",
          width: "100%", // Ensure it takes up full width of its parent
          maxWidth: "100%", // Ensure it does not exceed its parent container
          boxSizing: "border-box", // Include padding and border in the element's total width and height
          overflowWrap: "break-word", // Handle long words or URLs that may overflow
          pointerEvents: "none", // Disable interaction
        }}
      >
        <>{JSON.stringify(outputArray)}</> {/* Pretty print JSON */}
      </div>
    );
  };

  // Ensure fullProductNames is initialized as an array
  const validFullProductNames = Array.isArray(fullProductNames)
    ? fullProductNames.filter((name) => name !== null)
    : [];

  const inputStyles = {
    height: "2.5rem", // Set a fixed height for the input box
    "& .MuiInputBase-input": {
      height: "2.5rem", // Ensure the input itself does not grow
      padding: "0.5rem", // Adjust padding if needed
    },
  };

  const getOptionsStartsWith = (inputValue) => {
    return variableNames.filter((option) => option.startsWith(inputValue));
  };

  useEffect(() => {
    if (tieredData.length !== 0) {
      if (mode === "add") {
        setCalcRow([
          {
            calcDescription: "",
            calcEntityName: calcEntityNames[0],
            calcOption: "",
            calcField: "",
            calcSymbols: symbols[0],
            percentage: percentageOption[0],
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tieredData]);

  // crediting fields
  const Crediting = () => {
    return (
      <Grid container mt={-2}>
        {checkBoxFields.map((field, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <FormControl
              error={!!errors[field.name]}
              component="fieldset"
              variant="outlined"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={field.name}
                    checked={
                      field.name === "roleUp"
                        ? roleUpChecked
                        : field.name === "split"
                        ? splitChecked
                        : field.name === "tiered"
                        ? tieredChecked
                        : null
                    } // Use the state variable here
                    onChange={(e) => isCheckeds(field.name, e.target.checked)}
                    disabled={viewData}
                  />
                }
                label={field.label}
                {...register(field.name, {
                  required: field.required
                    ? `${field.label} is required`
                    : false,
                })}
              />
              {field.name === "roleUp" && (
                <RoleUPForm
                  open={openRoleUpForm}
                  onClose={handleRoleUpFormClose}
                  onRoleData={onRoleData}
                  roleData={roleData}
                  setRoleUpChecked={setRoleUpChecked}
                  viewData={viewData}
                />
              )}
              {field.name === "split" && (
                <SplitForm
                  open={openSplitForm}
                  onClose={handleSplitFormClose}
                  onSplitData={onSplitData}
                  splitData={splitData}
                  transactionEntity={transactionEntity}
                  setSplitChecked={setSplitChecked}
                  viewData={viewData}
                />
              )}
              {field.name === "tiered" && (
                <TieredForm
                  open={openTieredForm}
                  onClose={handleTieredFormClose}
                  onTieredData={onTieredData}
                  tieredData={tieredData}
                  setTieredChecked={setTieredChecked}
                  viewData={viewData}
                  cumulativeValue={cumulativeValue}
                  setCumulativeValue={setCumulativeValue}
                />
              )}
              {/* table display */}
              {field.name === "roleUp" && roleUpRow.length !== 0 && (
                <RenderDataGrid
                  rows={roleUpRow ?? []}
                  columns={roleUpColumn ?? []}
                  viewData={viewData}
                />
              )}
              {field.name === "split" && splitRow.length !== 0 && (
                <RenderDataGrid
                  rows={splitRow ?? []}
                  columns={splitColumn ?? []}
                  viewData={viewData}
                />
              )}
              {field.name === "tiered" && tieredRow.length !== 0 && (
                <RenderDataGrid
                  rows={tieredRow ?? []}
                  columns={tieredColumn ?? []}
                  viewData={viewData}
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    );
  };

  // to validate the commissionName
  const handleCommissionNameChange = (enteredValue) => {
    try {
      const trimmedEnteredValue = enteredValue.trim();
      const lowercasedEnteredValue = trimmedEnteredValue.toLowerCase();

      if (enteredValue !== trimmedEnteredValue) {
        // Leading or trailing spaces detected
        return "Please remove any leading or trailing spaces from the commission name.";
      }

      if (
        mode === "add" &&
        commissionConfigNames.some(
          (name) => name.toLowerCase() === lowercasedEnteredValue
        )
      ) {
        return "Commission name already exists";
      } else if (
        mode === "edit" &&
        commissionConfigNames.some(
          (name) => name.toLowerCase() === lowercasedEnteredValue
        ) &&
        editFormData.commissionName &&
        editFormData.commissionName.toLowerCase() !== lowercasedEnteredValue
      ) {
        return "Commission name already exists";
      } else {
        return true; // Return true for a valid input
      }
    } catch (error) {
      console.error(error); // Log error to console
    }
  };

  const joditConfig = useMemo(
    () => ({
      readonly: viewData,
      removeButtons: ["fullsize"],
      addNewLine: false,
      hidePoweredByJodit: true,
      events: {
        afterInit: (editor) => {
          const statusBar = editor.statusbar.container;
          const customMessage = document.createElement("div");
          customMessage.style.margin = "10px";
          customMessage.style.display = "inline";
          customMessage.textContent = `Content cannot exceed 1000 characters.`;

          customMessage.textContent = `Content cannot exceed ${
            editor.options.limitChars - 1
          } characters.`;
          statusBar.appendChild(customMessage);

          // Function to update message color and editor border based on content length
          const updateMessageColor = () => {
            const decodedText = decodeHTML(editor.value);
            // Remove HTML tags and spaces from plain text
            const plainText = decodedText.replace(/<[^>]*>/g, "");
            const plainTextWithoutSpaces = plainText.replace(/\s+/g, "");

            const editorContainer = editor.container; // Get the editor container

            if (plainTextWithoutSpaces.length > 1000) {
              customMessage.style.color = "red"; // Change message color when limit is reached
              editorContainer.style.borderColor = "red"; // Change border color when limit is reached
            } else {
              customMessage.style.color = ""; // Default message color
              editorContainer.style.borderColor = ""; // Reset to default border color
            }
          };
          // Attach event listeners to check the content length whenever it changes
          editor.events.on("change", updateMessageColor);
          editor.events.on("blur", updateMessageColor); // When focus is lost
          editor.events.on("keydown", updateMessageColor); // On key down

          // Initialize message color and border based on the current content and print length
          updateMessageColor();
        },
      },
    }),
    [viewData]
  );

  const fieldRefs = useRef({});

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(handleFormSubmit, (errors) =>
          orderErrorFocus(fields, errors, fieldRefs)
        )}
        mt={mode === "edit" ? 0 : -5}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sm={
                field.isEditor ||
                field.isCondition ||
                field.isTotalCondition ||
                field.isTotalCalculation ||
                field.isCalculation ||
                field.isCrediting ||
                field.isDivider
                  ? 12
                  : field.name === "credit" || field.name === "portion"
                  ? 3
                  : 6
              }
            >
              {field.name === "active" ? (
                <FormControl
                  error={!!errors[field.name]}
                  component="fieldset"
                  variant="outlined"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={field.name}
                        checked={isActiveChecked} // Use the state variable here
                        onChange={(e) => setIsActiveChecked(e.target.checked)} // Update the state when the checkbox changes
                        disabled={viewData}
                      />
                    }
                    label={field.label}
                    required={field.required}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                    })}
                  />
                  <FormHelperText>{errors[field.name]?.message}</FormHelperText>
                </FormControl>
              ) : field.isEditor ? (
                <Controller
                  name="commissionDescription"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <JoditEditor
                        value={field.value}
                        config={joditConfig}
                        onChange={(content) => {
                          field.onChange(content); // Update content if within the limit
                        }}
                      />
                    </>
                  )}
                />
              ) : field.type === "autocomplete" ? (
                <CustomAutocomplete
                  field={field}
                  register={register}
                  trigger={trigger}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                  fieldRefs={fieldRefs}
                  style={{
                    pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                  }}
                  InputLabelProps={{
                    style: {
                      pointerEvents: "none",
                    },
                  }}
                />
              ) : field.isCondition ? (
                <Conditions
                  ref={conditionRef}
                  isSubmitted={isSubmitted}
                  setIsSubmitted={setIsSubmitted}
                  mode={mode}
                  RemoveConditionRow={RemoveConditionRow}
                  row={row}
                  conditionFields={conditionFields}
                  getConditionOptions={getConditionOptions}
                  productNames={productNames}
                  validFullProductNames={validFullProductNames}
                  customerNames={customerNames}
                  viewData={viewData}
                  editItemId={editItemId}
                  inputStyles={inputStyles}
                  errors1={errors1}
                  handleCondition={handleCondition}
                  isValueContainsSplChars={isValueContainsSplChars}
                  isEmpty={isEmpty}
                  handleAddRow={handleAddRow}
                  braces={braces}
                />
              ) : field.isTotalCondition ? (
                TotalConditionTextField()
              ) : field.isTotalCalculation ? (
                TotalCalculationTextField()
              ) : field.isDivider ? (
                <Divider />
              ) : field.isCalculation ? (
                <Calculations
                  ref={calculationRef}
                  isSubmitted={isSubmitted}
                  setIsSubmitted={setIsSubmitted}
                  RemoveCalcRow={RemoveCalcRow}
                  row={row}
                  calculationFields={calculationFields}
                  getCalcOptions={getCalcOptions}
                  calcRow={calcRow}
                  mode={mode}
                  tieredData={tieredData}
                  productNames={productNames}
                  fullProductNames={fullProductNames}
                  customerNames={customerNames}
                  viewData={viewData}
                  editItemId={editItemId}
                  getOptionsStartsWith={getOptionsStartsWith}
                  setCalcRow={setCalcRow}
                  handleCalculation={handleCalculation}
                  isEmpty={isEmpty}
                  addCalcRow={addCalcRow}
                />
              ) : field.isSubtitle ? (
                <Typography variant="subtitle1" mt={-1}>
                  {field.subtitle}
                </Typography>
              ) : field.isCrediting ? (
                Crediting()
              ) : (
                <CustomTextField
                  field={field}
                  register={register}
                  trigger={trigger}
                  errors={errors}
                  fieldRefs={fieldRefs}
                  inputProps={{
                    ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                  }}
                  InputLabelProps={{
                    style: {
                      pointerEvents:
                        (viewData && editItemId) ||
                        (isDisabled && field.name === "commissionName")
                          ? "none"
                          : "auto",
                    },
                  }}
                  style={{
                    pointerEvents:
                      (viewData && editItemId) ||
                      (isDisabled && field.name === "commissionName")
                        ? "none"
                        : "auto",
                  }}
                  validate={{
                    handleCommissionNameChange: (value) =>
                      field.name === "commissionName"
                        ? handleCommissionNameChange(value)
                        : true,
                    isEmpty: (value) =>
                      field.required ? isEmpty(value) : true,
                    isValueContainsSplChars: (value) =>
                      field.required && field.name === "commissionName"
                        ? isValueContainsSplChars(value)
                        : true,
                    isValueStartsWithNumber: (value) =>
                      field.required && field.name === "commissionName"
                        ? isValueStartsWithNumber(value, field.label)
                        : true,
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
        {viewData && editItemId ? null : (
          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={(e) => {
                setIsSubmitted(true);
              }}
            >
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  );
}
