import { fData, transformFields } from "../../components/config/fieldConfig";

export const prepareTransformedObjects = (
  mainFormData = {},
  customFields = {},
  response = {},
  resObject = {}
) => {
  try {
    // Transform custom fields
    const transformedFields = transformFields(customFields);

    // Transform main form data
    const transformedData = fData(mainFormData);

    // Update transformedFields with values from response
    for (const key in transformedFields) {
      if (
        transformedFields.hasOwnProperty(key) &&
        response.hasOwnProperty(key)
      ) {
        transformedFields[key][key] = response[key];
      }
    }

    // Prepare the final object for API submission
    const transformedObjects = {
      mappingValue: resObject,
      customFields: transformedFields,
    };

    return { transformedData, transformedObjects };
  } catch (error) {
    // Handle any uncaught errors
    console.error(
      "An error occurred while preparing transformed objects:",
      error
    );

    // Optionally, return a fallback or error object
    return { transformedData: null, transformedObjects: null };
  }
};
