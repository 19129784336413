// Pay Frequency Options
export const frequencyOptions = [
  "Weekly",
  "Bi-Weekly",
  "Monthly",
  "Quarterly",
  "Half-Yearly",
  "Yearly",
];

// Generate a range of years, change as per your requirement
export const fiscalYearOptions = Array.from({ length: 30 }, (_, i) => {
  return { label: new Date().getFullYear() + 1 - i };
});

// Frequency table columns
export const frequencyColumn = [
  {
    field: "periodNumber",
    headerName: "Pay Period",
    width: 250,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 250,
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 250,
  },
];
