import toast from "react-hot-toast";
import ApiService from "../apis/ApiService";
import { EXCEPTION_MESSAGE } from "../components/config/toastMessage";
import { JWT_TOKEN } from "../components/config/sessionStorage";
import { TYPE_SCREEN } from "../components/config/constants";
import { handleMenuUpdate } from "./menuHandler";

/**
 * Adds a new type to the system.
 *
 * @param {Object} [value={}] - The object containing type details (default is an empty object).
 * @param {Function} [setValue=() => {}] - Function to update the form state with the new type (default is an empty function).
 * @param {Function} [setOptions=() => {}] - Function to update the options list with the new type (default is an empty function).
 * @param {String} [fieldName=""] - The name of the field to set in the form (default is an empty string).
 */

export const customAddType = async (
  value = {},
  setValue = () => {},
  setOptions = () => {},
  fieldName = ""
) => {
  try {
    // Retrieve the JWT token for authorization
    const token = JWT_TOKEN();

    // Check if the token is available; throw an error if not
    if (!token) throw new Error("Token not found or invalid");

    // Call the API to add the new type
    const res = await ApiService.addType(value, token);

    // Check if the API response indicates success
    if (res?.status === 200) {
      // Notify the user of the successful addition
      toast.success(res?.data);

      // Update the menu to reflect the newly added type
      await handleMenuUpdate(TYPE_SCREEN, value?.typeName);

      // Update the options list with the new type if it doesn't already exist
      setOptions((prevOptions) => {
        const lowerCasePrevOptions = prevOptions.map(
          (option) => option.toLowerCase() // Convert previous options to lowercase for case-insensitive comparison
        );
        // Check if the new type is already in the options
        if (!lowerCasePrevOptions.includes(value.typeValue.toLowerCase())) {
          // If it doesn't exist, add it to the options list
          return [...prevOptions, value.typeValue];
        }
        // If it already exists, return the previous options unchanged
        return prevOptions;
      });

      // Set the new type value in the form using the provided field name
      setValue(fieldName, value.typeValue);
    } else {
      // Handle errors with a toast notification
      toast.error(
        res?.code === "ERR_NETWORK"
          ? res?.message
          : res?.response?.data || EXCEPTION_MESSAGE
      );
    }
  } catch (error) {
    // Log any unexpected errors to the console for debugging
    console.error(error);
  }
};
