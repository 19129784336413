import { formatDateDDMMYYYY } from "../../config/fieldConfig";
import { formatCurrency } from "../../../utils/CurrencyFormatter";

export const flattenData = (tableData, sno) => {
  // Ensure tableData is a valid object
  if (!tableData || typeof tableData !== "object") {
    console.error("Invalid tableData:", tableData); // Optional: Debugging log
    return []; // Return an empty array if tableData is invalid
  }

  return Object.entries(tableData).flatMap(([groupName, groupRecords]) => {
    const initialSno = sno; // Store the initial sno value for this group
    const flattenedGroupRecords = groupRecords.map((record) => ({
      ...record,
      date: formatDateDDMMYYYY(record.date),
      sno: sno++, // Add sno property and increment the counter
    }));

    const groupTotal = flattenedGroupRecords.reduce(
      (acc, record) => {
        acc.salesAmount += record.salesAmount || 0;
        acc.creditAmount += record.creditAmount || 0;
        acc.commissionAmount += record.commissionAmount || 0;
        return acc;
      },
      { salesAmount: 0, creditAmount: 0, commissionAmount: 0 }
    );

    // Format the values to two decimal places
    groupTotal.salesAmount = formatCurrency(groupTotal.salesAmount);
    groupTotal.creditAmount = formatCurrency(groupTotal.creditAmount);
    groupTotal.commissionAmount = formatCurrency(groupTotal.commissionAmount);
    sno = initialSno + groupRecords.length; // Update sno counter for the next group
    return [
      {
        id: `${groupName}_header`,
        transactionId: `${groupName}`, // Using transactionId as part of the ID
      },
      ...flattenedGroupRecords.map((record) => ({
        ...record,
        // Format the values to two decimal places in records
        salesAmount: formatCurrency(record.salesAmount),
        creditAmount: formatCurrency(record.creditAmount),
        commissionRate: formatCurrency(record.commissionRate),
        commissionAmount: formatCurrency(record.commissionAmount),
      })),
      {
        id: `${groupName}_total`,
        productName: "Total",
        groupName: `${groupName} Total`,
        ...groupTotal,
      },
    ];
  });
};
