import { useEffect, useRef, useState } from "react";
import { groupBy } from "lodash";
import toast from "react-hot-toast";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  ORG_ID,
  USER_ID,
  USER_NAME,
} from "../../config/sessionStorage";
import ApiService from "../../../apis/ApiService";
import { CommissionByProdAndCustColumns } from "./columns";
import { NO_DATA_MSG } from "../../config/toastMessage";
import { getInitialDateRange } from "../../../utils/getInitialDateRange";

export const OtherReportService = ({
  custProdName,
  screenName,
  displayName,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true

  const stopRemount = useRef(true);

  const { startDate, endDate } = getInitialDateRange(stopRemount);

  const [reportingTo, setReportingTo] = useState([]);
  const [tableData, setTableData] = useState({});

  const userId = USER_ID();
  const userName = USER_NAME();
  const orgId = ORG_ID();

  const menu = [`${userId} - ${userName}`, "My Reportees"];

  const [selectedDropdown, setSelectedDropdown] = useState(menu[0]);
  const [reporteeIds, setReporteeIds] = useState([]);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;

      if (reporteeIds.length !== 0) {
        fetchData(startDate, endDate, "ApplyClick", reporteeIds);
      } else if (selectedDropdown === "My Reportees") {
        handleGetMyReportee();
      } else {
        fetchData(startDate, endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const fetchAndSetData = async (fetchFunctions, groupByKey) => {
    try {
      // Await the result of the fetch functions
      const [topCustomers] = await Promise.all(fetchFunctions);

      // Check if topCustomers is valid and contains data
      if (Array.isArray(topCustomers)) {
        const result = groupBy(
          topCustomers,
          (customer) => customer[groupByKey]
        );

        setTableData(result); // Update the state with grouped data
      } else {
        console.warn("No customers found or topCustomers is not an array.");
      }
    } catch (error) {
      toast.error("Failed to fetch and set data");
    }
  };

  const fetchData = async (startDate, endDate, selectedDropdown, salesRe) => {
    try {
      const TOKEN = JWT_TOKEN();

      // Fetch the `reportingTo` data
      const activeBeneficiaries = await ApiService.getActiveBeneficiaries(
        TOKEN
      );
      const response = activeBeneficiaries?.data;

      const transformedData = response.map((beneficiary) => ({
        userId: beneficiary.userId,
        userName: beneficiary.userName,
      }));

      const filteredData = transformedData.filter(
        (beneficiary) => beneficiary.userId !== userId
      );

      // Ensure filteredData is a valid array and not an object
      if (!Array.isArray(filteredData)) {
        throw new Error(" filteredData must be an array");
      }
      setReportingTo(filteredData);

      if (selectedDropdown === "My Data" || !selectedDropdown) {
        await fetchAndSetData(
          [ApiService.getIndividualReports(TOKEN, startDate, endDate)],
          custProdName // Just pass custProdName directly here
        );
      } else if (selectedDropdown === "My Reportees") {
        await fetchAndSetData(
          [ApiService.getReporteesReports(TOKEN, startDate, endDate)],
          custProdName
        );
      } else if (selectedDropdown === "ApplyClick") {
        await fetchAndSetData(
          [
            ApiService.getParticularUserReports(
              TOKEN,
              startDate,
              endDate,
              salesRe,
              orgId
            ),
          ],
          custProdName
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const currency = CURRENCY_NAME();

  const columns = CommissionByProdAndCustColumns(currency);
  const noTableDataMsg = NO_DATA_MSG;

  const handleGetMyDetailsApplyClick = (salesRe) => {
    setIsTableLoading(true);
    stopRemount.current = true;
    setReporteeIds(salesRe);
    fetchData(startDate, endDate, "ApplyClick", salesRe);
  };

  const handleGetMyReportee = () => {
    setIsTableLoading(true);
    stopRemount.current = true;
    setReporteeIds([]);
    fetchData(startDate, endDate, "My Reportees");
  };

  const handleGetMyDetails = () => {
    setIsTableLoading(true);
    stopRemount.current = true;
    setReporteeIds([]);
    fetchData(startDate, endDate, "My Data");
  };

  return {
    stopRemount,
    columns,
    noTableDataMsg,
    tableData,
    reportingTo,
    menu,
    handleGetMyDetailsApplyClick,
    handleGetMyReportee,
    handleGetMyDetails,
    isTableLoading,
    selectedDropdown,
    setSelectedDropdown,
    screenName,
    displayName,
  };
};
