import CustomDataGrid from "../../../utils/CustomDataGrid";
import { UserActivityService } from "./service";

export default function UserActivity() {
  const {
    displayName = "",
    rows = [],
    columns = [],
    loading = false,
    noTableDataMsg = "",
  } = UserActivityService();

  return (
    <>
      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={loading}
        message={noTableDataMsg}
        toolbarProps={{ displayName }}
      />
    </>
  );
}
