// Function to get breadcrumb trail based on the current path
export const getBreadcrumbs = (menuItems, settingMenuItems, currentPath) => {
  const breadcrumbTrail = [{ text: "CompFact", link: "/" }];
  const pathSegments = currentPath.replace(/^\//, "").split("/");
  let pathAccumulator = "";

  // Helper function to find breadcrumbs
  const findBreadcrumbs = (items, path, trail) => {
    for (const item of items) {
      // If the current item's link matches the path
      if (item.link === path) {
        // Add the item itself and its siblings to the trail
        let parent = trail[trail.length - 1]; // The immediate parent item

        if (parent && parent?.children) {
          // Check if the parent is "Settings" or "Master"
          const isSpecialParent =
            parent.text === "Settings" || parent.text === "Master";

          if (isSpecialParent) {
            // Only add siblings for "Setting" and "Master"
            trail.push(...parent.children.filter((child) => child.isAccess));
          } else {
            // Add the found item to the trail with both parent and child items
            trail.push(item);
          }
        } else {
          trail.push(item); // Add the found item to the trail
        }
        return true;
      }

      // If the current item has children, recursively search in them
      if (item.children) {
        trail.push(item); // Add the item to the trail before diving into its children
        for (const child of item.children) {
          if (findBreadcrumbs([child], path, trail)) return true; // If path is found, return true
        }
        trail.pop(); // If path is not found in children, remove this item from trail
      }
    }
    return false; // Return false if the path was not found
  };

  // Process each segment of the path
  pathSegments.forEach((segment, index) => {
    pathAccumulator += (index > 0 ? "/" : "") + segment;
    findBreadcrumbs(menuItems, pathAccumulator, breadcrumbTrail) ||
      findBreadcrumbs(settingMenuItems, pathAccumulator, breadcrumbTrail);
  });

  return breadcrumbTrail;
};
