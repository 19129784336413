export default function VariableColumns({ actionsColumn = null }) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "variableName",
      headerName: "Variable Name",
      width: 170,
    },
    {
      field: "variable",
      headerName: "Variable",
      width: 170,
    },
    {
      field: "scope",
      headerName: "Scope",
      width: 170,
    },
    {
      field: "description",
      headerName: "Description",
      width: 310,
    },
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
