export const fields = [
  {
    label: "Current Password",
    name: "oldPassword",
    type: "password",
    autoFocus: true,
    required: true,
  },
  {
    label: "New Password",
    name: "password",
    type: "password",
    required: true,
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    type: "password",
    required: true,
  },
];
