import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { CloseTwoTone } from "@mui/icons-material";
import {
  isDuplicateWithCaseSensitive,
  isValueContainsSplChars,
  orderErrorFocus,
} from "../../config/fieldConfig";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../../utils/CustomInputFields";

export default function VariableForm({
  screenName = "", // Name of the screen using the form
  open = false, // Is the form currently open?
  onClose = () => {}, // Function to call when the form is closed
  mode = "add", // Mode of the form: "add" or "edit"
  editItemId = null, // ID of the item being edited (if in edit mode)
  editFormData = {}, // Data of the item being edited
  fields = [], // Configuration for form fields
  variableData = [], // Additional data to populate the form fields
  onSubmitForm = () => {}, // Function to handle form submission
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const fieldRefs = useRef({});

  // Watch changes in the variableName field and update the variable field accordingly
  const variableName = watch("variableName");

  const [initialized, setInitialized] = useState(false); // To track form initialization

  useEffect(() => {
    if (!open) {
      // Reset form initialization state when the form is closed
      setInitialized(false);
    }
  }, [open]);

  useEffect(() => {
    try {
      setValue("variable", variableName || "");
    } catch (error) {
      console.error("Error setting variableName:", error);
    }
  }, [variableName, setValue]);

  // Reset the form when the `open` prop changes
  useEffect(() => {
    if (open) {
      try {
        reset(); // Only reset the form when the modal/dialog is opened
      } catch (error) {
        console.error("Error resetting form:", error);
      }
    }
  }, [open, reset]);

  // Handle loading form data in edit mode
  useEffect(() => {
    if (!initialized && open) {
      // Only run this effect if we're in edit mode, have a valid `editItemId`, and valid `editFormData`
      if (
        mode === "edit" &&
        editItemId !== null &&
        Object.keys(editFormData).length > 0
      ) {
        try {
          fields.forEach((field) => {
            if (editFormData[field.name] !== undefined) {
              // Check if the field name is 'variable'
              if (field.name === "variable") {
                // Remove the dollar sign before setting the value
                const valueWithoutDollar = editFormData[field.name].replace(
                  "$",
                  ""
                );
                setValue(field.name, valueWithoutDollar);
              } else {
                setValue(field.name, editFormData[field.name]);
              }
            } else {
              setValue(field.name, ""); // Set empty string for missing data
            }
          });
        } catch (error) {
          console.error("Error setting form values in edit mode:", error);
        }
      }
      setInitialized(true); // Set initialization to true after first reset
    }
  }, [editFormData, editItemId, fields, initialized, mode, open, setValue]);

  const onSubmit = (formData) => {
    try {
      // Validate that formData is not empty
      if (!formData || Object.keys(formData).length === 0) {
        throw new Error("Form data is empty");
      }

      // Append the `$` symbol to the variable field if it exists
      if (formData.variableName) {
        formData.variable = `$${formData.variableName}`;
      }

      if (mode === "add") {
        onSubmitForm(formData, "add");
      } else if (mode === "edit") {
        formData.id = editItemId;
        onSubmitForm(formData, "edit");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message); // Show toast with the error message
    } finally {
      onClose(); // Close the form after submission
    }
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {mode === "add" ? "Add" : "Edit"}
        &nbsp;{screenName}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            orderErrorFocus(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} key={index}>
                {field.type === "autocomplete" ? (
                  <CustomAutocomplete
                    field={field}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    fieldRefs={fieldRefs}
                  />
                ) : (
                  <CustomTextField
                    field={field}
                    register={register}
                    trigger={trigger}
                    errors={errors}
                    fieldRefs={fieldRefs}
                    InputLabelProps={
                      field.name === "variable"
                        ? { shrink: true, style: { pointerEvents: "none" } }
                        : {}
                    }
                    InputProps={{
                      sx: {
                        "& textarea": {
                          resize: "vertical", // Restrict resizing to vertical only
                        },
                      },
                      startAdornment:
                        field.name === "variable" ? (
                          <InputAdornment position="start">$</InputAdornment>
                        ) : null,
                      readOnly: field.name === "variable",
                      style: {
                        pointerEvents:
                          field.name === "variable" ? "none" : "auto",
                      }, // remove the hover effect
                    }}
                    validate={{
                      isDuplicate: (value) => {
                        const result =
                          field.name === "variableName"
                            ? isDuplicateWithCaseSensitive(
                                value,
                                variableData,
                                field.name,
                                editItemId,
                                field.label
                              )
                            : true;
                        return result === true ? true : result;
                      },
                      isValueContainsSplChars: (value) =>
                        isValueContainsSplChars(value),
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
