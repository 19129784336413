import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import JoditEditor from "jodit-react";
import {
  CalculateTwoTone,
  CloseTwoTone,
  EditTwoTone,
} from "@mui/icons-material";
import {
  adjustmentOptions,
  orderErrorFocus,
  transformHTML,
} from "../../config/fieldConfig";
import { ASSIGN_COMMISSION_SCREEN } from "../../config/constants";
import TransferList from "./transferList";
import AdjustmentComponent from "./adjustment";

export default function AssignCommissionForm({
  open = false,
  onClose = () => {},
  mode = "",
  editItemId = "",
  editFormData,
  fields = [],
  adjFields = [],
  beneficiariesOption = [],
  commissionData = [],
  terms = "",
  description = "",
  beneficiaries = "",
  setBeneficiaries = () => {},
  right = [],
  setRight = () => {},
  left = [],
  setLeft = () => {},
  commissionListOptions = [],
  extractedData = [],
  viewData = false,
  setViewData = () => {},
  handleCalculation = () => {},
  adjustmentComponents = [],
  setAdjustmentComponents = () => {},
  roleAccess = [],
  onSubmitForm = () => {},
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm();

  // Customization for jodit editor
  const config = useMemo(
    () => ({
      readonly: viewData,
      placeholder: "",
      removeButtons: ["fullsize"], // Remove the "fullsize" button
      limitChars: 1001,
      addNewLine: false,
      hidePoweredByJodit: true,
      events: {
        afterInit: (editor) => {
          const statusBar = editor.statusbar.container;
          const customMessage = document.createElement("div");
          customMessage.style.margin = "10px";
          customMessage.style.display = "inline";
          customMessage.textContent = "Content cannot exceed 1000 characters.";
          statusBar.appendChild(customMessage);
        },
      },
    }),
    [viewData]
  );

  useEffect(() => {
    reset();
    setBeneficiaries("");
    setRight([]);
    setSubmitted(false);
    setFormSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, open]);

  // State to track submission
  const [submitted, setSubmitted] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    try {
      if (mode === "edit" && editItemId !== null) {
        if (beneficiaries === "") {
          // Adjust the condition accordingly
          // Set form data with the provided editFormData

          fields.forEach((field) => {
            const filteredData =
              editFormData &&
              extractedData.filter(
                (item) => item.id === editFormData.beneficiaries
              );
            setBeneficiaries(filteredData[0]);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractedData]);

  useEffect(() => {
    try {
      if (mode === "edit" && editItemId !== null) {
        setValue("beneficiaries", beneficiaries.userName);

        if (terms || description) {
          const termsMatch = terms.match(/and\s+([^<]+)\s+regarding/i);
          const descriptionMatch = description.match(
            /and\s+([^<]+)\s+regarding/i
          );

          const wordsAfterAndBeforeTerms = termsMatch ? termsMatch[1] : null;
          const wordsAfterAndBeforeDescription = descriptionMatch
            ? descriptionMatch[1]
            : null;

          if (terms) {
            const formattedTerms = terms.replace(
              new RegExp(wordsAfterAndBeforeTerms, "g"),
              beneficiaries.userName
            );
            setValue("terms", formattedTerms);
          } else {
            const formattedTerms = editFormData.terms.replace(
              new RegExp(wordsAfterAndBeforeDescription, "g"),
              beneficiaries.userName
            );
            setValue("terms", formattedTerms);
          }
          if (description) {
            const formattedDesc = description.replace(
              new RegExp(wordsAfterAndBeforeDescription, "g"),
              beneficiaries.userName
            );
            setValue("description", formattedDesc);
          } else {
            const formattedDesc = editFormData.description.replace(
              new RegExp(wordsAfterAndBeforeTerms, "g"),
              beneficiaries.userName
            );
            setValue("description", formattedDesc);
          }
        } else {
          const match = editFormData.terms.match(/and\s+([^<]+)\s+regarding/i);

          const wordsAfterAndBeforeTerms = match ? match[1] : null;

          const formattedTerms = editFormData.terms.replace(
            new RegExp(wordsAfterAndBeforeTerms, "g"),
            beneficiaries.userName
          );

          setValue("terms", formattedTerms);
          const formattedDesc = editFormData.description.replace(
            new RegExp(wordsAfterAndBeforeTerms, "g"),
            beneficiaries.userName
          );

          setValue("description", formattedDesc);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries]);

  useEffect(() => {
    try {
      if (mode === "add") {
        setViewData();
        const match = terms.match(/and\s+([^<]+)\s+regarding/i);
        const wordsAfterAndBeforeTerms = match ? match[1] : null;

        if (!terms.includes("<")) {
          setValue("terms", `<p>${terms}</p>`);
          setValue("description", `<p>${description}</p>`);
        } else if (terms.includes("<") || wordsAfterAndBeforeTerms === "null") {
          setValue("terms", terms);
          setValue("description", description);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, terms, description]);

  const [childFormData, setChildFormData] = useState({});

  // set the adjustment value
  const handleChildData = (data) => {
    setChildFormData(data);
  };

  useEffect(() => {
    try {
      setValue("terms", terms);
      setValue("description", description);
      // If an editItemId is provided, fetch the data for editing
      if (mode === "edit" && editItemId !== null) {
        if (beneficiaries) {
          setBeneficiaries(beneficiaries);
          setValue("beneficiaries", beneficiaries.userName);
        }
      }
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormData, editItemId, mode, setValue]);

  // directly connect the children component for showing error message
  const adjustmentComponentRef = useRef(null);

  const handleFormSubmit = async (e) => {
    try {
      // Validate the child form adjustments
      const isChildFormValid =
        await adjustmentComponentRef.current.validateForm();

      // Filter out fields with undefined values
      const filteredErrors = Object.fromEntries(
        Object.entries(isChildFormValid).filter(
          ([key, value]) => value !== undefined
        )
      );
      setSubmitted(true);
      if (Object.keys(filteredErrors).length === 0) {
        // Only call handleSubmit if there are no validation errors
        handleSubmit(onSubmit)(e); // Call handleSubmit with onSubmit function
      } else {
        // Focus on the first field with an error
        const errorFields = document.querySelectorAll('[aria-invalid="true"]');
        if (errorFields.length > 0) {
          errorFields[0].focus();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (formData) => {
    try {
      // Validate the child form adjustments
      const isChildFormValid =
        await adjustmentComponentRef.current.validateForm();

      // Set the ID of the selected beneficiary as the value of the "beneficiaryName" field
      formData.beneficiaries = beneficiaries ? beneficiaries.id : null;

      // Set the ID of the selected beneficiary as the value of the "beneficiaryName" field
      formData.beneficiaryName = beneficiaries ? beneficiaries.userName : null;

      const selectedCommissionIds = right.map((name) => {
        const commission = commissionData.find(
          (config) => config.commissionName === name
        );
        return commission ? commission.id : null;
      });

      // Set the commission data from the right list
      formData.commission = selectedCommissionIds;

      // Set the adjustments
      formData.adjustments = adjustmentComponents.map((index) => ({
        [childFormData[`adjustmentLabel${index}`]]:
          childFormData[`adjustmentValue${index}`],
        sDate: childFormData[`sDate${index}`],
        eDate: childFormData[`eDate${index}`],
      }));

      // Remove unwanted fields from formData
      Object.keys(formData).forEach((key) => {
        if (
          key.startsWith("adjustmentLabel") ||
          key.startsWith("adjustmentValue") ||
          key.startsWith("sDate") ||
          key.startsWith("eDate")
        ) {
          delete formData[key];
        }
      });

      // Filter out fields with undefined values
      const filteredErrors = Object.fromEntries(
        Object.entries(isChildFormValid).filter(
          ([key, value]) => value !== undefined
        )
      );

      const filteredAdjustments = formData.adjustments.filter(
        (adjustment) =>
          adjustment.sDate &&
          adjustment.eDate &&
          Object.values(adjustment).some((value) => value !== "")
      );

      // add +
      const updatedAdjustments = filteredAdjustments.map((adjustment) => {
        let newAdjustment = { ...adjustment };

        Object.keys(newAdjustment).forEach((key) => {
          if (key.endsWith("Adjust")) {
            const value = newAdjustment[key];
            // if (!/[+\-*/]/.test(value)) {
            if (!/^[+-]/.test(value)) {
              newAdjustment[key] = "+" + value;
            }
          }
        });

        return newAdjustment;
      });

      // Set the filtered adjustments back to formData
      const formatFormData = {
        ...formData,
        adjustments: updatedAdjustments,
      };

      // format the style for editor
      const termsHTMLFormate = transformHTML(formatFormData.terms);
      const descriptionHTMLFormate = transformHTML(formatFormData.description);

      formatFormData.terms = termsHTMLFormate;
      formatFormData.description = descriptionHTMLFormate;

      if (
        Object.keys(filteredErrors).length === 0 &&
        selectedCommissionIds.length > 0
      ) {
        // Only call handleSubmit if there are no validation errors
        if (mode === "add") {
          onSubmitForm(formatFormData, "add");
        } else if (mode === "edit") {
          formatFormData.id = editItemId;
          onSubmitForm(formatFormData, "edit");
        }
        onClose(); // Close the dialog after form submission
      }
    } catch (error) {
      console.error(error);
    }
  };
  const editAccess = roleAccess.some(
    (item) => item.resource === ASSIGN_COMMISSION_SCREEN && item.writeAccess
  );
  const fieldRefs = useRef({});

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
        &nbsp;Assign Commission
        {viewData && editAccess ? (
          <IconButton
            aria-label="edit"
            style={{ position: "absolute", top: 10, right: 45 }}
            color="primary"
            title="Edit"
            onClick={() => setViewData(false)}
          >
            <EditTwoTone />
          </IconButton>
        ) : null}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleFormSubmit, (errors) =>
            orderErrorFocus(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={field.isHead ? 0 : 6} key={index}>
                {field.isHead ? ( // Check if the current field is a heading
                  <>
                    {field.isDivider && <Divider />}
                    {field.isSubtitle && (
                      <Typography variant="subtitle1" gutterBottom>
                        {field.subtitle}
                      </Typography>
                    )}
                    {field.isTerms && (
                      // Terms Editor
                      <Controller
                        name="terms"
                        control={control}
                        render={({ field }) => (
                          <JoditEditor
                            value={field.value}
                            onChange={(content) => {
                              // Remove HTML tags to get plain text length
                              const plainText = content.replace(/<[^>]*>/g, "");

                              // Remove spaces from plain text
                              const plainTextWithoutSpaces = plainText.replace(
                                /\s+/g,
                                ""
                              );

                              if (plainTextWithoutSpaces.length <= 1000) {
                                field.onChange(content); // Update content if within the limit
                                clearErrors("terms"); // Clear error if content length is valid
                              } else {
                                field.onChange(content); // Update content even if limit is exceeded
                                setError("terms", {
                                  type: "manual",
                                  message:
                                    "Content cannot exceed 1000 characters.",
                                });
                              }
                            }}
                            config={config}
                          />
                        )}
                      />
                    )}
                    {field.isDescriptions && (
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <JoditEditor
                            value={field.value}
                            onChange={(content) => {
                              // Remove HTML tags to get plain text length
                              const plainText = content.replace(/<[^>]*>/g, "");

                              // Remove spaces from plain text
                              const plainTextWithoutSpaces = plainText.replace(
                                /\s+/g,
                                ""
                              );

                              if (plainTextWithoutSpaces.length <= 1000) {
                                field.onChange(content); // Update content if within the limit
                                clearErrors("description"); // Clear error if content length is valid
                              } else {
                                field.onChange(content); // Update content even if limit is exceeded
                                setError("description", {
                                  type: "manual",
                                  message:
                                    "Content cannot exceed 1000 characters.",
                                });
                              }
                            }}
                            config={config}
                          />
                        )}
                      />
                    )}
                    {field.isAdjustment && (
                      <AdjustmentComponent
                        ref={adjustmentComponentRef}
                        adjFields={adjFields}
                        viewData={viewData}
                        editItemId={editItemId}
                        adjustmentOptions={adjustmentOptions}
                        setAdjustmentComponents={setAdjustmentComponents}
                        mode={mode}
                        open={open}
                        editFormData={editFormData}
                        extractedData={extractedData}
                        fields={fields}
                        adjustmentComponents={adjustmentComponents}
                        onChildDataChange={handleChildData}
                      />
                    )}
                    {field.isTransfer && (
                      <TransferList
                        editFormData={editFormData}
                        right={right}
                        setRight={setRight}
                        left={left}
                        setLeft={setLeft}
                        commissionListOptions={commissionListOptions}
                        viewData={viewData}
                        submitted={submitted} // Pass submission state to the children component
                        error={errors}
                        formSubmit={formSubmit}
                      />
                    )}
                  </>
                ) : field.type === "autocomplete" ? (
                  <Autocomplete
                    options={field.options} // Provide beneficiariesOption as options
                    getOptionLabel={(option) => option.idWithName} // Display idWithName
                    disableClearable //remove clear button
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    } // Custom equality check
                    value={beneficiaries ? beneficiaries : null} // Set the value directly instead of using defaultValue
                    onChange={(event, value) => {
                      setBeneficiaries(value); // Set selected beneficiary
                      setValue(field.name, value ? value.id : null);
                    }} // Set id as the value
                    style={{
                      pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        autoFocus={field.autoFocus}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            pointerEvents: "none",
                          },
                        }}
                        {...register(field.name, {
                          required:
                            field.required && mode !== "edit"
                              ? `${field.label} is required`
                              : false,
                        })}
                        error={!!errors[field.name] && !params.inputProps.value} // Check if field is empty
                        helperText={
                          errors[field.name]?.message &&
                          (!params.inputProps.value
                            ? errors[field.name]?.message
                            : "")
                        } // Show helper text only when there's an error and field is empty
                        FormHelperTextProps={{ sx: { mb: -3 } }}
                        inputRef={(el) => {
                          if (el) {
                            fieldRefs.current[field.name] = el;
                          }
                        }} // Set ref
                      />
                    )}
                  />
                ) : field.name === "calculate" ? (
                  viewData || mode === "add" ? null : (
                    <DialogActions style={{ padding: 3 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<CalculateTwoTone />}
                        onClick={handleCalculation}
                      >
                        {field.label}
                      </Button>
                    </DialogActions>
                  )
                ) : null}
              </Grid>
            ))}
          </Grid>
          {viewData && editItemId ? null : (
            <DialogActions sx={{ p: 0, mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  setFormSubmit(true);
                }}
              >
                {mode === "add" ? "Submit" : "Update"}
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
