import { useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { addSerialNumber } from "../../config/fieldConfig";
import {
  PAY_FREQUENCY_DISPLAY_NAME,
  PAY_FREQUENCY_FORM_DISPLAY_NAME,
  PAY_FREQUENCY_SCREEN,
} from "../../config/constants";
import { getAccessLevels } from "../../../utils/accessLevels";
import { getActionsColumn } from "../../../utils/actionColumn";
import useSubmitForm from "../../../utils/useSubmitForm";
import PayFrequencyFields from "./fields";
import PayFrequencyColumns from "./columns";

export const PayFrequencyService = () => {
  // State to store the table data for payment frequencies
  const [data, setTableData] = useState([]);
  // State to control the visibility of the edit form
  const [editFormOpen, setEditFormOpen] = useState(false);
  // State to store the ID of the item currently being edited
  const [editItemId, setEditItemId] = useState(null);
  // State to hold the data for the edit form
  const [editFormData, setEditFormData] = useState({});
  // State to store the frequency name for validation or checks
  const [frequencyNameCheck, setFrequencyNameCheck] = useState([]);
  // State to store frequency for validation or checks
  const [frequencyCheck, setFrequencyCheck] = useState([]);
  // State to control the visibility of data for viewing purposes
  const [viewData, setViewData] = useState(false);
  // State to control the visibility of calculated data for viewing purposes
  const [calculateViewData, setCalculateViewData] = useState(true);
  // State to store frequency data associated with specific IDs
  const [frequencyWithId, setFrequencyWithId] = useState([]);
  // State to manage the loading state of the data
  const [loading, setLoading] = useState(true);
  // State to control the visibility of the add form
  const [open, setOpen] = useState(false);

  // Constants for screen identifier
  const screenName = PAY_FREQUENCY_SCREEN || "";
  // Constants for display screen name UI identifier
  const displayName = PAY_FREQUENCY_DISPLAY_NAME || "";
  // Constants for display screen name form identifier
  const displayFormName = PAY_FREQUENCY_FORM_DISPLAY_NAME || "";

  // Function to open the add form dialog
  const addFormOpen = () => {
    try {
      setOpen(true); // Set open state to true to display add form
      formApiFetch(); // Fetch necessary data for the form
    } catch (error) {
      // Log any errors that occur while opening the form
      console.error("Error opening add form:", error);
    }
  };

  // Function to close the add form dialog
  const addFormClose = () => {
    try {
      setOpen(false); // Set open state to false to hide add form
    } catch (error) {
      console.error("Error closing add form:", error);
    }
  };

  const fetchData = async () => {
    try {
      // Get the JWT token for authentication
      const token = JWT_TOKEN();

      // If no token is found, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Fetch payment frequencies using the token
      const tableResponse = await ApiService.getPaymentFrequencies(token);

      // Check if the response is successful (status 200) and data is a valid array
      const tableData =
        tableResponse?.status === 200 && Array.isArray(tableResponse?.data)
          ? tableResponse?.data
          : [];

      // Set the fetched data into the table
      setTableData(tableData ?? []);

      // Map frequency names and IDs from the fetched data
      const frequencyNameId = tableData?.map(
        (paymentFrequency) => paymentFrequency?.frequencyName
      );
      const frequencyId = tableData?.map(
        (paymentFrequency) => paymentFrequency?.frequency
      );

      // Set frequency IDs and names for later use
      setFrequencyCheck(frequencyId ?? []);
      setFrequencyNameCheck(frequencyNameId ?? []);
    } catch (error) {
      // Log any errors that occur during the process
      console.error("Error fetching data:", error);
      setTableData([]); // Clear the table data if there's an error
      setFrequencyCheck([]);
      setFrequencyNameCheck([]);
    } finally {
      // Stop the loading state regardless of success or error
      setLoading(false);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const formApiFetch = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Fetch frequency data
      const frequencyResponse = await ApiService.getFrequencies(token);

      // Check if the response status is 200 and data is an array
      const frequencyData =
        frequencyResponse?.status === 200 &&
        Array.isArray(frequencyResponse?.data)
          ? frequencyResponse?.data
          : [];

      // Set frequency data if available
      setFrequencyWithId(frequencyData ?? []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setFrequencyWithId([]); // Clear the frequency data if there's an error
    } finally {
      setLoading(false);
    }
  };

  // Function to handle edit button click
  const handleEdit = (id, viewData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }

      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }

      // Find the row data based on the provided ID
      const rowData = data?.find((row) => row?.id === id);

      // Check if row data is found
      if (!rowData) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      // Ensure that paymentPeriod exists and is an array
      if (
        !Array.isArray(rowData?.paymentPeriod) ||
        rowData?.paymentPeriod?.length === 0
      ) {
        throw new Error(`No payment period data available for the ID: ${id}`);
      }

      // Check if any 'calculated' field is true
      const hasTrueCalculated = rowData?.paymentPeriod.some(
        (period) => period?.calculated
      );

      // Set the edit form state and populate with row data
      setEditItemId(id);
      setEditFormOpen(true);
      setEditFormData(rowData);
      setViewData(viewData);
      setCalculateViewData(hasTrueCalculated);
      formApiFetch();
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(`Error editing item: ${error?.message}`); // Display the exact error message in a toast
    }
  };

  // Get fields from fields, default to empty array if not an array
  const fields = Array.isArray(PayFrequencyFields())
    ? PayFrequencyFields()
    : [];

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData,
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Create column configuration by combining actual columns with action columns
  const columns = Array.isArray(
    PayFrequencyColumns({ actionsColumn, handleEdit })
  )
    ? PayFrequencyColumns({ actionsColumn, handleEdit })
    : []; // Default to empty array if not an array

  // Add serial numbers to data rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : []; // Default to empty array if not an array

  // Get access levels for the specified resource using a utility function
  const { writeAccess } = getAccessLevels(screenName);

  return {
    screenName, // Name of the current screen or component
    displayName, // Name to be displayed for the user context
    displayFormName, // Name of the form to be displayed
    rows, // Data rows for the table or grid
    columns, // Column configuration for the table or grid
    fields, // Fields or data structure used in forms
    open, // State to control visibility of a specific component or form
    editFormOpen, // Boolean state to control if the edit form is open
    editItemId, // ID of the item currently being edited
    editFormData, // Data to populate the edit form
    setEditFormOpen, // Function to set the edit form open state
    addFormOpen, // Boolean state to control if the add form is open
    addFormClose, // Function to close the add form
    frequencyNameCheck, // State to validate or check frequency name
    frequencyCheck, // State to validate or check frequency
    viewData, // State to control the visibility of view data
    setViewData, // Function to set the view data state
    calculateViewData, // State to control the visibility of calculated view data
    setCalculateViewData, // Function to set the calculated view data state
    frequencyWithId, // State to store frequencies associated with specific IDs
    loading, // Boolean state to indicate loading status
    submitLoading, // Boolean state to indicate loading status during form submission
    onSubmitForm, // Function to handle form submission
    writeAccess, // State or flag to indicate if the user has write access
  };
};
