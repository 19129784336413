import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CfTransBlueLogo, MFAImage } from "../../config/imageConfig";
import { onMfaVerify, onResendMfaCode } from "../../service/outerPagesService";
import { SIGN_IN_VALUES } from "../../config/sessionStorage";

export default function MfaVerify({
  setIsAuthenticated,
  setRes,
  setUserRoleAccess,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  const [timer, setTimer] = useState(120); // Set timer for 2 minutes (120 seconds)
  const [isSignInValid, setIsSignInValid] = useState(false); // To track if signInVal is valid

  useEffect(() => {
    const signInVal = SIGN_IN_VALUES();

    // Check if signInVal is null, undefined, or an empty object
    if (signInVal && Object.keys(signInVal).length > 0) {
      setEmail(signInVal?.email || "");
      setValues(signInVal || {});
      setIsSignInValid(true); // Set to true if signInVal is valid
    } else {
      setIsAuthenticated(false); // No session data, log out
      setRes(false);
      sessionStorage.clear(); // Clear session storage for logout
      setIsSignInValid(false); // Set to false if signInVal is invalid
      navigate("/sign_in");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle countdown timer if signInVal is valid
  useEffect(() => {
    if (isSignInValid) {
      let interval;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000); // Decrease the timer by 1 second
      } else {
        setResendVisible(true); // Show resend link after timer ends
        clearInterval(interval); // Clear interval when timer reaches 0
      }

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [timer, isSignInValid]);

  // Convert seconds to MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const { mfaCode } = data;

    try {
      await onMfaVerify(
        email,
        mfaCode,
        setUserRoleAccess,
        setIsAuthenticated,
        setRes,
        navigate,
        setLoading
      );
    } catch (error) {
      console.error("Error during MFA verification:", error);
    } finally {
      reset(); // Reset the form after submission
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);
    setResendVisible(false); // Hide resend link after resending
    setTimer(120); // Set reset timer for 2 minutes (120 seconds)
    try {
      await onResendMfaCode(values);
    } catch (error) {
      console.error("Failed to resend MFA code:", error);
    } finally {
      setResendLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setValue("mfaCode", value);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      {/* Left Grid */}
      <Grid
        item
        xs={false}
        md={7}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "white",
          pb: { xs: 5 },
          p: 2,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Link href={"/"}>
            <img
              src={CfTransBlueLogo}
              alt="compFact logo"
              style={{ width: "100%", maxWidth: 150 }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${MFAImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: 400,
            width: "100%",
            maxWidth: 600,
            mb: 4,
          }}
        />
        <Typography
          variant="h4"
          color={"primary"}
          gutterBottom
          sx={{
            textAlign: "center",
            mb: 2,
          }}
        >
          Keep Your Account Secure
        </Typography>
      </Grid>

      {/* Right Grid*/}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%", // Ensure the form container takes full width
            maxWidth: "400px", // Optional: limit the maximum width of the form
            p: 2,
          }}
        >
          <Typography
            variant="h4"
            color={"primary"}
            gutterBottom
            textAlign="center"
          >
            Verify via email address
          </Typography>
          <Typography variant="body1" gutterBottom textAlign="center">
            A one-time password will be sent to your Email Id
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%" }}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              autoFocus
              label="Enter OTP"
              placeholder="xxxxxx"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 6,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errors.mfaCode}
              helperText={
                errors.mfaCode ? "Please enter a valid 6-digit code." : ""
              }
              FormHelperTextProps={{ sx: { mb: -3 } }}
              {...register("mfaCode", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please enter a valid 6-digit code.",
                },
                onChange: handleChange,
              })}
            />

            {/* Only show the timer and resend link if signInVal is valid */}
            {isSignInValid && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {resendVisible ? (
                  <Link
                    variant="body2"
                    onClick={handleResendCode}
                    sx={{ cursor: "pointer" }}
                  >
                    {resendLoading ? "Resending..." : "Resend code"}
                  </Link>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Resend OTP in
                    <Typography
                      component="span"
                      sx={{ color: "green" }} // Set the timer color to green
                    >
                      &nbsp;{formatTime(timer)}
                    </Typography>
                  </Typography>
                )}
              </Box>
            )}

            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              loading={loading}
              loadingPosition="start"
              startIcon={<span />} // Placeholder or actual icon
              style={{
                backgroundColor: loading ? "#448aff" : undefined,
                color: loading ? "#fff" : undefined,
              }}
              sx={{ mt: 1 }}
            >
              {!loading ? "Verify" : "Loading..."}
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
