import ApiService from "../../../apis/ApiService";

export const getApiCalls = ({
  token = null,
  selectedDropdown = "",
  order = "",
  count = 0,
  customCount = 0,
  startDate = "",
  endDate = "",
  salesRe = null,
  orgId = null,
}) => {
  if (selectedDropdown === "My Data" || !selectedDropdown) {
    return [
      ApiService.getIndividualTotalCommission(
        token,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getIndividualSalesByProduct(
        token,
        order,
        customCount,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getIndividualSalesByCustomer(
        token,
        order,
        customCount,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getCreditTransForUser(
        token,
        order,
        count,
        startDate,
        endDate,
        null,
        null
      ),
    ];
  } else if (selectedDropdown === "My Reportees") {
    return [
      ApiService.getReporteesTotalCommission(
        token,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getReporteesSalesByProduct(
        token,
        order,
        customCount,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getReporteesSalesByCustomer(
        token,
        order,
        customCount,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getCreditTransForReportees(
        token,
        order,
        count,
        startDate,
        endDate,
        null,
        null
      ),
    ];
  } else if (selectedDropdown === "ApplyClick") {
    return [
      ApiService.getIndividualTotalCommission(
        null,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getIndividualSalesByProduct(
        null,
        order,
        customCount,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getIndividualSalesByCustomer(
        null,
        order,
        customCount,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getCreditTransForUser(
        null,
        order,
        count,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
    ];
  }
  return [];
};

export const getIndividualDashApiCalls = ({
  token = null,
  selectedDropdown = "",
  order = "",
  count = 0,
  tableCount = 0,
  startDate = "",
  endDate = "",
  salesRe = null,
  orgId = null,
}) => {
  if (selectedDropdown === "My Data" || !selectedDropdown) {
    return [
      ApiService.getIndividualTotalCommission(
        token,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getIndividualSalesByProduct(
        token,
        order,
        0,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getIndividualSalesByCustomer(
        token,
        order,
        count,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getIndividualUnProcessedTrans(
        token,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getCreditTransForUser(
        token,
        order,
        tableCount,
        startDate,
        endDate,
        null,
        null
      ),
    ];
  } else if (selectedDropdown === "My Reportees") {
    return [
      ApiService.getReporteesTotalCommission(
        token,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getReporteesSalesByProduct(
        token,
        order,
        0,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getReporteesSalesByCustomer(
        token,
        order,
        count,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getReporteesUnProcessedTrans(
        token,
        startDate,
        endDate,
        null,
        null
      ),
      ApiService.getCreditTransForReportees(
        token,
        order,
        tableCount,
        startDate,
        endDate,
        null,
        null
      ),
    ];
  } else if (selectedDropdown === "ApplyClick") {
    return [
      ApiService.getIndividualTotalCommission(
        null,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getIndividualSalesByProduct(
        null,
        order,
        0,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getIndividualSalesByCustomer(
        null,
        order,
        count,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getIndividualUnProcessedTrans(
        null,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
      ApiService.getCreditTransForUser(
        null,
        order,
        tableCount,
        startDate,
        endDate,
        salesRe,
        orgId
      ),
    ];
  }
  return [];
};
