import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  CheckCircleTwoTone,
  InfoTwoTone,
  NewReleasesTwoTone,
} from "@mui/icons-material";
import { CfTransBlueLogo } from "../config/imageConfig";

export default function MessageDisplay({
  icon = "success",
  msg1 = "",
  msg2 = "",
  msg1Link = "",
  msg2Link = "",
  msgBreak = "",
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: { xs: "0 10px", sm: "0 20px" },
        my: { xs: 2, md: 0 },
      }}
    >
      <Container maxWidth="md">
        {/* Logo Section */}
        <Box
          component="img"
          src={CfTransBlueLogo}
          alt="Logo"
          sx={{
            width: { xs: 100, sm: 150 },
            mb: 2,
            mx: "auto",
          }}
        />

        {/* Success Message Section */}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            {icon === "success" ? (
              <CheckCircleTwoTone
                color="success"
                sx={{ fontSize: { xs: 50, sm: 70, md: 100 } }}
              />
            ) : icon === "info" ? (
              <InfoTwoTone
                color="info"
                sx={{ fontSize: { xs: 50, sm: 70, md: 100 } }}
              />
            ) : (
              <NewReleasesTwoTone
                color="warning"
                sx={{ fontSize: { xs: 50, sm: 70, md: 100 } }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {msg1 && (
              <Typography variant="h4" sx={{ mb: 1 }}>
                {msg1}
                {msg1Link && (
                  <Link
                    to="/sign_in"
                    className="hover:underline text-violet-500"
                  >
                    <Typography variant="h5">{msg1Link}</Typography>
                  </Link>
                )}
              </Typography>
            )}
            {msg2 && (
              <Typography variant="h6">
                {msg2}
                {msg2Link && (
                  <Link
                    to="/sign_in"
                    className="hover:underline text-violet-500"
                  >
                    &nbsp;{msg2Link}
                  </Link>
                )}
                &nbsp;{msgBreak}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
