import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import error403 from "../../assets/images/errors/Error-403.svg";

export default function Error403() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img
                src={error403}
                alt="Forbidden Error"
                width={500}
                height={250}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h1">403</Typography>
              <Typography variant="h4">Forbidden Error!</Typography>
              <Typography variant="h6">
                You do not have permission to view this resource.
              </Typography>
              <Button
                onClick={goBack}
                variant="contained"
                style={{ marginTop: "1rem" }}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
