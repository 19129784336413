import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
} from "@mui/material";
import { CloseTwoTone } from "@mui/icons-material";

export default function CustomFieldDialog({ open, onClose, onAddCustomField }) {
  // Initial state object with default values
  const initialState = {
    labelName: "", // Set a valid default value like empty string
    dataType: null, // Set a valid default value like null
    isMandatory: false,
  };

  // State for customField
  const [customField, setCustomField] = useState(initialState);
  const [isFieldsValid, setIsFieldsValid] = useState(false);
  const [labelError, setLabelError] = useState("");

  const validateFields = ({ labelName, dataType }) => {
    let error = "";

    // Check if labelName is null, undefined, or empty
    if (!labelName) {
      error = "This field is required";
    }
    // Check if the value is empty or contains only whitespace
    else if (!labelName.trim()) {
      error = "This field is required";
    }
    // Check if labelName starts with a number
    else if (/^\d/.test(labelName)) {
      error = "Label Name cannot start with a number";
    }
    // Check if labelName contains only digits
    else if (/^\d+(\s\d+)*$/.test(labelName)) {
      error = "Label Name cannot be a number";
    }
    // Check for special characters other than - and _
    else if (/[^A-Za-z0-9\s_-]/.test(labelName)) {
      error = "Special characters are not allowed except - _";
    }
    // Check if labelName has leading or trailing spaces
    else if (labelName !== labelName.trim()) {
      error = "Value cannot have leading or trailing spaces";
    }

    // Determine if the fields are valid
    const isValid = !error && !!labelName && !!dataType;

    // Set the error message if any validation failed
    setLabelError(error);
    // Set the field validity status
    setIsFieldsValid(isValid);
  };

  const handleAddCustomField = () => {
    onAddCustomField(customField);
    handleClose();
  };

  const handleClose = () => {
    setCustomField(initialState);
    setIsFieldsValid(false);
    setLabelError("");
    onClose();
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <DialogTitle>
          Add Custom Field
          <IconButton
            aria-label="close"
            style={{ position: "absolute", top: "8px", right: "8px" }}
            onClick={handleClose}
          >
            <CloseTwoTone />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: 300 }}>
          <TextField
            label="Label Name"
            size="small"
            value={customField.labelName}
            onChange={(e) => {
              const labelName = e.target.value;
              setCustomField({ ...customField, labelName });
              validateFields({ ...customField, labelName });
            }}
            fullWidth
            margin="normal"
            error={!!labelError}
            helperText={labelError}
            FormHelperTextProps={{ sx: { mb: -3 } }}
          />
          <Autocomplete
            options={["Text", "Number", "Email", "Date"]}
            onChange={(e, newValue) => {
              if (newValue) {
                setCustomField({
                  ...customField,
                  dataType: newValue.toLowerCase(),
                });
                validateFields({
                  ...customField,
                  dataType: newValue.toLowerCase(),
                });
              } else {
                // Handle case where newValue is null (e.g., clear or no selection)
                setCustomField({ ...customField, dataType: "" }); // or handle null case as needed
                validateFields({ ...customField, dataType: "" }); // or handle null case as needed
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Data Type"
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                FormHelperTextProps={{ sx: { mb: -3 } }}
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={customField.isMandatory}
                onChange={(e) =>
                  setCustomField({
                    ...customField,
                    isMandatory: e.target.checked,
                  })
                }
                name="isMandatory"
              />
            }
            label="Is Mandatory"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCustomField}
            disabled={!isFieldsValid} // Disable the button if fields are not valid
            sx={{ mt: 1 }}
            fullWidth
          >
            Add Field
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
