import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  CancelTwoTone,
  CloseTwoTone,
  EditTwoTone,
  SaveTwoTone,
} from "@mui/icons-material";
import {
  isDuplicate,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  orderErrorFocus,
} from "../../config/fieldConfig";
import CustomFieldDialog from "../../common/customFieldDialog";
import { processFormData } from "../../../utils/Master/formDataProcessor";
import { handleSaveType } from "../../../utils/Master/handleSaveTypeHelper";
import { prepareTransformedObjects } from "../../../utils/Master/transformObjectsHelper";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../../utils/CustomInputFields";

export default function ProductForm({
  screenName = "",
  mode = "add",
  open = false,
  onClose = () => {},
  fields = [],
  writeAccess = false,
  editItemId = null,
  editFormData = {},
  viewData = false,
  setViewData = () => {},
  onSubmitForm = () => {},
  dynamicFormFields = [],
  handleAddCustomField = () => {},
  openCustomFieldDialog = false,
  setOpenCustomFieldDialog = () => {},
  isEData = false,
  resObject = {},
  response = {},
  addProductType = () => {},
  productTypeOptions = [],
  productTypeOptionsWithId = [],
  productIdCheck = [],
  productNameCheck = [],
  setNewFields = () => {},
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    setError,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm();

  // Default to false for add mode
  const [isActiveChecked, setIsActiveChecked] = useState(false);
  // Flag to track if adding a new product type
  const [isAddingProductType, setIsAddingProductType] = useState(false);
  // State for the new product type being added
  const [newProductType, setNewProductType] = useState("");

  // Ref for dynamically accessing form fields
  const fieldRefs = useRef({});

  // Effect to reset form and manage editing form data
  useEffect(() => {
    reset(); // Reset form on component load or when 'mode' changes
    setIsActiveChecked(false); // Reset active checkbox
    setIsAddingProductType(false); // Reset product type adding state
    setNewFields([]); // Reset the new fields

    // If in 'add' mode, clear view data
    if (mode === "add") {
      setViewData();
    }

    // If in 'edit' mode and editItemId is provided, load the form with existing data
    if (mode === "edit" && editItemId !== null && editFormData) {
      try {
        // Populate form fields with existing editFormData
        fields.forEach((field) => {
          setValue(field.name, editFormData[field.name]);
        });

        // Set the active checkbox based on the existing 'active' field
        setIsActiveChecked(editFormData.active === true);

        // Populate dynamic fields if they exist in customFields
        dynamicFormFields.forEach((field) => {
          const nestedField = editFormData.customFields[field.name];
          setValue(field.name, nestedField);

          // If a nested field is an object, set its value
          if (nestedField && nestedField.hasOwnProperty(field.name)) {
            setValue(field.name, nestedField[field.name]);
          }
        });
      } catch (error) {
        console.error("Error setting form values:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicFormFields, editItemId, mode, reset, setValue, open]);

  // Effect to trigger validation and set focus on first error when editing existing data
  useEffect(() => {
    if (editItemId && isEData) {
      trigger(["productId", "productName"]).then(() => {
        // Get list of fields with errors
        const errorFields = Object.keys(errors);
        if (errorFields.length > 0) {
          // Focus on the first field with an error
          setFocus(errorFields[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, setFocus, errors]);

  // Function to handle the addition of a new product type
  const handleAddProductType = () => {
    setIsAddingProductType(true); // Set flag to indicate product type is being added
    setNewProductType(""); // Clear input for new product type
  };

  // Function to cancel adding a new product type
  const handleCancelAdd = () => {
    setIsAddingProductType(false); // Reset product type adding state
    setNewProductType(""); // Clear input for new product type
    clearErrors("addNewProductType"); // Clear any errors related to adding product type
  };

  // Function to handle saving a new product type
  const handleSaveProductType = async (event) => {
    try {
      // Save the new product type by calling the API or handler function
      await handleSaveType({
        event,
        typeOptions: productTypeOptions,
        newType: newProductType,
        screenName,
        triggerValidation: trigger, // Validate form before saving
        setError,
        addType: addProductType, // API function for adding types
        setValue,
        clearErrors,
        setNewType: setNewProductType,
        setIsAddingType: setIsAddingProductType,
        typeName: "addNewProductType", // Key for tracking this product type addition
      });
    } catch (error) {
      console.error("Error saving the product type:", error);
    }
  };

  // Function to handle form submission
  const onSubmit = async (formData) => {
    try {
      // Process the formData into structured data for submission
      const { mainFormData, customFields } = processFormData(
        formData,
        fields,
        dynamicFormFields,
        productTypeOptionsWithId,
        "productType",
        "productTypeId"
      );

      // Prepare transformed data and objects using the helper function
      const { transformedData, transformedObjects } = prepareTransformedObjects(
        mainFormData,
        customFields,
        response,
        resObject
      );

      // Check if product type addition is completed before submission
      if (!isAddingProductType) {
        // Validate that transformedData is not empty
        if (!transformedData || Object.keys(transformedData).length === 0) {
          throw new Error("Transformed data is empty");
        }

        // Validate that transformedObjects is not empty
        if (
          !transformedObjects ||
          Object.keys(transformedObjects).length === 0
        ) {
          throw new Error("TransformedObjects is empty");
        }

        // Handle form submission for 'add' or 'edit' modes
        if (mode === "add") {
          onSubmitForm(transformedData, "add", transformedObjects);
        } else if (mode === "edit") {
          transformedData.id = editItemId;
          onSubmitForm(transformedData, "edit", transformedObjects);
        }
        onClose(); // Close the form after submission
      } else {
        // Set error if trying to submit while adding a new product type
        setError("addNewProductType", {
          type: "duplicate",
          message: "Please save or close the New Product Type",
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
        &nbsp;{screenName}
        {viewData && writeAccess ? (
          <IconButton
            aria-label="edit"
            style={{ position: "absolute", top: 10, right: 45 }}
            color="primary"
            title="Edit"
            onClick={() => setViewData(false)}
          >
            <EditTwoTone />
          </IconButton>
        ) : null}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            orderErrorFocus(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index}>
                {field.name === "active" ? (
                  <FormControl
                    error={!!errors[field.name]}
                    component="fieldset"
                    variant="outlined"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={isActiveChecked} // Use the state variable here
                          onChange={(e) => setIsActiveChecked(e.target.checked)} // Update the state when the checkbox changes
                          disabled={viewData} // Disable the checkbox based on viewData condition
                        />
                      }
                      label={field.label}
                      sx={{ mt: 1 }} // UI display center
                      required={field.required}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                    />
                    <FormHelperText>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                ) : field.name === "productType" ? (
                  isAddingProductType ? (
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        <TextField
                          label="Add New Product Type"
                          name="addNewProductType"
                          variant="outlined"
                          size="small"
                          margin="dense"
                          autoFocus
                          fullWidth
                          required
                          value={newProductType}
                          {...register("addNewProductType", {
                            required: "Add New Product Type is required",
                            validate: {
                              isEmpty: (value) => isEmpty(value),
                              isValueContainsSplChars: (value) =>
                                isValueContainsSplChars(value),
                              isValueStartsWithNumber: (value) =>
                                isValueStartsWithNumber(value, field.label),
                              isDuplicate: (value) => {
                                if (field.name === "productType") {
                                  return isDuplicate(
                                    productTypeOptions,
                                    field.label,
                                    value,
                                    mode,
                                    [],
                                    field.name,
                                    isEData
                                  );
                                } else {
                                  return true;
                                }
                              },
                            },
                          })}
                          error={!!errors.addNewProductType}
                          helperText={errors.addNewProductType?.message}
                          FormHelperTextProps={{ sx: { mb: -3 } }}
                          onChange={(e) => {
                            setNewProductType(e.target.value);
                            setValue("addNewProductType", e.target.value);
                            setError("addNewProductType");
                            trigger("addNewProductType");
                          }}
                          onBlur={() => {
                            trigger("addNewProductType");
                          }}
                          inputRef={(el) => {
                            if (el) {
                              fieldRefs.current[field.name] = el;
                            }
                          }} // Set ref
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <IconButton
                          aria-label="save"
                          color="success"
                          title="Save"
                          onClick={handleSaveProductType}
                        >
                          <SaveTwoTone />
                        </IconButton>
                        <IconButton
                          aria-label="cancel"
                          color="primary"
                          title="Cancel"
                          onClick={handleCancelAdd}
                        >
                          <CancelTwoTone />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={10}>
                        <CustomAutocomplete
                          field={field}
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          watch={watch}
                          errors={errors}
                          fieldRefs={fieldRefs}
                          style={{
                            pointerEvents:
                              viewData && editItemId ? "none" : "auto",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {!viewData && writeAccess ? (
                          <IconButton
                            aria-label="add"
                            color="primary"
                            title="Add Field"
                            onClick={handleAddProductType}
                          >
                            <AddCircleOutlineTwoTone />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  )
                ) : (
                  <CustomTextField
                    field={field}
                    register={register}
                    trigger={trigger}
                    errors={errors}
                    fieldRefs={fieldRefs}
                    autoFocus={editItemId && isEData ? true : field.autoFocus}
                    disabled={
                      editItemId && !isEData && field.name === "productId"
                    }
                    InputProps={{
                      sx: {
                        "& textarea": {
                          resize: "vertical", // Restrict resizing to vertical only
                        },
                      },
                      style: {
                        pointerEvents: viewData && editItemId ? "none" : "auto",
                      }, // remove the hover effect
                      inputProps: {
                        max: field.type === "date" ? "9999-12-31" : undefined,
                        style: {
                          textTransform:
                            field.type === "date" ? "uppercase" : "none",
                        },
                        ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                      },
                      pattern: field.type === "number" ? "\\d*" : undefined,
                    }}
                    InputLabelProps={{
                      shrink: field.type === "date" ? true : field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    validate={{
                      isValueContainsSplChars: (value) =>
                        field.type === "text" &&
                        field.type !== "email" &&
                        field.type !== "number"
                          ? isValueContainsSplChars(value)
                          : true,
                      isDuplicate: (value) => {
                        if (field.name === "productId") {
                          return isDuplicate(
                            productIdCheck,
                            field.label,
                            value,
                            mode,
                            editFormData,
                            field.name,
                            isEData
                          );
                        } else if (field.name === "productName") {
                          return isDuplicate(
                            productNameCheck,
                            field.label,
                            value,
                            mode,
                            editFormData,
                            field.name,
                            isEData
                          );
                        } else {
                          return true;
                        }
                      },
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          {viewData && editItemId ? null : (
            <DialogActions sx={{ p: 0, mt: 2 }}>
              <Button
                onClick={() => setOpenCustomFieldDialog(true)}
                variant="outlined"
                color="primary"
              >
                Add Custom Field
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {mode === "add" ? "Submit" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          )}
        </Box>
      </DialogContent>

      {/* Custom Field Dialog */}
      <CustomFieldDialog
        open={openCustomFieldDialog}
        onClose={() => setOpenCustomFieldDialog(false)}
        onAddCustomField={handleAddCustomField}
      />
    </Dialog>
  );
}
