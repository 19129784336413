import FullScreenLoader from "../../common/LoadingScreen";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import VariableForm from "./form";
import { VariableService } from "./service";

export default function Variables() {
  const {
    screenName = "",
    displayName = "",
    fields = [],
    rows = [],
    columns = [],
    data = [],
    open = false,
    editFormOpen = false,
    editItemId = null,
    editFormData = {},
    setEditFormOpen = () => {},
    addFormOpen = () => {},
    addFormClose = () => {},
    loading = false,
    submitLoading = false,
    onSubmitForm = () => {},
  } = VariableService() || {};

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader loading={submitLoading} />

      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={loading}
        toolbarProps={{ displayName, screenName, addFormOpen }}
      />

      {/* Handle both add and edit form display */}
      <VariableForm
        screenName={screenName} // Pass screen name to the form
        open={editFormOpen || open} // Open form for adding or editing
        onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose} // Handle form close action
        mode={editFormOpen ? "edit" : "add"} // Set form mode to edit or add
        editItemId={editFormOpen ? editItemId : undefined} // ID for the item being edited
        editFormData={editFormOpen ? editFormData : undefined} // Data for the item being edited
        fields={fields ?? []} // Form fields configuration
        variableData={data ?? []} // Additional data for form fields
        onSubmitForm={onSubmitForm} // Function to handle form submission
      />
    </>
  );
}
