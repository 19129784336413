export const handleSaveType = async ({
  event = null,
  typeOptions = [],
  newType = "",
  screenName = "",
  triggerValidation = async () => true, // Default to a function that always returns true
  setError = () => {}, // Default to a no-op function
  addType = async () => {}, // Default to a no-op async function
  setValue = () => {}, // Default to a no-op function
  clearErrors = () => {}, // Default to a no-op function
  setNewType = () => {}, // Default to a no-op function
  setIsAddingType = () => {}, // Default to a no-op function
  typeName = "",
}) => {
  try {
    event.preventDefault(); // Prevent form submission

    // Trigger validation for the field
    const isValid = await triggerValidation(typeName);

    if (!isValid) {
      return; // Exit if validation fails
    }

    // Convert all types to lowercase for case-insensitive comparison
    const lowerCaseOptions = typeOptions.map((type) => type.toLowerCase());

    const lowerCaseNewType = newType.toLowerCase();

    // Check if newType already exists (case insensitive)
    if (newType && lowerCaseOptions.includes(lowerCaseNewType)) {
      setError(typeName, {
        type: "duplicate",
        message: `"${newType}" already exists`,
      });
      return; // Exit if it's a duplicate
    }

    // Proceed with adding the new type
    const value = {
      typeValue: newType,
      typeName: screenName,
    };

    if (typeof value === "object" && !Array.isArray(value)) {
      // Call the provided function to add type
      await addType(value, setValue);

      // Clear the input and close the adding state after adding
      setNewType("");
      setIsAddingType(false);
      clearErrors(typeName);
    } else {
      setError(typeName, {
        type: "duplicate",
        message: `"${newType}" is invalid`,
      });
      return; // Exit if it's a duplicate
    }
  } catch (error) {
    // Handle the uncaught error
    console.error("An error occurred while saving the type:", error);
  }
};
