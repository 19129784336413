import { Box, Typography } from "@mui/material";
import { Vortex } from "react-loader-spinner";

export default function LoadingSpinner() {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
          zIndex: 9999, // Ensure it's above other content
        }}
      >
        <Vortex
          visible={true}
          height="80"
          width="80"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={["red", "green", "blue", "yellow", "orange", "purple"]}
        />
        <Typography gutterBottom>Verifying Token</Typography>
      </Box>
    </>
  );
}
