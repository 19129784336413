import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import { TYPE_SCREEN } from "../../config/constants";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { addSerialNumber, PAGE_OPTIONS } from "../../config/fieldConfig";
import { getAccessLevels } from "../../../utils/accessLevels";
import { getActionsColumn } from "../../../utils/actionColumn";
import useSubmitForm from "../../../utils/useSubmitForm";
import TypeFields from "./fields";
import TypeColumns from "./columns";

export const TypeService = () => {
  const [selectedOption, setSelectedOption] = useState(PAGE_OPTIONS[0] || ""); // Default screen selection
  const [data, setTableData] = useState([]); // Data to be displayed in the table
  const [typeOptions, setTypeOptions] = useState([1]); // Options for type dropdown or selection
  const [loading, setLoading] = useState(true); // Loading state for data fetching
  const [editFormOpen, setEditFormOpen] = useState(false); // Controls edit form visibility
  const [editItemId, setEditItemId] = useState(null); // Stores ID of the item being edited
  const [editFormData, setEditFormData] = useState({}); // Data to be edited in the form
  const [showForm, setShowForm] = useState(false); // Toggles the form visibility

  // Prevent unnecessary re-fetch of data when component remounts
  const stopRemount = useRef(true);

  // Constants for screen identifier
  const screenName = TYPE_SCREEN || "";

  // Function to toggle the form's visibility
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Fetch data when the selected option changes or on initial load
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false; // Prevent remount fetching
      setLoading(true); // Start loading when data fetching begins
      fetchData(); // Fetch data from API
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  // Function to fetch data for the table from the API
  const fetchData = async () => {
    try {
      const token = JWT_TOKEN(); // Get the JWT token for authorization

      // If the token is missing, handle the error and stop further execution
      if (!token) throw new Error("Token not found or invalid");

      // Make an API call to fetch data based on the selected option
      const res = await ApiService.getAllTypes(token, selectedOption);

      // Check if response is successful and contains valid data
      if (res?.status === 200 && Array.isArray(res?.data)) {
        setTableData(res?.data); // Set the fetched data to state

        // Extract type options from the data
        const typesOptions = res?.data.map((type) => type.typeValue);
        setTypeOptions(typesOptions); // Set the type options
      } else {
        // Handle case where data is not valid
        setTableData([]); // Reset table data to empty array
      }
    } catch (error) {
      // Log any error during data fetch and notify the user
      console.error("Error fetching data:", error);
      setTableData([]); // Clear the table data in case of error
    } finally {
      // End loading state once data fetching is complete
      setLoading(false);
    }
  };

  // Function to handle edit button click for a specific row
  const handleEdit = (id) => () => {
    try {
      // Validate the provided ID
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }

      // Ensure data is available for editing
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }

      // Find the row corresponding to the given ID
      const rowData = data?.find((row) => row?.id === id);

      // Validate if the row data is found
      if (!rowData) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      // Set the state for the edit form with the selected row data
      setEditItemId(id);
      setEditFormOpen(true); // Open the edit form
      setEditFormData(rowData); // Populate form with the row data
    } catch (error) {
      // Log and display error if any issues occur
      console.error("Error editing item:", error);
      toast.error(`Error editing item: ${error?.message}`);
    }
  };

  // Get fields for the form; ensure it's an array
  const fields = Array.isArray(TypeFields()) ? TypeFields() : [];

  // Get the form submission handler and loading state from custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData,
  });

  // Configure the actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Pass the screen name for actions reference
    handleEdit, // Pass the handleEdit function for edit action
    onSubmitForm, // Pass the form submit handler
  });

  // Configure columns for the table, including action buttons
  const columns = Array.isArray(TypeColumns({ actionsColumn }))
    ? TypeColumns({ actionsColumn })
    : [];

  // Add serial numbers to the rows for the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : [];

  // Get access levels for the current screen (read/write permissions)
  const { writeAccess } = getAccessLevels(screenName);

  // Ensure page options are an array; fallback to an empty array if not
  const pageOptions = Array.isArray(PAGE_OPTIONS) ? PAGE_OPTIONS : [];

  return {
    screenName, // Identifier for the screen
    rows, // Rows for table display
    columns, // Columns for table configuration
    fields, // Fields for form
    pageOptions, // Screen page options
    stopRemount, // Prevent unnecessary remounting
    setSelectedOption, // Function to change screen option
    typeOptions, // Options for type selection
    selectedOption, // Current selected option
    editFormData, // Data for the edit form
    editFormOpen, // Flag to show/hide edit form
    editItemId, // ID of the item being edited
    setEditFormOpen, // Function to toggle edit form visibility
    loading, // Loading state for the data fetch
    onSubmitForm, // Form submission handler
    submitLoading, // Loading state for form submission
    writeAccess, // Write access permission
    showForm, // Flag to toggle form visibility
    setShowForm, // Function to control form visibility
    toggleForm, // Function to toggle form visibility on button click
  };
};
