// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker {
  background-color: white !important; /* Set background to white */
  border: 1px solid #ccc; /* Optional: set border color */
}

.react-datepicker__header {
  background-color: white !important; /* Header background white */
  border-bottom: none !important; /* Remove default border */
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000 !important; /* Set text color for days */
}

.react-datepicker__current-month,
.react-datepicker__year-dropdown-container,
.react-datepicker__month-dropdown-container {
  color: #000 !important; /* Set text color for month and year */
}

/* All selected dates including start, end, and in-between dates */
.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #1e76fa !important;
  color: white !important;
}

/* Handle keyboard selection as well */
.react-datepicker__day--keyboard-selected {
  background-color: #1e76fa !important;
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/DatePicker.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC,EAAE,4BAA4B;EAChE,sBAAsB,EAAE,+BAA+B;AACzD;;AAEA;EACE,kCAAkC,EAAE,4BAA4B;EAChE,8BAA8B,EAAE,0BAA0B;AAC5D;;AAEA;;;EAGE,sBAAsB,EAAE,4BAA4B;AACtD;;AAEA;;;EAGE,sBAAsB,EAAE,sCAAsC;AAChE;;AAEA,kEAAkE;AAClE;;;;;EAKE,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA,sCAAsC;AACtC;EACE,oCAAoC;EACpC,uBAAuB;AACzB","sourcesContent":[".react-datepicker {\n  background-color: white !important; /* Set background to white */\n  border: 1px solid #ccc; /* Optional: set border color */\n}\n\n.react-datepicker__header {\n  background-color: white !important; /* Header background white */\n  border-bottom: none !important; /* Remove default border */\n}\n\n.react-datepicker__day-name,\n.react-datepicker__day,\n.react-datepicker__time-name {\n  color: #000 !important; /* Set text color for days */\n}\n\n.react-datepicker__current-month,\n.react-datepicker__year-dropdown-container,\n.react-datepicker__month-dropdown-container {\n  color: #000 !important; /* Set text color for month and year */\n}\n\n/* All selected dates including start, end, and in-between dates */\n.react-datepicker__day--selected,\n.react-datepicker__day--in-range,\n.react-datepicker__day--in-selecting-range,\n.react-datepicker__day--range-start,\n.react-datepicker__day--range-end {\n  background-color: #1e76fa !important;\n  color: white !important;\n}\n\n/* Handle keyboard selection as well */\n.react-datepicker__day--keyboard-selected {\n  background-color: #1e76fa !important;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
