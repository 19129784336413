import FullScreenLoader from "../../common/LoadingScreen";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import PayFrequencyForm from "./form";
import { PayFrequencyService } from "./service";

export default function PayFrequency() {
  const {
    screenName = "",
    displayName = "",
    displayFormName = "",
    rows = [],
    columns = [],
    fields = [],
    open = false,
    editFormOpen = false,
    editItemId = null,
    editFormData = {},
    setEditFormOpen = () => {},
    addFormOpen = false,
    addFormClose = () => {},
    frequencyNameCheck = [],
    frequencyCheck = [],
    viewData = false,
    setViewData = () => {},
    calculateViewData = true,
    setCalculateViewData = () => {},
    frequencyWithId = [],
    loading = false,
    submitLoading = false,
    onSubmitForm = () => {},
    writeAccess = false,
  } = PayFrequencyService() || {};

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader loading={submitLoading} />

      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={loading}
        toolbarProps={{ displayName, screenName, addFormOpen }}
      />

      {/* Handle both add and edit form display */}
      <PayFrequencyForm
        screenName={displayFormName} // Name of the form (add/edit)
        open={editFormOpen || open} // Open form for edit or add
        onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose} // Close form logic
        mode={editFormOpen ? "edit" : "add"} // Form mode (edit/add)
        editItemId={editFormOpen ? editItemId : undefined} // Item ID for edit mode
        editFormData={editFormOpen ? editFormData : undefined} // Pre-filled data in edit mode
        fields={fields ?? []} // Form fields
        frequencyNameCheck={frequencyNameCheck ?? []} // Frequency name validation
        frequencyCheck={frequencyCheck ?? []} // Frequency validation
        viewData={viewData} // Data to display
        setViewData={setViewData} // Update display data
        calculateViewData={calculateViewData} // Data calculations
        setCalculateViewData={setCalculateViewData} // Update calculated data
        frequencyWithId={frequencyWithId ?? []} // Frequencies with IDs
        onSubmitForm={onSubmitForm} // Form submission handler
        writeAccess={writeAccess} // Check if user has write access
      />
    </>
  );
}
