import axios from "axios";
import {
  API_RECOVER_PWD_URL,
  API_URL,
  BE_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
} from "../components/config/envConfig";

const axiosClient = axios.create({
  baseURL: BE_URL,
});

// BE given URL
axiosClient.defaults.apiUrl = API_URL;

// Recaptcha Site Key
axiosClient.defaults.apiUrl_Recaptcha = GOOGLE_RECAPTCHA_SITE_KEY;

// Forgot Password given URL
axiosClient.defaults.apiUrl_recoverPwd = API_RECOVER_PWD_URL;

axiosClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (
      err?.response?.status === 401 &&
      err?.response?.data === "User session ID is expired. Please log in again."
    ) {
      console.log(err);

      // Clear session storage or any other session data
      sessionStorage.clear();

      // Optionally, redirect to the login page
      window.location.href = "/session_logout";
    }
    return Promise.reject(err);
  }
);

export default axiosClient;
