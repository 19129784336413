import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import ApiService from "../../../apis/ApiService";
import Copyright from "../../common/Copyright";
import useFetchOnMount from "../../config/useFetchOnMount";
import { getPasswordErrorMessage } from "../../config/fieldConfig";
import { EXCEPTION_MESSAGE } from "../../config/toastMessage";
import { fields } from "./formFields";
import { onRecoverPwd } from "../../service/outerPagesService";
import { CfTransBlueLogo } from "../../config/imageConfig";
import LoadingSpinner from "../../common/LoadingSpinner";

export default function RecoverPwd() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  // Show Password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Loading Button
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const handleTogglePassword = () => setShowPassword((show) => !show);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const [resetToken, setResetToken] = useState("");

  const fetchData = async () => {
    try {
      // Get the hash part of the URL
      const hash = window.location.hash;
      // Remove the leading '#' character
      const verifyToken = hash.slice(1);
      setResetToken(verifyToken);

      if (verifyToken) {
        // Perform token verification
        const res = await ApiService.verifyResetPasswordToken(verifyToken);
        console.log(res);
        if (res?.status === 200 && res?.data === "valid") {
          navigate("/recover_pwd");
        } else if (
          res?.status === 200 &&
          res?.data === "Invalid verification token"
        ) {
          navigate("/token_verified");
        } else {
          navigate("/access_denied");
        }
      } else {
        navigate("/access_denied");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(EXCEPTION_MESSAGE); // Handle other types of errors
    } finally {
      setShow(false);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  let navigate = useNavigate();

  const onSubmit = (data) => {
    setLoading(true);

    onRecoverPwd(data, resetToken, navigate, setLoading);
  };

  return (
    <>
      {show && <LoadingSpinner />}

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            mt: 7,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ":hover": { boxShadow: 5 },
          }}
        >
          <Box sx={{ mb: 2 }}>
            <img
              src={CfTransBlueLogo}
              alt="compFact logo"
              style={{ width: "100%", maxWidth: 150 }}
            />
          </Box>

          <Typography component="h1" variant="h5">
            Recover Password
          </Typography>

          <Alert severity="info" color="warning" sx={{ m: 2 }}>
            Please do not refresh the page until the recover your password.
          </Alert>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            {fields.map((field) => (
              <TextField
                key={field.name}
                margin="normal"
                required
                fullWidth
                label={field.label}
                name={field.name}
                autoComplete="off"
                autoFocus={field.autoFocus}
                size="small"
                type={
                  field.name === "password"
                    ? showPassword
                      ? "text"
                      : "password"
                    : field.name === "confirmPassword"
                    ? showConfirmPassword
                      ? "text"
                      : "password"
                    : field.type
                }
                InputProps={
                  field.name === "password" || field.name === "confirmPassword"
                    ? {
                        endAdornment: (
                          <IconButton
                            aria-label={`toggle ${field.label} visibility`}
                            onClick={
                              field.name === "password"
                                ? handleTogglePassword
                                : handleToggleConfirmPassword
                            }
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {field.name === "password" ? (
                              showPassword ? (
                                <VisibilityOffTwoTone />
                              ) : (
                                <VisibilityTwoTone />
                              )
                            ) : showConfirmPassword ? (
                              <VisibilityOffTwoTone />
                            ) : (
                              <VisibilityTwoTone />
                            )}
                          </IconButton>
                        ),
                      }
                    : null
                }
                {...register(field.name, {
                  required: field.required
                    ? `${field.label} is required`
                    : false,
                  validate:
                    field.name === "password"
                      ? (value) => getPasswordErrorMessage(value)
                      : field.name === "confirmPassword"
                      ? (value) =>
                          value === getValues("password") ||
                          "Passwords do not match"
                      : null,
                })}
                error={!!errors[field.name]}
                helperText={errors[field.name]?.message}
                FormHelperTextProps={{ sx: { mb: -3 } }}
                onChange={(e) => {
                  setValue(field.name, e.target.value); // Update the form value
                  trigger(field.name); // Trigger validation on change
                  if (field.name === "password") {
                    // Revalidate confirmPassword if password changes
                    trigger("confirmPassword");
                  }
                }}
                onBlur={() => {
                  trigger(field.name); // Trigger validation on blur
                }}
              />
            ))}

            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              loading={loading}
              loadingPosition="start"
              startIcon={<></>}
              style={{
                backgroundColor: loading ? "#448aff" : undefined,
                color: loading ? "#fff" : undefined,
              }}
              sx={{ my: 2 }}
            >
              {!loading ? "Recover" : "Loading..."}
            </LoadingButton>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/sign_in" variant="body2">
                  Remember your password? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Container>
    </>
  );
}
