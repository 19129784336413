import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import DateRangePicker from "../../../common/DateRangePicker";
import showDatePicker from "../../../../utils/showDatePicker";
import BreadcrumbMenu from "./breadcrumbMenu";

export default function BreadcrumbBar({ baseMenu, settingMenuItems }) {
  const { pathname } = useLocation();
  // find path to show the DateRangePicker
  const formatPathname = (path) => {
    // Remove leading '/' and capitalize the first letter
    const formattedPath = path.replace("/", ""); // Remove leading '/'
    return formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1); // Capitalize first letter
  };

  const normalizedPathname = formatPathname(pathname);

  return (
    <Box sx={{ boxShadow: 3, p: 1, mx: 2, my: 1 }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={10}>
          {/* Full width on small screens, half on medium and up */}
          <BreadcrumbMenu
            baseMenu={baseMenu}
            settingMenuItems={settingMenuItems}
          />
        </Grid>

        <Grid item xs={12} md={2} container justifyContent="flex-end">
          {/* Align the DateRangePicker on the right */}
          {showDatePicker[normalizedPathname] && <DateRangePicker />}
        </Grid>
      </Grid>
    </Box>
  );
}
