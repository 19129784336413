import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import {
  ADMIN_NAME,
  BENEFICIARY_SCREEN,
  PLAN_SCREEN,
  TRANSACTION_SCREEN,
} from "../components/config/constants";
import { getAccessLevels } from "./accessLevels";

// Function to handle the edit click event
const handleEditClick = (handleEdit, id) => {
  try {
    handleEdit(id)(); // Call the edit handler correctly
  } catch (editError) {
    console.error("Error during edit action:", editError);
    toast.error(`Edit action failed: ${editError?.message}`); // Display specific error using toast
  }
};

// Function to handle the delete click event form submission
const handleDeleteClick = (onSubmitForm, screenName, id) => {
  try {
    // Display confirmation dialog before deleting an item
    Swal.fire({
      text: `Are you sure you want to delete this ${screenName}?`,
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      if (result?.isConfirmed) {
        // If confirmed, proceed with the delete action
        onSubmitForm({ id }, "delete");
      }
    });
  } catch (deleteError) {
    console.error("Error during delete action:", deleteError);
    toast.error(`Delete action failed: ${deleteError?.message}`); // Display specific error using toast
  }
};

// Function to get the actions column configuration for the data grid
export const getActionsColumn = ({
  screenName = "", // Name of the screen for confirmation messages
  handleEdit = () => {}, // Default to a no-op function if no handler is provided
  onSubmitForm = () => {}, // Function to handle form submission
  idsWithoutDelete = [], // Default to an empty array if no value is provided
}) => {
  // Define access levels
  let readAccess = false;
  let writeAccess = false;
  let deleteAccess = false;

  // If screenName is 'Plan', grant full access without checking getAccessLevels
  if (screenName === PLAN_SCREEN) {
    readAccess = true;
    writeAccess = true;
    deleteAccess = true;
  } else {
    // Get access levels for the specified resource using a utility function
    const accessLevels = getAccessLevels(screenName);
    readAccess = accessLevels.readAccess;
    writeAccess = accessLevels.writeAccess;
    deleteAccess = accessLevels.deleteAccess;
  }

  // Validate props to ensure they are correct types
  if (
    typeof screenName !== "string" || // Ensure screenName is string
    typeof handleEdit !== "function" || // Ensure handleEdit is function
    typeof readAccess !== "boolean" || // Ensure readAccess is boolean
    typeof writeAccess !== "boolean" || // Ensure writeAccess is boolean
    typeof deleteAccess !== "boolean" || // Ensure deleteAccess is boolean
    !Array.isArray(idsWithoutDelete) // Ensure idsWithoutDelete is an array
  ) {
    // Log an error and display a toast if invalid props are passed
    console.error("Invalid props passed to getActionsColumn.");
    toast.error("Invalid props passed to actions column."); // Display error using toast
    return null; // Return null to avoid rendering this column if props are invalid
  }

  // If read access is not granted, return null to avoid rendering the column
  if (!readAccess || (!writeAccess && !deleteAccess)) {
    return null;
  }

  // Define the actions column for the data grid
  return {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    align: "left",
    headerAlign: "left",
    cellClassName: "actions",
    width: 100,
    getActions: function ({ id, row }) {
      // Validate the id to ensure it's a valid value
      if (typeof id !== "string" && typeof id !== "number") {
        console.error("Invalid id passed to getActions:", id);
        toast.error("Invalid id for actions."); // Display error using toast
        return []; // Return an empty array to avoid UI issues
      }

      const actions = []; // Initialize an array to hold action buttons

      // Use a try-catch block to handle potential errors when adding actions
      try {
        // Condition to skip actions for Transaction screen with processed row
        const skipActionsForTransaction =
          screenName === TRANSACTION_SCREEN && row.processed;

        if (!skipActionsForTransaction) {
          // If write access is granted, add an Edit button
          if (writeAccess) {
            actions.push(
              <GridActionsCellItem
                icon={<EditTwoTone />} // Edit icon
                label="Edit"
                color="primary"
                onClick={() => handleEditClick(handleEdit, id)} // Call the handleEditClick function
                title="Edit" // Tooltip title based on access
              />
            );
          }

          // Determine whether to display the Delete button based on conditions
          const showDeleteButton =
            writeAccess &&
            deleteAccess &&
            !idsWithoutDelete.includes(id) &&
            (screenName !== BENEFICIARY_SCREEN ||
              row.error ||
              row.role !== ADMIN_NAME);

          // If delete access is granted, and the item is not in the exclusion list, add a Delete button
          if (showDeleteButton) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteTwoTone />} // Delete icon
                label="Delete"
                color="error"
                onClick={() => handleDeleteClick(onSubmitForm, screenName, id)} // Call the handleDeleteClick function
                title="Delete" // Tooltip title based on access
              />
            );
          }
        }
      } catch (error) {
        // Log any unexpected errors and display a toast
        console.error("Error in getActions:", error);
        toast.error(`Error in actions column: ${error?.message}`); // Display specific error using toast
        return []; // Return an empty array to avoid UI issues
      }

      return actions; // Return the constructed actions array
    },
  };
};
