import { Box, Button, Container, Grid, Typography } from "@mui/material";
// import error503 from "../../assets/images/errors/Error-503.svg";

export default function Error503() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* <img
                src={error503}
                alt="Error_503-Service Unavailable"
                width={500}
                height={250}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h1">503</Typography>
              <Typography variant="h4">Service Unavailable</Typography>
              <Typography variant="h6">
                Sorry, we are under maintenance!
              </Typography>
              <Button
                href="/"
                variant="contained"
                style={{ marginTop: "1rem" }}
              >
                Back Home
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
