import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { LogoutTwoTone } from "@mui/icons-material";
import { capitalizeWords } from "../../config/fieldConfig";
import { PROFILE_IMG } from "../../config/sessionStorage";

export default function SectionRight({
  isMonitor,
  isSuperAdmin,
  orgName,
  displayOrgName,
  userName,
  displayUserName,
  handleOpenUserMenu,
  anchorElUser,
  setAnchorElUser,
  handleCloseUserMenu,
  settingMenuItems,
  logout,
  setIsAuthenticated,
  setRes,
}) {
  // Helper function to map text
  const mapSettingText = (text) => {
    switch (text) {
      case "Tickets":
        return "Support";
      case "Subscription":
        return "Billing";
      default:
        return text;
    }
  };

  // Get the profile image data form session storage
  const profileImage = PROFILE_IMG() || "";

  return (
    <>
      {isMonitor === "false" && isSuperAdmin === "false" ? (
        <>
          <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            <Tooltip title={orgName || ""} TransitionComponent={Zoom} arrow>
              <Typography variant="subtitle1">
                {capitalizeWords(displayOrgName || "")}
                {","}
              </Typography>
            </Tooltip>
            <Tooltip
              title={capitalizeWords(userName) || ""}
              TransitionComponent={Zoom}
              arrow
            >
              <Typography variant="subtitle1" px={1}>
                {capitalizeWords(displayUserName || "")}
              </Typography>
            </Tooltip>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open Settings" TransitionComponent={Zoom} arrow>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={capitalizeWords(userName) || ""}
                  src={`data:image/*;base64,${profileImage}`} // Using a generic MIME type
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: 1 }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              TransitionProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {settingMenuItems
                .filter((setting) => setting.text !== "Pricing")
                .map((setting) => (
                  <MenuItem
                    key={setting.text}
                    component={setting.link === "/sign_in" ? "div" : Link}
                    to={setting.link === "/sign_in" ? undefined : setting.link}
                    onClick={
                      setting.link === "/sign_in"
                        ? logout({ setIsAuthenticated, setRes })
                        : () => setAnchorElUser(null)
                    }
                    sx={{
                      textDecoration: "none",
                      color:
                        window.location.pathname === `/${setting.link}`
                          ? "secondary.main"
                          : "inherit",
                    }}
                  >
                    {setting.link === "/sign_in" && (
                      <ListItemIcon>
                        <LogoutTwoTone fontSize="small" />
                      </ListItemIcon>
                    )}
                    <Typography textAlign="center">
                      {mapSettingText(setting.text)}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: { md: "flex" },
            alignItems: "center",
            ml: "auto",
          }}
        >
          <Button
            component={Link}
            to={"/sign_in"}
            onClick={logout({ setIsAuthenticated, setRes })}
            size="large"
            variant={"contained"}
            sx={{
              backgroundImage:
                "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
              borderRadius: "20px",
              ml: 2,
              height: "40px",
              border: 2,
              borderWidth: "2px",
              borderColor: "white",
              color: "white",
              backgroundColor:
                "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
            }}
          >
            Logout
          </Button>
        </Box>
      )}
    </>
  );
}
