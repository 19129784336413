import { ROLE_ACCESS } from "../components/config/sessionStorage";

// Function to get access levels for a specific resource
export const getAccessLevels = (resource) => {
  try {
    // Retrieve role access information from session storage
    const roleAccess = ROLE_ACCESS();

    // Validate that roleAccess is an array
    if (!Array.isArray(roleAccess)) {
      console.error(
        "ROLE_ACCESS did not return an array. Returning default access levels."
      );
      // Return default access levels if roleAccess is not an array
      return { readAccess: false, writeAccess: false, deleteAccess: false };
    }

    // Validate that the resource is a non-empty string
    if (typeof resource !== "string" || resource.trim() === "") {
      console.error(
        "Invalid resource provided. Returning default access levels."
      );
      // Return default access levels if resource is invalid
      return { readAccess: false, writeAccess: false, deleteAccess: false };
    }

    // Helper function to determine access levels based on the access type
    const getAccess = (accessType) =>
      roleAccess.some(
        (item) => item && item.resource === resource && item[accessType]
      );

    // Return the access levels by invoking the helper function for each access type
    return {
      readAccess: getAccess("readAccess"),
      writeAccess: getAccess("writeAccess"),
      deleteAccess: getAccess("deleteAccess"),
    };
  } catch (error) {
    // Handle unexpected errors and log them
    console.error("Error while fetching access levels:", error);
    // Return default access levels in case of an error
    return { readAccess: false, writeAccess: false, deleteAccess: false };
  }
};
