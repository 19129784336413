import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  Search,
} from "@mui/icons-material";

export default function handleReportingToMenuItems({
  anchorEl = null,
  handleMenuClose = () => {},
  menu = [],
  checkedItems = new Set(),
  reportingTo = [],
  handleMenuItemClick = () => {},
  selectedDropdown = "",
  handleDropdownIconClick = () => {},
  isReporteeMenuOpen = false,
  searchQuery = "",
  setSearchQuery = () => {},
  filteredReportees = [],
  handleMenuItemChange = () => {},
  handleApplyClick = () => {},
  handleCancelClick = () => {},
}) {
  return (
    Array.isArray(menu) && (
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "170px",
            maxHeight: "500px", // Set the maximum height for the entire menu
            maxWidth: "200px", // Set the maximum width for the menu
            overflow: "hidden", // Ensure no overflow for the whole menu
          },
        }}
      >
        {menu?.map((item) => {
          // Conditionally skip "My Reportees" if reportingTo is empty
          if (item === "My Reportees" && reportingTo?.length === 0) {
            return null;
          }
          return (
            <Box key={item}>
              <MenuItem
                onClick={(event) => handleMenuItemClick(item, event)}
                selected={selectedDropdown === item}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "3px 10px",
                }}
              >
                <Tooltip title={item?.length > 20 ? item : ""} arrow>
                  <Typography
                    color={selectedDropdown === item ? "secondary" : "inherit"}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                      display: "block",
                      flexGrow: 1,
                    }}
                  >
                    {item}
                  </Typography>
                </Tooltip>

                {/* Add a dropdown icon for "My Reportees" */}
                {item === "My Reportees" && (
                  <IconButton
                    size="small"
                    onClick={handleDropdownIconClick}
                    sx={{ marginLeft: "auto" }}
                  >
                    {isReporteeMenuOpen ? (
                      <ArrowDropUpTwoTone />
                    ) : (
                      <ArrowDropDownTwoTone />
                    )}
                  </IconButton>
                )}
              </MenuItem>

              {/* Render the checkboxes when the dropdown is open */}
              {item === "My Reportees" && isReporteeMenuOpen && (
                <Box
                  sx={{
                    maxHeight: 300,
                    maxWidth: 170,
                    overflowY: "auto",
                    marginTop: 0,
                  }}
                >
                  {/* Search field */}
                  <TextField
                    variant="standard"
                    size="small"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()} // Prevent the event from reaching the main menu
                    sx={{ transform: "scale(0.8)", padding: 0 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* Scrollable checkbox list */}
                  <Box sx={{ maxHeight: 150, overflowY: "auto" }}>
                    {filteredReportees?.length > 0 ? (
                      filteredReportees?.map((reportee) => (
                        <MenuItem
                          key={reportee.userId}
                          onClick={(event) =>
                            handleMenuItemChange(reportee, event)
                          }
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "3px 5px",
                          }}
                        >
                          <Checkbox
                            checked={checkedItems?.has(reportee.userId)}
                            size="small"
                            sx={{
                              transform: "scale(0.6)",
                              padding: 0,
                            }}
                          />
                          <Tooltip
                            title={
                              `${reportee.userId} - ${reportee.userName}`
                                .length > 9
                                ? `${reportee.userId} - ${reportee.userName}`
                                : ""
                            }
                            arrow
                          >
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px",
                                display: "block",
                              }}
                            >
                              {`${reportee.userId} - ${reportee.userName}`}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No results found</MenuItem>
                    )}
                  </Box>

                  {/* OK and Clear buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      borderTop: "1px solid #ddd",
                      paddingTop: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleApplyClick}
                      sx={{
                        padding: "2px 8px",
                        fontSize: "0.75rem",
                        minWidth: "unset",
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleCancelClick}
                      sx={{
                        padding: "2px 8px",
                        fontSize: "0.75rem",
                        minWidth: "unset",
                        marginLeft: "8px", // Add space between OK and Clear
                        marginRight: "8px",
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Menu>
    )
  );
}
