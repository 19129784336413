import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useToolbarConfig } from "./ToolbarMap";

export default function ExportButton({
  screenName = "",
  hasRecords = false,
  apiRef = {},
  apiEndpoint = "",
}) {
  // show Export button based on screenName
  if (!useToolbarConfig[screenName]?.showExportButton) {
    return null;
  }

  // Define a mapping object for API endpoints to their respective CSV file names
  const exportConfig = {
    getErrorBeneficiaries: "Beneficiary_Error_Records",
    getCurrentErrorBeneficiaries: "Beneficiary_Current_Error_Records",
    getErrorCustomers: "Customer_Error_Records",
    getCurrentErrorCustomers: "Customer_Current_Error_Records",
    getErrorProducts: "Product_Error_Records",
    getCurrentErrorProducts: "Product_Current_Error_Records",
    getErrorTrans: "Transaction_Error_Records",
    getCurrentErrorTrans: "Transaction_Current_Error_Records",
  };

  // Function to handle export
  const handleExport = () => {
    // Get the corresponding file name based on the current apiEndpoint
    const fileName = exportConfig[apiEndpoint] || screenName; // Default to screenName if no match found

    // Export data as CSV using the generated file name
    apiRef.current.exportDataAsCsv({ fileName });
  };

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<FileDownloadOutlined />}
      onClick={handleExport}
      disabled={!hasRecords}
    >
      Export
    </Button>
  );
}
