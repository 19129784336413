import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { ExpandMoreTwoTone } from "@mui/icons-material";
import { axisClasses, BarChart, LineChart } from "@mui/x-charts";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import {
  convertCamelToTitle,
  displayLimitedItems,
} from "../../config/fieldConfig";
import { DashboardService } from "./service";
import handleReportingToMenuItems from "../../../utils/reportingToMenu";

export default function Dashboard() {
  const {
    // Total Commissions
    totalCommission,
    //Trends Commission
    trendsLabelsCom,
    trendsValuesCom,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    // Commission by Product
    productNames,
    totalNetProductPayouts,
    // Commission by Customers
    customerNames,
    totalNetCustomerPayouts,
    currency,
    timeZone,
    reportingTo,
    menu,
    handleMenuItemClick,
    handleMenuClose,
    checkedItems,
    appliedCheckedNames,
    selectedDropdown,
    isReporteeMenuOpen,
    anchorEl,
    handleApplyClick,
    handleCancelClick,
    handleDropdownIconClick,
    handleMenuClick,
    handleMenuItemChange,
    searchQuery,
    setSearchQuery,
    filteredReportees,
    dynamicMaxValue,
    dynamicMaxValue1,
    dynamicMaxValue2,
  } = DashboardService() || {};

  return (
    <>
      <Box
        sx={{
          padding: 2,
          height: "auto",
        }}
        maxWidth={"xl"}
      >
        <Grid container spacing={2}>
          {/* First Container */}
          <Grid container sx={{ mt: 1, pt: 1 }}>
            <Grid
              item
              xs={12}
              md={8.36}
              sx={{
                pl: { xs: 2, sm: 2, md: 0 },
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }} // Column layout on extra-small screens, row layout on small screens and up
                spacing={2} // Space between items
                alignItems="center"
                justifyContent={"space-between"}
                sx={{
                  mx: { xs: 0, sm: 0, md: 2 },
                  p: 1,
                  boxShadow:
                    "0px -4px 6px -2px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.2)", // Top and bottom shadow effect
                  borderRadius: 2,
                  transition: "box-shadow 0.3s", // Add transition for smooth effect
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  Currency : {currency || ""}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  Time Zone : {timeZone || ""}
                </Typography>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              md={3.64}
              sx={{
                pt: { xs: 2, sm: 2, md: 0.15 },
                pl: { xs: 2, sm: 2, md: 0 },
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }} // Column layout on extra-small screens, row layout on small screens and up
                spacing={2} // Space between items
                alignItems="center"
                justifyContent="flex-end" // Align items to the end of the Grid
                sx={{
                  p: { xs: 0.5, sm: 1, md: 0.52 },
                  boxShadow:
                    "0px -4px 6px -2px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.2)", // Top and bottom shadow effect
                  borderRadius: 2,
                  whiteSpace: "nowrap", // Prevent the text from wrapping to a new line
                  overflow: "hidden",
                  textOverflow: "ellipsis", // Show ellipsis for truncated text
                  transition: "box-shadow 0.3s", // Add transition for smooth effect
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  onClick={handleMenuClick}
                  endIcon={reportingTo.length > 0 && <ExpandMoreTwoTone />}
                  sx={{
                    padding: "3px 8px",
                    fontSize: "0.85rem",
                    minWidth: "unset",
                    display: "flex", // Make sure the button content is flexbox for proper alignment
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      maxWidth: 120, // Adjust based on your needs
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    {displayLimitedItems(appliedCheckedNames) ||
                      convertCamelToTitle(selectedDropdown) ||
                      ""}
                  </Box>
                </Button>

                {reportingTo.length > 0 ? (
                  <>
                    {/* show the reporting to dropdown */}
                    {handleReportingToMenuItems({
                      anchorEl,
                      handleMenuClose,
                      menu,
                      checkedItems,
                      reportingTo,
                      handleMenuItemClick,
                      selectedDropdown,
                      handleDropdownIconClick,
                      isReporteeMenuOpen,
                      searchQuery,
                      setSearchQuery,
                      filteredReportees,
                      handleMenuItemChange,
                      handleApplyClick,
                      handleCancelClick,
                    })}
                  </>
                ) : null}
              </Stack>
            </Grid>
          </Grid>

          {/*Second Container */}
          <Grid item xs={12} md={8.2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" p={1}>
                      Commission Trends
                    </Typography>
                  </Box>

                  <Divider variant="middle" />

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <LineChart
                      series={[
                        {
                          data: trendsValuesCom,
                          area: true,
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: trendsLabelsCom,
                          // tickLabelStyle: {
                          //   angle: -45,
                          //   textAnchor: 'end',
                          // },
                        },
                      ]}
                      yAxis={[
                        {
                          scaleType: "linear",
                          tickLabelStyle: {
                            fontSize: 15,
                          },
                          valueFormatter: (value) => {
                            return value >= 1000 ? `${value / 1000}k` : value;
                          },
                          min: 0,
                          max:
                            dynamicMaxValue1 !== null ? dynamicMaxValue1 : null, // Default max value if dynamicMaxValue1 is not set
                        },
                      ]}
                      tooltip={
                        trendsValuesCom?.length === 0 &&
                        trendsLabelsCom?.length === 0
                          ? { trigger: "none" }
                          : {}
                      }
                      width={850}
                      height={300}
                      sx={{
                        ".MuiLineElement-root": {
                          display: "none",
                        },
                        // bottomAxis Line Styles
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        p: 1,
                        "& .css-ddfs93": {
                          fontSize: "0.895rem", // Default size for desktops and larger screens
                          "@media (max-width: 1024px)": {
                            fontSize: "1.1rem", // Adjusts for smaller laptops and large tablets
                          },
                          "@media (max-width: 768px)": {
                            fontSize: "1.3rem", // Adjusts for tablets
                          },
                          "@media (max-width: 570px)": {
                            fontSize: "1.7rem", // Adjusts for mobile devices
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" p={1}>
                      Commission by Products
                    </Typography>
                  </Box>

                  <Divider variant="middle" />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <BarChart
                      xAxis={[
                        {
                          id: "topSalesReps",
                          scaleType: "band",
                          data: productNames,
                          valueFormatter: (value, context) =>
                            context.location === "tick"
                              ? value.length > 5
                                ? `${value.slice(0, 5)}...`
                                : value
                              : value,
                          tickLabelStyle: {
                            angle: -45,
                            textAnchor: "end",
                            fontSize: 15,
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          tickLabelStyle: {
                            fontSize: 15,
                          },
                          label: `(${currency})`,
                          valueFormatter: (value) => {
                            return value >= 1000 ? `${value / 1000}k` : value;
                          },
                          min: 0, // Set the minimum value of the y-axis
                          max: dynamicMaxValue, // Set the dynamic maximum value of the y-axis
                        },
                      ]}
                      series={[
                        {
                          data: totalNetProductPayouts,
                          color: "#8884d8",
                        },
                      ]}
                      tooltip={
                        productNames?.length === 0 &&
                        totalNetProductPayouts?.length === 0
                          ? { trigger: "none" }
                          : {}
                      }
                      width={450}
                      height={300}
                      sx={{
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                          transform: "translate(-10px, 0)",
                        },
                        [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                          transform: "translate(-20px, 10px)",
                        },
                        // bottomAxis Line Styles
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        p: 1,
                        "& .css-ddfs93": {
                          fontSize: "1rem",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" p={1}>
                      Commission by Customers
                    </Typography>
                  </Box>

                  <Divider variant="middle" />

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <LineChart
                      series={[{ data: totalNetCustomerPayouts }]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: customerNames,
                          tickLabelStyle: {
                            angle: -45,
                            textAnchor: "end",
                            fontSize: 15,
                          },
                          valueFormatter: (value, context) =>
                            context.location === "tick"
                              ? value.length > 5
                                ? `${value.slice(0, 5)}...`
                                : value
                              : value,
                        },
                      ]}
                      yAxis={[
                        {
                          scaleType: "linear",
                          tickLabelStyle: {
                            fontSize: 15,
                          },
                          label: `(${currency})`,
                          valueFormatter: (value) => {
                            return value >= 1000 ? `${value / 1000}k` : value;
                          },
                          min: 0, // Set the minimum value of the y-axis
                          max: dynamicMaxValue2,
                        },
                      ]}
                      tooltip={
                        customerNames?.length === 0 &&
                        totalNetCustomerPayouts?.length === 0
                          ? { trigger: "none" }
                          : {}
                      }
                      width={450}
                      height={300}
                      sx={{
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                          transform: "translate(-10px, 0)",
                        },
                        ".MuiLineElement-root, .MuiMarkElement-root": {
                          strokeWidth: 1,
                        },
                        ".MuiLineElement-series-pvId": {
                          strokeDasharray: "5 5",
                        },
                        ".MuiLineElement-series-uvId": {
                          strokeDasharray: "3 4 5 2",
                        },
                        ".MuiMarkElement-root:not(.MuiMarkElement-highlighted)":
                          {
                            fill: "#fff",
                          },
                        "& .MuiMarkElement-highlighted": {
                          stroke: "none",
                        },
                        // bottomAxis Line Styles
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        pl: 1,
                        "& .css-ddfs93": {
                          fontSize: "0.975rem",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Third Container */}
          <Grid item xs={12} md={3.8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                    color: "white",
                    bgcolor: "#2196f3",
                    p: 2,
                    pb: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Total Commission
                    </Typography>
                  </Box>

                  <Divider color="white" />

                  <Typography
                    variant="h4"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      py: 3,
                      px: 1,
                      fontWeight: "light",
                    }}
                  >
                    {totalCommission}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                    pb: 3,
                  }}
                >
                  <Typography variant="h6" gutterBottom sx={{ p: 2, pb: 1 }}>
                    Commission by SalesRep
                  </Typography>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DataGrid
                      columns={salesRepsColumns ?? []}
                      rows={salesRepsRows ?? []}
                      slots={{ noRowsOverlay: EmptyBox }}
                      density="compact"
                      disableColumnMenu
                      slotProps={{
                        footer: {
                          style: {
                            display: "none",
                          },
                        },
                      }}
                      sx={{
                        width: 500,
                        height: 430,
                        // removing the cell border
                        "& .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeader:focus-within": {
                          outline: "none !important",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
