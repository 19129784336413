import { useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { addSerialNumber } from "../../config/fieldConfig";
import { JWT_TOKEN, USER_NAME } from "../../config/sessionStorage";
import { PLAN_DISPLAY_NAME, PLAN_SCREEN } from "../../config/constants";
import { getActionsColumn } from "../../../utils/actionColumn";
import useSubmitForm from "../../../utils/useSubmitForm";
import { PlanColumns } from "./columns";
import { PlanFields } from "./fields";

export default function PlanService() {
  // States to manage table data and form visibility
  const [data, setTableData] = useState([]);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null); // ID of the item being edited
  const [editFormData, setEditFormData] = useState({}); // Form data for the item being edited
  const [viewData, setViewData] = useState(true); // State for view-only purposes
  const [showForm, setShowForm] = useState(false); // Toggles between showing and hiding the form
  const [loading, setLoading] = useState(true); // Loading state while fetching data

  // Screen name and display name constants for the current screen
  const screenName = PLAN_SCREEN || "";
  const displayName = PLAN_DISPLAY_NAME || "";

  // Fetch the logged-in user's name from session storage
  const userName = USER_NAME();

  // Toggles the form visibility
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Resets the edit form state when closing or submitting the form
  const reset = () => {
    setEditFormOpen(false);
  };

  // Function to fetch plan data from the API
  const fetchData = async () => {
    try {
      // Get JWT token for API authentication
      const token = JWT_TOKEN();
      if (!token) throw new Error("Token not found or invalid");

      // API call to get plan data
      const res = await ApiService.getPricingPlans(token);

      // Set data if the API call is successful, otherwise set an empty array
      if (res?.status === 200 && Array.isArray(res?.data)) {
        setTableData(res?.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData([]); // Clear the data in case of an error
    } finally {
      setLoading(false); // Stop the loading indicator
    }
  };

  // Custom hook to fetch data when the component mounts
  useFetchOnMount(fetchData);

  // Function to handle editing an item
  const handleEdit = (id, viewData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }

      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }

      // Find the row data based on the provided ID
      const rowData = data?.find((row) => row?.id === id);

      // Check if row data is found
      if (!rowData) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      // Set the edit form state and populate with row data
      setEditItemId(id); // Set the ID of the item being edited
      setEditFormOpen(true); // Open the edit form
      setEditFormData(rowData); // Populate form with item data
      setViewData(viewData); // Set view state for the form
    } catch (error) {
      console.error(error);
      toast.error(`Error editing item: ${error?.message}`); // Display error message
    }
  };

  // Get form fields and set defaults in case of errors
  const fields = Array.isArray(PlanFields()) ? PlanFields() : [];

  // Handle form submission and loading state using a custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData,
    adminUserName: userName,
  });

  // Configure the actions column for editing and deleting items
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Retrieve columns for the data grid
  const columnsResult = PlanColumns({
    actionsColumn,
    handleEdit,
  });

  // Ensure columns is an array
  const columns = Array.isArray(columnsResult) ? columnsResult : [];

  // Add serial numbers to data rows for table display
  const rows = Array.isArray(data) ? addSerialNumber(data) : [];

  // Return the state and handlers used in the component
  return {
    screenName, // Screen identifier for the current page
    displayName, // UI display name for the screen
    rows, // Data rows for the table with serial numbers
    columns, // Columns configuration for the data grid
    fields, // Form fields for creating or editing a plan
    loading, // Loading state indicator
    editFormOpen, // Controls visibility of the edit form
    editItemId, // ID of the item being edited
    editFormData, // Data of the selected item for editing
    viewData, // View mode for read-only display
    showForm, // Flag to show or hide the form
    reset, // Function to reset the edit form state
    toggleForm, // Function to toggle form visibility
    setViewData, // Function to update viewData state
    onSubmitForm, // Function to handle form submission
    submitLoading, // Loading state during form submission
    setShowForm,
  };
}
