import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Error } from "@mui/icons-material";
import ApiService from "../../../apis/ApiService";
import {
  addSerialNumber,
  defaultCustomFields,
  removeDuplicateFields,
} from "../../config/fieldConfig";
import {
  JWT_TOKEN,
  USER_ID,
  USER_NAME,
  USER_ROLE,
} from "../../config/sessionStorage";
import { LOADING_MSG } from "../../config/toastMessage";
import {
  ADMIN_NAME,
  BENEFICIARY_DISPLAY_NAME,
  BENEFICIARY_SCREEN,
} from "../../config/constants";
import BeneficiaryColumns from "./columns";
import BeneficiaryFields from "./fields";
import { customAddType } from "../../../utils/addTypeFunctions";
import { getActionsColumn } from "../../../utils/actionColumn";
import { useDynamicFields } from "../../../utils/Master/useDynamicFields";
import { addCustomFields } from "../../../utils/Master/addCustomField";
import { masterUploadFile } from "../../../utils/Master/fileUploadHelper";
import useSubmitForm from "../../../utils/useSubmitForm";
import { extractCustomFieldApiValues } from "../../../utils/Master/customFieldApiHandler";
import { extractCustomField } from "../../../utils/Master/customFieldExtractor";
import { getAccessLevels } from "../../../utils/accessLevels";

export const BeneficiaryService = (fileInputRef) => {
  const [data, setTableData] = useState([]);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [customFieldsArrayObject, setCustomFieldsArrayObject] = useState({});
  const [fieldMappingArrayObject, setFieldMappingArrayObject] = useState([]);
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false);
  const [allRole, setAllRole] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [reportingToOptions, setReportingToOptions] = useState([]);
  const [role, setRole] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [roleName, setRoleName] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [viewData, setViewData] = useState(false); // view purpose only
  const [isEData, setIsEData] = useState(false); // edit error records
  const [resObject, setResObject] = useState({});
  const [response, setResponse] = useState({});
  const [fieldsLabel, setFieldsLabel] = useState();
  const [configObj, setConfigObj] = useState();
  const [userIdCheck, setUserIdCheck] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
  const [employeeTypeOptionsWithId, setEmployeeTypeOptionsWithId] = useState(
    []
  );
  const [isNavigateToAllErrorsActive, setIsNavigateToAllErrorsActive] =
    useState(false);
  const [isReportingTo, setIsReportingTo] = useState(false);
  const [isErrorColor, setIsErrorColor] = useState(false);
  const [uploading, setUploadInProgress] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true
  const [isUploading, setIsUploading] = useState(false); // State to track the upload status
  const [selectedOption, setSelectedOption] = useState("Eligible Beneficiary");
  const [apiEndpoint, setApiEndpoint] = useState("getActiveBeneficiaries");
  const stopRemount = useRef(true);

  // Constants for screen identifier
  const screenName = BENEFICIARY_SCREEN || "";

  // Constants for display screen name UI identifier
  const displayName = BENEFICIARY_DISPLAY_NAME || "";

  // Message to show during upload
  const uploadLoadingMsg = LOADING_MSG;

  // Get the role of the current user
  const ROLE_NAME = USER_ROLE();
  const USERNAME = USER_NAME();

  const [newFields, setNewFields] = useState([]);
  const [open, setOpen] = useState(false);

  const addFormOpen = () => {
    try {
      setOpen(true);
      apiCall(null);
    } catch (error) {
      console.error("Error opening add form:", error);
    }
  };

  const addFormClose = () => {
    try {
      setOpen(false);
    } catch (error) {
      console.error("Error closing add form:", error);
    }
  };

  // Handles option change and sets loading state, API endpoint, and selected option
  const handleOptionChange = (option, endpoint) => {
    // Set loading state only if the option has changed
    setIsTableLoading(selectedOption !== option);
    // Update API endpoint based on the selected option
    setApiEndpoint(endpoint);
    // Set the currently selected option
    setSelectedOption(option);
    // Reset the red error color on option change
    setIsErrorColor(false);
    // Flag to trigger data fetching on next render
    stopRemount.current = true;
  };

  const menuItems = [
    {
      label: "Eligible Beneficiary",
      action: () =>
        handleOptionChange("Eligible Beneficiary", "getActiveBeneficiaries"),
    },
    {
      label: "My Data",
      action: () => handleOptionChange("My Data", "getMyBeneficiary"),
    },
    isReportingTo && {
      label: "My Reportee",
      action: () =>
        handleOptionChange("My Reportee", "getBeneficiariesForDirectReportees"),
    },
    {
      label: "Not Eligible",
      action: () =>
        handleOptionChange("Not Eligible", "getInActiveBeneficiaries"),
    },
    ROLE_NAME === ADMIN_NAME && {
      label: "All Errors",
      action: () => {
        handleOptionChange("All Errors", "getErrorBeneficiaries");
        setIsErrorColor(true); // Set the navigate scenario active
      },
    },
  ].filter(Boolean);

  // Props for the toolbar menu, including items and currently selected option
  const toolbarMenuProps = {
    menuItems,
    selectedOption,
    isReportingTo: true,
  };

  const handleCurrentError = () => {
    handleOptionChange("All Errors", "getCurrentErrorBeneficiaries");
    setIsErrorColor(true); // Set the navigate scenario active
  };

  useEffect(() => {
    setConfigObj(configObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configObj]);

  // The useEffect you already have
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, role]);

  const fetchData = async (isSubmit = false) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      const { getPojo, typeData, mappingPojoRes } =
        await extractCustomFieldApiValues(
          screenName,
          token,
          setEmployeeTypeOptionsWithId,
          setEmployeeTypeOptions,
          setConfigObj,
          setFieldsLabel
        );
      // Fetch all roles
      const allRoles = await ApiService.getRoles(token);
      setAllRole(allRoles); // Set roles state

      // Extract role names
      const roleNames = allRoles.map((role) => role.roleName);
      setRoleName(roleNames);

      // Fetch all beneficiaries
      const allBeneficiaries = await ApiService.getBeneficiariesWithHierarchy(
        token
      );

      const userIds = allBeneficiaries?.map(
        (beneficiary) => beneficiary.userId
      );
      setUserIdCheck(userIds);

      const userId = USER_ID();
      // show view option(Check if there are any records where reportingTo matches userId)
      const reportingToRecords = allBeneficiaries.some(
        (item) => item.reportingTo === userId
      );
      setIsReportingTo(reportingToRecords);

      // Determine the effective endpoint based on the isSubmit flag and current apiEndpoint
      const effectiveEndpoint =
        isSubmit && apiEndpoint === "getCurrentErrorBeneficiaries"
          ? "getActiveBeneficiaries"
          : apiEndpoint;

      // Set error color to false only if this is a submit request and the endpoint was modified
      if (isSubmit && effectiveEndpoint === "getActiveBeneficiaries") {
        setIsErrorColor(false);
        setSelectedOption("Eligible Beneficiary");
        setApiEndpoint("getActiveBeneficiaries");
      }

      // Make an API request to the specified endpoint using the token
      const response = await ApiService[effectiveEndpoint](token);

      // Check if the response status is 200 and the data is an array
      if (response?.status === 200 && Array.isArray(response?.data)) {
        const transData = response?.data;
        console.log("Response:", response.data);

        // Transform beneficiary data
        const transferData = response.data.map((item) => ({
          ...item,
          role: allRoles.find((rol) => rol.id === item.role)?.roleName,
        }));

        // Map over transferData and replace employeeType ID with typeValue
        const updatedTransferData = transferData.map((transfer) => {
          // Find the corresponding employeeTypeData entry
          const matchedEmployeeType = typeData?.find(
            (employeeType) =>
              employeeType.id === parseInt(transfer.employeeTypeId) // Ensure both are numbers
          );

          // Return a new object with the updated employeeType
          return {
            ...transfer,
            employeeType: matchedEmployeeType
              ? matchedEmployeeType.typeValue
              : transfer.employeeType, // Use typeValue or fallback to original
          };
        });
        setTableData(updatedTransferData);

        // Transform beneficiary data
        const transformedData = allBeneficiaries.map((beneficiary) => ({
          id: beneficiary.id,
          userId: beneficiary.userId,
          userName: beneficiary.userName,
          role: allRoles.find((role) => role.rank === beneficiary.role)
            ?.roleName,
          roleName: beneficiary.roleName,
          reportingTo: beneficiary.reportingTo,
          reportingToName: beneficiary.reportingToName,
        }));
        setAllTableData(transformedData); // Set transformed data state

        // Extract roles after the user's role - Determine role options
        const rolesAfterUser = roleNames.slice(roleNames.indexOf(ROLE_NAME));
        setRoleOptions(rolesAfterUser); // Set role options state

        extractCustomField(
          transData,
          getPojo,
          mappingPojoRes,
          setResponse,
          setResObject,
          setDynamicColumns,
          setCustomFieldsArrayObject,
          setFieldMappingArrayObject
        );
      } else {
        // Clear table data if API response is not as expected
        setTableData([]);
        setIsReportingTo(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Stop the loading indicator once data fetching is complete
      setIsTableLoading(false);
    }
  };

  const apiCall = async (role) => {
    try {
      // Fetch all Users
      const allUserEmails = await ApiService.getAllUserEmails();
      setEmailCheck(allUserEmails);

      const currentRole = allRole.find((r) => r.roleName === role);

      let higherRankRoles = [];

      if (currentRole) {
        higherRankRoles = allRole
          .filter((r) => r.rank < currentRole.rank)
          .map((r) => r.roleName);
        console.log(higherRankRoles);
        if (higherRankRoles.length > 0) {
          console.log(higherRankRoles);
        } else {
          higherRankRoles = allRole
            .filter((r) => r.rank === currentRole.rank)
            .map((r) => r.roleName);
        }
      } else {
        console.log(`Role ${role} not found in the list.`);
      }

      let extractedUserNames;

      if (role === null) {
        extractedUserNames = [];
      } else if (allTableData.length > 0) {
        extractedUserNames = allTableData
          .filter((beneficiary) =>
            higherRankRoles.includes(beneficiary.roleName)
          )
          .map((beneficiary) => ({
            repoId: beneficiary.userId,
            repoName: `${beneficiary.userId} - ${beneficiary.userName}`,
          }));
      }
      setReportingToOptions(extractedUserNames); // Set reporting to options state
    } catch (error) {
      console.log(error);
    }
  };

  // Assuming customFieldsArrayObject is an array of objects
  const dynamicFormFields = useDynamicFields(
    customFieldsArrayObject, // Custom fields from the user
    fieldMappingArrayObject, // Field mapping configurations
    configObj // Additional settings for the fields
  );

  // Iterate over the fields to remove
  defaultCustomFields.forEach((fieldToRemove) => {
    // Find the index of the field with the corresponding label
    const indexToRemove = dynamicFormFields.findIndex(
      (field) => field.label === fieldToRemove
    );
    // If found, remove the field from dynamicFormFields
    if (indexToRemove !== -1) {
      dynamicFormFields.splice(indexToRemove, 1);
    }
  });

  // Function to handle adding a custom field
  const handleAddCustomField = (customFieldData) => {
    // Call addCustomFields to add the new custom field
    addCustomFields(
      customFieldData, // Data for the custom field
      fieldsLabel, // List of existing labels
      dynamicFormFields, // Current dynamic form fields
      newFields, // Custom fields that have been added
      setNewFields, // Function to update new fields
      setOpenCustomFieldDialog // Function to close the dialog
    );
  };

  // Function to handle add employee type
  const addEmployeeType = async (value, setValue) => {
    await customAddType(
      value,
      setValue,
      setEmployeeTypeOptions,
      "employeeType"
    );
  };

  // Function to handle file upload for import
  const handleUploadFile = (event) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call masterUploadFile and wait for it to complete
        await masterUploadFile(
          event,
          fileInputRef, // Reference to the file input element
          screenName, // Screen name for the upload context
          () => fetchData(true), // Call fetchData with `true`
          setIsUploading, // Function to toggle loading state
          handleCurrentError, // Function to handle any error during the process
          reject, // Reject the promise on failure
          resolve // Resolve the promise on success
        );
      } catch (error) {
        // Log any error that occurs during the file upload process
        console.error("File upload failed:", error);
      }
    });
  };

  // Function to handle edit button click
  const handleEdit = (id, viewData, isEData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }
      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }
      const rowData = data.find((row) => row.id === id); // Find the row data based on the id
      // Check if row data is found
      if (!rowData || typeof rowData !== "object" || Array.isArray(rowData)) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      setEditItemId(id); // Set the edit form state and data
      setSelectedRepo(rowData?.reportingTo);
      setEditFormOpen(true);
      setEditFormData(rowData); // Set edit form data
      setViewData(viewData);
      setIsEData(isErrorColor);
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Get fields from fields, default to empty array if not an array
  const formFields = Array.isArray(
    BeneficiaryFields({
      roleOptions,
      reportingToOptions,
      employeeTypeOptions,
      dynamicFormFields,
      newFields,
    })
  )
    ? BeneficiaryFields({
        roleOptions,
        reportingToOptions,
        employeeTypeOptions,
        dynamicFormFields,
        newFields,
      })
    : [];

  // Remove duplicates from `formFields`
  const fields = removeDuplicateFields(formFields);

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData: () => fetchData(true), // Pass `true` to fetchData for submission-specific logic
    adminUserName: USERNAME,
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Call CustomerColumns once and store the result
  const columnsResult = BeneficiaryColumns({
    actionsColumn,
    dynamicColumns,
    isErrorColor,
    handleEdit,
  });

  // Check if the result is an array
  const columns = Array.isArray(columnsResult) ? columnsResult : [];

  // Add serial numbers to the rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : [];

  // Get access levels for the specified resource using a utility function
  const { writeAccess } = getAccessLevels(screenName);

  // Memoize column visibility model to prevent recreation on every render
  const columnVisibilityModel = useMemo(
    () => ({
      territory: false,
      notes: false,
      startDate: false,
      endDate: false,
      eligible: false,
      mobileNumber: false,
      ...dynamicColumns.reduce((acc, column) => {
        acc[column.field] = false;
        return acc;
      }, {}),
    }),
    [dynamicColumns]
  );

  // Define properties for the toolbar import component
  const toolbarImportProps = {
    handleUploadFile, // Function to handle file upload
    fileInputRef, // Reference to the file input element
    uploading, // State to track if a file is currently uploading
    setUploadInProgress, // Function to update upload progress state
  };

  return {
    rows,
    columns,
    dynamicColumns,
    editFormOpen,
    editItemId,
    editFormData,
    setEditFormOpen,
    dynamicFormFields,
    handleAddCustomField,
    fields,
    openCustomFieldDialog,
    setOpenCustomFieldDialog,
    handleUploadFile,
    isNavigateToAllErrorsActive,
    allRole,
    data,
    roleOptions,
    reportingToOptions,
    setRole,
    open,
    addFormOpen,
    addFormClose,
    apiCall,
    newFields,
    viewData,
    allTableData,
    setViewData,
    apiEndpoint,
    userIdCheck,
    emailCheck,
    addEmployeeType,
    employeeTypeOptions,
    isReportingTo,
    isErrorColor,
    setIsErrorColor,
    uploading,
    setUploadInProgress,
    isEData,
    setIsEData,
    setApiEndpoint,
    setIsNavigateToAllErrorsActive,
    employeeTypeOptionsWithId,
    setEmployeeTypeOptionsWithId,
    selectedRepo,
    setSelectedRepo,
    selectedOption,
    isTableLoading,
    uploadLoadingMsg,
    displayName,
    screenName,
    menuItems,
    submitLoading, // Submission loading state
    writeAccess,
    columnVisibilityModel,
    toolbarImportProps,
    onSubmitForm,
    resObject,
    response,
    setNewFields,
    toolbarMenuProps,
    isUploading,
  };
};
