import { Link, Typography } from "@mui/material";
import { WEB_LINK, WEB_URL } from "../config/constants";

export default function Copyright(props) {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href={WEB_URL} target="_blank">
          {WEB_LINK}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </>
  );
}
