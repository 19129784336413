export const FormFields = (
  beneficiariesOptions,
  yearOptions,
  frequenciesOptions,
  periodOptions
) => [
  {
    name: "beneficiaries",
    label: "Beneficiaries",
    type: "autocomplete",
    options: beneficiariesOptions,
    required: true,
  },
  {
    name: "year",
    label: "Year",
    type: "autocomplete",
    options: yearOptions,
    required: true,
  },
  {
    name: "frequencies",
    label: "Frequencies",
    type: "autocomplete",
    options: frequenciesOptions,
    required: true,
  },
  {
    name: "period",
    label: "Period",
    type: "autocomplete",
    options: periodOptions,
    required: true,
  },
];
