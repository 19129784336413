import toast from "react-hot-toast";
import { EXCEPTION_MESSAGE } from "../components/config/toastMessage";
import { handleMenuUpdate } from "./menuHandler";

// Function to handle API calls with feedback messages
export const handleApiCall = async (
  screen, // Current screen
  promise, // Promise representing the API call
  action, // Action performed (e.g., edit, delete)
  fetchData, // Function to fetch fresh data after API call
  additionalParam = null // Additional parameter for menu update
) => {
  try {
    // Await the promise resolution
    const res = await promise;

    // Log the response for debugging purposes
    console.log(`Response for ${action}:`, res);

    // Display a feedback message using toast
    if (res?.status === 200) {
      toast.success(res?.data);
    } else if (res?.code === "ERR_NETWORK") {
      toast.error(res?.message); // Network server error
    } else {
      toast.error(res?.response?.data || EXCEPTION_MESSAGE);
    }

    // Condition to check the action is add, and response is 200
    if (action === "add" && res?.status === 200) {
      // Call the updateMenu function conditionally
      if (additionalParam) {
        await handleMenuUpdate(screen, additionalParam); // Pass additionalParam if it exists
      } else {
        await handleMenuUpdate(screen); // Call without additionalParam
      }
    }

    // Always fetch new data after the API call, including after deletion
    fetchData(); // Refresh the data on the screen
  } catch (error) {
    // Log any errors that occur during the API call or subsequent operations
    console.error(`Error during ${action}:`, error);
  }
};
