import { useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { EXCEPTION_MESSAGE } from "../../config/toastMessage";
import { JWT_TOKEN } from "../../config/sessionStorage";
import ProfileFields from "./fields";

export const ProfileService = () => {
  // State for profile data and loading status
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);

  // Function to fetch profile data
  const fetchProfile = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();
      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      const res = await ApiService.getMyBeneficiary(token);

      // Check if response is successful and data is a non-empty array
      if (
        res?.status === 200 &&
        Array.isArray(res?.data) &&
        res?.data?.length > 0
      ) {
        // Set profile data from the first element of the response array
        setProfileData(res.data[0]);

        // Set session storage for user name and profile image
        sessionStorage.setItem("userName", res?.data[0]?.userName);
        sessionStorage.setItem("profileImage", res?.data[0]?.imageData);
      } else {
        // Set default profile data if response is not as expected
        setProfileData({});
      }
    } catch (error) {
      console.error("Failed to fetch profile data:", error);
      // Optionally set default profile data on error
      setProfileData({});
    } finally {
      setLoading(false); // Ensure loading is set to false after fetching
    }
  };

  // Use custom hook to fetch profile on component mount
  useFetchOnMount(fetchProfile);

  // Function to update profile data
  const updateProfile = async (updatedData) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();
      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");
      // Check if updatedData is an object
      if (typeof updatedData !== "object" || updatedData === null) {
        throw new Error("Invalid data format for profile update.");
      }
      // Set loading to true while the request is in progress
      setLoading(true);

      // Proceed with the API call to update the profile
      const res = await ApiService.updateBeneficiary(updatedData, token);

      // Check if update was successful
      if (res?.status === 200) {
        toast.success(res?.data); // Show success message
        setProfileData(updatedData); // Update local profile data
        fetchProfile(); // Re-fetch profile data to ensure it's up-to-date
      } else {
        // Handle error response
        toast.error(
          res?.code === "ERR_NETWORK"
            ? res?.message
            : res?.response?.data || EXCEPTION_MESSAGE
        );
      }
    } catch (error) {
      console.error("Failed to update profile data:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false after request completion
    }
  };

  // Get fields from fields, default to empty array if not an array
  const fields = Array.isArray(ProfileFields()) ? ProfileFields() : [];

  // Return profile data, loading state, and API functions
  return {
    fields, // Fields for profile data
    profileData, // User's profile information
    loading, // Loading state for data fetching
    fetchProfile, // Function to get profile data
    updateProfile, // Function to update profile data
  };
};
