import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, DialogActions, Grid } from "@mui/material";
import {
  isValueContainsSplChars,
  orderErrorFocus,
} from "../../config/fieldConfig";
import FeaturesBox from "./features";
import SubscriptionPeriodsBox from "./subscriptionPeriods";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../../utils/CustomInputFields";

export default function PlanForm({
  mode = "add",
  onClose = () => {},
  onSubmitForm = () => {},
  fields = [],
  viewData = false,
  editItemId = null,
  editFormData,
  setShowForm = () => {},
}) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dynamicFields: [], // Initialize dynamicFields with an empty array
      subscriptionPeriods: [], // Initialize subscriptionPeriods with an empty array
      frequency: "Month", // Default frequency set to "Month"
    },
  });

  const [selectedFrequency, setSelectedFrequency] = useState("Month");

  const fieldRefs = useRef({});

  useEffect(() => {
    if (mode === "edit" && editFormData) {
      setValue(
        "dynamicFields",
        editFormData.features.map((feature) => ({ name: feature }))
      );
      setValue(
        "subscriptionPeriods",
        editFormData.subscriptionPeriods.map((period) => ({
          duration: period.duration,
          amount: period.amount,
          discountPercentage: period.discountPercentage,
          discountAmount: period.discountAmount,
          totalAmount: period.totalAmount,
        }))
      );
      // Also, set other form values
      fields.forEach((field) => setValue(field.name, editFormData[field.name]));
      setSelectedFrequency(editFormData.frequency || "Month"); // Set frequency state
    } else if (mode === "add") {
      // Reset for add mode
      setValue("dynamicFields", [{ name: "" }]);
      setValue("subscriptionPeriods", [
        {
          duration: "",
          amount: "",
          discountPercentage: "",
          discountAmount: "",
          totalAmount: "",
        },
      ]);
      setSelectedFrequency("Month"); // Set default frequency to "Month"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormData, mode, setValue]);

  // Watch for subscription periods duration changes
  const subscriptionPeriods = watch("subscriptionPeriods");
  useEffect(() => {
    console.log("Subscription Periods Duration Values:", subscriptionPeriods);
  }, [subscriptionPeriods]);

  const onSubmit = (formData) => {
    // Transform dynamic fields into an array
    const featuresArray = formData.dynamicFields.map((field) => field.name);

    // Transform subscription periods into an array
    const subscriptionArray = formData.subscriptionPeriods.map((period) => ({
      duration: period.duration,
      amount: period.actualAmount,
      discountPercentage: period.discountPercentage,
      discountAmount: period.discountAmount,
      totalAmount: period.totalAmount,
    }));

    // Create the transformed data object
    const transformedData = {
      description: formData.description,
      features: featuresArray,
      notes: formData.notes,
      price: formData.price,
      title: formData.title,
      frequency: formData.frequency,
      subscriptionPeriods: subscriptionArray,
    };

    console.log(transformedData);

    // Handle form submission here (e.g., send `transformedData` to the server)
    if (mode === "add") {
      onSubmitForm(transformedData, "add");
    } else if (mode === "edit") {
      transformedData.id = editItemId;
      onSubmitForm(transformedData, "edit");
    }
    setShowForm(false);
    onClose();
  };

  const handleDurationChange = (durations) => {
    console.log("Updated Durations:", durations);
    // Perform necessary updates based on new duration values
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit, (errors) =>
          orderErrorFocus(fields, errors, fieldRefs)
        )}
        mt={mode === "edit" ? 0 : -7}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              {field.type === "autocomplete" ? (
                <CustomAutocomplete
                  field={field}
                  register={register}
                  trigger={trigger}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                  fieldRefs={fieldRefs}
                  value={selectedFrequency}
                  onChange={(event, newValue) => {
                    setSelectedFrequency(newValue);
                    setValue(field.name, newValue);
                  }}
                  style={{
                    pointerEvents: viewData && editItemId ? "none" : "auto",
                  }}
                  disableClearable //remove clear button
                />
              ) : (
                <CustomTextField
                  field={field}
                  register={register}
                  trigger={trigger}
                  errors={errors}
                  fieldRefs={fieldRefs}
                  InputProps={{
                    sx: {
                      "& textarea": {
                        resize: "vertical", // Restrict resizing to vertical only
                      },
                    },
                    style: {
                      pointerEvents: viewData && editItemId ? "none" : "auto",
                    },
                    pattern: field.type === "number" ? "\\d*" : undefined,
                    inputProps: {
                      ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                    },
                  }}
                  InputLabelProps={{
                    shrink: field.type === "date" ? true : field.shrink,
                    style: {
                      pointerEvents: "none",
                    },
                  }}
                  validate={{
                    isValueContainsSplChars: (value) =>
                      field.type === "text" && field.type !== "number"
                        ? isValueContainsSplChars(value)
                        : true,
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>

        {/* Features */}
        <FeaturesBox
          viewData={viewData}
          editItemId={editItemId}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          trigger={trigger}
        />

        {/* Subscription Periods */}
        <SubscriptionPeriodsBox
          key={watch("price")} // Force re-render when price changes
          viewData={viewData}
          editItemId={editItemId}
          register={register}
          errors={errors}
          selectedFrequency={selectedFrequency}
          setValue={setValue}
          price={watch("price")}
          getValues={getValues}
          control={control}
          watch={watch}
          setError={setError}
          onDurationChange={handleDurationChange} // Pass the callback
          clearErrors={clearErrors}
        />

        {viewData && editItemId ? null : (
          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  );
}
