import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import clm from "country-locale-map";
import { LoadingButton } from "@mui/lab";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import Copyright from "../../common/Copyright";
import {
  encryptPwd,
  getPasswordErrorMessage,
  preventNonDigitInput,
  preventNonNumericInput,
} from "../../config/fieldConfig";
import { onSignUp } from "../../service/outerPagesService";
import { fields } from "./formFields";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { resourceComponents } from "../../../router";
import { CfTransBlueLogo } from "../../config/imageConfig";
import LoadingSpinner from "../../common/LoadingSpinner";

export default function SignUp() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  // Show Password Start
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Currency Options
  const [options, setOptions] = useState([]);

  // email value
  const [email, setEmail] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [vToken, setVToken] = useState("");

  const [orgId, setOrgId] = useState("");
  const [userName, setUserName] = useState("");

  // Loading Button
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const handleTogglePassword = () => setShowPassword((show) => !show);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  let navigate = useNavigate();

  const fetchData = async () => {
    try {
      const verifyToken = window.location.hash.slice(1);

      if (verifyToken) {
        // Perform token verification
        const res = await ApiService.verifiedToken(verifyToken);
        setVToken(verifyToken);
        console.log(res);

        if (
          res?.status === 200 &&
          res?.data?.status === "valid" &&
          res?.data?.email !== null &&
          res?.data?.country !== null &&
          res?.data?.subscriptionPlan !== null
        ) {
          setEmail(res.data.email);
          setSubscriptionPlan(res.data.subscriptionPlan);
          setOrgId(res.data.organizationId);
          setUserName(res.data.userName);

          const currencyValue = clm.getCurrencyByName(res.data.country);
          const validCurrencyOptions = [currencyValue].filter(
            (option) => option !== undefined
          );
          // Set options
          setOptions(validCurrencyOptions);
          setValue("currency", currencyValue);

          navigate("/sign_up");
        } else if (
          res?.status === 200 &&
          res?.data?.status === "Token Already verified"
        ) {
          navigate("/token_verified");
        } else {
          navigate("/access_denied");
        }
      } else {
        navigate("/access_denied");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShow(false);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  // Create an array of resource names
  const resourceNames = Object.keys(resourceComponents);

  // Admin Access
  const initialTableData = resourceNames.map((resource) => {
    return {
      resource,
      menuManagement: true,
      readAccess: true,
      writeAccess: true,
      deleteAccess: true,
    };
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // Encrypt the password
      const encryptedPassword = encryptPwd(data.password);

      // Modify the data object
      data.password = encryptedPassword;
      data.confirmPwd = "";

      delete data.confirmPassword;

      // Prepare combined data
      const combinedData = { roleName: 1, userRoleAccess: initialTableData };

      // Call onSignUp
      await onSignUp(
        data,
        navigate,
        vToken,
        setLoading,
        combinedData,
        orgId,
        userName
      );
    } catch (error) {
      console.error("Error during form submission:", error);
      // Optionally, you can set an error state here to display a message to the user
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  return (
    <>
      {show && <LoadingSpinner />}

      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 7,
            pt: 3,
            pb: 5,
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ":hover": { boxShadow: 5 },
          }}
        >
          <Box sx={{ mb: 2 }}>
            <img
              src={CfTransBlueLogo}
              alt="compFact logo"
              style={{ width: "100%", maxWidth: 150 }}
            />
          </Box>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          <Alert severity="info" color="warning" sx={{ m: 1 }}>
            Please do not refresh the page until the signup completed.
          </Alert>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            {fields.map((field) =>
              // Check if the field name is "currency"
              field.name === "currency" ? (
                <Autocomplete
                  key={field.name}
                  options={options}
                  disableClearable
                  value={
                    options.includes(watch(field.name))
                      ? watch(field.name)
                      : null
                  }
                  onChange={(event, newValue) => {
                    if (field.name === "currency") {
                      if (options.includes(newValue)) {
                        setValue("currency", newValue);
                        clearErrors("currency");
                      }
                    }
                  }}
                  onBlur={() => {
                    trigger(field.name); // Trigger validation on blur
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      required={field.required}
                      fullWidth
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      FormHelperTextProps={{ sx: { mb: -3 } }}
                    />
                  )}
                />
              ) : field.name === "userId" ||
                field.name === "subscriptionPlan" ? (
                <TextField
                  key={field.name}
                  margin="normal"
                  required={field.required}
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  value={
                    field.name === "userId" ? email || "" : subscriptionPlan
                  }
                  disabled
                />
              ) : (
                <TextField
                  key={field.name}
                  margin="normal"
                  required={field.required}
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  autoComplete="off"
                  type={
                    field.name === "password"
                      ? showPassword
                        ? "text"
                        : "password"
                      : field.name === "confirmPassword"
                      ? showConfirmPassword
                        ? "text"
                        : "password"
                      : field.type
                  }
                  onWheel={(event) => {
                    // Check if the input type is number to prevent scrolling
                    if (field.type === "number") {
                      event.target.blur();
                    }
                  }}
                  inputProps={{
                    ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                  }}
                  InputProps={
                    field.name === "password" ||
                    field.name === "confirmPassword"
                      ? {
                          endAdornment: (
                            <IconButton
                              aria-label={`toggle ${field.label} visibility`}
                              onClick={
                                field.name === "password"
                                  ? handleTogglePassword
                                  : handleToggleConfirmPassword
                              }
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                            >
                              {field.name === "password" ? (
                                showPassword ? (
                                  <VisibilityOffTwoTone />
                                ) : (
                                  <VisibilityTwoTone />
                                )
                              ) : showConfirmPassword ? (
                                <VisibilityOffTwoTone />
                              ) : (
                                <VisibilityTwoTone />
                              )}
                            </IconButton>
                          ),
                        }
                      : null
                  }
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    validate:
                      field.name === "password"
                        ? (value) => getPasswordErrorMessage(value)
                        : field.name === "confirmPassword"
                        ? (value) =>
                            value === getValues("password") ||
                            "Passwords do not match"
                        : null,
                    min: field.type === "number" && {
                      value: 1,
                      message: `${field.label} must be at least 1`,
                    },
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  FormHelperTextProps={{ sx: { mb: -3 } }}
                  onChange={(e) => {
                    setValue(field.name, e.target.value); // Update the form value
                    trigger(field.name); // Trigger validation on change
                    if (field.name === "password") {
                      // Revalidate confirmPassword if password changes
                      trigger("confirmPassword");
                    }
                  }}
                  onBlur={() => {
                    trigger(field.name); // Trigger validation on blur
                  }}
                  onKeyDown={(e) => {
                    if (field.type === "number") {
                      field.isNonDecimal
                        ? preventNonDigitInput(e)
                        : preventNonNumericInput(e); // Custom number field handling
                    }
                  }}
                />
              )
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Link href="/landing#pricing" target="_blank" variant="body2">
                View subscription plans
              </Link>
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  {...register("terms", { required: true })}
                  color="primary"
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    setValue("terms", isChecked);

                    if (isChecked) {
                      clearErrors("terms");
                    } else {
                      setError("terms", {
                        type: "manual",
                        message: "Please accept the terms & conditions",
                      });
                    }
                  }}
                  onBlur={() => {
                    trigger("terms");
                  }}
                />
              }
              required
              label={
                <Box component={"span"}>
                  I accept the{" "}
                  <Link href="/terms_conditions" target="_blank">
                    Terms &amp; Conditions
                  </Link>
                </Box>
              }
            />
            <Box sx={{ minHeight: 24 }}>
              {/* Reserve space for the error message */}
              {errors?.terms ? (
                <FormHelperText error>
                  {errors?.terms?.message ||
                    "Please accept the terms & conditions"}
                </FormHelperText>
              ) : (
                <FormHelperText>&nbsp;</FormHelperText> // Empty placeholder to reserve space
              )}
            </Box>

            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              loading={loading}
              loadingPosition="start"
              startIcon={<></>}
              style={{
                backgroundColor: loading ? "#448aff" : undefined,
                color: loading ? "#fff" : undefined,
              }}
              sx={{ mb: 1 }}
            >
              {!loading ? "Complete Registration" : "Loading..."}
            </LoadingButton>
          </Box>

          <Copyright sx={{ mt: 4, mb: 2 }} />
        </Box>
      </Container>
    </>
  );
}
