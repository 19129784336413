import toast from "react-hot-toast";
import { areLabelsDuplicates } from "../../components/config/fieldConfig";
import { EXCEPTION_MESSAGE } from "../../components/config/toastMessage";

export const addCustomFields = (
  customFieldData = {}, // Default to an empty object
  fieldsLabel = [], // Default to an empty array
  dynamicFormFields = [], // Default to an empty array
  newFields = [], // Default to an empty array
  setNewFields = () => {}, // Default to a no-op function
  setOpenCustomFieldDialog = () => {} // Default to a no-op function
) => {
  try {
    // Ensure customFieldData has required properties
    if (!customFieldData.labelName || !customFieldData.dataType) {
      throw new Error("Custom field data is incomplete.");
    }

    // Check if the field with the same name already exists
    const isDuplicate = fieldsLabel.some(
      (label) =>
        areLabelsDuplicates(label, customFieldData.labelName) ||
        dynamicFormFields.some(
          (field) =>
            field.name &&
            field.name.toLowerCase() !== label.toLowerCase() &&
            field.name.toLowerCase() === customFieldData.labelName.toLowerCase()
        ) ||
        newFields.some(
          (field) =>
            field.name &&
            field.name.toLowerCase() !== label.toLowerCase() &&
            field.name.toLowerCase() === customFieldData.labelName.toLowerCase()
        )
    );

    const InitCap =
      customFieldData.labelName.charAt(0).toUpperCase() +
      customFieldData.labelName.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");

    if (isDuplicate) {
      // Handle duplicate field name scenario
      toast.error(`"${customFieldData.labelName}" already exists.`);
      return;
    }

    // Create the new field object
    const newField = {
      name: customFieldData.labelName,
      label: InitCap,
      type: customFieldData.dataType, // Data type based on user input
      required: customFieldData.isMandatory, // Mandatory based on user input
      isCustom: true, // Flag to identify as a custom field
    };

    // Update state with the new custom field
    setNewFields((prevFields) => [...prevFields, newField]);

    // Close the custom field dialog
    setOpenCustomFieldDialog(false);

    // Show success message
    toast.success(
      `Custom Field "${customFieldData.labelName}" added successfully!`
    );
  } catch (error) {
    // Handle any unexpected errors here
    console.error("An error occurred while adding custom field:", error);
    toast.error(error?.message || EXCEPTION_MESSAGE);
  }
};
