export default function BeneficiaryFields({
  roleOptions = [],
  reportingToOptions = [],
  employeeTypeOptions = [],
  dynamicFormFields = [],
  newFields = [],
}) {
  const fields = [
    {
      name: "userId",
      label: "User ID",
      type: "text",
      autoFocus: true,
      required: true,
    },
    {
      name: "userName",
      label: "User Name",
      type: "text",
      required: true,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      required: true,
    },
    {
      name: "role",
      label: "Role",
      type: "autocomplete",
      options: Array.isArray(roleOptions) ? roleOptions : [],
      required: true,
    },
    {
      name: "territory",
      label: "Territory",
      type: "text",
      required: false,
    },
    {
      name: "reportingTo",
      label: "Reporting To",
      type: "autocomplete",
      options: Array.isArray(reportingToOptions) ? reportingToOptions : [],
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      shrink: true,
      required: false,
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
      shrink: true,
      required: false,
    },
    {
      name: "jobCategory",
      label: "Job Category",
      type: "text",
      required: false,
    },
    {
      name: "employeeType",
      label: "Employee Type",
      type: "autocomplete",
      options: Array.isArray(employeeTypeOptions) ? employeeTypeOptions : [],
      required: true,
    },
    {
      name: "notes",
      label: "Notes",
      type: "textarea",
      multiline: true,
      rows: 1,
      required: false,
    },
    {
      name: "eligible",
      label: "Eligible",
      type: "checkbox",
      required: false,
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "tel",
      required: false,
    },
    {
      isHeading: true,
      divider: true,
      heading: "Custom Fields",
      subtitle: "Payout to continue the compFact",
    },
    {
      name: "salaryAccount",
      label: "Salary Account",
      type: "text",
      required: false,
      isCustom: true,
    },
    {
      name: "payoutCurrency",
      label: "Payout Currency",
      type: "number",
      required: false,
      isCustom: true,
    },
    {
      name: "adjustmentToPayout",
      label: "Adjustment To Payout",
      type: "text",
      required: false,
      isCustom: true,
    },
    {
      name: "recoveryFromPayout",
      label: "Recovery From Payout",
      type: "text",
      required: false,
      isCustom: true,
    },
    {
      name: "targetCompensation",
      label: "Target Compensation",
      type: "text",
      required: false,
      isCustom: true,
    },
    ...(Array.isArray(dynamicFormFields) ? dynamicFormFields : []),
    ...(Array.isArray(newFields) ? newFields : []),
  ];

  return fields;
}
