import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import { RAZORPAY_KEY_ID } from "../../config/envConfig";
import {
  EMAIL_ID,
  ORG_ID,
  ORG_NAME,
  USER_NAME,
} from "../../config/sessionStorage";
import { CfTransBlueLogo } from "../../config/imageConfig";
import { BILLING_SCREEN, PRICING_SCREEN } from "../../config/constants";
import { handleMenuUpdate } from "../../../utils/menuHandler";

export default function useRazorpay({ payData = {} }) {
  // Default to an empty object if payData is not provided
  console.log("payData1", payData);

  // State to hold order ID and loading state
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderReceiptId, setOrderReceiptId] = useState("");

  // Load Razorpay SDK script
  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error("Razorpay SDK failed to load"));
      document.body.appendChild(script);
    });
  };

  // Create order with Razorpay API
  const createOrder = async (payData) => {
    console.log("Creating order with payData", payData); // Log payData for debugging
    try {
      const amount = payData?.amountToPay; // Amount in paise (e.g., 50000 -> ₹500)
      const currency = payData?.currency;

      // Call API to create order
      const result = await ApiService.createOrder(amount, currency);

      // Check if the result indicates success
      if (result?.status === 200) {
        setOrderId(result?.data?.id); // Set the order ID
        setOrderReceiptId(result?.data?.receipt); // Set the order receipt ID
      }

      return result; // Return the result for further processing
    } catch (err) {
      toast.error("Failed to create order. Please try again.");
      console.error("Order creation error:", err);
      throw err; // Rethrow the error for handling by calling function
    }
  };

  const navigate = useNavigate();

  // Reset state for the Razorpay process
  const resetState = () => {
    setOrderId("");
    setOrderReceiptId("");
    setIsLoading(false); // Hide loading spinner
  };

  // Display Razorpay payment modal
  const displayRazorpay = async (
    payData = {}, // Default to an empty object if payData is not provided
    fetchData = () => {},
    handleClose = () => {},
    token = "",
    isUpgrade = false
  ) => {
    setIsLoading(true);

    try {
      // Load Razorpay SDK
      const scriptLoaded = await loadRazorpayScript();

      if (!scriptLoaded) {
        toast.error("Razorpay SDK failed to load. Are you online?");
        setIsLoading(false);
        return; // Early exit if script loading fails
      }

      // Create order
      const order = await createOrder(payData);
      if (!order) {
        toast.error("Failed to create order. Please try again.");
        setIsLoading(false);
        return;
      }

      // Update orderId and orderReceiptId from created order
      setOrderId(order?.data?.id);
      setOrderReceiptId(order?.data?.receipt);

      // Configure Razorpay instance
      const razorpay = new window.Razorpay({
        key: RAZORPAY_KEY_ID, // Your Razorpay API key
        amount: payData?.amountToPay * 100, // Amount in paise
        currency: payData?.currency,
        name: "CompFact",
        description: "Payment for CompFact",
        image: CfTransBlueLogo, // Your logo
        order_id: orderId, // Use the order ID from the created order
        handler: async (response) => {
          try {
            // Prepare payment capture details
            const values = {
              paymentId: response?.razorpay_payment_id,
              orderId: orderId,
              orderReceiptId: orderReceiptId,
              userName: USER_NAME() || "",
              orgName: ORG_NAME() || "",
              orgId: ORG_ID() || "",
              subscriptionStartDate: payData?.subscriptionStartDate || "",
              subscriptionEndDate: payData?.subscriptionEndDate || "",
              renewalDate: payData?.renewalDate || "",
              discountAmount: payData?.discountAmount || 0,
              discount: payData?.discountPercentage || 0,
              cgst: payData?.cgst || 0,
              sgst: payData?.sgst || 0,
              pricingPlan: payData?.pricingPlan || "",
              month: payData?.month || "",
              currency: payData?.currency,
              beneficiary: payData?.beneficiary || "",
              totalAmount: payData?.totalAmount || 0,
              amountToPay: payData?.amountToPay || 0,
            };

            // Capture payment
            const result = await ApiService.capturePayment(values);
            console.log("capturePayment result", result);

            if (result?.status === 200 && result?.data?.status === "Success") {
              // Update menu based on payment success
              await handleMenuUpdate(
                isUpgrade ? PRICING_SCREEN : BILLING_SCREEN
              );
              handleClose(); // Close the modal
              navigate("/billing"); // Navigate to billing page
              fetchData(); // Refresh data after payment
              toast.success("Payment successful!");
            } else {
              toast.error("Failed to process payment");
            }
          } catch (error) {
            toast.error("Payment capture failed");
            console.error("Payment capture error:", error);
          } finally {
            resetState(); // Reset states after payment process is done
          }
        },

        // Prefill user details in Razorpay form
        prefill: {
          name: USER_NAME() || "",
          email: EMAIL_ID() || "",
          contact: "8838660266", // Default contact number
        },
        notes: {
          address: "CompFact Corporate Office",
        },
        theme: {
          color: "#F37254", // Razorpay modal theme color
        },
        config: {
          display: {
            blocks: {
              banks: {
                name: "All payment methods",
                instruments: [
                  { method: "upi" },
                  { method: "card" },
                  { method: "wallet" },
                  { method: "netbanking" },
                ],
              },
            },
            sequence: ["block.banks"], // Display order
            preferences: {
              show_default_blocks: false, // Hide default payment blocks
            },
          },
        },
        modal: {
          ondismiss: () => {
            resetState(); // Reset states when the modal is closed
          },
        },
      });

      razorpay.open(); // Open the Razorpay payment modal
    } catch (err) {
      resetState(); // Reset state in case of an error
      toast.error(
        err.message || "An error occurred while initializing Razorpay"
      );
      console.error("Razorpay initialization error:", err);
    }
  };

  return { displayRazorpay, isLoading }; // Expose functions and loading state
}
