import { forwardRef, useRef, useState } from "react";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Chip, Dialog, DialogActions } from "@mui/material";
import { ArrowDropDownTwoTone } from "@mui/icons-material";
import { format } from "date-fns";
import "../styles/DatePicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getInitialDateRange } from "../../utils/getInitialDateRange";

export default function DateRangePicker() {
  const { pathname } = useLocation(); // find the path

  const navigate = useNavigate(); // Hook to navigate

  const stopRemount = useRef(true);

  const { startDate: sDate, endDate: eDate } = getInitialDateRange(stopRemount);

  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  const handleChange = (update) => {
    try {
      // Update temporary dates without affecting the chip display
      setTempStartDate(update[0]);
      setTempEndDate(update[1]);
    } catch (error) {
      console.error("Error updating temporary dates:", error);
      toast.error(error?.message || "Date Range is invalid.");
    }
  };

  const handleConfirm = () => {
    try {
      // Update startDate and endDate with confirmed values
      const sDate = format(tempStartDate, "yyyy-MM-dd");
      const eDate = format(tempEndDate, "yyyy-MM-dd");
      sessionStorage.setItem("startDate", sDate);
      sessionStorage.setItem("endDate", eDate);
      setStartDate(sDate);
      setEndDate(eDate);

      // Redirect to the current pathname and pass a prop
      navigate(pathname);
      setIsPickerOpen(false);
    } catch (error) {
      console.error("Error confirming date selection:", error);
      toast.error(error?.message || "Date Range is invalid.");
    }
  };

  const handleCancel = () => {
    try {
      // Revert to original dates and close the picker
      setTempStartDate(startDate);
      setTempEndDate(endDate);
      setIsPickerOpen(false);
    } catch (error) {
      console.error("Error canceling date selection:", error);
      toast.error(error?.message || "Date Range is invalid.");
    }
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => {
      try {
        return (
          <Chip
            variant="outlined"
            color="secondary"
            className={className}
            ref={ref}
            onClick={onClick}
            onDelete={onClick}
            deleteIcon={<ArrowDropDownTwoTone />}
            label={value ? value : "Select Date Range"}
            sx={{
              width: { xs: "100%", sm: "auto" }, // Full width on small screens, auto on larger
              minWidth: 150, // Set a minimum width for consistency
              maxWidth: 300, // Optional: Set a max width
              flexGrow: 1, // Allows it to grow if placed within a flex container
            }}
          />
        );
      } catch (error) {
        console.error("Error rendering ExampleCustomInput:", error);
        toast.error(error?.message || "Date Range is invalid.");
        return null; // or return a fallback UI
      }
    }
  );

  const formattedStartDate = (() => {
    try {
      return tempStartDate ? format(tempStartDate, "MMM d, yyyy") : "";
    } catch (error) {
      console.error("Error formatting start date:", error);
      toast.error(error?.message || "Invalid start date format.");
      return ""; // fallback
    }
  })();

  const formattedEndDate = (() => {
    try {
      return tempEndDate ? format(tempEndDate, "MMM d, yyyy") : "";
    } catch (error) {
      console.error("Error formatting end date:", error);
      toast.error(error?.message || "Invalid end date format.");
      return ""; // fallback
    }
  })();

  const displayValue = (() => {
    try {
      return formattedStartDate && formattedEndDate
        ? `${formattedStartDate} - ${formattedEndDate}`
        : "Select Date Range";
    } catch (error) {
      console.error("Error creating display value:", error);
      toast.error(error?.message || "Date Range is invalid.");
      return "Select Date Range"; // fallback
    }
  })();

  const isOkButtonDisabled = !tempStartDate || !tempEndDate; // Disable OK if start or end date is missing

  return (
    <>
      <ExampleCustomInput
        value={displayValue}
        onClick={() => setIsPickerOpen(true)}
      />

      {/* Dialog for Date Picker with OK and Cancel Buttons */}
      <Dialog open={isPickerOpen}>
        <Box sx={{ p: 1, pb: 0 }}>
          <DatePicker
            selected={tempStartDate}
            onChange={handleChange}
            startDate={tempStartDate}
            endDate={tempEndDate}
            selectsRange
            inline
            monthsShown={2}
            dateFormat="MMM d, yyyy"
            showMonthDropdown
            showYearDropdown
          />
        </Box>

        {/* OK and Cancel Buttons */}
        <DialogActions sx={{ pt: 0 }}>
          <Button
            variant="outlined"
            onClick={handleCancel}
            size="small"
            sx={{ fontSize: 11 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            sx={{ fontSize: 11 }}
            size="small"
            disabled={isOkButtonDisabled} // Disable button when no dates are selected
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
