export default function TypeColumns({ actionsColumn = null }) {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "typeName",
      headerName: "Type Name",
      width: 150,
    },
    {
      field: "typeValue",
      headerName: "Type Value",
      width: 150,
    },
  ];

  // Add the actions column if it exists
  if (actionsColumn) {
    columns.push(actionsColumn);
  }

  return columns;
}
