import { AuditLogService } from "./service";
import CustomDataGrid from "../../../utils/CustomDataGrid";

export default function AuditLog() {
  const {
    rows = [],
    columns = [],
    screenName = "",
    displayName = "",
    noTableDataMsg = "",
    isTableLoading = false,
    toolbarMenuProps = {},
  } = AuditLogService() || [];

  return (
    <>
      {/* CustomDataGrid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={isTableLoading}
        message={noTableDataMsg}
        toolbarProps={{ displayName, screenName, ...toolbarMenuProps }}
      />
    </>
  );
}
