import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import { JWT_TOKEN, USER_ROLE } from "../../config/sessionStorage";
import { LOADING_MSG } from "../../config/toastMessage";
import { addSerialNumber } from "../../config/fieldConfig";
import {
  ADMIN_NAME,
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_SCREEN,
} from "../../config/constants";
import { getActionsColumn } from "../../../utils/actionColumn";
import { customAddType } from "../../../utils/addTypeFunctions";
import useSubmitForm from "../../../utils/useSubmitForm";
import { getAccessLevels } from "../../../utils/accessLevels";
import { masterUploadFile } from "../../../utils/Master/fileUploadHelper";
import { addCustomFields } from "../../../utils/Master/addCustomField";
import { useDynamicFields } from "../../../utils/Master/useDynamicFields";
import { extractCustomField } from "../../../utils/Master/customFieldExtractor";
import { extractCustomFieldApiValues } from "../../../utils/Master/customFieldApiHandler";
import CustomerFields from "./fields";
import CustomerColumns from "./columns";

export const CustomerService = (fileInputRef) => {
  const [data, setTableData] = useState([]); // State to hold customer data for the table
  const [editFormOpen, setEditFormOpen] = useState(false); // State to control the visibility of the edit form
  const [editItemId, setEditItemId] = useState(null); // State to store the ID of the item being edited
  const [editFormData, setEditFormData] = useState({}); // State to store the data for the edit form
  const [dynamicColumns, setDynamicColumns] = useState([]); // State to manage dynamic columns for the table
  const [customFieldsArrayObject, setCustomFieldsArrayObject] = useState({}); // State to store custom fields
  const [fieldMappingArrayObject, setFieldMappingArrayObject] = useState([]); // State to hold field mapping data
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false); // State to control the custom field dialog visibility
  const [viewData, setViewData] = useState(false); // State to determine if data is being viewed (read-only mode)
  const [isEData, setIsEData] = useState(false); // State to indicate if editing error records
  const [resObject, setResObject] = useState({}); // State to hold response object from API calls
  const [response, setResponse] = useState({}); // State to store API responses
  const [fieldsLabel, setFieldsLabel] = useState([]); // State to hold labels for form fields
  const [configObj, setConfigObj] = useState([]); // State to store configuration settings
  const [customerIdCheck, setCustomerIdCheck] = useState([]); // State to store customer ID checks
  const [relationshipTypeOptions, setCustomerTypeOptions] = useState([]); // State to hold options for customer types
  const [relationshipTypeOptionsWithId, setRelationshipTypeOptionsWithId] =
    useState([]); // State to hold customer types with IDs
  const [isErrorColor, setIsErrorColor] = useState(false); // State to determine if error color should be applied
  const [uploading, setUploadInProgress] = useState(false); // State to track if an upload is in progress
  const [isUploading, setIsUploading] = useState(false); // State to track the upload status
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial state for table loading (true when loading)
  const [newFields, setNewFields] = useState([]); // State to hold new field data
  const [open, setOpen] = useState(false); // State to control the visibility of a dialog or modal
  const [apiEndpoint, setApiEndpoint] = useState("getCustomers"); // State to define the API endpoint for fetching data
  const [selectedOption, setSelectedOption] = useState("Get All"); // State to hold the currently selected option for data retrieval

  // Ref to prevent remounting of components
  const stopRemount = useRef(true);

  // Constants for screen identifier
  const screenName = CUSTOMER_SCREEN || "";
  // Constants for display screen name UI identifier
  const displayName = CUSTOMER_DISPLAY_NAME || "";

  // Message to show during upload loading state
  const uploadLoadingMsg = LOADING_MSG;

  // Role name of the current user for access control
  const ROLE_NAME = USER_ROLE();

  // Function to open the add form dialog
  const addFormOpen = () => {
    try {
      setOpen(true); // Set open state to true to display add form
      formApiFetch();
    } catch (error) {
      console.error("Error opening add form:", error);
    }
  };

  // Function to close the add form dialog
  const addFormClose = () => {
    try {
      setOpen(false); // Set open state to false to hide add form
    } catch (error) {
      console.error("Error closing add form:", error);
    }
  };

  // Handles option change and sets loading state, API endpoint, and selected option
  const handleOptionChange = (option, endpoint) => {
    // Set loading state only if the option has changed
    setIsTableLoading(selectedOption !== option);
    // Update API endpoint based on the selected option
    setApiEndpoint(endpoint);
    // Set the currently selected option
    setSelectedOption(option);
    // Reset the red error color on option change
    setIsErrorColor(false);
    // Flag to trigger data fetching on next render
    stopRemount.current = true;
  };

  // Menu items with labels and associated actions for each option
  const menuItems = [
    {
      label: "Get All",
      action: () => handleOptionChange("Get All", "getCustomers"),
    },
    ROLE_NAME === ADMIN_NAME && {
      label: "All Errors",
      action: () => {
        handleOptionChange("All Errors", "getErrorCustomers");
        setIsErrorColor(true); // Set the navigate scenario active
      },
    },
  ].filter(Boolean);

  // Props for the toolbar menu, including items and currently selected option
  const toolbarMenuProps = { menuItems, selectedOption };

  // Function to handle current error selection
  const handleCurrentError = () => {
    handleOptionChange("All Errors", "getCurrentErrorCustomers");
    setIsErrorColor(true); // Set the navigate scenario active
  };

  // Effect to sync the configuration object whenever it changes
  useEffect(() => {
    setConfigObj(configObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configObj]);

  // Effect to handle data fetching based on the API endpoint
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint]);

  // Fetches data from the API and updates table and custom field states.
  const fetchData = async (isSubmit = false) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Extract custom field values and required data from the API
      const { getPojo, typeData, mappingPojoRes } =
        await extractCustomFieldApiValues(
          screenName, // The screen for which data is fetched
          token, // Authorization token
          setRelationshipTypeOptionsWithId, // Set relationship type options in the state
          setCustomerTypeOptions, // Set customer type options in the state
          setConfigObj, // Set configuration object
          setFieldsLabel // Set field labels for the UI
        );

      // Determine the effective endpoint based on the isSubmit flag and current apiEndpoint
      const effectiveEndpoint =
        isSubmit && apiEndpoint === "getCurrentErrorCustomers"
          ? "getCustomers"
          : apiEndpoint;

      // Set error color to false only if this is a submit request and the endpoint was modified
      if (isSubmit && effectiveEndpoint === "getCustomers") {
        setIsErrorColor(false);
        setSelectedOption("Get All");
        setApiEndpoint("getCustomers");
      }

      // Make an API request to the specified endpoint using the token
      const response = await ApiService[effectiveEndpoint](token);

      // Check if the response status is 200 (OK) and if the data is an array
      if (response?.status !== 200 || !Array.isArray(response?.data)) {
        throw new Error("Invalid response format or status");
      }

      // Extract the response data
      const custData = response?.data;

      // Map over the custData array to replace relationshipTypeId with typeValue
      const updatedTransferData = custData?.map((transfer) => {
        // Find the corresponding relationshipType entry from the fetched typeData
        const matchedRelationshipType = typeData?.find(
          (relationshipType) =>
            relationshipType.id === parseInt(transfer.relationshipTypeId) // Ensure both are numbers
        );

        // Return a new object with the updated relationshipType (or fallback if not found)
        return {
          ...transfer,
          relationshipType: matchedRelationshipType
            ? matchedRelationshipType.typeValue // Replace with typeValue if found
            : transfer.relationshipType, // Fallback to original relationshipType
        };
      });

      // Update table data with the modified customer data
      setTableData(updatedTransferData);

      // Extract additional custom field information and update related states
      extractCustomField(
        custData,
        getPojo, // Get Pojo data for further mapping
        mappingPojoRes, // Mapping response data for UI fields
        setResponse, // Set the raw response data
        setResObject, // Set response as object for further operations
        setDynamicColumns, // Set dynamic columns for table display
        setCustomFieldsArrayObject, // Set the custom field array for UI rendering
        setFieldMappingArrayObject // Set field mapping data for correct UI presentation
      );
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error fetching data:", error);

      // Handle the error by resetting relevant states to empty or default values
      setTableData([]); // Set table data to empty array
      setResponse([]); // Set response to an empty array
      setResObject({}); // Set response object to an empty object
      setDynamicColumns([]); // Clear dynamic columns
      setCustomFieldsArrayObject([]); // Clear custom fields
      setFieldMappingArrayObject([]); // Clear field mappings
      setRelationshipTypeOptionsWithId([]); // Clear relationship type options
      setCustomerTypeOptions([]); // Clear customer type options
      setConfigObj({}); // Clear configuration object
      setFieldsLabel([]); // Clear field labels
    } finally {
      // Whether the request succeeded or failed, stop the table loading spinner
      setIsTableLoading(false);
    }
  };

  // To fetch customer data
  const formApiFetch = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // Check if the token is not available
      if (!token) throw new Error("Token not found or invalid");

      // Fetch all Customer data using the API service
      const response = await ApiService.getCustomers(token);

      // Check if the response status is 200 and if the data is an array
      if (response?.status === 200 && Array.isArray(response?.data)) {
        // Extract customer IDs from the response data
        const custId = response?.data?.map((customer) => customer.customerId);
        // Set the state with the fetched customer IDs
        setCustomerIdCheck(custId);
      } else {
        // If response is not 200 or data is not an array, set an empty array
        setCustomerIdCheck([]);
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error fetching customer data:", error?.message);
      // Optionally, set the customer IDs to an empty array if an error occurs
      setCustomerIdCheck([]);
    }
  };

  // Get dynamic form fields using the custom fields and config
  const dynamicFormFields = useDynamicFields(
    customFieldsArrayObject, // Custom fields from the user
    fieldMappingArrayObject, // Field mapping configurations
    configObj // Additional settings for the fields
  );

  // Function to handle adding a custom field
  const handleAddCustomField = (customFieldData) => {
    // Call addCustomFields to add the new custom field
    addCustomFields(
      customFieldData, // Data for the custom field
      fieldsLabel, // List of existing labels
      dynamicFormFields, // Current dynamic form fields
      newFields, // Custom fields that have been added
      setNewFields, // Function to update new fields
      setOpenCustomFieldDialog // Function to close the dialog
    );
  };

  // Function to handle adding a customer type
  const addCustomerType = async (value, setValue) => {
    await customAddType(
      value,
      setValue,
      setCustomerTypeOptions,
      "relationshipType"
    );
  };

  // Function to handle file upload for import
  const handleUploadFile = (event) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call masterUploadFile and wait for it to complete
        await masterUploadFile(
          event,
          fileInputRef, // Reference to the file input element
          screenName, // Screen name for the upload context
          () => fetchData(true), // Call fetchData with `true`
          setIsUploading, // Function to toggle loading state
          handleCurrentError, // Function to handle any error during the process
          reject, // Reject the promise on failure
          resolve // Resolve the promise on success
        );
      } catch (error) {
        // Log any error that occurs during the file upload process
        console.error("File upload failed:", error);
      }
    });
  };

  // Function to handle edit button click
  const handleEdit = (id, viewData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }
      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }
      // Find the row data based on the provided ID
      const rowData = data?.find((row) => row?.id === id);
      // Check if row data is found
      if (!rowData) {
        throw new Error(`No data found for the ID: ${id}`);
      }
      // Set the edit form state and populate with row data
      setEditItemId(id); // Set the edit form state and data
      setEditFormOpen(true);
      setEditFormData(rowData); // Set edit form data
      setViewData(viewData);
      setIsEData(isErrorColor);
      formApiFetch();
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(`Error editing item: ${error?.message}`); // Display the exact error message in a toast
    }
  };

  // Get fields from fields, default to empty array if not an array
  const fields = Array.isArray(
    CustomerFields({ dynamicFormFields, newFields, relationshipTypeOptions })
  )
    ? CustomerFields({ dynamicFormFields, newFields, relationshipTypeOptions })
    : [];

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData: () => fetchData(true), // Pass `true` to fetchData for submission-specific logic
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Call CustomerColumns once and store the result
  const columnsResult = CustomerColumns({
    actionsColumn,
    dynamicColumns,
    isErrorColor,
    handleEdit,
  });

  // Check if the result is an array
  const columns = Array.isArray(columnsResult) ? columnsResult : [];

  // Add serial numbers to the rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : [];

  // Get access levels for the specified resource using a utility function
  const { writeAccess } = getAccessLevels(screenName);

  // Memoize the column visibility model to avoid recreating it on every render
  const columnVisibilityModel = useMemo(
    () => ({
      // Set initial visibility for standard columns to false (hidden)
      fullCustomerName: false,
      customerLevel: false,
      subCustomerOf: false,
      leadSource: false,
      startDate: false,
      endDate: false,
      renewalDate: false,
      active: false,
      // Set visibility for dynamic columns to false (hidden)
      ...dynamicColumns.reduce((acc, column) => {
        acc[column.field] = false; // Hide each dynamic column
        return acc; // Return the accumulator
      }, {}),
    }),
    [dynamicColumns] // Recalculate if dynamicColumns changes
  );

  // Define properties for the toolbar import component
  const toolbarImportProps = {
    handleUploadFile, // Function to handle file upload
    fileInputRef, // Reference to the file input element
    uploading, // State to track if a file is currently uploading
    setUploadInProgress, // Function to update upload progress state
  };

  return {
    screenName, // Current screen name
    displayName, // UI display name
    uploadLoadingMsg, // Upload loading message
    rows, // Table data rows
    columns, // Table column configuration
    fields, // Customer form fields
    writeAccess, // User write access flag
    toolbarMenuProps, // Toolbar menu properties
    toolbarImportProps, // Import toolbar properties
    open, // Dialog/modal open state
    addFormOpen, // Add form visibility state
    addFormClose, // Function to close the add form
    editFormOpen, // Edit form visibility state
    editItemId, // ID of the item being edited
    editFormData, // Data for the edit form
    setEditFormOpen, // Function to toggle edit form state
    viewData, // Read-only view flag
    setViewData, // Function to set viewData state
    onSubmitForm, // Form submission handler
    submitLoading, // Submission loading state
    dynamicFormFields, // Dynamically changing form fields
    handleAddCustomField, // Function to add a custom field
    openCustomFieldDialog, // Custom field dialog visibility
    setOpenCustomFieldDialog, // Function to set custom field dialog state
    columnVisibilityModel, // Column visibility management
    apiEndpoint, // API endpoint for customer data
    isUploading, // Uploading state flag
    isTableLoading, // Table loading state
    isErrorColor, // Error styling flag
    isEData, // Error data flag
    resObject, // Response object from API
    response, // API response data
    addCustomerType, // Function to add customer type
    relationshipTypeOptions, // Options for relationship types
    relationshipTypeOptionsWithId, // Relationship types with IDs
    customerIdCheck, // Customer ID check state
    setNewFields,
  };
};
