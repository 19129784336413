import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import UpgradeDialog from "../Pricing/upgradeDialog";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";
import { FREE_PLAN } from "../../config/constants";

export default function Subscriptions({
  subscriptionStartDate = "",
  subscriptionEndDate = "",
  renewalPrice = "-",
  renewalPlan = "-",
  renewalMonth = 1,
  noOfBeneficiary = 1,
  currentCurrency = "-",
  fetchData = () => {},
  planFetchData = async () => [],
  token = "",
  lastAmountToPay = 0,
  planRenewalDate = "",
}) {
  // State to control dialog visibility
  const [open, setOpen] = useState(false);
  // State to store selected plan
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Function to handle dialog opening and fetching plans
  const handleClickOpen = async (planTitle) => {
    try {
      // Call the planFetchData function to fetch pricing plans
      const fetchedPlans = await planFetchData();

      // Check if fetchedPlans is null or undefined
      if (!Array.isArray(fetchedPlans)) {
        console.error("Fetched plans is not an array:", fetchedPlans);
        return; // Exit if the fetched plans are not valid
      }

      // Check if fetchedPlans has been updated
      if (fetchedPlans.length === 0) {
        console.error("No pricing plans available.");
        return; // Exit if there are no pricing plans
      }

      // Find the selected plan by its title
      const plan = fetchedPlans.find((p) => p.title === planTitle);

      if (plan) {
        setSelectedPlan(plan); // Set the selected plan object
        setOpen(true); // Open the dialog here after setting the plan
      } else {
        console.error("Selected plan not found:", planTitle);
      }
    } catch (error) {
      // Catch any unexpected errors
      console.error("An error occurred while opening the plan dialog:", error);
    }
  };

  // Function to close the dialog and reset selected plan
  const handleClose = () => {
    setOpen(false); // Close the dialog
    setSelectedPlan(null); // Reset selected plan
  };

  return (
    <>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={1.5}>
          <Typography gutterBottom>Current Plan:</Typography>
          <Typography gutterBottom>{renewalPlan || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>No of Beneficiary:</Typography>
          <Typography gutterBottom>{noOfBeneficiary || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>Start Date:</Typography>
          <Typography gutterBottom>
            {formatDateDDMMYYYY(subscriptionStartDate) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>End Date:</Typography>
          <Typography gutterBottom>
            {formatDateDDMMYYYY(subscriptionEndDate) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>{`Renewal Cost (${
            currentCurrency || "-"
          }):`}</Typography>
          <Typography gutterBottom>{renewalPrice || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2.5}>
          <Button
            variant="contained"
            component={Link}
            to={"/pricing"}
            sx={{ mt: 2, mr: 2 }}
          >
            Upgrade
          </Button>

          {lastAmountToPay >= 0 && renewalPlan !== FREE_PLAN && (
            <Button
              variant="outlined"
              onClick={() => handleClickOpen(renewalPlan)} // Example: passing the plan
              sx={{ mt: 2 }}
            >
              Renew now
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Render UpgradeDialog if open and a plan is selected */}
      {open && selectedPlan && (
        <Box>
          <UpgradeDialog
            open={open}
            handleClose={handleClose}
            plan={selectedPlan} // Pass the selected plan object
            noOfBeneficiary={noOfBeneficiary}
            token={token}
            fetchData={fetchData}
            renewalMonth={renewalMonth}
            planRenewalDate={planRenewalDate}
          />
        </Box>
      )}
      <Divider />
    </>
  );
}
