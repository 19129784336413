import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import moment from "moment-timezone";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  CloseTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import {
  isDuplicate,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  orderErrorFocus,
} from "../../config/fieldConfig";
import { CustomTextField } from "../../../utils/CustomInputFields";
import calculateScheduler from "./calculateScheduler";
import FrequencyTable from "./frequencyTable";

export default function PayFrequencyForm({
  screenName = "", // Name of the form (add/edit)
  open = false, // Controls if form is open
  onClose = () => {}, // Function to close the form
  mode = "add", // Mode of the form (add/edit)
  editItemId = null, // ID of the item to be edited
  editFormData = { current: false }, // Pre-filled data for editing (default prevents undefined)
  fields = [], // List of form fields
  frequencyNameCheck = [], // Frequency name validation function
  frequencyCheck = [], // Frequency validation function
  viewData = false, // Data to display
  setViewData = () => {}, // Function to set view data
  calculateViewData = false, // Data for calculations
  setCalculateViewData = () => {}, // Function to set calculated data
  frequencyWithId = [], // Frequencies with their IDs
  onSubmitForm = () => {}, // Function to handle form submission
  writeAccess = false, // Controls write access permissions
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  // Local state management
  const [isCurrentChecked, setIsCurrentChecked] = useState(false); // Default to false for add mode
  const [showScheduler, setShowScheduler] = useState(false);
  const [frequencyRows, setFrequencyRows] = useState([]);
  const [initialFrequency, setInitialFrequency] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null); // Year Selector Textfield
  const [initialized, setInitialized] = useState(false); // To track form initialization

  const fieldRefs = useRef({});

  const frequency = watch("frequency");

  // Reset form initialization state when the form is closed
  useEffect(() => {
    if (!open) {
      setInitialized(false);
    }
  }, [open]);

  // Reset the form with the default and dynamic fields
  useEffect(() => {
    if (mode === "add") {
      reset();
    }
  }, [mode, open, reset]);

  // Initialize or reset the form when the modal is opened
  useEffect(() => {
    if (open && !initialized) {
      try {
        reset();
        setIsCurrentChecked(false);
        setSelectedYear(null);
        setShowScheduler(false);

        // If edit mode, populate the form with edit data
        if (mode === "edit" && editItemId !== null && editFormData) {
          // Set form data with the provided editFormData
          fields.forEach((field) => {
            if (field.name === "fiscalYear") {
              // Set the selectedYear state to the value of the fiscalYear field
              setSelectedYear({ label: editFormData[field.name] });
              setValue("fiscalYear", editFormData[field.name]);
            } else {
              setValue(field.name, editFormData[field.name]);
            }
          });

          // Set the isCurrentChecked state based on the value from editFormData
          setIsCurrentChecked(editFormData.current === true);
        }
        setInitialized(true); // Mark the form as initialized
      } catch (error) {
        console.error("Error initializing form:", error);
      }
    }
  }, [
    editFormData,
    editItemId,
    fields,
    initialized,
    mode,
    open,
    reset,
    setValue,
  ]);

  // Set initial frequency and handle form logic when the component mounts or edits occur
  useEffect(() => {
    try {
      if (mode === "add") {
        setViewData();
        setCalculateViewData(false);
        setInitialFrequency(null);
      }

      if (mode === "edit") {
        const currentFrequency = watch("frequency");
        setInitialFrequency(currentFrequency);
      }

      if (mode === "edit" && viewData === true) {
        calculateScheduler(watch, setFrequencyRows);
      }
    } catch (error) {
      console.error("Error setting initial frequency:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, mode]);

  // Handle scheduler button click
  const handleSchedulerClick = () => {
    try {
      // Helper function to check if a field is empty
      const isEmpty = (field) => {
        const value = watch(field);
        return value === "" || value === null || value === undefined;
      };

      // Validate required fields
      const hasFrequencyError = errors?.frequency;
      const isStartDateValid = !isEmpty("startDate");
      const isFrequencyValid = !isEmpty("frequency");

      // Show error messages if fields are invalid
      if (!isStartDateValid) {
        setError("startDate", {
          type: "manual",
          message: "Start Date is required",
        });
      }

      if (!isFrequencyValid) {
        setError("frequency", {
          type: "manual",
          message: "Frequency is required",
        });
      }

      // Proceed if all fields are valid
      if (!hasFrequencyError && isStartDateValid && isFrequencyValid) {
        calculateScheduler(watch, setFrequencyRows);
        setShowScheduler(!showScheduler);
      }
    } catch (error) {
      console.error("Error handling scheduler:", error);
    }
  };

  // Handle form submission
  const onSubmit = (formData) => {
    try {
      // Calculate the scheduler data
      const paymentPeriod = calculateScheduler(watch, setFrequencyRows);

      // Concatenate the scheduler data with the form data
      const mainFormData = {
        ...formData,
        paymentPeriod: calculateViewData
          ? editFormData?.paymentPeriod
          : paymentPeriod,
      };

      // Map frequency to its corresponding ID
      const TypeWithId = frequencyWithId.find(
        (frequency) => frequency.frequency === formData.frequency
      );

      if (TypeWithId) {
        mainFormData.frequencyId = TypeWithId.id;
      } else {
        mainFormData.frequencyId = null;
      }

      // Determine the action based on mode
      if (mode === "add") {
        onSubmitForm(mainFormData, "add");
      } else if (mode === "edit") {
        mainFormData.id = editItemId;
        onSubmitForm(mainFormData, "edit");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error.message); // Show toast with the error message
    } finally {
      onClose(); // Close the form after submission
    }
  };

  // Validate and handle frequency change
  const handleFrequencyChange = (enteredValue) => {
    try {
      if (
        frequencyCheck.includes(enteredValue) &&
        enteredValue !== initialFrequency
      ) {
        setError("frequency", {
          type: "duplicate",
          message: "This frequency is already selected.",
        });
        setShowScheduler(false);
      } else {
        clearErrors("frequency");
      }
    } catch (error) {
      console.error("Error handling frequency change:", error);
    }
  };

  // Custom validation for frequency input
  const validateFrequency = (value) => {
    try {
      if (frequencyCheck.includes(value) && value !== initialFrequency) {
        return "This frequency is already selected.";
      } else if (!value) {
        return "Frequency is required";
      }
      return true;
    } catch (error) {
      console.error("Error during frequency validation:", error);
      return "An error occurred during validation. Please try again.";
    }
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
        &nbsp;{screenName}
        {viewData && writeAccess ? (
          <IconButton
            aria-label="edit"
            style={{ position: "absolute", top: 10, right: 45 }}
            color="primary"
            title="Edit"
            onClick={() => setViewData(false)}
          >
            <EditTwoTone />
          </IconButton>
        ) : null}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            orderErrorFocus(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index}>
                {field.name === "current" ? (
                  <FormControl
                    error={!!errors[field.name]}
                    component="fieldset"
                    variant="outlined"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={isCurrentChecked} // Use the state variable here
                          onChange={(e) =>
                            setIsCurrentChecked(e.target.checked)
                          } // Update the state when the checkbox changes
                          disabled={viewData} // Disable the checkbox based on viewData condition
                        />
                      }
                      sx={{ mt: 1 }} // UI display center
                      label={field.label}
                      required={field.required}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                    />
                    <FormHelperText>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                ) : field.type === "autocomplete" ? (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={null}
                    rules={{
                      validate: validateFrequency,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl error={!!error} fullWidth>
                        <Autocomplete
                          sx={{ mt: 1 }} // without custom autocomplete only apply the style
                          name={field.name}
                          options={field.options}
                          getOptionLabel={(option) =>
                            field.name === "frequency" && option
                              ? option.toString()
                              : option?.label?.toString() ?? ""
                          }
                          value={
                            field.name === "frequency"
                              ? field.options.includes(watch(field.name))
                                ? watch(field.name)
                                : null
                              : selectedYear
                          }
                          readOnly={
                            !!(
                              (viewData && editItemId) ||
                              (calculateViewData &&
                                (field.name === "frequency" ||
                                  field.name === "fiscalYear"))
                            )
                          }
                          style={{
                            pointerEvents:
                              (viewData && editItemId) ||
                              (calculateViewData &&
                                (field.name === "frequency" ||
                                  field.name === "fiscalYear"))
                                ? "none"
                                : "auto",
                          }}
                          onChange={(event, value) => {
                            if (field.name === "frequency") {
                              if (value) {
                                setValue(field.name, value);
                                handleFrequencyChange(value);
                                const hasFrequencyError = errors.frequency;
                                if (
                                  !hasFrequencyError &&
                                  watch("startDate") !== ""
                                ) {
                                  calculateScheduler(watch, setFrequencyRows); // Recalculate table values
                                }
                              } else {
                                setShowScheduler(false);
                              }
                            } else {
                              setSelectedYear(value);
                              const startDateYear =
                                watch("startDate") !== "" &&
                                value &&
                                moment(watch("startDate")).year();
                              if (
                                startDateYear &&
                                startDateYear !== value.label
                              ) {
                                const newStartDate = moment(watch("startDate"))
                                  .set("year", value.label)
                                  .format("YYYY-MM-DD");
                                setValue("startDate", newStartDate);
                                const hasFrequencyError = errors.frequency;
                                if (
                                  !hasFrequencyError &&
                                  watch("startDate") !== ""
                                ) {
                                  calculateScheduler(watch, setFrequencyRows); // Recalculate table values
                                }
                              }
                            }
                          }}
                          isOptionEqualToValue={(option, value) => {
                            if (field.name === "fiscalYear") {
                              return option.label === value?.label; // Compare label property
                            }
                            return option === value;
                          }}
                          onBlur={() => {
                            const currentFrequency = watch("frequency");
                            if (validateFrequency(currentFrequency) === true) {
                              clearErrors("frequency");
                            }
                            trigger(field.name); // Trigger validation on blur
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={field.label}
                              variant="outlined"
                              required={field.required}
                              size="small"
                              fullWidth
                              {...register(field.name, {
                                required: field.required
                                  ? `${field.label} is required`
                                  : false,
                              })}
                              InputLabelProps={{
                                style: {
                                  pointerEvents: "none",
                                },
                              }}
                              error={!!errors[field.name]} // Ensure error handling is consistent
                              helperText={errors[field.name]?.message ?? ""}
                              FormHelperTextProps={{ sx: { mb: -3 } }}
                              inputRef={(el) => {
                                if (el) {
                                  fieldRefs.current[field.name] = el;
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                ) : (
                  <CustomTextField
                    field={field}
                    register={register}
                    trigger={trigger}
                    errors={errors}
                    fieldRefs={fieldRefs}
                    value={watch(field.name) || ""}
                    InputLabelProps={{
                      shrink: field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    InputProps={{
                      style: {
                        pointerEvents:
                          (viewData && editItemId) ||
                          (calculateViewData && field.name === "startDate")
                            ? "none"
                            : "auto",
                      }, // This disables interaction based on conditions
                      inputProps: {
                        max: `${watch("fiscalYear")}-12-31`,
                        min: `${watch("fiscalYear")}-01-01`,
                        ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                        style: {
                          textTransform:
                            field.type === "date" ? "uppercase" : "none",
                        },
                      },
                    }}
                    validate={{
                      isValueContainsSplChars: (value) =>
                        field.type === "text" &&
                        field.type !== "email" &&
                        field.type !== "number"
                          ? isValueContainsSplChars(value)
                          : true,
                      isValueStartsWithNumber: (value) =>
                        field.required && field.name === "frequencyName"
                          ? isValueStartsWithNumber(value, field.label)
                          : true,
                      isDuplicate: (value) => {
                        if (field.name === "frequencyName") {
                          return isDuplicate(
                            frequencyNameCheck,
                            field.label,
                            value,
                            mode,
                            editFormData,
                            field.name,
                            false
                          );
                        } else {
                          return true;
                        }
                      },
                    }}
                    onChange={(e) => {
                      const hasFrequencyError = errors.frequency;
                      if (!hasFrequencyError && watch("startDate") !== "") {
                        calculateScheduler(watch, setFrequencyRows); // Recalculate table values
                      } else {
                        setShowScheduler(false);
                      }
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>

          {viewData && editItemId ? null : (
            <DialogActions sx={{ p: 0, mt: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSchedulerClick}
                endIcon={
                  showScheduler ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
                }
              >
                View Pay Period
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {mode === "add" ? "Submit" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          )}

          {(showScheduler || viewData) && (
            <FrequencyTable
              rows={frequencyRows}
              frequency={frequency}
              viewData={viewData}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
