import { Box, Button, Container, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

const PaymentFailed = () => {
  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <ErrorOutline sx={{ fontSize: 80, color: "red" }} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Payment Failed
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Unfortunately, your payment could not be processed. Please try again.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" href="/billing">
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentFailed;
