import { Box, Button } from "@mui/material";
import toast from "react-hot-toast";

export default function MonitorColumns({
  approvalStatus = {},
  toggleApproval = () => {},
  registeredUser = false,
}) {
  try {
    const columns = [
      {
        field: "sNo",
        headerName: "S.No.",
        align: "center",
        headerAlign: "center",
        width: 80,
      },
      {
        field: "userName",
        headerName: "User Name",
        width: 170,
      },
      {
        field: "organizationName",
        headerName: "Organization Name",
        width: 170,
      },
      // Conditionally add the roleName column
      ...(!registeredUser
        ? [
            {
              field: "roleName",
              headerName: "Role",
              width: 130,
            },
          ]
        : []),
      {
        field: "createdDate",
        headerName: "Join Date",
        width: 200,
      },
      {
        field: "email",
        headerName: "Email",
        width: 250,
      },

      // Conditionally add the action column
      ...(!registeredUser
        ? [
            {
              field: "approved", // Assuming 'approved' is a field in your data
              headerName: "Status",
              width: 110,
              renderCell: (params) => {
                // Explicit check to handle cases where params.value is not a valid boolean
                const isApproved = params?.value === true;

                return (
                  <Box
                    style={{
                      color: isApproved ? "green" : "red", // Set text color based on status
                      fontWeight: "bold", // Make the text bold
                      padding: 3,
                      borderRadius: 5,
                      backgroundColor: isApproved ? "#e0ffe0" : "#ffe0e0", // Light background for better readability
                    }}
                  >
                    {isApproved ? "Approved" : "Suspend"}
                  </Box>
                );
              },
            },
            {
              field: "action",
              headerName: "Action",
              type: "actions",
              align: "center",
              headerAlign: "center",
              width: 110, // Adjust width to fit both buttons
              renderCell: (params) => {
                const { email, beneficiaryId } = params?.row || {};

                // Check if approvalStatus[email] is exactly true
                const isApproved = approvalStatus[email] === true;

                return (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      bgcolor: isApproved ? "error.main" : "success.main",
                      "&:hover": {
                        bgcolor: isApproved ? "error.dark" : "success.dark", // Adjust hover state if needed
                      },
                    }}
                    onClick={() => {
                      try {
                        // Handle cases where email or beneficiaryId might be missing
                        if (!email || !beneficiaryId) {
                          throw Error(
                            "Missing email or beneficiaryId for action."
                          );
                        }
                        // Toggle approval status on click
                        toggleApproval(email, beneficiaryId);
                      } catch (error) {
                        // Handle any errors that might occur during the toggle process
                        console.error("Error toggling approval status:", error);
                        toast.error(error?.message);
                      }
                    }}
                  >
                    {isApproved ? "Suspend" : "Approve"}
                  </Button>
                );
              },
            },
          ]
        : []),
    ];

    return columns; // Ensure the columns array is returned
  } catch (error) {
    console.error("Error generating columns: ", error);
    return [];
  }
}
