import ApiService from "../apis/ApiService";
import { IS_MENU, JWT_TOKEN } from "../components/config/sessionStorage";

// Utility function to check if an object is empty
const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

// Function to update the menu and prevent duplicates
export const handleMenuUpdate = async (screenName, additionalParam) => {
  const isMenu = IS_MENU(); // Get current menu

  // Initialize menu structure if it's empty
  if (isEmptyObject(isMenu)) {
    return await updateMenu({ [screenName]: { [screenName]: true } });
  }

  // Get current values for the screenName
  const currentScreenValues = isMenu[screenName] || {};

  // Determine the key to check (either screenName or additionalParam)
  const keyToCheck = additionalParam || screenName;

  // Skip update if the key already exists and is true
  if (currentScreenValues[keyToCheck]) {
    console.log(
      `${keyToCheck} already exists in ${screenName}, skipping update.`
    );
    return;
  }

  // Create the updated menu object
  const menuValue = {
    ...isMenu,
    [screenName]: {
      ...currentScreenValues,
      [keyToCheck]: true,
    },
  };

  // Update the menu
  await updateMenu(menuValue);
};

// Helper function to update the menu via API and save to sessionStorage
const updateMenu = async (menuValue) => {
  try {
    const token = JWT_TOKEN(); // Retrieve the JWT token from session storage
    if (!token) {
      throw new Error("Token is missing."); // Check if the token is present
    }

    const menuRes = await ApiService.updateAndGetMenus(token, menuValue);

    if (menuRes?.status === 200 && menuRes?.data) {
      sessionStorage.setItem("menus", JSON.stringify(menuRes?.data));
      console.log("Menus updated and saved:", menuRes?.data);
    } else {
      console.error("Failed to update menus:", menuRes);
    }
  } catch (error) {
    console.error("Error during menu update:", error);
  }
};
