import { useState } from "react";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { addSerialNumber, formatDateDDMMYYYY } from "../../config/fieldConfig";
import { SCHEDULE_STATUS_DISPLAY_NAME } from "../../config/constants";
import ScheduleStatusColumns from "./columns";

export const ScheduleStatusService = () => {
  // State to store table data and loading state
  const [data, setTableData] = useState([]);
  // Initial loading state is true
  const [loading, setLoading] = useState(true);

  // Constants for identifying the current screen and its display name (used in UI)
  const displayName = SCHEDULE_STATUS_DISPLAY_NAME || "";

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Make API call to get job progress
      const res = await ApiService.getAllJobProgress(token);

      // Check if response is successful and data is an array
      if (res?.status === 200 && Array.isArray(res?.data)) {
        // Format the response data before storing it
        const formattedData = res?.data.map((item) => {
          return {
            ...item,
            startDate: formatDateDDMMYYYY(item?.startDate), // Format start date
            endDate: formatDateDDMMYYYY(item?.endDate), // Format end date
            nextRunDate: formatDateDDMMYYYY(item?.nextRunDate), // Format next run date
          };
        });

        // Store the formatted data in state
        setTableData(formattedData);
      } else {
        // If data is not valid or not an array, set an empty array
        setTableData([]);
      }
    } catch (error) {
      // Log any error that occurs during the fetch process
      console.error("Error fetching data:", error);
      setTableData([]); // Clear the table data if there's an error
    } finally {
      // Set loading state to false after data fetch attempt (success or failure)
      setLoading(false);
    }
  };

  // Fetch data on component mount and every 15 seconds
  useFetchOnMount(fetchData, 15000);

  // Create column configuration by combining actual columns with action columns
  const columns = Array.isArray(ScheduleStatusColumns())
    ? ScheduleStatusColumns()
    : [];

  // Add serial numbers to each data row (for table display)
  const rows = Array.isArray(data) ? addSerialNumber(data) : [];

  // Return data for the schedule status screen
  return {
    displayName, // Display name for the screen
    rows, // Data rows for the table
    columns, // Column definitions for the table
    loading, // Loading state indicator for the table
  };
};
