import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import {
  JWT_TOKEN,
  ORG_NAME,
  ROLE_ACCESS,
  USER_ID,
} from "../../config/sessionStorage";
import { addSerialNumber, validateArray } from "../../config/fieldConfig";
import { No_USER_ACCESS } from "../../config/toastMessage";
import {
  ASSIGN_COMMISSION_DISPLAY_NAME,
  ASSIGN_COMMISSION_SCREEN,
} from "../../config/constants";
import { AdjustFields } from "./fields";
import useSubmitForm from "../../../utils/useSubmitForm";
import { getActionsColumn } from "../../../utils/actionColumn";
import AssignCommissionColumns from "./columns";

export const AssignCommissionService = () => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");
  const [orgName, setOrgName] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [commissionData, setCommissionData] = useState([]);
  const [beneficiariesOption, setBeneficiariesOption] = useState([]);
  const [commissionListOptions, setCommissionListOptions] = useState([]);
  const [terms, setTerms] = useState("");
  const [description, setDescription] = useState("");
  const [beneficiaries, setBeneficiaries] = useState("");
  const [extractedData, setExtractedData] = useState([]);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);
  const [isReportingTo, setIsReportingTo] = useState(false);
  const [viewData, setViewData] = useState(true); // view purpose only
  const [adjustmentComponents, setAdjustmentComponents] = useState([0]); // Initialize adjustment components array with a single component
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true

  const roleAccess = ROLE_ACCESS();
  // Constants for screen identifier
  const screenName = ASSIGN_COMMISSION_SCREEN || "";
  // Constants for display screen name UI identifier
  const displayName = ASSIGN_COMMISSION_DISPLAY_NAME || "";

  const adjFields = AdjustFields;

  const [open, setOpen] = useState(false);

  const addFormOpen = () => {
    setOpen(true);
    formApiCall(null);
  };

  const addFormClose = () => {
    setOpen(false);
  };

  const [apiEndpoint, setApiEndpoint] = useState(
    "getAssignedCommWithHierarchy"
  );

  const [selectedOption, setSelectedOption] = useState("Get All");

  // Function to handle menu item clicks
  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  // Handles option change and sets loading state, API endpoint, and selected option
  const handleOptionChange = (option, endpoint) => {
    // Set loading state only if the option has changed
    setIsTableLoading(selectedOption !== option);
    // Update API endpoint based on the selected option
    setApiEndpoint(endpoint);
    // Set the currently selected option
    setSelectedOption(option);
    // Flag to trigger data fetching on next render
    stopRemount.current = true;
  };

  // Menu items with labels and associated actions for each option
  const menuItems = [
    {
      label: "Get All",
      action: () =>
        handleOptionChange("Get All", "getAssignedCommWithHierarchy"),
    },
    {
      label: "My Data",
      action: () => handleOptionChange("My Data", "getMyAssignedCommissions"),
    },
    {
      label: "My Reportee",
      action: () =>
        handleOptionChange("My Reportee", "getAssignedCommissionsForReportees"),
    },
  ].filter(Boolean);

  // Display the menuToolbar
  const toolbarMenuProps = { menuItems, selectedOption, isReportingTo };

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    try {
      const match = terms.match(/and\s+([^<]+)\s+regarding/i);

      const wordsAfterAndBeforeTerms = match ? match[1] : null;

      const formattedTerms = terms.replace(
        new RegExp(wordsAfterAndBeforeTerms, "g"),
        beneficiaries.userName
      );
      setTerms(formattedTerms);

      const formattedDesc = description.replace(
        new RegExp(wordsAfterAndBeforeTerms, "g"),
        beneficiaries.userName
      );
      setDescription(formattedDesc);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries, apiEndpoint]);

  const fetchData = async () => {
    try {
      const TOKEN = JWT_TOKEN();
      const ORGNAME = ORG_NAME();
      const userId = USER_ID();

      // If token is not available, throw an error
      if (!TOKEN) throw new Error("Token not found or invalid");
      setToken(TOKEN);
      setOrgName(ORGNAME);
      const commissionData = await ApiService.getCommissions(TOKEN);

      // Check the commissionData is an array
      validateArray(commissionData, "CommissionData");
      setCommissionData(commissionData);

      // Fetch all beneficiaries
      const allBeneficiaries = await ApiService.getBeneficiariesWithHierarchy(
        TOKEN
      );
      validateArray(allBeneficiaries, "AllBeneficiaries");

      // show view option(Check if there are any records where reportingTo matches userId)
      const reportingToRecords = allBeneficiaries.some(
        (item) => item.reportingTo === userId
      );
      setIsReportingTo(reportingToRecords);

      // Call the API service function using the current endpoint
      const response = await ApiService[apiEndpoint](TOKEN);
      // Check if the response status is 200 and the data is an array
      if (response?.status === 200 && Array.isArray(response?.data)) {
        const getAllAssignCommission = response?.data;

        // Function to map commission IDs to their respective names
        const mapIDsToNames = (ids, data) =>
          ids.map(
            (id) => data.find((item) => item.id === id)?.commissionName || null
          );

        // Transform data
        const transformedData = getAllAssignCommission.map((item) => ({
          ...item,
          commission: mapIDsToNames(item.commission, commissionData),
        }));

        // Set table data
        setTableData(transformedData);
      } else {
        // Clear table data if API response is not as expected
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsTableLoading(false);
    setLoading(false);
  };

  let navigate = useNavigate();

  const handleCalculation = async () => {
    // Disable interactions on the screen
    const overlay = document.createElement("div");

    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    // overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black overlay
    overlay.style.zIndex = "9999"; // Ensure the overlay is on top of other elements
    document.body.appendChild(overlay);

    // Show loading toast
    const loadingToastId = toast.loading(
      `Please wait until the commission is calculated for ${beneficiaries.userName}`,
      { duration: 0 }
    );

    try {
      const iconImage = await ApiService.getImageBytes(token);

      const res = await ApiService.calculateCommissionForSingleUser(
        beneficiaries.id,
        token
      );

      if (res?.status === 200 && iconImage) {
        if (
          res.data.filteredTransactions &&
          res.data.filteredTransactions.length > 0
        ) {
          navigate("/report", {
            state: {
              viewData: true,
              SingleData: res.data,
              IconImage: iconImage,
            },
          });
        } else {
          toast.error(No_USER_ACCESS);
        }
      } else {
        // Error response from the server
        toast.error(res.response.data);
      }
    } catch (error) {
      // Network error or other unexpected errors during upload
      toast.error("An error occurred while processing your request.");
      console.error("errorMessage", error);
    } finally {
      // Re-enable interactions on the screen and remove the overlay
      document.body.removeChild(overlay);
      toast.dismiss(loadingToastId); // Dismiss the loading toast when done
    }
  };

  const formApiCall = async (beneficiaryName) => {
    try {
      const getAllAssignCommissions =
        await ApiService.getAssignedCommWithHierarchy(token);

      const activeBeneficiaries = await ApiService.getActiveBeneficiaries(
        token
      );
      const eligibleBen = activeBeneficiaries?.data;

      validateArray(eligibleBen, "EligibleBenificiaries");
      const extractedData = eligibleBen?.map((item) => ({
        userName: item.userName,
        id: item.userId,
        idWithName: `${item.userName} - ${item.email}`,
      }));
      setExtractedData(extractedData || []);

      const valuesToRemove = getAllAssignCommissions?.data?.map(
        (item) => item.beneficiaries
      );

      const filteredOptions = extractedData.filter((option) => {
        return (
          !valuesToRemove.includes(option.id) ||
          option.userName === beneficiaryName
        );
      });

      setBeneficiariesOption(filteredOptions);

      const getAllCommissionNames = await ApiService.getCommissionNames(token);
      setCommissionListOptions(getAllCommissionNames);

      // Use regular expressions with String.replace() to replace placeholders
      const getTermsDescription = await ApiService.getTermsDescription();

      const formattedTerms = getTermsDescription.terms
        .replace(/\{ABC Inc\}/g, orgName)
        .replace(/\{Steve Rep\}/g, beneficiaryName);
      beneficiaryName === null && setTerms(formattedTerms);

      const formattedDescription = getTermsDescription.description
        .replace(/\{ABC Inc\}/g, orgName)
        .replace(/\{Steve Rep\}/g, beneficiaryName);
      beneficiaryName === null && setDescription(formattedDescription);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Add serial numbers to data rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : []; // Default to empty array if not an array

  const handleEdit = (id, viewData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }
      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }

      const rowData = data.find((row) => row.id === id); // Find the row data based on the id

      // Check if row data is found
      if (!rowData || typeof rowData !== "object" || Array.isArray(rowData)) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      setEditItemId(id); // Set the edit form state and data
      setEditFormOpen(true);
      setEditFormData(rowData); // Set edit form data
      setViewData(viewData);
      formApiCall(rowData.beneficiaryName);
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };
  const handlePdfDownload = async (params) => {
    try {
      const response = await ApiService.downloadAssignCommissionPdf(
        token,
        params
      );

      // Check if response data is valid
      if (!response.data) {
        console.error("Error: Empty response data");
        return;
      }

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${params.row.beneficiaryName}.pdf`;
      a.click();

      // Revoke object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData,
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Create column configuration by combining actual columns with action columns
  const columns = Array.isArray(
    AssignCommissionColumns(actionsColumn, handleEdit, handlePdfDownload)
  )
    ? AssignCommissionColumns(actionsColumn, handleEdit, handlePdfDownload)
    : []; // Default to empty array if not an array

  return {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    setEditFormOpen,
    adjFields,
    commissionListOptions,
    commissionData,
    beneficiariesOption,
    terms,
    description,
    setBeneficiaries,
    right,
    setRight,
    left,
    setLeft,
    open,
    addFormOpen,
    addFormClose,
    roleAccess,
    extractedData,
    beneficiaries,
    handlePdfDownload,
    viewData,
    setViewData,
    setTerms,
    setDescription,
    handleCalculation,
    adjustmentComponents,
    setAdjustmentComponents,
    apiEndpoint,
    loading,
    handleSelectOption,
    isTableLoading,
    displayName,
    screenName,
    submitLoading,
    columns,
    onSubmitForm,
    toolbarMenuProps,
  };
};
