import {
  DASHBOARD_SCREEN,
  COMMISSION_BY_PRODUCT_SCREEN,
  USER_ACTIVITY_SCREEN,
  AUDIT_LOG_SCREEN,
  TRANSACTION_SCREEN,
  COMMISSION_BY_CUSTOMER_SCREEN,
} from "../components/config/constants";

// Mapping of API operations for different screens in the application
const showDatePicker = {
  [DASHBOARD_SCREEN]: true, // Show DatePicker for dashboard
  [COMMISSION_BY_PRODUCT_SCREEN]: true, // Show DatePicker for commission by product
  [USER_ACTIVITY_SCREEN]: true, // Show DatePicker for commission by product
  [AUDIT_LOG_SCREEN]: true, // Show DatePicker for commission by product
  [TRANSACTION_SCREEN]: true, // Show DatePicker for commission by product
  [COMMISSION_BY_CUSTOMER_SCREEN]: true, // Show DatePicker for commission by product
};

// Exporting the apiMappings object for use in other parts of the application
export default showDatePicker;
