import { Button, Grid, Link, Typography } from "@mui/material";

export default function Section5({ bgImgSlide }) {
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${bgImgSlide})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        padding: { xs: 2, sm: 4, md: 5 }, // Adjust padding for better spacing on different screen sizes
        height: { xs: "auto", md: "auto" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h3"
          sx={{
            pt: { xs: 4, sm: 6, md: 8 }, // Adjust top padding for better spacing on different screen sizes
            px: { xs: 2, sm: 4, md: 5 }, // Adjust horizontal padding for better spacing on different screen sizes
            fontWeight: "bold",
            backgroundImage: "linear-gradient(220deg, #0066ff, #7730e6)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            lineHeight: 1.2,
            fontSize: { xs: "1.75rem", sm: "2rem", md: "2.5rem" }, // Adjust font size for responsiveness
            textAlign: "center", // Center align the text
          }}
          align="center"
          gutterBottom
        >
          Ready to Transform Your Sales Commissions?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            px: { xs: 2, sm: 4, md: 5 }, // Adjust horizontal padding for better spacing on different screen sizes
            fontSize: { xs: "1rem", sm: "1.25rem", md: "1.375rem" }, // Adjust font size for responsiveness
            textAlign: "center", // Center align the text
            mb: { xs: 2, sm: 3, md: 4 }, // Adjust margin bottom for spacing between elements
          }}
          align="center"
          gutterBottom
        >
          Join the thousands of businesses already benefiting from CompFact.
          Schedule a demo or start your free trial today and experience the
          difference firsthand.
        </Typography>
      </Grid>

      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          sx={{ pt: { xs: 2, sm: 3, md: 4 } }}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <Button
            component={Link}
            href="/register" // Updated to 'href' for MUI Button Link compatibility
            variant="contained"
            size="large"
            sx={{
              color: "white",
              backgroundImage:
                "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
              borderRadius: 10,
              px: { xs: 2, sm: 3 }, // Adjust horizontal padding for different screens
              py: { xs: 1, sm: 1.5 }, // Adjust vertical padding for different screens
            }}
          >
            Get Started Today
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
