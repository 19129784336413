import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { ListTwoTone } from "@mui/icons-material";
import { ADMIN_NAME } from "../../components/config/constants";
import { IS_MONITOR, USER_ROLE } from "../../components/config/sessionStorage";
import { useToolbarConfig } from "./ToolbarMap";

export default function ViewMenu({
  screenName = "",
  menuItems = [],
  selectedOption = "",
  isReportingTo = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  // open menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // close menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const ROLE_NAME = USER_ROLE();

  const isMonitor = IS_MONITOR();

  const showMenu =
    isReportingTo || ROLE_NAME === ADMIN_NAME || isMonitor === "true";

  // show view based on screenName
  if (!useToolbarConfig[screenName]?.showMenu || !showMenu) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        size="small"
        startIcon={<ListTwoTone />}
        onClick={handleMenuOpen}
        aria-label="View Options"
      >
        {isMonitor === "true" ? "View Users" : "View"}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems?.map(({ label, action }) => (
          <MenuItem
            key={label}
            onClick={() => {
              action();
              handleMenuClose();
            }}
            selected={selectedOption === label}
            sx={{
              color: selectedOption === label ? "secondary.main" : "inherit",
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
