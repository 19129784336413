const FormFields = [
  {
    label: "Email Address",
    name: "email",
    type: "email",
    readonly: true,
    required: true,
  },
  {
    isOtp: true,
  },
  {
    isButton: true,
  },
  {
    label: "GST IN",
    name: "gst",
    type: "text",
    readonly: false,
    required: false,
  },
  {
    label: "Organization Name",
    name: "organizationName",
    type: "text",
    readonly: true,
    required: true,
  },
  {
    label: "Contact Number",
    name: "mobileNumber",
    type: "tel",
    required: true,
    readonly: false,
  },
  {
    label: "Currency",
    name: "currency",
    autoComplete: "on",
    type: "text",
    required: true,
  },
  {
    label: "Time Zone",
    name: "timeZone",
    autoComplete: "on",
    type: "text",
    required: true,
  },
  {
    label: "Address Line1",
    name: "addressLine1",
    type: "textarea",
    multiline: true,
    rows: 1,
    readonly: false,
    required: false,
  },
  {
    label: "Address Line2",
    name: "addressLine2",
    type: "textarea",
    multiline: true,
    rows: 1,
    readonly: false,
    required: false,
  },
  {
    label: "Address Line3",
    name: "addressLine3",
    type: "textarea",
    multiline: true,
    rows: 1,
    readonly: false,
    required: false,
  },
];

export default FormFields;
