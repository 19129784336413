import { createTheme } from "@mui/material";

const font = "Source Sans 3,'sans-serif'";

const Themes = createTheme({
  palette: {
    primary: {
      // main: "#6200ea",
      main: "#0066ff",
      // main: "#0069b5",
      // main: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      // main: "#006dba",
    },
  },
  typography: {
    fontFamily: font,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#1e76fa", // Set the background color for contained buttons
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "rgba(0, 0, 0, 0.87)",
          backgroundColor: "#fff",
        },
      },
    },
  },
});

export default Themes;
