import { useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { JWT_TOKEN } from "../../config/sessionStorage";
import { addSerialNumber } from "../../config/fieldConfig";
import { VARIABLE_DISPLAY_NAME, VARIABLE_SCREEN } from "../../config/constants";
import useSubmitForm from "../../../utils/useSubmitForm";
import { getActionsColumn } from "../../../utils/actionColumn";
import VariableFields from "./fields";
import VariableColumns from "./columns";

export const VariableService = () => {
  // State to store the data for the table
  const [data, setTableData] = useState([]);
  // State to control edit form visibility
  const [editFormOpen, setEditFormOpen] = useState(false);
  // State to store the ID of the item being edited
  const [editItemId, setEditItemId] = useState(null);
  // State to store the data for the edit form
  const [editFormData, setEditFormData] = useState({});
  // State to indicate table loading status
  const [loading, setLoading] = useState(true);
  // State to control add form visibility
  const [open, setOpen] = useState(false);

  // Constants for screen identifier
  const screenName = VARIABLE_SCREEN || "";
  // Constants for display screen name UI identifier
  const displayName = VARIABLE_DISPLAY_NAME || "";

  // Function to open the add form dialog
  const addFormOpen = () => {
    try {
      setOpen(true); // Set open state to true to display add form
    } catch (error) {
      console.error("Error opening add form:", error);
    }
  };

  // Function to close the add form dialog
  const addFormClose = () => {
    try {
      setOpen(false); // Set open state to false to hide add form
    } catch (error) {
      console.error("Error closing add form:", error);
    }
  };

  // Function to fetch data for the table
  const fetchData = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Make an API call to get the variable data
      const res = await ApiService.getVariables(token);

      // Validate the response
      if (res?.status === 200 && Array.isArray(res?.data)) {
        setTableData(res?.data); // Update the table data state
      } else {
        // Set default values when response is not valid
        setTableData([]); // Default to empty array if data is invalid
      }
    } catch (error) {
      console.error("Error fetching data:", error); // Log error to console
      setTableData([]); // Clear the table data if there's an error
    } finally {
      setLoading(false); // Set loading to false regardless of the outcome
    }
  };

  // Call fetchData when the component mounts
  useFetchOnMount(fetchData); // Load initial data

  // Function to handle edit button click
  const handleEdit = (id) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }

      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }

      // Find the row data based on the provided ID
      const rowData = data?.find((row) => row?.id === id);

      // Check if row data is found
      if (!rowData) {
        throw new Error(`No data found for the ID: ${id}`);
      }

      // Set the edit form state and populate with row data
      setEditItemId(id);
      setEditFormOpen(true);
      setEditFormData(rowData);
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(`Error editing item: ${error?.message}`); // Display the exact error message in a toast
    }
  };

  // Get fields from fields, default to empty array if not an array
  const fields = Array.isArray(VariableFields()) ? VariableFields() : [];

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData,
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
    idsWithoutDelete: [1, 2], // IDs where delete is disabled
  });

  // Create column configuration by combining actual columns with action columns
  const columns = Array.isArray(VariableColumns({ actionsColumn }))
    ? VariableColumns({ actionsColumn })
    : []; // Default to empty array if not an array

  // Add serial numbers to data rows for displaying in the table
  const rows = Array.isArray(addSerialNumber(data))
    ? addSerialNumber(data)
    : []; // Default to empty array if not an array

  // Return all state variables and functions to be used in the component
  return {
    screenName, // Identifier for the screen
    displayName, // Display name for the UI
    fields, // Form fields for use in the component
    rows, // Rows for the table
    columns, // Columns for the table
    data, // Data fetched for the table
    open, // Control state for add form visibility
    editFormOpen, // Control state for edit form visibility
    editItemId, // ID of the item being edited
    editFormData, // Data for the edit form
    setEditFormOpen, // Function to set edit form visibility
    addFormOpen, // Function to open the add form
    addFormClose, // Function to close the add form
    loading, // Indicates if the table is loading
    submitLoading, // Indicates if the form is submitting
    onSubmitForm, // Function to handle form submission
  };
};
