import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { END_DATE, START_DATE } from "../components/config/sessionStorage";

export const getInitialDateRange = (stopRemount = false) => {
  try {
    // Check if stopRemount is valid and has a current property
    if (!stopRemount) {
      throw new Error(
        "stopRemount is either undefined or not a ref object. Ensure it's passed as a valid useRef."
      );
    }

    // Get today's date
    const today = new Date();

    // Retrieve custom start and end dates if available
    const sDate = START_DATE(); // Assuming this function returns a custom start date or null
    const eDate = END_DATE(); // Assuming this function returns a custom end date or null

    // Calculate the end of the last month
    const dateOfLastMonth = subMonths(today, 1);
    const endOfLastMonth = endOfMonth(dateOfLastMonth);

    // Format the end date as yyyy-mm-dd (last day of the previous month)
    const formattedEndDate = format(endOfLastMonth, "yyyy-MM-dd");

    // Calculate the start of three months ago
    const dateThreeMonthsAgo = subMonths(today, 3);
    const startOfThreeMonthsAgo = startOfMonth(dateThreeMonthsAgo);

    // Format the start date as yyyy-mm-dd (first day of three months ago)
    const formattedStartDate = format(startOfThreeMonthsAgo, "yyyy-MM-dd");

    // Use the provided start and end dates if available, otherwise use the calculated range
    const startDate = sDate ? sDate : formattedStartDate;
    const endDate = eDate ? eDate : formattedEndDate;

    // Set stopRemount to true to prevent component remounts
    stopRemount.current = true;

    // Log the start and end dates
    // console.log("Start Date:", startDate, "End Date:", endDate);

    // Return the final start and end dates as an object
    return { startDate, endDate };
  } catch (error) {
    // Handle any error that occurs in the process
    console.error("An error occurred while calculating the date range:", error);
    return { startDate: null, endDate: null }; // Return null dates in case of error
  }
};
