import { Outlet } from "react-router-dom";
import { Box, Container, CssBaseline } from "@mui/material";
import { ORG_NAME, USER_NAME } from "../../config/sessionStorage";
import { CfTransLogo } from "../../config/imageConfig";
import Logout from "../../pages/Logout/index";
import { menus, settingMenu } from "./menus";
import TopNavBar from "./topNavBar";
import BreadcrumbBar from "./Breadcrumb";

export default function HorizontalLayout({
  setIsAuthenticated,
  setRes,
  arrayOfObjects,
  role,
  isMonitor,
  isSuperAdmin,
}) {
  // Generate menu items
  const menuItems = menus({ arrayOfObjects, role, isMonitor, isSuperAdmin });

  const settingMenuItems = settingMenu({
    arrayOfObjects,
    role,
    isMonitor,
    isSuperAdmin,
  });

  return (
    <>
      <Container disableGutters maxWidth="xl">
        <CssBaseline />
        {/* Top Navigation Bar */}
        <TopNavBar
          cfTransLogo={CfTransLogo}
          orgName={ORG_NAME()}
          userName={USER_NAME()}
          logout={Logout}
          menuItems={menuItems}
          settingMenuItems={settingMenuItems}
          setIsAuthenticated={setIsAuthenticated}
          setRes={setRes}
          isMonitor={isMonitor}
          isSuperAdmin={isSuperAdmin}
        />
        {/* Breadcrumb display */}
        <BreadcrumbBar
          baseMenu={menuItems}
          settingMenuItems={settingMenuItems}
        />
        {/* Outlet the page component display */}
        <Box sx={{ m: 2, boxShadow: 3 }}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
}
