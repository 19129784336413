import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Error } from "@mui/icons-material";
import ApiService from "../../../apis/ApiService";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  USER_ID,
  USER_ROLE,
} from "../../config/sessionStorage";
import { addSerialNumber, validateArray } from "../../config/fieldConfig";
import {
  ADMIN_NAME,
  TRANSACTION_DISPLAY_NAME,
  TRANSACTION_SCREEN,
} from "../../config/constants";
import TransactionColumns from "./columns";
import { getInitialDateRange } from "../../../utils/getInitialDateRange";
import { getActionsColumn } from "../../../utils/actionColumn";
import useSubmitForm from "../../../utils/useSubmitForm";
import { LOADING_MSG, NO_DATA_MSG } from "../../config/toastMessage";
import { extractCustomField } from "../../../utils/Master/customFieldExtractor";
import { addCustomFields } from "../../../utils/Master/addCustomField";
import { useDynamicFields } from "../../../utils/Master/useDynamicFields";
import { customAddType } from "../../../utils/addTypeFunctions";
import { getAccessLevels } from "../../../utils/accessLevels";
import { extractCustomFieldApiValues } from "../../../utils/Master/customFieldApiHandler";
import { masterUploadFile } from "../../../utils/Master/fileUploadHelper";
import { formatCurrency } from "../../../utils/CurrencyFormatter";
import TransactionFields from "./fields";

export const TransactionService = (fileInputRef) => {
  const [data, setTableData] = useState([]);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [customFieldsArrayObject, setCustomFieldsArrayObject] = useState([]);
  const [fieldMappingArrayObject, setFieldMappingArrayObject] = useState([]);
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false);
  const [viewData, setViewData] = useState(false); // view purpose only
  const [isEData, setIsEData] = useState(false); // edit error records
  const [resObject, setResObject] = useState({});
  const [response, setResponse] = useState({});
  const [fieldsLabel, setFieldsLabel] = useState();
  const [configObj, setConfigObj] = useState();
  const [transactionCombos, setTransactionCombos] = useState();
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);
  const [transactionTypeOptionsWithId, setTransactionTypeOptionsWithId] =
    useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [salesRepOptions, setSalesRepOptions] = useState([]);
  const [selectedSalesRep1, setSelectedSalesRep1] = useState(null);
  const [selectedCustomer1, setSelectedCustomer1] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [isReportingTo, setIsReportingTo] = useState(false);
  const [isErrorColor, setIsErrorColor] = useState(false);
  const [uploading, setUploadInProgress] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // State to track the upload status
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true
  const [newFields, setNewFields] = useState([]);
  const [open, setOpen] = useState(false);
  const [apiEndpoint, setApiEndpoint] = useState("getTransByHierarchy");
  const [selectedOption, setSelectedOption] = useState("Get All");

  // Reference to prevent unnecessary component remounts
  const stopRemount = useRef(true);

  // Constants for screen identifier
  const screenName = TRANSACTION_SCREEN || "";

  // Constants for display screen name UI identifier
  const displayName = TRANSACTION_DISPLAY_NAME || "";

  // Message to display when there is no data
  const noTableDataMsg = NO_DATA_MSG || "";

  // Message to show during upload
  const uploadLoadingMsg = LOADING_MSG;

  // Get the role of the current user
  const ROLE_NAME = USER_ROLE();

  // Get the currency used in the system
  const currency = CURRENCY_NAME();

  // Get the userId of the current user
  const userId = USER_ID();

  // Function to open the add form dialog
  const addFormOpen = () => {
    try {
      setOpen(true); // Set open state to true to display add form
      formApiFetch();
    } catch (error) {
      console.error("Error opening add form:", error);
    }
  };

  // Function to close the add form dialog
  const addFormClose = () => {
    try {
      setOpen(false); // Set open state to false to hide add form
    } catch (error) {
      console.error("Error closing add form:", error);
    }
  };

  // Get the daterange to list the data
  const { startDate, endDate } = getInitialDateRange(stopRemount);

  // Handles option change and sets loading state, API endpoint, and selected option
  const handleOptionChange = (option, endpoint) => {
    // Set loading state only if the option has changed
    setIsTableLoading(selectedOption !== option);
    // Update API endpoint based on the selected option
    setApiEndpoint(endpoint);
    // Set the currently selected option
    setSelectedOption(option);
    // Reset the red error color on option change
    setIsErrorColor(false);
    // Flag to trigger data fetching on next render
    stopRemount.current = true;
  };

  // Menu items with labels and associated actions for each option
  const menuItems = [
    {
      label: "Get All",
      action: () => handleOptionChange("Get All", "getTransByHierarchy"),
    }, // Action for fetching all logs
    {
      label: "My Data",
      action: () => handleOptionChange("My Data", "getMyTransaction"),
    }, // Action for fetching user-specific logs
    {
      label: "My Reportee",
      action: () => handleOptionChange("My Reportee", "getReporteesTrans"),
    }, // Action for fetching reportee logs
    ROLE_NAME === ADMIN_NAME && {
      label: "All Errors",
      action: () => {
        handleOptionChange("All Errors", "getErrorTrans");
        setIsErrorColor(true); // Set the navigate scenario active
      },
    },
  ].filter(Boolean);

  // Props for the toolbar menu, including items and currently selected option
  const toolbarMenuProps = { menuItems, selectedOption, isReportingTo };

  // Function to handle current error selection
  const handleCurrentError = () => {
    handleOptionChange("All Errors", "getCurrentErrorTrans");
    setIsErrorColor(true); // Set the navigate scenario active
  };

  // Effect to sync the configuration object whenever it changes
  useEffect(() => {
    setConfigObj(configObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configObj]);

  // Effect to handle data fetching based on the API endpoint, startDate, endDate
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, startDate, endDate]);

  const fetchData = async (isSubmit = false) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      const { getPojo, typeData, mappingPojoRes } =
        await extractCustomFieldApiValues(
          screenName,
          token,
          setTransactionTypeOptionsWithId,
          setTransactionTypeOptions,
          setConfigObj,
          setFieldsLabel
        );

      // Determine the effective endpoint based on the isSubmit flag and current apiEndpoint
      const effectiveEndpoint =
        isSubmit && apiEndpoint === "getCurrentErrorTrans"
          ? "getTransByHierarchy"
          : apiEndpoint;

      // Set error color to false only if this is a submit request and the endpoint was modified
      if (isSubmit && effectiveEndpoint === "getTransByHierarchy") {
        setIsErrorColor(false);
        setSelectedOption("Get All");
        setApiEndpoint("getTransByHierarchy");
      }

      // Make an API request to the specified endpoint using the token
      const response = await ApiService[effectiveEndpoint](
        token,
        startDate,
        endDate
      );

      // Check if the response status is 200 and the data is an array
      if (response?.status === 200 && Array.isArray(response?.data)) {
        const transData = response?.data;

        // Fetch all necessary data in parallel
        const [productData, customerData, beneficiaryData] = await Promise.all([
          ApiService.getProducts(token),
          ApiService.getCustomers(token),
          ApiService.getBeneficiariesWithHierarchy(token),
        ]);

        // Create maps for product IDs, customer IDs, and beneficiary IDs
        const productMap = productData?.data?.reduce(
          (map, product) => ({
            ...map,
            [product.productId]: product.productName,
          }),
          {}
        );

        const prodArray = Object.keys(productMap)?.map((key) => ({
          prodLabel: productMap[key],
          prodId: key,
        }));
        // Check the prodArray is an array
        validateArray(prodArray, "ProdArray");
        setProductOptions(prodArray);

        const customerMap = customerData?.data?.reduce(
          (map, customer) => ({
            ...map,
            [customer.customerId]: customer.customerName,
          }),
          {}
        );

        const customerArray = Object.keys(customerMap)?.map((key) => ({
          custLabel: `${key} - ${customerMap[key]}`,
          custId: key,
        }));

        // Check the customerArray is an array
        validateArray(customerArray, "CustomerArray");
        setCustomerOptions(customerArray);

        const beneficiaryMap = beneficiaryData?.reduce(
          (map, beneficiary) => ({
            ...map,
            [beneficiary.userId]: beneficiary.userName,
          }),
          {}
        );

        const beneficiaryArray = Object.keys(beneficiaryMap)?.map((key) => ({
          salesRepLabel: `${key} - ${beneficiaryMap[key]}`,
          salesRepId: key,
        }));

        // Check the beneficiaryArray is an array
        validateArray(beneficiaryArray, "BeneficiaryArray");
        setSalesRepOptions(beneficiaryArray);

        // show view option(Check if there are any records where reportingTo matches userId)
        const reportingToRecords = beneficiaryData?.some(
          (item) => item.reportingTo === userId
        );
        setIsReportingTo(reportingToRecords);
        // Assuming ApiService calls and mapping for productMap, customerMap, and beneficiaryMap
        const updatedTransferData = transData?.map((transfer) => {
          // Find the corresponding transactionType entry
          const matchedtransactionType = typeData?.find(
            (transactionType) =>
              transactionType.id === parseInt(transfer.transactionTypeId)
          );

          // Find corresponding productName using productId
          const productName =
            productMap[transfer.productId] || transfer.productId || "";

          // Find corresponding customerName using customerId
          const customerName =
            customerMap[transfer.customerId] || transfer.customerId || "";

          // Find corresponding salesRepName using userId from beneficiaryMap
          const salesRepName =
            beneficiaryMap[transfer.salesRep] || transfer.salesRep || "";

          // Return a new object with the updated fields
          return {
            ...transfer,
            transactionType: matchedtransactionType
              ? matchedtransactionType.typeValue
              : transfer.transactionType, // Use typeValue or fallback to original
            productNameTrans: productName, // Add the product name from productMap
            customerNameTrans: customerName, // Add the customer name from customerMap
            salesRepNameTrans: salesRepName, // Add the sales rep name from beneficiaryMap
          };
        });

        // Check the updatedTransferData is an array
        validateArray(updatedTransferData, "UpdatedTransferData");
        // Now set the updated data with the additional fields
        setTableData(updatedTransferData);

        extractCustomField(
          transData,
          getPojo,
          mappingPojoRes,
          setResponse,
          setResObject,
          setDynamicColumns,
          setCustomFieldsArrayObject,
          setFieldMappingArrayObject
        );
      } else {
        // Clear table data if API response is not as expected
        setTableData([]);
        setTransactionTypeOptionsWithId([]);
        setTransactionTypeOptions([]);
        setFieldsLabel([]);
        setProductOptions([]);
        setCustomerOptions([]);
        setSalesRepOptions([]);
        setIsReportingTo(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTableData([]);
      setTransactionTypeOptionsWithId([]);
      setTransactionTypeOptions([]);
      setFieldsLabel([]);
      setProductOptions([]);
      setCustomerOptions([]);
      setSalesRepOptions([]);
      setIsReportingTo(false);
    } finally {
      // Stop the loading indicator once data fetching is complete
      setIsTableLoading(false);
    }
  };

  // To fetch product data
  const formApiFetch = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // Check if the token is not available
      if (!token) throw new Error("Token not found or invalid");

      // Fetch all Transaction data using the API service
      const response = await ApiService.getAllTransactions(token);

      // Check if the response status is 200 and if the data is an array
      if (response.status === 200 && Array.isArray(response?.data)) {
        // Combine transactionId and transactionLine into a single structure
        const transactionCombos = response?.data?.map((transaction) => ({
          transactionId: transaction.transactionId,
          transactionLine: transaction.transactionLine,
        }));

        // Store the combined data for validation
        setTransactionCombos(transactionCombos || []);
      } else {
        // If response is not 200 or data is not an array, set an empty array
        setTransactionCombos([]);
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error fetching customer data:", error?.message);

      // Optionally, set the transactionCombos to an empty array if an error occurs
      setTransactionCombos([]);
    }
  };

  // Assuming customFieldsArrayObject is an array of objects
  const dynamicFormFields = useDynamicFields(
    customFieldsArrayObject, // Custom fields from the user
    fieldMappingArrayObject, // Field mapping configurations
    configObj // Additional settings for the fields
  );

  // Function to handle adding a custom field
  const handleAddCustomField = (customFieldData) => {
    // Call addCustomFields to add the new custom field
    addCustomFields(
      customFieldData, // Data for the custom field
      fieldsLabel, // List of existing labels
      dynamicFormFields, // Current dynamic form fields
      newFields, // Custom fields that have been added
      setNewFields, // Function to update new fields
      setOpenCustomFieldDialog // Function to close the dialog
    );
  };

  // Function to handle add transaction type
  const addTransactionType = async (value, setValue) => {
    await customAddType(
      value,
      setValue,
      setTransactionTypeOptions,
      "transactionType"
    );
  };

  // Function to handle file upload for import
  const handleUploadFile = (event) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call masterUploadFile and wait for it to complete
        await masterUploadFile(
          event,
          fileInputRef, // Reference to the file input element
          screenName, // Screen name for the upload context
          () => fetchData(true), // Call fetchData with `true`
          setIsUploading, // Function to toggle loading state
          handleCurrentError, // Function to handle any error during the process
          reject, // Reject the promise on failure
          resolve // Resolve the promise on success
        );
      } catch (error) {
        // Log any error that occurs during the file upload process
        console.error("File upload failed:", error);
      }
    });
  };

  // Function to handle edit button click
  const handleEdit = (id, viewData, isEData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }
      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }
      const rowData = data.find((row) => row.id === id); // Find the row data based on the id
      // Check if row data is found
      if (!rowData || typeof rowData !== "object" || Array.isArray(rowData)) {
        throw new Error(`No data found for the ID: ${id}`);
      }
      // Set the edit form state and populate with row data
      setEditItemId(id); // Set the edit form state and data
      setEditFormOpen(true);
      setEditFormData(rowData); // Set edit form data
      setSelectedSalesRep1(rowData.salesRep);
      setSelectedCustomer1(rowData.customerId);
      setSelectedProduct1(rowData.productId);
      setViewData(viewData);
      setIsEData(isErrorColor);
      formApiFetch();
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  // Get fields from fields, default to empty array if not an array
  const fields = Array.isArray(
    TransactionFields({
      salesRepOptions,
      customerOptions,
      productOptions,
      transactionTypeOptions,
      dynamicFormFields,
      newFields,
    })
  )
    ? TransactionFields({
        salesRepOptions,
        customerOptions,
        productOptions,
        transactionTypeOptions,
        dynamicFormFields,
        newFields,
      })
    : [];

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData: () => fetchData(true), // Pass `true` to fetchData for submission-specific logic
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Call CustomerColumns once and store the result
  const columnsResult = TransactionColumns(
    actionsColumn,
    dynamicColumns,
    isErrorColor,
    handleEdit,
    currency
  );

  // Check if the result is an array
  const columns = Array.isArray(columnsResult) ? columnsResult : [];

  // Function to format fields and add serial numbers
  const processTableData = (data) =>
    Array.isArray(data)
      ? addSerialNumber(
          data.map((item) => ({
            ...item,
            unitCost: formatCurrency(item.unitCost),
            salesAmount: formatCurrency(item.salesAmount),
            commissionAmount: formatCurrency(item.commissionAmount),
            commissionRate: formatCurrency(item.commissionRate),
            grossProfit: formatCurrency(item.grossProfit),
            profitPercentage: formatCurrency(item.profitPercentage),
            discount: formatCurrency(item.discount),
          }))
        )
      : [];

  // Add serial numbers to the rows for displaying in the table
  const rows = processTableData(data ?? []);

  // Get access levels for the specified resource using a utility function
  const { writeAccess } = getAccessLevels(screenName);

  // Memoize column visibility model to prevent recreation on every render
  const columnVisibilityModel = useMemo(
    () => ({
      // Set initial visibility for standard columns to false (hidden)
      projection: false,
      grossProfit: false,
      transactionLineType: false,
      profitPercentage: false,
      discount: false,
      commissionAmount: false,
      commissionRate: false,
      // Set visibility for dynamic columns to false (hidden)
      ...dynamicColumns.reduce((acc, column) => {
        acc[column.field] = false; // Hide each dynamic column
        return acc; // Return the accumulator
      }, {}),
    }),
    [dynamicColumns] // Recalculate if dynamicColumns changes
  );

  // Define properties for the toolbar import component
  const toolbarImportProps = {
    handleUploadFile, // Function to handle file upload
    fileInputRef, // Reference to the file input element
    uploading, // State to track if a file is currently uploading
    setUploadInProgress, // Function to update upload progress state
  };

  return {
    screenName, // Current screen name
    displayName, // UI display name
    uploadLoadingMsg, // Upload loading message
    rows, // Table data rows
    columns, // Table column configuration
    fields, // Transaction form fields
    writeAccess, // User write access flag
    toolbarMenuProps, // Toolbar menu properties
    toolbarImportProps, // Import toolbar properties
    open, // Dialog/modal open state
    addFormOpen, // Add form visibility  state
    addFormClose, // Function to close add form
    editFormOpen, // Edit form visibility state
    editItemId, // ID of the item being edited
    editFormData, // Data for the edit form
    setEditFormOpen, // Function to set edit form state
    viewData, // Read-only view flag
    setViewData, // Function to set viewData state
    onSubmitForm, // Form submission handler
    submitLoading, // Submission loading state
    dynamicFormFields, // Dynamically changing form fields
    handleAddCustomField, // Function to add a custom field
    openCustomFieldDialog, // Custom field dialog visibility
    setOpenCustomFieldDialog, // Function to set custom field dialog state
    columnVisibilityModel, // Column visibility management
    apiEndpoint, // API endpoint for products
    isUploading, // Upload in progress flag
    isTableLoading, // Table loading state
    isErrorColor, // Error styling flag
    isEData, // Error data flag
    resObject, // Response object from API
    response, // API response data
    selectedSalesRep1,
    setSelectedSalesRep1,
    selectedCustomer1,
    setSelectedCustomer1,
    selectedProduct1,
    setSelectedProduct1,
    setNewFields,
    transactionCombos,
    addTransactionType,
    transactionTypeOptions,
    transactionTypeOptionsWithId,
    noTableDataMsg,
  };
};
