export const SalesRepsColumns = ({ currency }) => {
  const columns = [
    {
      field: "beneficiaryName",
      headerName: "Beneficiary",
      width: 200,
    },
    {
      field: "totalNetPayout",
      headerName: `Net Payout (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 150,
    },
  ];

  return columns;
};
