import { format } from "date-fns";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";

// Function to calculate schedule dates based on start date and frequency
export default function calculateScheduler(
  watch = () => ({}),
  setFrequencyRows = () => []
) {
  try {
    const startDate = watch("startDate") || new Date();
    const frequency = watch("frequency") || "Monthly"; // Provide default value as "Monthly"

    if (!startDate || !frequency) {
      throw new Error("Start date or frequency is missing.");
    }

    let startDateObj = new Date(startDate);
    const scheduleList = [];
    let periodNumber = 1;

    // Function to get the end date for a given start date and frequency
    function getEndDate(startDate, daysToAdd) {
      try {
        let endDate = new Date(startDate);

        if (
          frequency === "Monthly" ||
          frequency === "Quarterly" ||
          frequency === "Half-Yearly" ||
          frequency === "Yearly"
        ) {
          endDate.setMonth(endDate.getMonth() + daysToAdd);
          // If the day is greater than 28, set the day to the last day of the month
          if (endDate.getDate() < startDate.getDate()) {
            endDate.setDate(0); // Set to the last day of the month
          } else {
            endDate.setDate(startDate.getDate() - 1);
          }
        } else {
          endDate.setDate(endDate.getDate() + daysToAdd);
        }

        return endDate;
      } catch (error) {
        console.error("Error in getEndDate function:", error);
        return new Date(); // Return current date to prevent failure
      }
    }

    // Function to calculate and print the dates based on frequency
    function calculateDates(startDateObj, daysToAdd, periods) {
      try {
        for (let i = 0; i < periods; i++) {
          let endDateObj = getEndDate(startDateObj, daysToAdd);

          const formattedStartDate = format(startDateObj, "yyyy-MM-dd");
          const formattedEndDate = format(endDateObj, "yyyy-MM-dd");

          scheduleList.push({
            id: periodNumber,
            periodNumber: periodNumber,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          });

          // Set the start date for the next period to be the day after the current end date
          periodNumber++;
          startDateObj = new Date(endDateObj);
          startDateObj.setDate(startDateObj.getDate() + 1);
        }
      } catch (error) {
        console.error("Error in calculateDates function:", error);
      }
    }

    // Switch based on frequency
    switch (frequency) {
      case "Monthly":
        calculateDates(startDateObj, 1, 12); // Calculate for 12 months
        break;
      case "Weekly":
        calculateDates(startDateObj, 6, 52); // Calculate for 1 year (52 periods of 6 days each)
        break;
      case "Bi-Weekly":
        calculateDates(startDateObj, 13, 26); // Calculate for 1 year (26 periods of 13 days each)
        break;
      case "Quarterly":
        calculateDates(startDateObj, 3, 4); // Calculate for 1 year (4 periods of 3 months each)
        break;
      case "Half-Yearly":
        calculateDates(startDateObj, 6, 2); // Calculate for 1 year (2 periods of 6 months each)
        break;
      case "Yearly":
        calculateDates(startDateObj, 12, 1); // Calculate for 1 year (12 months)
        break;
      default:
        console.warn("Unknown frequency:", frequency);
        break;
    }

    console.log("scheduleList", scheduleList);

    // Format the startDate and endDate of each schedule
    const formatDateScheduleList = scheduleList.map((schedule) => ({
      ...schedule,
      startDate: formatDateDDMMYYYY(schedule?.startDate ?? ""),
      endDate: formatDateDDMMYYYY(schedule?.endDate ?? ""),
    }));

    setFrequencyRows(formatDateScheduleList ?? []);

    const formattedScheduleList = scheduleList.map(
      ({ periodNumber, startDate, endDate }) => ({
        periodNumber,
        startDate,
        endDate,
      })
    );

    // Return the formatted scheduleList
    return formattedScheduleList ?? [];
  } catch (error) {
    console.error("Error in calculateScheduler function:", error);
    setFrequencyRows([]);
  }
}
