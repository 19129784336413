// Helper function to capitalize each word, handling camel case and spaces
export const toInitCaps = (str) => {
  try {
    // Validate that input is a string
    if (typeof str !== "string") {
      throw new TypeError("Invalid input: Expected a string");
    }

    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case words
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  } catch (error) {
    console.error("Error in toInitCaps function:", error?.message);
    return str; // Return the original string in case of an error
  }
};
