import ApiService from "../../apis/ApiService";
import {
  transformToObject,
  transformToObjectFromObject,
  validateArray,
} from "../../components/config/fieldConfig";

// Extract custom field values from a single object
export const extractCustomFieldApiValues = async (
  screenName = "",
  token = "",
  setTypeOptionsWithId = () => {},
  setTypeOptions = () => {},
  setConfigObj = () => {},
  setFieldsLabel = () => {}
) => {
  try {
    // If token is not available, throw an error
    if (!token) throw new Error("Token not found or invalid");

    // Fetch transaction types
    const typeData = await ApiService.getAllTypes(token, screenName);

    // Validate typeData response and set transactionType options
    if (typeData?.status === 200 && Array.isArray(typeData?.data)) {
      // Check the typeData is an array
      validateArray(typeData?.data, `${screenName}TypeData`);

      // Map and set transactionType options with ID
      const extractedData = typeData?.data.map((transactionType) => ({
        typeValue: transactionType.typeValue,
        id: transactionType.id,
      }));
      setTypeOptionsWithId(extractedData);

      // Extract transaction types for setTypeOptions
      const transactionTypes = typeData?.data.map(
        (transaction) => transaction.typeValue
      );
      validateArray(transactionTypes, `${screenName}TypeData`);
      setTypeOptions(transactionTypes);
    } else {
      // Clear options if the response is not valid
      setTypeOptionsWithId([]);
      setTypeOptions([]);
    }

    // Fetch mappings for POJO
    const response = await ApiService.getMappingsByPojo(screenName, token);
    const result = response?.data;

    let mappingPojoRes;

    // Validate mappings response and transform to object
    if (response?.status === 200 && result) {
      if (
        Array.isArray(result) &&
        result?.length > 0 &&
        result[0]?.customFields
      ) {
        // Transform array result
        mappingPojoRes = transformToObject(
          result[0].customFields,
          result[0].mappingValue
        );
      } else if (typeof result === "object" && !Array.isArray(result)) {
        // Transform object result
        mappingPojoRes = transformToObjectFromObject(result);
      } else {
        throw new Error("Unexpected data structure in mappings");
      }

      // Set config object and field labels
      setConfigObj(result);

      const resKey = Object.keys(mappingPojoRes);
      setFieldsLabel(resKey);
    } else {
      // Clear config and fields if the response is not valid
      setConfigObj({});
      setFieldsLabel([]);
    }

    // Fetch fields for POJO
    const getPojo = await ApiService.getFieldsForPojo(screenName);
    let fieldsForPojo;
    // Validate POJO fields response
    if (
      getPojo?.status === 200 &&
      typeof getPojo?.data === "object" &&
      !Array.isArray(getPojo?.data)
    ) {
      fieldsForPojo = getPojo?.data;
    } else {
      fieldsForPojo = {};
    }

    // Return the fetched data
    return { getPojo: fieldsForPojo, typeData: typeData?.data, mappingPojoRes };
  } catch (error) {
    console.error("Error extracting custom field values:", error);

    // Handle error case by resetting states
    setTypeOptionsWithId([]);
    setTypeOptions([]);
    setConfigObj({});
    setFieldsLabel([]);

    // Optionally return an empty object or reject the promise with an error
    return {}; // or return Promise.reject(error);
  }
};
