export const processFormData = (
  formData = {},
  fields = [],
  dynamicFormFields = [],
  typeOptionsWithId = [],
  type = "",
  typeId = ""
) => {
  try {
    const mainFormData = { ...formData };
    const customFields = {};

    // Find the type ID
    const typeWithId = typeOptionsWithId.find(
      (typeOptions) => typeOptions.typeValue === formData[type]
    );
    mainFormData[typeId] = typeWithId ? typeWithId.id : null;

    // Process custom fields
    Object.entries(formData).forEach(([key, value]) => {
      const customField =
        fields.find((field) => field.name === key) ||
        dynamicFormFields.find((field) => field.name === key);

      if (customField && customField.isCustom) {
        customFields[key] = {
          [key]: value,
          dataType: customField.type,
          isMandatory: customField.required,
        };
        delete mainFormData[key]; // Remove custom fields from main form data
      }
    });

    // If there are custom fields, add them to the mainFormData
    if (Object.keys(customFields).length > 0) {
      mainFormData.customFields = customFields;
    }

    return { mainFormData, customFields };
  } catch (error) {
    // Handle any uncaught errors
    console.error("An error occurred while processing form data:", error);

    // Optionally, return fallback values
    return { mainFormData: {}, customFields: {} };
  }
};
