import { Box, Button, Container, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const PaymentSuccess = () => {
  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <CheckCircleOutline sx={{ fontSize: 80, color: "green" }} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Payment Successful!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Your payment has been processed successfully.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button variant="contained" color="primary" href="/billing">
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
