import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  CloseTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import { preventNonNumericInput } from "../../config/fieldConfig";
import { COMM_PERC_ERROR } from "../../config/toastMessage";

export default function RoleUPForm({
  open = false,
  onClose = () => {},
  roleData = {},
  onRoleData = () => {},
  setRoleUpChecked = () => {},
}) {
  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { fields, remove, insert, update } = useFieldArray({
    control,
    name: "dynamicFields",
  });

  useEffect(() => {
    try {
      const defaultFields = [{ name: "" }]; // Your default value
      const fieldsToSet =
        Object.keys(roleData).length > 0
          ? Object.keys(roleData).map((key) => ({ name: roleData[key] }))
          : defaultFields;
      // Check if data is an array and has elements
      if (!Array.isArray(fieldsToSet)) {
        throw new Error("RowData is not available or empty");
      }
      setValue("dynamicFields", fieldsToSet);
      reset({ dynamicFields: fieldsToSet }); // Reset the form with the default and dynamic fields
    } catch (error) {
      console.error(error); // Log error to console
    }
  }, [roleData, setValue, reset]);

  useEffect(() => {
    reset(); // Reset the form with the default and dynamic fields
  }, [onClose, reset]);

  const onSubmit = (data) => {
    try {
      const filteredData = data.dynamicFields.filter(
        (field) => field.name !== ""
      );
      const transformedData = filteredData.reduce((acc, field, index) => {
        acc[`level ${index + 1}`] = field.name;
        return acc;
      }, {});

      // Check if formattedData is an object
      if (
        !transformedData ||
        typeof transformedData !== "object" ||
        Array.isArray(transformedData)
      ) {
        throw new Error("Data is not available or empty");
      }
      onRoleData(transformedData);
      onClose(false);
      if (Object.keys(transformedData).length === 0) {
        setRoleUpChecked(false);
      }
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(error?.message); // Display the exact error message in a toast
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          width: "400px", // Adjust this value as needed
          maxWidth: "400px", // Ensure that the maximum width is set
        },
      }}
    >
      <DialogTitle>
        Role Up Commission
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={() => {
            onClose();
            if (Object.keys(roleData).length === 0) {
              setRoleUpChecked(false);
            }
          }}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          {/* for RoleUp */}
          {fields.map((field, index) => (
            <Grid container spacing={0.5} key={index} pb={1}>
              <Grid item xs={12} sm={2} mt={2}>
                <Typography>{`Level ${index + 1}`}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <TextField
                  name={`dynamicFields.${index}.name`}
                  label="Comm Percentage"
                  type="number"
                  required={!!watch(`dynamicFields[${index}].name`)}
                  onKeyDown={(e) => {
                    if (`dynamicFields[${index}].name`) {
                      preventNonNumericInput(e);
                    }
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="off"
                  sx={{
                    "& input[type=number]": {
                      appearance: "textfield", // Remove the default appearance
                      MozAppearance: "textfield", // Remove for Firefox
                      WebkitAppearance: "none", // Remove for Webkit browsers
                      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                        {
                          display: "none", // Hide webkit spin buttons
                        },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  {...register(`dynamicFields[${index}].name`, {
                    required: COMM_PERC_ERROR, // Add required validation
                  })}
                  onChange={(e) => {
                    const newFields = [...fields];
                    newFields[index].name = e.target.value;
                    setValue(`dynamicFields[${index}].name`, e.target.value); // Set the value
                    trigger(`dynamicFields[${index}].name`); // Trigger revalidation
                    setValue("dynamicFields", newFields);
                  }}
                  value={field.name}
                  error={!!errors?.dynamicFields?.[index]?.name} // Check if there's an error for this field
                  helperText={errors?.dynamicFields?.[index]?.name?.message} // Display the error message
                  FormHelperTextProps={{ sx: { mb: -3 } }}
                />
              </Grid>
              <Grid item xs={12} sm={3} mt={1}>
                <IconButton
                  onClick={async () => {
                    const isValid = await trigger([
                      `dynamicFields[${index}].name`,
                    ]);
                    const FirstIndex =
                      index.length > 0 ? index[index.length + 1] : +1;
                    const selectedValue = watch(`dynamicFields[${index}].name`);
                    const selected1RowValue = watch(
                      `dynamicFields[${FirstIndex}].name`
                    );

                    // Check if fields are valid and not empty before adding a row
                    if (
                      isValid &&
                      selectedValue &&
                      selected1RowValue === undefined
                    ) {
                      insert(index + 1, { name: "" });
                    } else {
                      if (!watch(`dynamicFields[${index}].name`)) {
                        setError(`dynamicFields[${index}].name`, {
                          type: "manual",
                          message: COMM_PERC_ERROR,
                        });
                      } else if (
                        selectedValue &&
                        !watch(`dynamicFields[${FirstIndex}].name`)
                      ) {
                        setError(`dynamicFields[${FirstIndex}].name`, {
                          type: "manual",
                          message: COMM_PERC_ERROR,
                        });
                      } else if (
                        isValid &&
                        selectedValue &&
                        selected1RowValue
                      ) {
                        insert(index + 1, { name: "" });
                      }
                    }
                  }}
                  color="success"
                >
                  <AddCircleOutlineTwoTone />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => {
                    if (fields.length > 1) {
                      // Clear errors first, then remove the row
                      clearErrors(`dynamicFields[${index}]`);
                      remove(index);
                    } else {
                      // Clear the row by updating it and clear errors
                      update(index, { name: "" });
                      clearErrors(`dynamicFields[${index}]`);
                    }
                  }}
                >
                  <RemoveCircleOutlineTwoTone />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              {roleData ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
