import FullScreenLoader from "../../common/LoadingScreen";
import CustomDataGrid from "../../../utils/CustomDataGrid";
import ScheduleForm from "./form";
import { ScheduleService } from "./service";

export default function Schedule() {
  const {
    screenName = "",
    fields = [],
    rows = [],
    columns = [],
    open = false,
    editFormOpen = false,
    editItemId = null,
    editFormData = {},
    timeZone = "",
    setEditFormOpen = () => {},
    addFormOpen = false,
    addFormClose = () => {},
    loading = false,
    submitLoading = false,
    onSubmitForm = () => {},
  } = ScheduleService() || {};

  return (
    <>
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader loading={submitLoading} />

      {/* Data grid to display the list with CRUD operations */}
      <CustomDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        loading={loading}
        toolbarProps={{ displayName: screenName, screenName, addFormOpen }}
      />

      {/* Handle both add and edit form display */}
      <ScheduleForm
        screenName={screenName} // Pass the screen name to the form
        open={editFormOpen || open} // Open the form if editing or adding
        onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose} // Function to close the form
        mode={editFormOpen ? "edit" : "add"} // Set mode to edit or add
        editItemId={editFormOpen ? editItemId : undefined} // Pass ID if editing
        editFormData={editFormOpen ? editFormData : undefined} // Pass data if editing
        fields={fields ?? []} // Pass form fields to the form
        timeZone={timeZone} // Pass time zone information
        onSubmitForm={onSubmitForm} // Handle form submission
      />
    </>
  );
}
