import { Box } from "@mui/material";
import SideNav from "./SideNav";

function VerticalLayout() {
  return (
    <>
      <Box>
        <SideNav />
      </Box>
    </>
  );
}

export default VerticalLayout;
