import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import SectionRight from "./sectionRight";
import DesktopMenu from "./desktopMenu";
import { MenuTwoTone } from "@mui/icons-material";
import RenderMobileView from "./mobileMenu";

export default function TopNavBar({
  cfTransLogo,
  orgName,
  userName,
  logout,
  menuItems,
  settingMenuItems,
  setIsAuthenticated,
  setRes,
  isMonitor,
  isSuperAdmin,
}) {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const displayOrgName =
    orgName.length > 10 ? `${orgName.slice(0, 10)}...` : orgName;

  const displayUserName =
    userName.length > 10 ? `${userName.slice(0, 10)}...` : userName;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenMenu = (event, menu) => {
    console.log(event, menu);
    setCurrentMenu(menu);

    if (event && event.currentTarget) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }

    // Check if the clicked menu item has children and toggle dropdown
    if (menu.children) {
      setOpenDropdown(openDropdown === menu ? null : menu);
    }
  };

  const handleCloseMenu = () => {
    setCurrentMenu(null);
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const location = useLocation();

  useEffect(() => {
    return () => {
      Swal.close(); // Close the Swal dialog
    };
  }, [location.pathname]); // Depend on the pathname to trigger the effect

  const currentPath = location.pathname.split("/")[1]; // Get the current path

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {/* Mobile Menu Button */}
          <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleToggleDrawer}
              color="inherit"
            >
              <MenuTwoTone />
            </IconButton>
          </Box>

          {/* Left Portion */}
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              flexGrow: 1, // Ensures left portion takes space
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/dashboard"
              sx={{
                mr: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={cfTransLogo} alt="compFact logo" className="h-12" />
            </Typography>
          </Box>

          {/* Middle Portion */}
          <Box
            sx={{
              flexGrow: 2, // Take space equally in the middle portion
              display: { xs: "flex", sm: "flex", md: "none" },
              justifyContent: "center", // Centers the middle section
            }}
          >
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/dashboard"
              sx={{
                display: { xs: "flex", sm: "flex", md: "none" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={cfTransLogo} alt="compFact logo" className="h-9" />
            </Typography>
          </Box>

          {/* Desktop Display - Menu Items */}
          <Box
            sx={{
              flexGrow: 1, // Increase space for menu items
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "center", // Center items for desktop
            }}
          >
            <DesktopMenu
              menuItems={menuItems}
              currentPath={currentPath}
              handleOpenMenu={handleOpenMenu}
              currentMenu={currentMenu}
              anchorEl={anchorEl}
              handleCloseMenu={handleCloseMenu}
            />
          </Box>

          {/* Right Portion */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1, // Right portion to be at the end
              alignItems: "center",
            }}
          >
            <SectionRight
              isMonitor={isMonitor}
              isSuperAdmin={isSuperAdmin}
              orgName={orgName}
              displayOrgName={displayOrgName}
              userName={userName}
              displayUserName={displayUserName}
              handleOpenUserMenu={handleOpenUserMenu}
              anchorElUser={anchorElUser}
              setAnchorElUser={setAnchorElUser}
              handleCloseUserMenu={handleCloseUserMenu}
              settingMenuItems={settingMenuItems}
              logout={logout}
              setIsAuthenticated={setIsAuthenticated}
              setRes={setRes}
            />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Box
        sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <RenderMobileView
          drawerOpen={drawerOpen}
          handleToggleDrawer={handleToggleDrawer}
          menuItems={menuItems}
          currentPath={currentPath}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
        />
      </Box>
    </>
  );
}
