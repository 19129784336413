import { useEffect, useRef, useState } from "react";
import { BillingService } from "../Billing/service";
import PricingCard from "../../../utils/PricingCard";
import UpgradeDialog from "./upgradeDialog";

export default function Pricing() {
  const {
    token = "",
    planTitle = "",
    noOfBeneficiary = 1,
    fetchData = () => {},
    planFetchData = async () => [],
  } = BillingService() || {};

  // State to store the pricing plans
  const [pricingPlans, setPricingPlans] = useState([]);
  // State to control the UpgradeDialog visibility
  const [open, setOpen] = useState(false);
  // State for the selected plan
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Ref to track the initial render
  const isInitialRender = useRef(true);

  // Fetch pricing plans on initial render
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        // Attempt to fetch pricing plans
        const plans = await planFetchData();
        // Ensure it's an array, or set to an empty array
        setPricingPlans(Array.isArray(plans) ? plans : []);
      } catch (error) {
        console.error("Error fetching plans:", error);
        setPricingPlans([]); // Fallback to an empty array if error occurs
      }
    };

    if (isInitialRender.current) {
      isInitialRender.current = false; // Ensure this block only runs on initial render
      fetchPlans(); // Fetch plans on the first render
    }
  }, [planFetchData]); // Re-fetch plans if planFetchData changes

  // Find the current pricing plan based on the title
  const currentPlan = pricingPlans?.find((plan) => plan.title === planTitle);

  // Extract ID or set null
  const currentPlanId = currentPlan ? currentPlan.id : null;

  // Open the UpgradeDialog when a plan is clicked
  const handleOpen = (plan) => {
    setSelectedPlan(plan); // Set the selected plan
    setOpen(true); // Open the dialog
  };

  // Close the UpgradeDialog
  const handleClose = () => {
    setOpen(false); // Close the dialog
    setSelectedPlan(null); // Clear the selected plan
  };

  return (
    <>
      {/* Render the Pricing Cards with available plans */}
      <PricingCard
        pricingPlans={pricingPlans} // Array of pricing plans to display
        onCardClick={handleOpen} // Function to handle card click to open dialog
        currentPlanId={currentPlanId} // ID of the currently selected plan
      />

      {/* Conditionally render UpgradeDialog for selected plan */}
      {selectedPlan && (
        <UpgradeDialog
          open={open} // Controls whether the dialog is open
          handleClose={handleClose} // Function to close the dialog
          plan={selectedPlan} // The selected plan to display in the dialog
          noOfBeneficiary={noOfBeneficiary} // Number of beneficiaries for the selected plan
          token={token} // Authentication token for user session
          fetchData={fetchData} // Function to fetch updated data after selection
        />
      )}
    </>
  );
}
