import { CURRENCY_NAME } from "../components/config/sessionStorage";
import { getDefaultLocale } from "./localeUtils";

/**
 * Formats a given amount with or without a currency symbol.
 *
 * @param {number|string} amount - The amount to format.
 * @param {string} currency - Currency code (e.g., "USD", "INR"). Defaults to app's currency if not provided.
 * @param {boolean} symbol - Whether to include the currency symbol in the output. Defaults to `true`.
 * @returns {string} - Formatted amount string or an empty string in case of an error.
 */
export const formatCurrencySymbol = (amount = 0, currency, symbol = true) => {
  try {
    // Use the application's default currency if none is provided
    const currencyData = currency || CURRENCY_NAME();

    // Clean up the amount if it is provided as a string (e.g., remove commas or other characters)
    const cleanedAmount =
      typeof amount === "string" ? amount.replace(/[^0-9.]/g, "") : amount;

    // Convert the cleaned amount to a number
    const numericAmount = parseFloat(cleanedAmount);

    // If conversion fails, log a warning and return an empty string
    if (isNaN(numericAmount)) {
      console.warn(
        "Invalid amount provided to formatCurrencySymbol. Expected a number or a valid numeric string:",
        amount
      );
      return ""; // Return a fallback value
    }

    // Determine the appropriate locale for the given currency
    const locale = getDefaultLocale(currencyData);

    // Create a formatter instance for the specified locale and currency
    const formatter = new Intl.NumberFormat(locale, {
      style: symbol ? "currency" : "decimal", // Include currency symbol if `symbol` is true
      currency: currencyData,
      minimumFractionDigits: 2, // Ensure two decimal places
      maximumFractionDigits: 2,
      currencyDisplay: "symbol", // Display the currency symbol
    });

    // Extract the currency symbol for the provided currency
    // const currencySymbol = formatter
    //   .format(0) // Format a small amount to extract the symbol
    //   .replace(/\d|\.|,|/g, "") // Remove digits, periods, and commas
    //   .trim(); // Remove any extra whitespace

    // Log the extracted currency symbol for debugging
    // console.log(`Currency symbol for ${currencyData}: ${currencySymbol}`);

    // Return the formatted amount
    return formatter.format(numericAmount);
  } catch (error) {
    // Log any unexpected errors during the formatting process
    console.error(
      "An error occurred while formatting the currency:",
      { amount, currency, symbol },
      error
    );

    // Return an empty string as a fallback
    return "";
  }
};

/**
 * Format an amount with the default currency symbol
 * @param {number} amount - The amount to format
 * @returns {string} - Formatted string with default currency
 */
export const formatCurrency = (amount) => {
  try {
    // Get the current currency name
    const currencyNameData = CURRENCY_NAME();

    // Get the default locale for the currency
    const locale = getDefaultLocale(currencyNameData);

    // Create and return a formatter instance
    const formatter = new Intl.NumberFormat(locale, {
      currency: currencyNameData,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencyDisplay: "symbol",
    });

    return formatter.format(amount);
  } catch (error) {
    console.error("Error formatting currency:", amount, error);
    return ""; // Fallback for errors
  }
};
