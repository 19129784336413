import {
  COMMISSION_BY_CUSTOMER_DISPLAY_NAME,
  COMMISSION_BY_CUSTOMER_SCREEN,
  COMMISSION_BY_PRODUCT_DISPLAY_NAME,
  COMMISSION_BY_PRODUCT_SCREEN,
} from "../config/constants";
import { OtherReportService } from "../pages/OtherReports/service";

export const CommByProductService = () => {
  // Constants for screen identifier
  const screenName = COMMISSION_BY_PRODUCT_SCREEN;
  // Constants for display screen name UI identifier
  const displayName = COMMISSION_BY_PRODUCT_DISPLAY_NAME;
  // Call the `OtherReportService` function with `custProdName`
  return OtherReportService({
    custProdName: "productName",
    screenName,
    displayName,
  });
};

export const CommByCustomerService = () => {
  // Constants for screen identifier
  const screenName = COMMISSION_BY_CUSTOMER_SCREEN;
  // Constants for display screen name UI identifier
  const displayName = COMMISSION_BY_CUSTOMER_DISPLAY_NAME;
  // Call the `OtherReportService` function with `custProdName`
  return OtherReportService({
    custProdName: "customerName",
    screenName,
    displayName,
  });
};
