import { Button } from "@mui/material";
import { AddTwoTone } from "@mui/icons-material";
import { useToolbarConfig } from "./ToolbarMap";
import { getAccessLevels } from "../accessLevels";

export default function AddButton({ screenName = "", addFormOpen = () => {} }) {
  // 1) Check if screenName or addFormOpen is missing
  if (!screenName || !addFormOpen) return null;

  // 2) Check if the showAddButton exists for the given screenName in the config
  const toolbarConfig = useToolbarConfig[screenName];

  if (!toolbarConfig || typeof toolbarConfig?.showAddButton === "undefined") {
    return null;
  }

  // 3) Get access levels for the specified resource using a utility function
  const { writeAccess } = getAccessLevels(screenName);

  // 4) Only show the button if showAddButton is true and writeAccess is granted
  const isDisplay = toolbarConfig?.showAddButton && writeAccess;
  if (!isDisplay) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      startIcon={<AddTwoTone />}
      onClick={addFormOpen}
    >
      Add
    </Button>
  );
}
